import { useCallback, useMemo, useState } from "react";

import { Box } from "@chakra-ui/react";
import produce from "immer";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import {
  Wizard,
  WizardNavigation,
  WizardProps,
  WizardStepList,
} from "@/components/disclosure";
import { useReferer, useServerState } from "@/hooks";
import { useNavigate } from "@/router";
import { DraftCampaign } from "@/screens/campaigns/types";
import { useCompleteTour } from "@/services/hooks/account";
import { ArrayUtils } from "@/utils";

import { CampaignWizardCompleteModal } from "./campaign-wizard-complete-modal";
import {
  AudienceStep,
  BillingStep,
  ScheduleStep,
  SequenceStep,
  audienceStepDefaultValues,
  billingStepDefaultValues,
  scheduleStepDefaultValues,
  sequenceStepDefaultValues,
} from "./steps";
import { CampaignWizardFieldValues } from "./types";

export interface CampaignWizardProps extends Partial<WizardProps> {}

export const CampaignWizard = (props: CampaignWizardProps) => {
  const navigate = useNavigate();

  const { refererUri } = useReferer({
    defaultUri: "/campaigns",
    defaultDisplayName: "Campaigns",
  });

  const [draftCampaignId] = useQueryParam(
    "cid",
    withDefault(StringParam, nanoid())
  );

  const [draftCampaigns = [], setDraftCampaigns] =
    useServerState<DraftCampaign[]>("draft-campaigns");

  const draftCampaign = useMemo(
    () => draftCampaigns.find((x) => x.id === draftCampaignId),
    [draftCampaignId, draftCampaigns]
  );

  const { mutateAsync: completeTour } = useCompleteTour();

  const [wizardData, setWizardData] = useState<CampaignWizardFieldValues>({
    ...audienceStepDefaultValues,
    ...sequenceStepDefaultValues,
    ...scheduleStepDefaultValues,
    ...billingStepDefaultValues,
    ...draftCampaign,
  });

  const handleWizardChange = useCallback(
    async (data: CampaignWizardFieldValues) => {
      setWizardData(data);

      if (isEqual(data, wizardData)) {
        return;
      }

      setDraftCampaigns((prev) => [
        {
          id: draftCampaignId,
          status: "draft",
          title:
            draftCampaign?.title ??
            `Draft Campaign (${DateTime.now().toLocaleString(
              DateTime.DATETIME_SHORT
            )})`,
          ...data,
        },
        ...(prev?.filter((x) => x.id !== draftCampaignId) ?? []),
      ]);
    },
    [wizardData, setDraftCampaigns, draftCampaignId, draftCampaign?.title]
  );

  const handleWizardComplete = useCallback(async () => {
    try {
      await completeTour({ name: "campaignWizard" });
    } catch {
      /* empty */
    }

    setDraftCampaigns((prev) =>
      produce(prev, (draftState) => {
        ArrayUtils.spliceWhere(draftState, (x) => x.id === draftCampaignId);
      })
    );
  }, [completeTour, draftCampaignId, setDraftCampaigns]);

  return (
    <>
      <Wizard
        {...props}
        data={wizardData}
        onChange={handleWizardChange}
        onComplete={handleWizardComplete}
      >
        <Box w="full" maxW="container.sm" mx="auto">
          <WizardNavigation />

          <WizardStepList mt={8}>
            <AudienceStep name="Audience" />
            <SequenceStep name="Sequence" />
            <ScheduleStep name="Schedule" />
            <BillingStep name="Billing" />
          </WizardStepList>
        </Box>

        <Box
          id="campaign-wizard__action-bar-portal-ref"
          position={{ base: "relative", md: "sticky" }}
          bottom={0}
          w="full"
          maxW="container.xl"
          mt="auto"
          pt={8}
          pb={2}
        />
      </Wizard>

      <CampaignWizardCompleteModal
        onSubmit={() => {
          navigate(refererUri);
        }}
      />
    </>
  );
};
