import { tabsAnatomy } from "@chakra-ui/anatomy";
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

export const Tabs: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    tab: {
      fontWeight: "semibold",
    },
  }),
  variants: {
    subtle: definePartsStyle({
      tab: {
        borderBottom: "2px solid",
        borderColor: "transparent",
        color: "gray.500",
        mb: "-2px",
        py: 2,
        _selected: {
          color: "gray.900",
          borderColor: "currentColor",
        },
      },
      tablist: {
        borderBottom: "2px solid",
        borderColor: "inherit",
      },
    }),
  },
});
