import { AxiosInstance } from "axios";
import { isNil } from "lodash";

import { API } from "@/config";
import { AuthService } from "@/services";

export const addAuthInterceptors = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async (request) => {
    if (isNil(request.baseURL) || ![API.ENDPOINT].includes(request.baseURL)) {
      return Promise.resolve(request);
    }

    const accessToken = await AuthService.getAccessToken();

    if (!isNil(accessToken)) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    return Promise.resolve(request);
  });
};
