import { PopoverFooter, PopoverFooterProps } from "@chakra-ui/react";

export interface FilterFooterProps extends PopoverFooterProps {}

export const FilterFooter = (props: FilterFooterProps) => {
  return (
    <PopoverFooter
      position="sticky"
      bottom={0}
      px={0}
      py={2}
      background="white"
      {...props}
    />
  );
};
