import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof ConversationService.scheduleMessage>[0];
type Data = AsyncReturnType<typeof ConversationService.scheduleMessage>;
type Error = unknown;

export const useScheduleMessage = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    ConversationService.scheduleMessage,
    options
  );

  return result;
};
