import {
  QueryKey,
  QueryClient as ReactQueryClient,
  hashQueryKey,
  useQueryClient as useReactQueryClient,
} from "@tanstack/react-query";

const pausedQueries = new Set();

const pauseQuery = (queryKey: QueryKey) => {
  const hashedQueryKey = hashQueryKey(queryKey);
  pausedQueries.add(hashedQueryKey);
};

const resumeQuery = (queryKey: QueryKey) => {
  const hashedQueryKey = hashQueryKey(queryKey);
  pausedQueries.delete(hashedQueryKey);
};

const isQueryPaused = (queryKey: QueryKey) => {
  const hashedQueryKey = hashQueryKey(queryKey);
  return pausedQueries.has(hashedQueryKey);
};

export type QueryClient = ReactQueryClient & {
  pauseQuery: typeof pauseQuery;
  resumeQuery: typeof resumeQuery;
  isQueryPaused: typeof isQueryPaused;
};

export const useQueryClient = (): ReactQueryClient & {
  pauseQuery: typeof pauseQuery;
  resumeQuery: typeof resumeQuery;
  isQueryPaused: typeof isQueryPaused;
} => {
  const queryClient = useReactQueryClient() as QueryClient;

  queryClient.pauseQuery = pauseQuery;
  queryClient.resumeQuery = resumeQuery;
  queryClient.isQueryPaused = isQueryPaused;

  return queryClient;
};
