import { RichTextValue } from "@/types";

import { CreateStep } from "./create-step";

export interface EditStepProps {
  defaultValue?: RichTextValue;
  onChange: (value: RichTextValue) => void;
}

export const EditStep = ({ defaultValue, onChange }: EditStepProps) => {
  return <CreateStep defaultValue={defaultValue} onCreate={onChange} />;
};
