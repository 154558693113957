import { ButtonGroup, ModalFooter } from "@chakra-ui/react";

import { Button } from "@/components/form";
import { useTemplates } from "@/services/hooks/campaigns";
import { useMediaQuery } from "@/ui/hooks";

export type Action = "start" | "create" | "pick";

export interface ChooseStepProps {
  onChoose: (action: Action) => void;
}

export const ChooseStep = ({ onChoose }: ChooseStepProps) => {
  const [isMdScreen] = useMediaQuery("md");

  const { data: hasTemplates, isLoading: isLoadingHasTemplates } = useTemplates(
    {
      select: (x) => !!x.elements.length,
    }
  );

  return (
    <ModalFooter justifyContent="flex-start">
      <ButtonGroup
        orientation={isMdScreen ? "horizontal" : "vertical"}
        w="full"
      >
        <Button colorScheme="primary" onClick={() => onChoose("create")}>
          Start from scratch
        </Button>

        <Button
          loadingText="Start from template"
          variant="outline"
          spinnerPlacement="end"
          isLoading={isLoadingHasTemplates}
          isDisabled={!hasTemplates}
          onClick={() => onChoose("pick")}
        >
          Start from template
        </Button>
      </ButtonGroup>
    </ModalFooter>
  );
};
