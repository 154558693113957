import { AsyncReturnType } from "type-fest";

import { ServerStorage } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryFnData = AsyncReturnType<typeof ServerStorage.get>;
type Error = unknown;

const queryKey = () => ["common", "server-storage"];

export const useServerStorage = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    ServerStorage.get,
    options
  );

  return result;
};

useServerStorage.queryKey = queryKey;
