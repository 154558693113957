import { RequestData, RequestParams, fetchApi } from "./openapi";

const getRegions = ({
  search,
  skip = 0,
  limit,
}: RequestParams<"/typeahead/regions", "get">) => {
  const result = fetchApi("/typeahead/regions", "get", {
    params: { query: { search, skip, limit } },
  });

  return result;
};

const getOccupations = ({
  search,
  skip = 0,
  limit,
}: RequestParams<"/typeahead/occupations", "get">) => {
  const result = fetchApi("/typeahead/occupations", "get", {
    params: { query: { search, skip, limit } },
  });

  return result;
};

const createOccupation = ({
  label,
}: RequestData<"/typeahead/occupations", "post">) => {
  const result = fetchApi("/typeahead/occupations", "post", {
    data: { label },
  });

  return result;
};

const getCompanies = ({
  search,
  skip = 0,
  limit,
}: RequestParams<"/typeahead/companies", "get">) => {
  const result = fetchApi("/typeahead/companies", "get", {
    params: { query: { search, skip, limit } },
  });

  return result;
};

const createCompany = ({
  label,
}: RequestData<"/typeahead/companies", "post">) => {
  const result = fetchApi("/typeahead/companies", "post", {
    data: { label },
  });

  return result;
};

const getIndustries = ({
  search,
  skip = 0,
  limit,
}: RequestParams<"/typeahead/industries", "get">) => {
  const result = fetchApi("/typeahead/industries", "get", {
    params: { query: { search, skip, limit } },
  });

  return result;
};

const getSeniorities = () => {
  const result = fetchApi("/typeahead/seniorities", "get");

  return result;
};

const getFunctions = () => {
  const result = fetchApi("/typeahead/functions", "get");

  return result;
};

const getHeadcounts = () => {
  const result = fetchApi("/typeahead/headcounts", "get");

  return result;
};

const getExperiences = () => {
  const result = fetchApi("/typeahead/experiences", "get");

  return result;
};

const getLanguages = () => {
  const result = fetchApi("/typeahead/languages", "get");

  return result;
};

export const TypeaheadService = Object.freeze({
  getRegions,
  getOccupations,
  getCompanies,
  createOccupation,
  createCompany,
  getIndustries,
  getSeniorities,
  getFunctions,
  getHeadcounts,
  getExperiences,
  getLanguages,
});
