import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  VStack,
} from "@chakra-ui/react";
import { MdLabelImportant } from "react-icons/md";

import { Text } from "@/components/display";
import { Switch } from "@/components/form";

import { useConversationFilterProvider } from "./conversation-filter-context";

export const StatusTabPanel = () => {
  const { value, onChange } = useConversationFilterProvider();

  return (
    <VStack spacing={2} alignItems="flex-start" w="full">
      <Box w="full">
        <FormLabel fontWeight="medium" mr={0}>
          Mark conversations as important{" "}
          <Icon as={MdLabelImportant} w={3} h={3} color="primary.500" /> when
          prospects are...
        </FormLabel>

        <Slider
          min={20}
          max={80}
          step={5}
          value={value.interest}
          onChange={(x) => onChange({ ...value, interest: x })}
        >
          <SliderTrack bg="gray.200">
            <SliderFilledTrack bg="blue.500" />
          </SliderTrack>

          <SliderThumb boxSize={4} border="4px" borderColor="blue.500" />
        </Slider>

        <Flex justifyContent="space-between" fontSize="sm">
          <Text>less interested</Text>
          <Text>highly interested</Text>
        </Flex>
      </Box>

      <Divider />

      <Flex w="full" justifyContent="space-between" gap={2}>
        <FormLabel fontWeight="medium" mr={0} mb={0}>
          Sort by recent conversations
        </FormLabel>

        <Switch
          value={value.recent}
          defaultValue={value.recent}
          onChange={(x) => onChange({ ...value, recent: x })}
        />
      </Flex>

      <Divider />

      <Flex w="full" justifyContent="space-between" gap={2}>
        <FormLabel fontWeight="medium" mr={0} mb={0}>
          View important conversations only
        </FormLabel>

        <Switch
          value={value.important}
          defaultValue={value.important}
          onChange={(x) => onChange({ ...value, important: x })}
        />
      </Flex>
    </VStack>
  );
};
