import { useMemo, useState } from "react";

import { Box, BoxProps, Spinner } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { AxisOptions, Chart } from "react-charts";
import { DateRange } from "react-day-picker";

import { DatePicker } from "@/components/form";
import {
  useConnectedReport,
  useReachReport,
  useRespondedReport,
} from "@/services/hooks/reports";

export interface CampaignChartProps extends BoxProps {
  campaignId: number;
}

export const CampaignChart = ({ campaignId, ...props }: CampaignChartProps) => {
  const today = DateTime.local();

  const fallbackDateRange = {
    from: today.startOf("month").toJSDate(),
    to: today.endOf("month").toJSDate(),
  };

  const [dateRange = fallbackDateRange, setDateRange] = useState<
    DateRange | undefined
  >(fallbackDateRange);

  const hasSelectedDateRange = !!dateRange?.from && !!dateRange.to;

  const { data: reach, isLoading: isLoadingReach } = useReachReport(
    {
      campaignIds: [campaignId],
      from: hasSelectedDateRange
        ? dateRange.from?.toISOString()
        : fallbackDateRange.from.toISOString(),
      to: hasSelectedDateRange
        ? dateRange.to?.toISOString()
        : fallbackDateRange.to.toISOString(),
    },
    {
      enableSkeletonData: true,
      select: (data) => ({
        label: "Reach",
        data: data.report.map((x) => ({
          date: new Date(x.date),
          count: x.count,
        })),
      }),
    }
  );

  const { data: connected, isLoading: isLoadingConnected } = useConnectedReport(
    {
      campaignIds: [campaignId],
      from: hasSelectedDateRange
        ? dateRange.from?.toISOString()
        : fallbackDateRange.from.toISOString(),
      to: hasSelectedDateRange
        ? dateRange.to?.toISOString()
        : fallbackDateRange.to.toISOString(),
    },
    {
      enableSkeletonData: true,
      select: (data) => ({
        label: "Connected",
        data: data.report.map((x) => ({
          date: new Date(x.date),
          count: x.count,
        })),
      }),
    }
  );

  const { data: responded, isLoading: isLoadingResponded } = useRespondedReport(
    {
      campaignIds: [campaignId],
      from: hasSelectedDateRange
        ? dateRange.from?.toISOString()
        : fallbackDateRange.from.toISOString(),
      to: hasSelectedDateRange
        ? dateRange.to?.toISOString()
        : fallbackDateRange.to.toISOString(),
    },
    {
      enableSkeletonData: true,
      select: (data) => ({
        label: "Responded",
        data: data.report.map((x) => ({
          date: new Date(x.date),
          count: x.count,
        })),
      }),
    }
  );

  const primaryAxis = useMemo(
    (): AxisOptions<NonNullable<typeof reach>["data"][number]> => ({
      scaleType: "time",
      getValue: (datum) => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<NonNullable<typeof reach>["data"][number]>[] => [
      {
        elementType: "line",
        min: 0,
        max: 15,
        getValue: (datum) => datum.count,
      },
    ],
    []
  );

  return (
    <Box
      w="full"
      p={2}
      background="white"
      border="1px solid"
      borderColor="inherit"
      borderRadius="md"
      {...props}
    >
      <Box mb={2}>
        <DatePicker
          value={dateRange}
          mode="range"
          size="xs"
          min={2}
          max={31}
          to={today.endOf("month").toJSDate()}
          withPresets
          onChange={setDateRange}
        />
      </Box>

      <Box position="relative" w="full" h="200px">
        <Chart
          options={{
            data: [reach!, connected!, responded!],
            primaryAxis,
            secondaryAxes,
          }}
        />

        {(isLoadingReach || isLoadingConnected || isLoadingResponded) && (
          <Box
            position="absolute"
            inset={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            w="full"
            h="full"
            background="whiteAlpha.800"
            borderRadius="md"
          >
            <Spinner size="sm" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
