import {
  Flex,
  FlexProps,
  UseRadioGroupProps,
  useRadioGroup,
} from "@chakra-ui/react";

import { withController } from "@/components/form/hocs";

import { RadioGroupProvider } from "./radio-group-context";

export interface RadioGroupProps
  extends Omit<FlexProps, keyof UseRadioGroupProps>,
    UseRadioGroupProps {}

export const RadioGroup = ({ children, ...props }: RadioGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup(props);

  return (
    <RadioGroupProvider value={{ getRadioProps }}>
      <Flex wrap="wrap" gap={1} {...getRootProps()}>
        {children}
      </Flex>
    </RadioGroupProvider>
  );
};

export const RadioGroupFormField = withController(RadioGroup);
