import { useCheckboxGroup as useChakraCheckboxGroup } from "@chakra-ui/react";

import { createContext } from "@/ui/utils";

export interface CheckboxGroupContextProps {
  getCheckboxProps?:
    | ReturnType<typeof useChakraCheckboxGroup>["getCheckboxProps"]
    | null;
}

export const [CheckboxGroupProvider, useCheckboxGroupContext] =
  createContext<CheckboxGroupContextProps>({
    name: "CheckboxGroupContext",
    strict: false,
    defaultValue: {
      getCheckboxProps: null,
    },
  });
