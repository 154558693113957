import { createContext, useEffect } from "react";

import * as fullstory from "@fullstory/browser";
import * as sentry from "@sentry/react";
import { isNil } from "lodash";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";
import smartlook from "smartlook-client";

import { FULLSTORY, MIXPANEL, SMARTLOOK } from "@/config";
import { WithChildren } from "@/ui/types";

import { User, useAuth } from "./auth-context";

export type Platform = "mixpanel" | "fullstory" | "smartlook" | "hubspot";

export const TrackingContext = createContext({});

export interface TrackingProviderProps extends WithChildren {}

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (!!user) {
      initialize();
      identify(user);
    }
  }, [user]);

  useEffect(() => {
    pageView(location.pathname, location.search);
  }, [location.pathname, location.search]);

  return (
    <TrackingContext.Provider value={{}}>{children}</TrackingContext.Provider>
  );
};

const isInitialized = (platform: Platform) => {
  if (platform === "mixpanel") return window.__$isMixpanelInitialized$__;
  if (platform === "fullstory") return window.__$isFullstoryInitialized$__;
  if (platform === "smartlook") return window.__$isSmartlookInitialized$__;
  if (platform === "hubspot") return !!window._hsq;

  return false;
};

const initialize = () => {
  const initializeMixpanel = () => {
    if (isInitialized("mixpanel") || !MIXPANEL.TOKEN) return;

    mixpanel.init(MIXPANEL.TOKEN, {
      ignore_dnt: MIXPANEL.IGNORE_DNT,
      debug: MIXPANEL.DEBUG,
    });

    window.__$isMixpanelInitialized$__ = true;
  };

  const initializeFullstory = () => {
    if (isInitialized("fullstory") || !FULLSTORY.ORG_ID) return;

    fullstory.init({ orgId: FULLSTORY.ORG_ID, devMode: FULLSTORY.DEV_MODE });

    window.__$isFullstoryInitialized$__ = true;
  };

  const initializeSmartlook = () => {
    if (isInitialized("smartlook") || !SMARTLOOK.KEY) return;

    smartlook.init(SMARTLOOK.KEY);
    smartlook.record({
      numbers: true,
      emails: true,
      forms: true,
      ips: true,
      api: true,
    });

    window.__$isSmartlookInitialized$__ = true;
  };

  initializeMixpanel();
  initializeFullstory();
  initializeSmartlook();
};

const identify = (user: User) => {
  if (!user) return;

  const identifyMixpanel = () => {
    if (!isInitialized("mixpanel")) return;

    mixpanel.identify(user.sub);

    mixpanel.people.set({
      user_id: user.sub,
      name: user.name,
      username: user.username,
      email: user.email,
      $email: user.email,
      email_verified: user.email_verified,
      phone_number: user.phone_number,
      phone_number_verified: user.phone_number_verified,
    });
  };

  const identifyFullstory = () => {
    if (!isInitialized("fullstory")) return;

    fullstory.identify(user.sub, {
      displayName: user.name ?? undefined,
      email: user.email ?? undefined,
    });
  };

  const identifySentry = () => {
    sentry.setUser({
      id: user.sub,
      email: user.email ?? undefined,
      username: user.username ?? undefined,
      name: user.name,
    });
  };

  const identifyHubspot = () => {
    if (!isInitialized("hubspot")) return;

    const hubspot = window._hsq;
    hubspot?.push?.(["identify"], {
      id: user.sub,
      email: user.email,
      username: user.username,
      name: user.name,
    });
  };

  const identifySmartlook = () => {
    if (!isInitialized("smartlook")) return;

    smartlook.identify(user.sub, {
      email: user.email ?? "",
      username: user.username ?? "",
      name: user.name ?? "",
    });
  };

  identifyMixpanel();
  identifyFullstory();
  identifySentry();
  identifyHubspot();
  identifySmartlook();
};

const pageView = (pathname: string, search: string) => {
  if (isNil(pathname)) return;

  const pageViewMixpanel = () => {
    if (!isInitialized("mixpanel")) return;

    mixpanel.track("page_view", { pathname, search });
  };

  const pageViewHubspot = () => {
    if (!isInitialized("hubspot")) return;

    const hubspot = window._hsq;

    hubspot?.push?.(["setPath", pathname]);
    hubspot?.push?.(["trackPageView"]);
  };

  const pageViewSmartlook = () => {
    if (!isInitialized("smartlook")) return;

    smartlook.track("page_view", { pathname, search });
  };

  pageViewMixpanel();
  pageViewHubspot();
  pageViewSmartlook();
};
