import { useCallback } from "react";

import { BoxProps, Flex, FormLabel, useDisclosure } from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";

import { MaybePromise, Nullish } from "@/common";
import {
  EditablePopover,
  EditablePopoverProps,
  Form,
  FormFieldControl,
  TextAreaFormField,
  useForm,
} from "@/components/form";

export interface NotePopoverFieldProps
  extends Omit<BoxProps, "defaultValue" | "onChange"> {
  defaultValue?: Nullish<string>;
  hidePreviewLabel?: boolean;
  orientation?: EditablePopoverProps["orientation"];
  onChange: (value: Nullish<string>) => MaybePromise<void>;
}

export const NotePopoverField = ({
  id,
  defaultValue = "",
  hidePreviewLabel = false,
  orientation = "vertical",
  onChange,
  ...props
}: NotePopoverFieldProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const methods = useForm<NotePopoverFieldValues>({
    defaultValues: { note: defaultValue ?? "" },
  });

  const {
    formState: { isValid, isSubmitting },
  } = methods;

  const handleSubmit = useCallback(
    async (data: NotePopoverFieldValues) => {
      await onChange(data.note);
      onClose();
    },
    [onChange, onClose]
  );

  return (
    <Flex
      maxW="full"
      direction={orientation === "vertical" ? "column" : "row"}
      alignItems={orientation === "vertical" ? "normal" : "center"}
      justifyContent={orientation === "vertical" ? "normal" : "space-between"}
      gap={1}
      {...props}
    >
      {!hidePreviewLabel && <FormLabel m={0}>Note</FormLabel>}

      <EditablePopover
        isOpen={isOpen}
        defaultValue={defaultValue ?? ""}
        emptyLabel="Add a note"
        orientation={orientation}
        isDisabled={!isValid}
        isLoading={isSubmitting}
        onOpen={onOpen}
        onClose={onClose}
        onSave={methods.handleSubmit(handleSubmit)}
      >
        <FormProvider {...methods}>
          <Form id={id} onSubmit={methods.handleSubmit(handleSubmit)}>
            <FormFieldControl name="note" mb={2}>
              <FormLabel>Note</FormLabel>

              <TextAreaFormField />
            </FormFieldControl>
          </Form>
        </FormProvider>
      </EditablePopover>
    </Flex>
  );
};

type NotePopoverFieldValues = { note: string };
