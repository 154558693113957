import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof CampaignService.createCategory>[0];
type Data = AsyncReturnType<typeof CampaignService.createCategory>;
type Error = unknown;

export const useCreateCategory = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    CampaignService.createCategory,
    options
  );

  return result;
};
