import { ReactNode, createContext, useContext } from "react";

import { SuspenseStatus } from "./types";

export interface QuerySuspenseContextProps {
  status: SuspenseStatus;
}

export const QuerySuspenseContext = createContext<QuerySuspenseContextProps>({
  status: "success",
});

export interface QuerySuspenseProviderProps {
  status: SuspenseStatus;
  children?: ReactNode;
}

export const QuerySuspenseProvider = ({
  status,
  children,
}: QuerySuspenseProviderProps) => {
  return (
    <QuerySuspenseContext.Provider value={{ status }}>
      {children}
    </QuerySuspenseContext.Provider>
  );
};

export const useQuerySuspense = () => {
  const { status } = useContext(QuerySuspenseContext);

  return {
    status,
    isSuspended: status !== "success",
    isIdle: status === "idle",
    isLoading: status === "loading",
    isSuccess: status === "success",
    isError: status === "error",
  };
};
