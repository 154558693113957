import {
  CampaignRequest,
  CampaignResult,
  MessageTemplateRequest,
} from "@/services/openapi";
import { Campaign, MessageTemplate, Typeahead } from "@/services/types";
import { RichText } from "@/utils";

export const asCampaignViewModel = (result: CampaignResult): Campaign => ({
  id: result.id,
  status: result.status,
  title: result.title,
  description: result.description ?? "",
  group: result.group,
  type: result.type,
  categories: result.categories,
  keywords: result.keywords,
  regions: result.regions,
  industries: result.industries,
  occupations: result.occupations,
  functions: result.functions,
  languages: result.languages,
  companies: result.companies,
  experiences: result.experiences,
  headcounts: result.headcounts,
  seniorities: result.seniorities,
  customAudienceList: result.customAudienceList,
  sequence: result.sequence.map((x) => ({
    id: x.id,
    content: RichText.deserializeJson(x.contentJson),
    offset: x.offset,
  })),
  sendFollowUpAfterInitialMessageReply:
    result.sendFollowUpAfterInitialMessageReply,
  reachAmount: result.reachAmount,
  recurring: result.recurring,
  createdDate: result.createdDate,
  startDate: result.startDate,
});

export const asTypeaheadRequest = (
  viewModel?: Typeahead[] | null
): number[] => {
  return viewModel ? viewModel.map((x) => x.id) : [];
};

export const asCampaignRequest = (viewModel: Campaign): CampaignRequest => {
  return asPartialCampaignRequest(viewModel) as CampaignRequest;
};

export const asSequenceRequest = (
  items?: MessageTemplate[]
): MessageTemplateRequest[] => {
  return items
    ? items.map((template, index) => ({
        order: index + 1,
        offset: template.offset,
        content: RichText.serializeText(template.content),
        contentJson: RichText.serializeJson(template.content),
        contentHtml: RichText.serializeHtml(template.content),
      }))
    : [];
};

export const asPartialCampaignRequest = (
  viewModel: Partial<Campaign & { profiles: string[] }>
): Partial<CampaignRequest> => {
  return {
    id: viewModel.id,
    title: viewModel.title,
    description: viewModel.description,
    group: viewModel.group?.id,
    type: viewModel.type,
    keywords: viewModel.keywords,
    categories: asTypeaheadRequest(viewModel.categories),
    regions: asTypeaheadRequest(viewModel.regions),
    industries: asTypeaheadRequest(viewModel.industries),
    occupations: asTypeaheadRequest(viewModel.occupations),
    companies: asTypeaheadRequest(viewModel.companies),
    experiences: asTypeaheadRequest(viewModel.experiences),
    functions: asTypeaheadRequest(viewModel.functions),
    headcounts: asTypeaheadRequest(viewModel.headcounts),
    languages: asTypeaheadRequest(viewModel.languages),
    seniorities: asTypeaheadRequest(viewModel.seniorities),
    sequence: asSequenceRequest(viewModel.sequence),
    customAudienceList: viewModel.customAudienceList,
    sendFollowUpAfterInitialMessageReply:
      viewModel.sendFollowUpAfterInitialMessageReply,
    reachAmount: viewModel.reachAmount,
    recurring: viewModel.recurring,
    startDate: viewModel.startDate,
  };
};
