import { StyleConfig } from "@chakra-ui/react";

export const Menu: StyleConfig = {
  baseStyle: {
    list: {
      paddingY: 1,
      paddingX: 0,
      minWidth: "20ch",
      fontFamily: "heading",
    },
    item: {
      paddingY: 1,
      paddingX: 2,

      ".chakra-menu__icon-wrapper": {
        marginInlineEnd: 2,
      },
    },
  },
};
