import { isNumber } from "lodash";
import {
  QueryParamConfig,
  decodeNumericArray,
  encodeNumericArray,
} from "use-query-params";

export const RequiredNumericArrayParam: QueryParamConfig<
  number[] | null | undefined,
  number[] | null | undefined
> = {
  encode: (x) => encodeNumericArray(x),
  decode: (x) => decodeNumericArray(x)?.filter(isNumber),
};
