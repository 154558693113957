import { Fragment, useMemo, useState } from "react";

import {
  Box,
  BoxProps,
  ButtonGroup,
  Flex,
  Icon,
  useDisclosure,
  useHighlight,
} from "@chakra-ui/react";
import { trimEnd, trimStart } from "lodash";
import {
  MdExpandLess,
  MdExpandMore,
  MdRemoveCircleOutline,
} from "react-icons/md";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { Text } from "@/components/display";
import { Button, IconButton } from "@/components/form";
import { EditSequenceStepModal } from "@/partials/campaigns";
import { RichTextValue } from "@/types";
import { useStyleProps } from "@/ui/hooks";
import { RichText } from "@/utils";

export interface SequenceMessagePreviewProps
  extends Omit<BoxProps, "onChange"> {
  value: RichTextValue;
  isReadOnly?: boolean;
  onChange?: (value: RichTextValue) => void;
  onSelect?: () => void;
  onRemove?: () => void;
}

const SequenceMessagePreviewRaw = ({
  value,
  isReadOnly = false,
  onChange,
  onSelect,
  onRemove,
  ...props
}: SequenceMessagePreviewProps) => {
  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const serializedValue = useMemo(() => RichText.serializeText(value), [value]);

  const chunks = useHighlight({
    text: serializedValue,
    query: RichText.Placeholders.map((x) => `{{${x.label}}}`),
  });

  return (
    <Box
      position="relative"
      w="full"
      p={4}
      background="gray.100"
      borderRadius="md"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      {...props}
    >
      {onRemove && !isReadOnly && (
        <IconButton
          aria-label="Remove"
          icon={<Icon as={MdRemoveCircleOutline} w={3} h={3} />}
          size="xs"
          variant="ghost"
          position="absolute"
          top={1}
          right={1}
          opacity={isMouseOver ? 1 : 0}
          transform={isMouseOver ? "scale(1)" : "scale(0.8)"}
          transitionProperty="transform, opacity, background"
          transitionDuration="fast"
          transitionTimingFunction="ease-out"
          transitionDelay="150ms, 150ms, 0ms"
          _focusVisible={{
            opacity: 1,
            transform: "scale(1)",
          }}
          onClick={onRemove}
        />
      )}

      <Text
        noOfLines={isExpanded ? undefined : 1}
        whiteSpace={isExpanded ? "pre-line" : "normal"}
      >
        {chunks.map(({ match, text }, index) => (
          <Fragment key={index}>
            {match ? (
              <Text as="span" color="blue.600" fontWeight="semibold">
                {trimStart(trimEnd(text, "}"), "{")}
              </Text>
            ) : (
              text
            )}
          </Fragment>
        ))}
      </Text>

      <Flex alignItems="center" justifyContent="space-between" mt={2}>
        {serializedValue.length <= 30 ? (
          <Box />
        ) : (
          <Button
            variant="link"
            colorScheme="blue"
            rightIcon={<Icon as={isExpanded ? MdExpandLess : MdExpandMore} />}
            onClick={() => setIsExpanded((x) => !x)}
            sx={{
              "& > .chakra-button__icon": {
                marginLeft: 0.5,
              },
            }}
          >
            {isExpanded ? "Show less" : "Show more"}
          </Button>
        )}

        <ButtonGroup>
          {onChange && !isReadOnly && (
            <Button variant="link" colorScheme="blue" onClick={openEditModal}>
              Edit
            </Button>
          )}

          {onSelect && (
            <Button variant="link" colorScheme="blue" onClick={onSelect}>
              Select
            </Button>
          )}
        </ButtonGroup>
      </Flex>

      {onChange && !isReadOnly && (
        <EditSequenceStepModal
          defaultValue={value}
          isOpen={isEditModalOpen}
          onChange={onChange}
          onClose={closeEditModal}
        />
      )}
    </Box>
  );
};

export const SequenceMessagePreview = withSkeleton<SequenceMessagePreviewProps>(
  SequenceMessagePreviewRaw,
  ({ pulse, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return (
      <Skeleton
        pulse={pulse}
        w="full"
        h="fit-content"
        borderRadius="md"
        {...styleProps}
      >
        <SequenceMessagePreviewRaw {...restProps} />
      </Skeleton>
    );
  }
);
