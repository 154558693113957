import { FormErrorMessage } from "@chakra-ui/react";
import { isNil, isString } from "lodash";

type Error = undefined | string | { message: string };

export interface FormErrorProps {
  errors: Error | Error[];
}

export const FormError = ({ errors }: FormErrorProps) => {
  const innerError = Array.isArray(errors) ? errors : [errors];

  return (
    <>
      {innerError.map((error, index) =>
        isNil(error) ? (
          <></>
        ) : (
          <FormErrorMessage key={index}>
            {isString(error) ? error : error.message}
          </FormErrorMessage>
        )
      )}
    </>
  );
};
