import { DependencyList, EffectCallback, useEffect, useRef } from "react";

import { cloneDeep, isEqual } from "lodash";

export const useDeepEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const isInitialRenderRef = useRef(true);
  const prevDepsRef = useRef(cloneDeep(deps));

  useEffect(() => {
    const hasChanged = !isEqual(prevDepsRef.current, deps);

    if (isInitialRenderRef.current || hasChanged) {
      effect();

      isInitialRenderRef.current = false;
      prevDepsRef.current = cloneDeep(deps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
