import { useMemo } from "react";

import { omit } from "lodash";

export const useOmitProps = <T extends object, K extends keyof T>(
  props: T,
  keys: K[],
  defaultValues: Partial<T> = {}
) => {
  return useMemo(
    () => ({ ...defaultValues, ...omit(props, keys) } as Omit<T, K>),
    [props, keys, defaultValues]
  );
};
