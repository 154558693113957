import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { Modal, ModalProps } from "@/components/disclosure";
import { withReset } from "@/hocs";
import { RichTextValue } from "@/types";

import { EditStep } from "./steps";

export interface EditSequenceStepModalProps
  extends Omit<ModalProps, "children"> {
  defaultValue?: RichTextValue;
  onChange: (value: RichTextValue) => void;
}

const EditSequenceStepModalRaw = ({
  defaultValue,
  onChange,
  onClose,
  ...props
}: EditSequenceStepModalProps) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size="2xl"
      onClose={onClose}
      {...props}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Edit sequence message</ModalHeader>
        <ModalCloseButton />

        <EditStep
          defaultValue={defaultValue}
          onChange={(value) => {
            onChange(value);
            onClose();
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export const EditSequenceStepModal = withReset(
  EditSequenceStepModalRaw,
  "isOpen"
);
