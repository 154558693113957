import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services/crm-service";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof CrmService.createTag>[0];
type Data = AsyncReturnType<typeof CrmService.createTag>;
type Error = unknown;

export const useCreateTag = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    CrmService.createTag,
    options
  );

  return result;
};
