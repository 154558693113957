import { AnyFn } from "@/common";

/** Executed once the state batch queue has been processed */
// https://medium.com/psynamic/demystifying-the-event-loop-featuring-settimeout-f702d4020d9a
export const executeAfterStateUpdate = (callback: AnyFn) => {
  setTimeout(() => {
    // setTimeout() schedules a macrotask that's executed after all microtasks (e.g. Promises)
    callback();
  });
};
