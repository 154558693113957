import { Children, ReactElement, cloneElement } from "react";

import { TabList, TabListProps } from "@chakra-ui/react";

import { FilterTab } from "./filter-tab";

export interface FilterTabListProps extends TabListProps {}

export const FilterTabList = ({ children, ...props }: FilterTabListProps) => {
  return (
    <TabList
      position={{ base: "unset", md: "sticky" }}
      overflowX={{ base: "scroll", md: "unset" }}
      top={0}
      gap={1}
      h="fit-content"
      alignItems="flex-start"
      whiteSpace="nowrap"
      {...props}
    >
      {Children.toArray(children)
        .filter((x) => (x as ReactElement).type === FilterTab)
        .map((x, index) => cloneElement(x as ReactElement, { index }))}
    </TabList>
  );
};
