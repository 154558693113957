import { Option, SelectProps } from "@/components/form/select";

export const defaultTagProps: Partial<SelectProps<Option>> = {
  autoFocus: true,
  defaultMenuIsOpen: true,
  blurInputOnSelect: true,
  closeMenuOnSelect: true,
  hideSelectedOptions: true,
  chakraStyles: {
    control: (styles) => ({
      ...styles,
      minHeight: "initial",
      border: "none",
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      maxWidth: "15ch",
    }),
    inputContainer: (styles) => ({
      ...styles,
      margin: 0,
    }),
    placeholder: (styles) => ({
      ...styles,
      margin: 0,
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
    menuList: (styles) => ({
      ...styles,
      minWidth: "20ch",
    }),
  },
};
