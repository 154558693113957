import { useState } from "react";

import { Box, Wrap, WrapItem } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { Tag } from "@/components/display";
import {
  CreatableSelect,
  Editable,
  EditableField,
  EditablePreview,
  Option,
  useIsDirty,
} from "@/components/form";
import { useCategories, useCreateCategory } from "@/services/hooks/campaigns";
import { asOption } from "@/services/hooks/typeahead";
import { createId } from "@/utils";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const CategoriesEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<Option[]>) => {
  const [nextValue, setNextValue] = useState(defaultValue);

  const isDirty = useIsDirty(
    defaultValue?.map((x) => x.value).sort(),
    nextValue?.map((x) => x.value).sort()
  );

  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: categories = [],
    setInterimData: setInterimCategories,
    isLoading: isLoadingCategories,
  } = useCategories({
    select: (x) => x.elements.map(asOption),
  });

  const { mutateAsync: createCategory } = useCreateCategory({
    onMutate: ({ label }) => {
      return setInterimCategories((draft) => {
        draft?.elements.push({ id: createId(), label });
      });
    },
  });

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Categories</EditableFieldLabel>

      <Editable
        label="Categories"
        isReadOnly={isReadOnly}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>
          <Wrap spacing={1}>
            {defaultValue?.map((category) => (
              <WrapItem key={category.value}>
                <Tag>{category.label}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </EditablePreview>

        <EditableField>
          <CreatableSelect
            defaultValue={defaultValue}
            value={nextValue}
            options={categories}
            inputValue={searchQuery}
            placeholder="Search for categories..."
            tagVariant="outline"
            isMulti
            isLoading={isLoadingCategories}
            onSearchQueryChange={(value) => {
              setSearchQuery(value);
            }}
            onChange={(value) => {
              setNextValue(value);
              onChange?.(value);
            }}
            onCreateOption={async (inputValue) => {
              const { id, label } = await createCategory({
                label: inputValue,
              });

              setNextValue((prevValue) => [
                ...(prevValue || []),
                { value: id.toString(), label },
              ]);

              setTimeout(() => {
                onChange?.(nextValue);
              });
            }}
          />
        </EditableField>
      </Editable>
    </Box>
  );
};
