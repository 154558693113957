export const createEmptyPromise = <T = void>() => {
  return new Promise<T>((resolve) => resolve(null as T));
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const PromiseUtils = {
  createEmptyPromise,
  sleep,
};
