export const format = (value: number | bigint) => {
  return Intl.NumberFormat("en-us").format(value);
};

export const formatSignificantDigits = (
  value: number | bigint,
  { maxSignificantDigits = 10 }: { maxSignificantDigits?: number } = {}
) => {
  return Intl.NumberFormat("en-us", {
    maximumSignificantDigits: maxSignificantDigits,
  }).format(value);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const tryParseInt = (value: string) => parseInt(value) || undefined;

export const tryParseFloat = (value: string) => parseFloat(value) || undefined;

export const NumberUtils = Object.freeze({
  format,
  formatSignificantDigits,
  formatBytes,
});
