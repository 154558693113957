import { forwardRef, memo, useState } from "react";

import {
  Circle,
  Grid,
  GridProps,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  MdLabelImportant,
  MdLabelImportantOutline,
  MdMoreHoriz,
  MdOutlineMarkChatRead,
  MdOutlineMarkChatUnread,
} from "react-icons/md";

import { Text } from "@/components/display";
import { IconButton } from "@/components/form";
import { Avatar } from "@/components/media";
import { Conversation } from "@/services/types";
import { DateUtils } from "@/utils";

export interface ConversationListItemProps extends Omit<GridProps, "onSelect"> {
  conversation: Conversation;
  isImportant?: boolean;
  isActive?: boolean;
  onSelect: (conversation: Conversation) => void;
  onMarkAsRead?: (conversation: Conversation) => void;
  onMarkAsUnread?: (conversation: Conversation) => void;
  onMarkAsImportant?: (conversation: Conversation) => void;
  onMarkAsNotImportant?: (conversation: Conversation) => void;
}

const ConversationListItemRaw = forwardRef<
  HTMLDivElement,
  ConversationListItemProps
>(
  (
    {
      conversation,
      isImportant = false,
      isActive = false,
      onSelect,
      onMarkAsRead,
      onMarkAsUnread,
      onMarkAsImportant,
      onMarkAsNotImportant,
      ...props
    },
    ref
  ) => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
      <Grid
        ref={ref}
        position="relative"
        gridTemplateAreas={`
        "avatar name timestamp"
        "avatar message message"
      `}
        gridTemplateColumns="auto 1fr"
        gridColumnGap={4}
        gridRowGap={0}
        w="full"
        p={{ base: 2, lg: 4 }}
        borderRadius="md"
        background={isActive ? "gray.50" : "unset"}
        cursor="pointer"
        transition={`
          background var(--transition-very-fast) linear, 
          box-shadow var(--transition-very-fast) linear`}
        onClick={() => onSelect(conversation)}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        _hover={{
          background: "gray.50",
        }}
        {...props}
      >
        <Avatar
          src={conversation.recipient.image ?? undefined}
          name={conversation.recipient.fullName}
          gridArea="avatar"
        />

        <Text gridArea="name" noOfLines={1}>
          {conversation.recipient.fullName ||
            conversation.recipient.firstName ||
            "Anonymous"}
        </Text>

        <Text
          gridArea="message"
          color={conversation.isRead ? "gray.500" : "gray.800"}
          fontWeight={conversation.isRead ? "normal" : "bold"}
          noOfLines={1}
        >
          {conversation.lastMessage?.isOurs && "You: "}{" "}
          {conversation.lastMessage?.text ||
            conversation.lastMessage?.attachments?.[0]?.name}
        </Text>

        <HStack spacing={1} position="absolute" top="1px" right={1} h={3}>
          {isImportant && (
            <Icon as={MdLabelImportant} w={3} h={3} color="primary.500" />
          )}

          {!conversation.isRead && <Circle size={2} bg="blue.500" />}
        </HStack>

        <Text gridArea="timestamp" noOfLines={1} color="gray.500" fontSize="xs">
          {DateUtils.toRelative(conversation.lastMessage?.sentDate)}
        </Text>

        {(isMouseOver || isMenuOpen) && (
          <Menu
            placement="bottom-end"
            isOpen={isMenuOpen}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
          >
            <MenuButton
              as={IconButton}
              icon={<Icon as={MdMoreHoriz} />}
              size="xs"
              variant="outline"
              position="absolute"
              right={4}
              bottom={4}
              background="white"
              borderRadius="full"
              onClick={(ev) => {
                setIsMenuOpen(true);

                ev.stopPropagation();
                return false;
              }}
            />

            <MenuList fontSize="sm">
              <MenuItem
                icon={
                  <Icon
                    as={
                      conversation.isRead
                        ? MdOutlineMarkChatUnread
                        : MdOutlineMarkChatRead
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                }
                onClick={(ev) => {
                  if (conversation.isRead) {
                    onMarkAsUnread?.(conversation);
                  } else {
                    onMarkAsRead?.(conversation);
                  }

                  ev.stopPropagation();
                  return false;
                }}
              >
                Mark as {conversation.isRead ? "Unread" : "Read"}
              </MenuItem>

              <MenuItem
                icon={
                  <Icon
                    as={MdLabelImportantOutline}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                }
                onClick={(ev) => {
                  if (isImportant) {
                    onMarkAsNotImportant?.(conversation);
                  } else {
                    onMarkAsImportant?.(conversation);
                  }

                  ev.stopPropagation();
                  return false;
                }}
              >
                Mark as {isImportant ? "Not Important" : "Important"}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Grid>
    );
  }
);

ConversationListItemRaw.displayName = "ConversationListItem";

export const ConversationListItem = memo(ConversationListItemRaw);
