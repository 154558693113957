import { inputAnatomy } from "@chakra-ui/anatomy";
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

export const Input: ComponentStyleConfig = defineMultiStyleConfig({
  defaultProps: {
    variant: "filled",
  },
});
