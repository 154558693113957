import { SetStateAction, useMemo, useState } from "react";

import { debounce } from "lodash";
import {
  DecodedValueMap,
  QueryParamConfig,
  QueryParamConfigMap,
  QueryParamConfigMapWithInherit,
  StringParam,
  useQueryParams,
} from "use-query-params";

type ExpandInherits<QPCMap extends QueryParamConfigMapWithInherit> = {
  [ParamName in keyof QPCMap]: QPCMap[ParamName] extends string
    ? typeof StringParam
    : QPCMap[ParamName] extends QueryParamConfig<any>
    ? QPCMap[ParamName]
    : never;
};

type UseDebouncedQueryParamsResult<QPCMap extends QueryParamConfigMap> = [
  DecodedValueMap<QPCMap>,
  (value: SetStateAction<DecodedValueMap<QPCMap>>) => void
];

const DEFAULT_DELAY = 500;

export const useDebouncedQueryParams = <
  QPCMap extends QueryParamConfigMapWithInherit,
  OutputQPCMap extends QueryParamConfigMap = ExpandInherits<QPCMap>
>(
  paramConfigMap: QPCMap
): UseDebouncedQueryParamsResult<OutputQPCMap> => {
  const [_queryParams, _setQueryParams] = useQueryParams<QPCMap, OutputQPCMap>(
    paramConfigMap
  );

  const setQueryParamsDebounced = useMemo(
    () => debounce(_setQueryParams, DEFAULT_DELAY),
    [_setQueryParams]
  );

  const [queryParams, setQueryParams] = useState(_queryParams);

  const state = queryParams;
  const setState = (...args: Parameters<typeof setQueryParams>) => {
    setQueryParams(...args);
    setQueryParamsDebounced(...args);
  };

  return [state, setState];
};
