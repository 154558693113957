import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

export const Switch = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    container: {
      borderRadius: "md",
    },
    thumb: {
      borderRadius: "md",
    },
    track: {
      borderRadius: "md",
      background: "gray.200",
      _checked: {
        background: "blue.400",
      },
    },
  }),
});
