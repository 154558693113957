import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import { BeaconRenderProps } from "react-joyride";

import { pulse } from "@/styles/keyframes";

export interface BeaconProps extends BoxProps, BeaconRenderProps {}

export const Beacon = forwardRef<BeaconProps, "button">((props, ref) => (
  <Box
    ref={ref}
    as="button"
    position="relative"
    display="flex"
    alignItems="center"
    justifyContent="center"
    w={8}
    h={8}
    {...props}
  >
    <Box
      as="span"
      display="block"
      w={3}
      h={3}
      background="blue.500"
      border="2px solid"
      borderColor="white"
      borderRadius="full"
      animation={`${pulse} 1s ease-in-out infinite`}
    />
  </Box>
));

Beacon.displayName = "Beacon";
