import { Box } from "@chakra-ui/react";

import { Heading, Text } from "@/components/display";
import { Button } from "@/components/form";
import { Link } from "@/components/navigation";

export const NotFoundScreen = () => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      w="full"
      minH="var(--cosiall-sizes-content-height)"
      px={4}
    >
      <Box
        position="relative"
        zIndex={2}
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        justifyContent="center"
        maxW="70ch"
        p={8}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        background="whiteAlpha.800"
        backdropFilter="blur(8px)"
      >
        <Heading mb={8}>Whoops. You landed on the wrong page 😵‍💫</Heading>

        <Text mb={8} lineHeight="tall">
          Maybe this page no longer exists or the URL is spelled wrong. If you
          think this is an error, reach out to <b>support@cosiall.com</b>.
        </Text>

        <Button as={Link} to="/campaigns" colorScheme="primary">
          Take me back
        </Button>
      </Box>
    </Box>
  );
};
