import { extendTheme } from "@chakra-ui/react";

import { breakpoints } from "./theme/breakpoints";
import { colors } from "./theme/colors";
import {
  Alert,
  Badge,
  Button,
  FormLabel,
  Icon,
  Input,
  Link,
  Menu,
  Modal,
  Popover,
  Spinner,
  Switch,
  Tabs,
  Textarea,
} from "./theme/components";
import { shadows } from "./theme/shadows";
import { sizes } from "./theme/sizes";
import { styles } from "./theme/styles";
import { typography } from "./theme/typography";

export const THEME = extendTheme({
  config: {
    cssVarPrefix: "cosiall",
  },

  zIndices: {
    scrollbar: 1000,
    drawer: 1200,
  },

  components: {
    Alert,
    Badge,
    Button,
    Popover,
    Link,
    Spinner,
    Menu,
    Modal,
    Switch,
    Tabs,
    FormLabel,
    Icon,
    Input,
    Textarea,
  },

  styles,
  sizes,
  colors,
  shadows,

  breakpoints,

  ...typography,
});

export default THEME;
