import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

import { ZAPIER } from "@/config";

import "./integration-screen.css";

export const IntegrationScreen = () => {
  return (
    <>
      <Helmet>
        <title>My Integrations - Cosiall</title>

        <script
          type="module"
          src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"
        />
      </Helmet>

      <Box maxWidth="container.sm" mx="auto">
        <zapier-full-experience
          client-id={ZAPIER.CLIENT_ID}
          theme="light"
          intro-copy-display="hide"
          app-search-bar-display="show"
          zap-limit={4}
          zap-style="card"
          zap-call-to-action-display="show"
          zap-create-from-scratch-display="hide"
        />
      </Box>
    </>
  );
};
