import { ChangeEvent } from "react";

export interface UseFileUploadProps {
  onFileLoad?: (file: File, content: string) => void;
  onFileError?: (error: unknown) => void;
}

export const useFileUpload = ({
  onFileLoad,
  onFileError,
}: UseFileUploadProps) => {
  const handler = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target?.result as string;
      onFileLoad?.(file, content);
    };

    reader.onerror = () => {
      onFileError?.(reader.error);
    };

    reader.readAsText(file);

    // We reset the input so that we can allow another file upload even if user selects the same one
    ev.target.value = "";
  };

  return handler;
};
