import { Box, ButtonGroup, Icon } from "@chakra-ui/react";
import { MdCheck, MdClose, MdEdit } from "react-icons/md";

import { Text } from "@/components/display";
import { Button, IconButton } from "@/components/form";

import { useEditableContext } from "./editable-context";

export const EditableControls = () => {
  const {
    label,
    variant,
    hasValue,
    isEditing,
    isSaving,
    isDisabled,
    isReadOnly,
    onEdit,
    onSave,
    onCancel,
  } = useEditableContext();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="xs" spacing={1} mt={2}>
      <IconButton
        icon={<Icon as={MdCheck} w={3} h={3} />}
        size="xs"
        isLoading={isSaving}
        isDisabled={isDisabled}
        aria-label="Save"
        onClick={() => {
          onSave();
        }}
      />

      <IconButton
        icon={<Icon as={MdClose} w={3} h={3} />}
        size="xs"
        variant="outline"
        isDisabled={isSaving}
        aria-label="Close"
        onClick={() => {
          onCancel();
        }}
      />
    </ButtonGroup>
  ) : (
    <Box>
      {variant === "default" ? (
        <>
          {!label || hasValue ? (
            <IconButton
              display={isReadOnly ? "none" : undefined}
              icon={<Icon as={MdEdit} w={3} h={3} />}
              size="xs"
              variant="outline"
              mt={2}
              aria-label="Edit"
              isDisabled={isReadOnly}
              onClick={() => {
                onEdit();
              }}
            />
          ) : (
            <Button
              variant="link"
              colorScheme="blue"
              size="sm"
              isDisabled={isReadOnly}
              onClick={() => {
                onEdit();
              }}
            >
              Add&nbsp;
              <Text as="span" textTransform="lowercase">
                {label}
              </Text>
            </Button>
          )}
        </>
      ) : null}
    </Box>
  );
};
