import { AsyncReturnType } from "type-fest";

import { AccountService } from "@/services/account-service";
import { useQueryClient } from "@/services/hooks";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

import { useOnboarding } from "./use-onboarding";

type Variables = Parameters<typeof AccountService.completeTour>[0];
type Data = AsyncReturnType<typeof AccountService.completeTour>;
type Error = unknown;

export const useCompleteTour = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const client = useQueryClient();

  const result = useMutation<Data, Error, Variables, TContext>(
    AccountService.completeTour,
    {
      onSuccess: () => {
        client.invalidateQueries(useOnboarding.queryKey());
      },
      ...options,
    }
  );

  return result;
};
