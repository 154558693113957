import { Helmet } from "react-helmet-async";

import { useFeature } from "@/modules/flags";
import { useNavigate } from "@/router";

import { CampaignWizard } from "./wizards";

export const CreateCampaignScreen = () => {
  const isCampaignFeatureEnabled = useFeature("campaigns");

  const navigate = useNavigate();

  if (!isCampaignFeatureEnabled) {
    navigate("/", { replace: true });
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Create a Campaign - Cosiall</title>
      </Helmet>

      <CampaignWizard
        display="flex"
        flexDir="column"
        minHeight="var(--cosiall-sizes-content-height)"
      />
    </>
  );
};
