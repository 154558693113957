import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { useStyleProps } from "@/ui/hooks";

export type HeadingProps = ChakraHeadingProps;

export const Heading = withSkeleton<HeadingProps, "h2">(
  ChakraHeading,
  ({ pulse, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return (
      <Skeleton
        pulse={pulse}
        w="fit-content"
        h="fit-content"
        maxH={6}
        noOfLines={1}
        borderRadius="md"
        {...styleProps}
      >
        <ChakraHeading {...restProps} />
      </Skeleton>
    );
  }
);

export const SectionHeading = (props: HeadingProps) => {
  return (
    <Heading
      as="h3"
      size="xs"
      color="gray.500"
      textTransform="uppercase"
      letterSpacing="wide"
      mb={2}
      suspendable={false}
      {...props}
    />
  );
};
