import { createContext, useContext } from "react";

import { noop } from "lodash";

import { AnyObject, MaybePromise } from "@/common";
import { WithChildren } from "@/ui/types";

import { Step } from "./types";

interface WizardContextProps<T extends AnyObject = AnyObject> {
  state: {
    steps: Step[];
    setSteps: (value: Step[]) => void;
    currentStep?: Step | null;
    setCurrentStep: (value: string | number) => void;
    isFirstStep: boolean;
    isFinalStep: boolean;
    onStepDataChange: (data: Partial<T>) => MaybePromise<void>;
  };
  data: T;
  next: () => void;
  previous: () => void;
}

const WizardContext = createContext<WizardContextProps<any>>({
  state: {
    currentStep: null,
    setCurrentStep: noop,
    steps: [],
    setSteps: noop,
    isFirstStep: true,
    isFinalStep: false,
    onStepDataChange: noop,
  },
  data: {},
  next: noop,
  previous: noop,
});

export interface WizardProviderProps<T extends AnyObject = AnyObject>
  extends WithChildren {
  value: WizardContextProps<T>;
}

export const WizardProvider = <TData extends AnyObject>(
  props: WizardProviderProps<TData>
) => <WizardContext.Provider {...props} />;

export const useWizardContext = <TData extends AnyObject>() => {
  const context = useContext<WizardContextProps<TData>>(WizardContext);

  if (context === undefined) {
    throw Error(
      `"useWizardContext" must used within "${WizardProvider.name}".`
    );
  }

  return context;
};
