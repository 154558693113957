import { Box } from "@chakra-ui/react";

import { Heading, Text } from "@/components/display";
import { Button } from "@/components/form";

export interface FallbackErrorScreenProps {
  resetError: () => void;
}

// This error screen uses only minimal functionality so we can wrap every other Component inside
export const FallbackErrorScreen = ({
  resetError,
}: FallbackErrorScreenProps) => (
  <Box
    position="relative"
    display="flex"
    flexDir="column"
    alignItems="center"
    justifyContent="center"
    overflow="hidden"
    w="full"
    minH="100vh"
    px={4}
  >
    <Box
      position="relative"
      zIndex={2}
      display="flex"
      flexDir="column"
      alignItems="flex-start"
      justifyContent="center"
      maxW="70ch"
      p={8}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      background="whiteAlpha.800"
      backdropFilter="blur(8px)"
    >
      <Heading mb={8}>Whoops. Something went wrong 🫨</Heading>

      <Text mb={8} lineHeight="tall">
        Something went wrong on our side. If this keeps happening you can
        contact our customer support team at <b>support@cosiall.com</b>.
      </Text>

      <Button colorScheme="primary" onClick={resetError}>
        Take me back
      </Button>
    </Box>
  </Box>
);
