import { modalAnatomy } from "@chakra-ui/anatomy";
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

export const Modal: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    header: {
      fontSize: "md",
    },
    dialog: {
      margin: 2,
    },
  }),
});
