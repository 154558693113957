import { ChangeEvent, DragEvent, useCallback, useState } from "react";

import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { MdAttachment, MdUploadFile } from "react-icons/md";

import { Text } from "@/components/display";
import { ToasterManager } from "@/components/feedback";
import { Button, IconButton } from "@/components/form";
import { createId } from "@/utils";

const DEFAULT_ACCEPT =
  "image/*,.ai,.psd,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt,.pub,.html,.7z,.eml";

// 30MB in Bytes
const MAX_SIZE_B = 30 * 10 ** 6;

export interface PickedFile {
  id: number;
  name: string;
  type: string;
  size: number;
  data: File;
}

export interface FilePickerProps extends Omit<PopoverProps, "children"> {
  accept?: string;
  onSelect: (files: PickedFile[]) => void;
}

export const FilePicker = ({
  accept = DEFAULT_ACCEPT,
  onSelect,
  ...props
}: FilePickerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onSelectedHandler = useCallback(
    (ev: DragEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
      const isValidFile = (file: File | PickedFile) => file.size <= MAX_SIZE_B;

      const target = ev.target as HTMLInputElement;

      // @ts-ignore
      const initialFiles = [...target.files] as File[];
      const invalidFiles = initialFiles.filter((file) => !isValidFile(file));

      if (invalidFiles.length) {
        const invalidFilesText = (() => {
          const limit = 2;
          let text = invalidFiles
            .slice(0, limit)
            .map((x) => x.name)
            .join(", ");

          if (limit < invalidFiles.length) {
            text += ` and ${invalidFiles.length - limit} more`;
          }

          return text;
        })();

        ToasterManager.error({
          title: "Some of the files could not be uploaded",
          message: (
            <>
              <b>{invalidFilesText}</b> could not be uploaded. Please check
              whether the file type is supported and the file size is within the
              limit (30MB).
            </>
          ),
        });
      }

      const validFiles = initialFiles
        .map((file) => ({
          id: createId(),
          name: file.name,
          type: file.type,
          size: file.size,
          data: file,
        }))
        .filter(isValidFile);

      onSelect(validFiles);
      onClose();

      setIsDraggingOver(false);
    },
    [onSelect, onClose]
  );

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
      {...props}
    >
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={MdAttachment} />}
          size="xs"
          variant="ghost"
          aria-label="Attach files"
        />
      </PopoverTrigger>

      <PopoverContent borderColor="transparent">
        <Box>
          <FormControl
            border={isDraggingOver ? "1px dashed" : "1px solid"}
            borderColor={isDraggingOver ? "blue.600" : "gray.200"}
            borderRadius="md"
            cursor="pointer"
            transitionProperty="common"
            transitionDuration="normal"
            sx={{
              "&:hover": {
                borderColor: "blue.600",
              },
            }}
          >
            <FormLabel
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              m={0}
              px={4}
              py={16}
            >
              <Icon as={MdUploadFile} w={8} h={8} color="gray.600" />

              <Text mt={2} fontSize="xs" fontWeight="semibold" color="gray.600">
                Drag media here to upload
              </Text>

              <Button mt={4}>Upload from device</Button>
            </FormLabel>

            <Input
              type="file"
              accept={accept}
              multiple
              position="absolute"
              top={0}
              left={0}
              opacity={0}
              w="full"
              h="full"
              cursor="pointer"
              onChange={onSelectedHandler}
              onDrop={onSelectedHandler}
              onDragEnter={() => setIsDraggingOver(true)}
              onDragLeave={() => setIsDraggingOver(false)}
            />
          </FormControl>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
