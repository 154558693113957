import { useCallback } from "react";

import { FormLabel, Tooltip, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { isNil, pick, toNumber } from "lodash";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

import { PartialRecord } from "@/common";
import {
  WizardStep,
  WizardStepProps,
  useWizardContext,
} from "@/components/disclosure";
import {
  CheckboxBlock,
  CheckboxGroupFormField,
  CheckboxGroupProps,
  CreatableSelectFormField,
  Form,
  FormFieldControl,
  FormSection,
  InputFormField,
  Option,
  SelectFormField,
  useForm,
} from "@/components/form";
import { Transform } from "@/components/form/hocs";
import { createSelectFieldTransform } from "@/components/form/utils";
import { useDebouncedState, useDeepMemo } from "@/hooks";
import {
  CustomAudiencePicker,
  CustomAudiencePickerProps,
} from "@/partials/campaigns";
import { AudienceStepFieldValues } from "@/screens/campaigns/wizards";
import {
  asOptions,
  useCompanies,
  useCreateCompany,
  useCreateOccupation,
  useExperiences,
  useFunctions,
  useHeadcounts,
  useIndustries,
  useLanguages,
  useOccupations,
  useRegions,
  useSeniorities,
} from "@/services/hooks/typeahead";
import { Typeahead } from "@/services/types";
import { profileLinkSchema, typeaheadSchema } from "@/ui/schemas";
import { createId } from "@/utils";

import { useHandleSubmit } from "./use-handle-submit";
import { WizardStepActionBar } from "./wizard-step-action-bar";
import { WizardStepAudienceBar } from "./wizard-step-audience-bar";

export interface AudienceStepProps extends Pick<WizardStepProps, "name"> {}

export const AudienceStep = ({ name }: AudienceStepProps) => {
  const { data: wizardData } = useWizardContext();

  const methods = useForm<AudienceStepFieldValues>({
    defaultValues: pick(
      wizardData,
      Object.keys(audienceStepDefaultValues)
    ) as AudienceStepFieldValues,
    resolver: zodResolver(schema),
  });

  const { formState, watch, setValue, setError } = methods;

  const stepData = watch();
  const stepDataMemo = useDeepMemo(() => stepData, [stepData]);

  const [searchQuery, debouncedSearchQuery, setSearchQuery] = useDebouncedState<
    PartialRecord<keyof AudienceStepFieldValues, string>
  >({
    regions: "",
    companies: "",
    industries: "",
    occupations: "",
  });

  const { data: regions = [], isLoading: isLoadingRegions } = useRegions(
    { search: debouncedSearchQuery.regions },
    { select: asOptions }
  );

  const { data: industries = [], isLoading: isLoadingIndustries } =
    useIndustries(
      { search: debouncedSearchQuery.industries },
      { select: asOptions }
    );

  const { data: languages = [], isLoading: isLoadingLanguages } = useLanguages({
    select: asOptions,
  });

  const {
    data: occupations = [],
    setInterimData: setInterimOccupations,
    isLoading: isLoadingOccupations,
  } = useOccupations(
    { search: debouncedSearchQuery.occupations },
    { select: asOptions }
  );

  const { mutateAsync: createOccupation } = useCreateOccupation({
    onMutate: ({ label }) => {
      return setInterimOccupations((draft) => {
        draft?.elements.push({ id: createId(), label });
      });
    },
  });

  const { data: functions = [], isLoading: isLoadingFunctions } = useFunctions({
    select: asOptions,
  });

  const { data: seniorities = [], isLoading: isLoadingSeniorities } =
    useSeniorities({ select: asOptions });

  const { data: experiences = [], isLoading: isLoadingExperiences } =
    useExperiences({ select: asOptions, enableSkeletonData: true });

  const {
    data: companies = [],
    setInterimData: setInterimCompanies,
    isLoading: isLoadingCompanies,
  } = useCompanies(
    { search: debouncedSearchQuery.companies },
    { select: asOptions }
  );

  const { mutateAsync: createCompany } = useCreateCompany({
    onMutate: ({ label }) => {
      return setInterimCompanies((draft) => {
        draft?.elements.push({ id: createId(), label });
      });
    },
  });

  const { data: headcounts = [], isLoading: isLoadingHeadcounts } =
    useHeadcounts({ select: asOptions, enableSkeletonData: true });

  const handleSearchQueryChange = useCallback(
    (name: string) => (value: string) => {
      setSearchQuery((prev) => ({ ...prev, [name]: value }));
    },
    [setSearchQuery]
  );

  const handleAudiencePick = useCallback<
    NonNullable<CustomAudiencePickerProps["onPick"]>
  >(
    (audience) => {
      const options = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      if (isNil(audience)) {
        setValue("type", "default", options);
        setValue("customAudienceList", null, options);
      } else {
        setValue("type", "custom", options);
        setValue("customAudienceList", audience, options);
      }
    },
    [setValue]
  );

  const handleAudiencePickError = useCallback<
    NonNullable<CustomAudiencePickerProps["onPickError"]>
  >(
    (error) => {
      const options = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      setValue("type", "default", options);
      setValue("customAudienceList", null, options);

      setError("customAudienceList", {
        message: error,
      });
    },
    [setError, setValue]
  );

  const handleAudienceRemove = useCallback(() => {
    const options = {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    };

    setValue("type", "default", options);
    setValue("customAudienceList", null, options);
  }, [setValue]);

  const handleSubmit = useHandleSubmit<AudienceStepFieldValues>({
    onError: (field, error) => setError(field, error),
  });

  return (
    <WizardStep
      name={name}
      data={stepDataMemo}
      isValid={formState.isValid}
      isSubmitting={formState.isSubmitting}
    >
      <FormProvider {...methods}>
        <Form id={name} onSubmit={methods.handleSubmit(handleSubmit)}>
          <VStack spacing={4} alignItems="normal">
            <FormSection
              name="General"
              fields={["regions", "industries", "languages", "keywords"]}
              isCollapsible
              defaultIsExpanded
              isDisabled={stepData.type === "custom"}
            >
              <VStack spacing={4} alignItems="normal">
                <FormFieldControl
                  name="regions"
                  isRequired={stepData.type === "default"}
                  isLoading={isLoadingRegions}
                >
                  <FormLabel>Region</FormLabel>

                  <SelectFormField
                    inputValue={searchQuery.regions}
                    isMulti
                    placeholder="Search for regions..."
                    tagVariant="outline"
                    options={regions}
                    fieldTransform={selectFieldTransform}
                    onSearchQueryChange={handleSearchQueryChange("regions")}
                  />
                </FormFieldControl>

                <FormFieldControl
                  name="industries"
                  isLoading={isLoadingIndustries}
                >
                  <FormLabel>Industry</FormLabel>

                  <SelectFormField
                    inputValue={searchQuery.industries}
                    isMulti
                    placeholder="Search for industries..."
                    tagVariant="outline"
                    options={industries}
                    fieldTransform={selectFieldTransform}
                    onSearchQueryChange={handleSearchQueryChange("industries")}
                  />
                </FormFieldControl>

                <FormFieldControl
                  name="languages"
                  isLoading={isLoadingLanguages}
                >
                  <FormLabel>Language</FormLabel>

                  <SelectFormField
                    isMulti
                    placeholder="Search for languages..."
                    tagVariant="outline"
                    options={languages}
                    fieldTransform={selectFieldTransform}
                  />
                </FormFieldControl>

                <FormFieldControl name="keywords">
                  <FormLabel>Keywords</FormLabel>
                  <InputFormField changeOnBlur />
                </FormFieldControl>
              </VStack>
            </FormSection>

            <FormSection
              name="Role & Tenure"
              fields={[
                "occupations",
                "functions",
                "seniorities",
                "experiences",
              ]}
              isCollapsible
              isDisabled={stepData.type === "custom"}
            >
              <VStack spacing={4}>
                <FormFieldControl
                  name="occupations"
                  isLoading={isLoadingOccupations}
                >
                  <FormLabel>Occupation</FormLabel>

                  <CreatableSelectFormField
                    inputValue={searchQuery.occupations}
                    isMulti
                    placeholder="Search for occupations..."
                    tagVariant="outline"
                    options={occupations}
                    fieldTransform={selectFieldTransform}
                    onSearchQueryChange={handleSearchQueryChange("occupations")}
                    onCreate={async (inputValue) => {
                      const { id, label, image } = await createOccupation({
                        label: inputValue,
                      });

                      return { value: id.toString(), label, image };
                    }}
                  />
                </FormFieldControl>

                <FormFieldControl
                  name="functions"
                  isLoading={isLoadingFunctions}
                >
                  <FormLabel>Function</FormLabel>

                  <SelectFormField
                    isMulti
                    placeholder="Search for functions..."
                    tagVariant="outline"
                    options={functions}
                    fieldTransform={selectFieldTransform}
                    onSearchQueryChange={handleSearchQueryChange("functions")}
                  />
                </FormFieldControl>

                <FormFieldControl
                  name="seniorities"
                  isLoading={isLoadingSeniorities}
                >
                  <FormLabel>Seniority level</FormLabel>

                  <SelectFormField
                    isMulti
                    placeholder="Search for seniorities..."
                    tagVariant="outline"
                    options={seniorities}
                    fieldTransform={selectFieldTransform}
                    onSearchQueryChange={handleSearchQueryChange("seniorities")}
                  />
                </FormFieldControl>

                <FormFieldControl
                  name="experiences"
                  isLoading={isLoadingExperiences}
                >
                  <FormLabel>Years of experience</FormLabel>

                  <CheckboxGroupFormField
                    fieldTransform={getCheckboxGroupFieldTransform(experiences)}
                  >
                    {experiences.map((experience) => (
                      <CheckboxBlock
                        key={experience.value}
                        htmlValue={experience.value}
                      >
                        {experience.label}
                      </CheckboxBlock>
                    ))}
                  </CheckboxGroupFormField>
                </FormFieldControl>
              </VStack>
            </FormSection>

            <FormSection
              name="Company"
              fields={["companies", "headcounts"]}
              isCollapsible
              isDisabled={stepData.type === "custom"}
            >
              <VStack spacing={4}>
                <FormFieldControl
                  name="companies"
                  isLoading={isLoadingCompanies}
                >
                  <FormLabel>Company name</FormLabel>

                  <CreatableSelectFormField
                    inputValue={searchQuery.companies}
                    isMulti
                    placeholder="Search for companies..."
                    tagVariant="outline"
                    options={companies}
                    fieldTransform={selectFieldTransform}
                    onSearchQueryChange={handleSearchQueryChange("companies")}
                    onCreate={async (inputValue) => {
                      const { id, label, image } = await createCompany({
                        label: inputValue,
                      });

                      return { value: id.toString(), label, image };
                    }}
                  />
                </FormFieldControl>

                <FormFieldControl
                  name="headcounts"
                  isLoading={isLoadingHeadcounts}
                >
                  <FormLabel>Company headcount</FormLabel>

                  <CheckboxGroupFormField
                    fieldTransform={getCheckboxGroupFieldTransform(headcounts)}
                  >
                    {headcounts.map((headcount) => (
                      <CheckboxBlock
                        key={headcount.value}
                        htmlValue={headcount.value}
                      >
                        {headcount.label}
                      </CheckboxBlock>
                    ))}
                  </CheckboxGroupFormField>
                </FormFieldControl>
              </VStack>
            </FormSection>

            <FormFieldControl name="customAudienceList">
              <Tooltip
                label="Import an audience from a file. If a file is uploaded, the existing audience filters will not apply."
                placement={stepData.type === "custom" ? "top" : "bottom"}
                hasArrow
              >
                <CustomAudiencePicker
                  defaultValue={{
                    fileName: stepData.customAudienceList?.fileName ?? "",
                    profiles: stepData.customAudienceList?.profiles ?? [],
                  }}
                  onPick={handleAudiencePick}
                  onPickError={handleAudiencePickError}
                  onRemove={handleAudienceRemove}
                />
              </Tooltip>
            </FormFieldControl>
          </VStack>
        </Form>
      </FormProvider>

      <WizardStepActionBar>
        <WizardStepAudienceBar />
      </WizardStepActionBar>
    </WizardStep>
  );
};

export const audienceStepDefaultValues: AudienceStepFieldValues = {
  type: "default",
  regions: [],
  industries: [],
  languages: [],
  keywords: "",
  occupations: [],
  functions: [],
  seniorities: [],
  experiences: [],
  companies: [],
  headcounts: [],
  customAudienceList: null,
};

const schema = z
  .object({
    type: z.enum(["custom", "default"]),
    regions: z.array(typeaheadSchema),
    industries: z.array(typeaheadSchema),
    languages: z.array(typeaheadSchema),
    keywords: z.string(),
    occupations: z.array(typeaheadSchema),
    functions: z.array(typeaheadSchema),
    seniorities: z.array(typeaheadSchema),
    experiences: z.array(typeaheadSchema),
    companies: z.array(typeaheadSchema),
    headcounts: z.array(typeaheadSchema),
    customAudienceList: z
      .object({
        fileName: z.string(),
        profiles: z.array(profileLinkSchema),
      })
      .nullable(),
  })
  .refine(
    (data) => {
      return (
        data.type === "default" ||
        0 < (data.customAudienceList?.profiles.length ?? 0)
      );
    },
    {
      path: ["customAudienceList"],
      message: "The uploaded file does not contain any profile URLs",
    }
  )
  .refine(
    (data) => {
      return data.type === "custom" || data.regions.length > 0;
    },
    {
      path: ["regions"],
      message: "You must select at least one value",
    }
  );

const selectFieldTransform = createSelectFieldTransform<Typeahead>(
  ({ id, label, image }) => ({
    value: id.toString(),
    label,
    image,
  }),
  ({ value, label, image }) => ({ id: toNumber(value), label, image })
);

const getCheckboxGroupFieldTransform = (
  options: Option[]
): Transform<CheckboxGroupProps, Typeahead[]> => ({
  in: (values) => values.map((value) => value.id.toString()),
  out: (values) =>
    values?.map((value) => ({
      id: toNumber(value),
      label: options.find((x) => x.value === value)!.label,
      image: options.find((x) => x.value === value)!.image,
    })) ?? [],
});
