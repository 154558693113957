import { Icon, PopoverTrigger } from "@chakra-ui/react";
import { MdOutlineTune } from "react-icons/md";

import { Button } from "@/components/form";

export interface FilterButtonProps {
  isApplied?: boolean;
}

export const FilterButton = ({ isApplied = false }: FilterButtonProps) => {
  return (
    <PopoverTrigger>
      <Button
        fontWeight={isApplied ? "bold" : undefined}
        rightIcon={<Icon as={MdOutlineTune} />}
      >
        Filter
      </Button>
    </PopoverTrigger>
  );
};
