export const BREAKPOINT = Object.freeze({
  base: "0em",
  small: "30m",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

export const MEDIA_QUERY = Object.freeze({
  base: `(min-width: ${BREAKPOINT.base})`,
  small: `(min-width: ${BREAKPOINT.small})`,
  md: `(min-width: ${BREAKPOINT.md})`,
  lg: `(min-width: ${BREAKPOINT.lg})`,
  xl: `(min-width: ${BREAKPOINT.xl})`,
  "2xl": `(min-width: ${BREAKPOINT["2xl"]})`,
});
