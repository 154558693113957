export const EN = {
  COMMON: {
    ERROR: {
      FALLBACK_TITLE: "Something went wrong",
      FALLBACK_MESSAGE:
        "Please contact our support team if the problem persists.",
    },
  },
  CAMPAIGN: {
    ERROR: {
      VALIDATION_FAILED_TITLE: "Something went wrong creating your campaign",
      VALIDATION_FAILED_MESSAGE:
        "Please check whether all fields are entered correctly.",
    },
  },
  BILLING: {
    ERROR: {
      NO_PAYMENT_METHOD_TITLE: "No default payment method selected",
      NO_PAYMENT_METHOD_MESSAGE:
        "Please select at least one default payment method before proceeding.",

      DECLINED_TITLE: "Your card was declined",
      DECLINED_BY_ISSUER_MESSAGE:
        "Your card issuing bank declined the transaction. Please check your card info or use a different payment method and try again.",
    },
  },
};
