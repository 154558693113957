import {
  Box,
  ButtonGroup,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import {
  MdInfoOutline,
  MdLabelImportantOutline,
  MdMoreHoriz,
  MdOutlineMarkChatRead,
  MdOutlineMarkChatUnread,
  MdOutlinePersonAddAlt1,
  MdOutlineSchedule,
  MdPersonRemoveAlt1,
} from "react-icons/md";

import { MaybePromise } from "@/common";
import { Text } from "@/components/display";
import { ButtonBox, IconButton } from "@/components/form";
import { Avatar } from "@/components/media";
import { useBoolean } from "@/hooks";
import { useCreateLead, useLead, useRemoveLead } from "@/services/hooks/crm";
import { Conversation } from "@/services/types";

export interface ConversationChatHeaderProps {
  conversation: Conversation;
  isImportant: boolean;
  onMarkAsRead: (conversation: Conversation) => MaybePromise<void>;
  onMarkAsUnread: (conversation: Conversation) => MaybePromise<void>;
  onMarkAsImportant: (conversation: Conversation) => MaybePromise<void>;
  onMarkAsNotImportant: (conversation: Conversation) => MaybePromise<void>;
  onViewScheduledMessages: () => void;
  onViewInfo: () => void;
}

export const ConversationChatHeader = ({
  conversation,
  isImportant,
  onMarkAsRead,
  onMarkAsUnread,
  onMarkAsImportant,
  onMarkAsNotImportant,
  onViewScheduledMessages,
  onViewInfo,
}: ConversationChatHeaderProps) => {
  const [isConversationMenuOpen, openConversationMenu, closeConversationMenu] =
    useBoolean(false);

  const {
    data: hasLead,
    setInterimData: setInterimLead,
    isLoading: isLoadingLead,
    refetch: refetchLead,
  } = useLead(
    {
      id: conversation.recipient.id,
      fromLinkedInId: true,
      campaignId: conversation.campaignId,
    },
    {
      select: (data) => !isNil(data),
    }
  );

  const { mutateAsync: createLead, isLoading: isCreatingLead } = useCreateLead({
    onMutate: () => {
      // @ts-ignore
      // we dont care about the actual value as
      // we're just checking whether it exists or not
      return setInterimLead(() => conversation.recipient);
    },
    onSuccess: () => {
      refetchLead();
    },
  });

  const { mutateAsync: removeLead, isLoading: isRemovingLead } = useRemoveLead({
    onMutate: () => {
      return setInterimLead(() => null);
    },
    onSuccess: () => {
      refetchLead();
    },
  });

  return (
    <HStack
      spacing={4}
      px={{ base: 0, md: 2 }}
      py={2}
      pr={1}
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      <HStack as={ButtonBox} spacing={4} onClick={onViewInfo}>
        <Avatar
          size="sm"
          src={conversation.recipient.image ?? undefined}
          name={conversation.recipient.fullName}
        />

        <VStack spacing={1} alignItems="flex-start">
          <Text
            lineHeight="shorter"
            fontWeight="bold"
            color="gray.800"
            textAlign="left"
            noOfLines={2}
          >
            {conversation.recipient.fullName || "Anonymous"}
          </Text>

          <Text
            lineHeight="shorter"
            fontSize="sm"
            color="gray.600"
            textAlign="left"
          >
            Contacted via {conversation.sender.fullName}
          </Text>
        </VStack>
      </HStack>

      <ButtonGroup ml="auto !important">
        <IconButton
          icon={
            <Icon as={hasLead ? MdPersonRemoveAlt1 : MdOutlinePersonAddAlt1} />
          }
          variant="outline"
          isLoading={isLoadingLead}
          aria-label={hasLead ? "Remove from contacts" : "Add add contacts"}
          onClick={async () => {
            if (isCreatingLead || isRemovingLead) {
              return;
            }

            if (hasLead) {
              await removeLead({
                id: conversation.recipient.id,
                campaignId: conversation.campaignId,
              });
            } else {
              await createLead({
                profileId: conversation.recipient.id,
                campaignId: conversation.campaignId,
              });
            }
          }}
        />

        <Box>
          <Menu
            placement="bottom-end"
            isOpen={isConversationMenuOpen}
            onOpen={openConversationMenu}
            onClose={closeConversationMenu}
          >
            <MenuButton
              as={IconButton}
              icon={<Icon as={MdMoreHoriz} />}
              variant="ghost"
              aria-label="More menu"
            />

            <MenuList fontSize="sm">
              <MenuItem
                icon={
                  <Icon
                    as={
                      conversation.isRead
                        ? MdOutlineMarkChatUnread
                        : MdOutlineMarkChatRead
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                }
                onClick={() =>
                  conversation.isRead
                    ? onMarkAsUnread?.(conversation)
                    : onMarkAsRead?.(conversation)
                }
              >
                Mark as {conversation.isRead ? "Unread" : "Read"}
              </MenuItem>

              <MenuItem
                icon={
                  <Icon
                    as={MdLabelImportantOutline}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                }
                onClick={() =>
                  isImportant
                    ? onMarkAsNotImportant?.(conversation)
                    : onMarkAsImportant?.(conversation)
                }
              >
                Mark as {isImportant ? "Not Important" : "Important"}
              </MenuItem>

              <MenuItem
                icon={
                  <Icon
                    as={MdOutlineSchedule}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                }
                onClick={onViewScheduledMessages}
              >
                Scheduled Messages
              </MenuItem>

              <MenuItem
                icon={
                  <Icon
                    as={MdInfoOutline}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  />
                }
                onClick={onViewInfo}
              >
                Info
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </ButtonGroup>
    </HStack>
  );
};
