import { ComponentProps, memo, useCallback } from "react";

import { useConst } from "@chakra-ui/react";
import JoyrideRaw, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Status,
  Step,
} from "react-joyride";
import { ValueOf } from "type-fest";

import { MaybePromise } from "@/common";
import { useTour } from "@/contexts";
import { cssVar } from "@/dom";
import { useEventListener, useSessionStorage } from "@/hooks";
import { useCompleteTour } from "@/services/hooks/account";
import { TourStatus, TourType } from "@/services/types";

import { Beacon } from "./beacon";
import { Tooltip } from "./tooltip";

export interface TourProps extends Omit<ComponentProps<typeof Joyride>, "run"> {
  name: TourType;
  steps: Step[];
  preliminary?: boolean;
  restartOnReload?: boolean;
  callback?: (args: CallBackProps) => MaybePromise<void>;
  onComplete?: (name: string) => MaybePromise<void>;
  onSkip?: (name: string) => MaybePromise<void>;
}

const TourRaw = ({
  name,
  steps,
  preliminary = false,
  restartOnReload = false,
  callback,
  onComplete,
  onSkip,
  ...props
}: TourProps) => {
  const tourStatus = useTour(name);
  const { mutateAsync: completeTour } = useCompleteTour();

  const tourSessionKey = `${name}${steps.map((x) => x.target).join("")}`;
  const [tourSessionState, setTourSessionState] =
    useSessionStorage<TourSessionState>(`tour-session-${tourSessionKey}`, {
      stepIndex: 0,
      size: 0,
      status: "pending",
    });

  const floaterProps = useConst({
    styles: {
      wrapper: {
        zIndex: parseInt(cssVar("--cosiall-zIndices-popover")),
      },
    },
  });

  const styles = useConst({
    overlay: {
      zIndex: parseInt(cssVar("--cosiall-zIndices-overlay")),
    },
    options: {
      zIndex: parseInt(cssVar("--cosiall-zIndices-popover")),
    },
    tooltipContainer: {
      zIndex: parseInt(cssVar("--cosiall-zIndices-popover")),
    },
    tooltip: {
      zIndex: parseInt(cssVar("--cosiall-zIndices-popover")),
    },
    spotlight: {
      borderRadius: "var(--cosiall-radii-md)",
    },
  });

  const handleInit = useCallback(
    (size: number) => {
      // HACK: Using setTimeout because when we immediately update the state
      // using the default way, we end up messing up the Joyride popper.
      setTimeout(() => {
        setTourSessionState((prev) => ({
          stepIndex: restartOnReload ? 0 : prev.stepIndex,
          size,
          status: restartOnReload ? "pending" : prev.status,
        }));
      });
    },
    [restartOnReload, setTourSessionState]
  );

  const handleStepChange = useCallback(
    async (nextStepIndex: number, size?: number) => {
      const nextStep = steps[nextStepIndex];

      // @ts-ignore
      await nextStep?.onBeforeLoad?.();

      // HACK: Using setTimeout because when we immediately update the state
      // using the default way, we end up messing up the Joyride popper.
      setTimeout(() => {
        setTourSessionState((prev) => ({
          stepIndex: nextStepIndex,
          size: size ?? prev.size,
          status: nextStepIndex === size ? "completed" : "started",
        }));
      });
    },
    [steps, setTourSessionState]
  );

  const handleComplete = useCallback(
    async (status: ValueOf<Status> = "finished") => {
      if (status === "finished") {
        if (!preliminary) {
          await completeTour({ name });
        }

        await onComplete?.(name);
      }

      if (status === "skipped") {
        await completeTour({ name });
        await onSkip?.(name);
      }

      // HACK: Using setTimeout because when we immediately update the state
      // using the default way, we end up messing up the Joyride popper.
      setTimeout(() => {
        setTourSessionState((prev) => ({
          ...prev,
          status: "completed",
        }));
      });
    },
    [name, preliminary, setTourSessionState, completeTour, onSkip, onComplete]
  );

  const handleCallback = useCallback(
    async (args: CallBackProps) => {
      const { type, status, action, index, size } = args;

      const cast = <T,>(value: unknown) => value as T;

      if (cast<string[]>([EVENTS.TOUR_START]).includes(type)) {
        handleInit(size);
      }

      if (cast<string[]>([EVENTS.STEP_AFTER]).includes(type)) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        await handleStepChange(nextStepIndex, size);
      }

      if (cast<string[]>([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
        await handleComplete(status);
      }

      await callback?.(args);
    },
    [handleInit, handleStepChange, handleComplete, callback]
  );

  useEventListener(
    "mousedown",
    async (ev) => {
      const currentStep = steps[tourSessionState?.stepIndex];

      // @ts-ignore
      if (!currentStep?.proceedOnTargetClick) return;

      const eventTargetElement = ev.target as HTMLElement;

      const stepTargetSelector = steps[tourSessionState.stepIndex].target;
      const stepTargetElement = document.querySelector(
        stepTargetSelector as string
      );

      if (!stepTargetElement?.contains(eventTargetElement)) return;

      const isLastStep =
        tourSessionState.stepIndex + 1 === tourSessionState.size;

      if (isLastStep) {
        await handleComplete(STATUS.FINISHED);
      } else {
        await handleStepChange(tourSessionState.stepIndex + 1);
      }
    },
    window
  );

  if (!tourStatus) return null;

  // HACK: When we rerender the component the popper position seems to get messed up.
  // That's the reason why Joyride is memoized, in efforts to avoid rerendering the component...
  return (
    <Joyride
      key={tourSessionKey}
      run={
        tourStatus !== "completed" && tourSessionState.status !== "completed"
      }
      steps={steps}
      stepIndex={tourSessionState.stepIndex}
      continuous
      scrollToFirstStep
      showSkipButton
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
      tooltipComponent={Tooltip}
      beaconComponent={Beacon}
      floaterProps={floaterProps}
      styles={styles}
      callback={handleCallback}
      {...props}
    />
  );
};

export const Tour = memo(TourRaw);

const Joyride = memo(JoyrideRaw);

interface TourSessionState {
  stepIndex: number;
  size: number;
  status: TourStatus;
}
