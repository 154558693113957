import { StyleConfig } from "@chakra-ui/react";

export const Badge: StyleConfig = {
  baseStyle: {
    fontFamily: "heading",
  },
  variants: {
    subtle: ({ theme, colorScheme }) => ({
      color: `${colorScheme}.500`,
      background: `${theme.colors[colorScheme][50]}`,
    }),
    outline: ({ theme, colorScheme }) => ({
      color: `${colorScheme}.500`,
      boxShadow: `inset 0 0 0px 1px ${theme.colors[colorScheme][500]}`,
    }),
  },
};
