import { RefObject, useEffect, useRef } from "react";

export const useElementRef = <T extends HTMLElement>(
  selector: string
): RefObject<T> => {
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    const element = document.querySelector(selector) as T;
    elementRef.current = element;
  }, [selector]);

  return elementRef;
};
