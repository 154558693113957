import { useRef } from "react";

import { useEffectOnce } from "./use-effect-once";

export const useUnmount = (callback: () => void) => {
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  useEffectOnce(() => () => callbackRef.current());
};
