import { Fragment, useCallback, useRef, useState } from "react";

import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import {
  MdMoreHoriz,
  MdOutlineCancelScheduleSend,
  MdOutlineScheduleSend,
} from "react-icons/md";

import { Nullish } from "@/common";
import { Modal, ModalProps, QuerySuspense } from "@/components/disclosure";
import { Text } from "@/components/display";
import { IconButton } from "@/components/form";
import { useEffectOnce } from "@/hooks";
import { ChatBubble } from "@/partials/conversations";
import {
  useCancelScheduledMessage,
  useScheduledMessages,
  useUpdateScheduledMessage,
} from "@/services/hooks/conversations";
import { Conversation, ScheduledMessage } from "@/services/types";
import { ArrayUtils, DateUtils } from "@/utils";

import { ScheduleMessageModal } from "./schedule-message-modal";

export interface ScheduledMessagesModalProps
  extends Omit<ModalProps, "children"> {
  conversation: Conversation;
}

export const ScheduledMessagesModal = ({
  conversation,
  ...props
}: ScheduledMessagesModalProps) => {
  const chatboxEndRef = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<Record<number, boolean>>({});
  const [scheduledMessageModalState, setScheduledMessageModalState] = useState<{
    isOpen: boolean;
    message: Nullish<ScheduledMessage>;
  }>({ isOpen: false, message: null });

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      chatboxEndRef.current?.scrollIntoView();
    });
  }, []);

  useEffectOnce(() => {
    scrollToBottom();
  });

  const {
    data: messages,
    setInterimData: setInterimMessages,
    status: scheduledMessagesStatus,
  } = useScheduledMessages(
    { id: conversation.id },
    { select: (x) => x.elements, enableSkeletonData: true }
  );

  const { mutateAsync: updateScheduledMessage } = useUpdateScheduledMessage({
    onMutate: ({ messageId, data }) => {
      return setInterimMessages((draft) => {
        const message = draft?.elements.find((x) => x.id === messageId);
        if (!message) return;

        message.text = data.text;
        message.scheduledDate = data.scheduledDate;
      });
    },
  });

  const { mutateAsync: cancelScheduledMessage } = useCancelScheduledMessage({
    onMutate: ({ messageId }) => {
      return setInterimMessages((draft) => {
        ArrayUtils.spliceWhere(
          draft?.elements ?? [],
          (x) => x.id === messageId
        );
      });
    },
  });

  return (
    <Modal size="lg" {...props}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Scheduled messages</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <ScheduleMessageModal
            isOpen={scheduledMessageModalState.isOpen}
            onSchedule={async (date) => {
              if (!scheduledMessageModalState.message) return;

              await updateScheduledMessage({
                id: conversation.id,
                messageId: scheduledMessageModalState.message.id,
                data: {
                  text: scheduledMessageModalState.message.text!,
                  scheduledDate: date.toISOString(),
                },
              });
            }}
            onClose={() => {
              setScheduledMessageModalState({ isOpen: false, message: null });
            }}
          />

          <QuerySuspense status={scheduledMessagesStatus}>
            <VStack
              position="relative"
              alignItems="flex-start"
              spacing={2}
              h="full"
            >
              {!!messages?.length ? (
                messages?.map((message, messageIndex) => (
                  <Fragment key={message.id}>
                    {DateUtils.areSameDay(
                      message.scheduledDate,
                      messages[messageIndex - 1]?.scheduledDate
                    ) ? null : (
                      <Text
                        mx="auto"
                        fontSize="xs"
                        fontWeight="bold"
                        color="gray.500"
                      >
                        {DateUtils.toRelativeCalendar(message.scheduledDate)}
                      </Text>
                    )}

                    <ChatBubble
                      conversation={conversation}
                      message={{
                        id: message.id,
                        isOurs: true,
                        sentDate: message.scheduledDate,
                        text: message.text,
                        status: message.status === "failed" ? "failed" : "sent",
                      }}
                      menu={(isVisible) => (
                        <Menu
                          isOpen={isMenuOpen[message.id]}
                          onClose={() =>
                            setIsMenuOpen((x) => ({
                              ...x,
                              [message.id]: false,
                            }))
                          }
                        >
                          <MenuButton
                            as={IconButton}
                            icon={<Icon as={MdMoreHoriz} />}
                            size="xs"
                            borderRadius="full"
                            visibility={
                              isVisible || isMenuOpen[message.id]
                                ? "visible"
                                : "hidden"
                            }
                            onClick={() =>
                              setIsMenuOpen((x) => ({
                                ...x,
                                [message.id]: true,
                              }))
                            }
                          />

                          <MenuList minW={32} py={1} fontSize="sm">
                            <MenuItem
                              icon={<Icon as={MdOutlineScheduleSend} />}
                              onClick={() => {
                                setScheduledMessageModalState({
                                  isOpen: true,
                                  message,
                                });
                              }}
                            >
                              Reschedule
                            </MenuItem>

                            <MenuItem
                              icon={<Icon as={MdOutlineCancelScheduleSend} />}
                              onClick={() =>
                                cancelScheduledMessage({
                                  id: conversation.id,
                                  messageId: message.id,
                                })
                              }
                            >
                              Cancel
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      )}
                    />
                  </Fragment>
                ))
              ) : (
                <Text color="gray.600">
                  You have no scheduled messages for this conversation
                </Text>
              )}

              <Box
                ref={chatboxEndRef}
                w={0}
                h={0}
                opacity={0}
                visibility="hidden"
              />
            </VStack>
          </QuerySuspense>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
