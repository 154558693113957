import { useState } from "react";

import { Box } from "@chakra-ui/react";
import { isEmpty, isNil } from "lodash";
import { z } from "zod";

import {
  Editable,
  EditableField,
  EditablePreview,
  Input,
  useIsDirty,
} from "@/components/form";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const TitleEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<string>) => {
  const [nextValue, setNextValue] = useState(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const isDirty = useIsDirty(defaultValue || "", nextValue || "");

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Title</EditableFieldLabel>

      <Editable
        label="Title"
        isReadOnly={isReadOnly}
        isDisabled={!isNil(error)}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          if (!isNil(error)) return;
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>{defaultValue}</EditablePreview>

        <EditableField>
          <Input
            w="full"
            value={nextValue || ""}
            onChange={(value) => {
              schema.safeParseAsync(value).then((result) => {
                if (result.success) {
                  setError(null);
                } else {
                  setError(result.error.issues[0].message);
                }
              });

              setNextValue(value);
              onChange?.(value);
            }}
          />
        </EditableField>
      </Editable>
    </Box>
  );
};

const schema = z.string().nonempty().max(50);
