import { Appearance } from "@stripe/stripe-js";

export const STRIPE_THEME: Appearance = {
  theme: "flat",
  variables: {
    colorBackground: "#F4F2F6",
    colorText: "#584E65",
    colorTextPlaceholder: "#948AA1",
    colorDanger: "#E53E3E",
    borderRadius: "8px",
    fontSize3Xs: "0.45rem",
    fontSize2Xs: "0.625rem",
    fontSizeXs: "0.75em",
    fontSizeSm: "0.875em",
    fontSizeBase: "1em",
    fontSizeLg: "1.125em",
    fontSizeXl: "1.25em",
  },
  rules: {
    ".Label": {
      marginBottom: "0.5rem",
      fontSize: "var(--fontSizeBase)",
      fontWeight: "600",
    },
    ".Input": {
      backgroundColor: "#F4F2F6",
      padding: "0.875rem 1rem 0.875rem 1rem",
      border: "2px solid",
      borderColor: "transparent",
      outline: "none",
      boxShadow: "none",
      transition:
        "background-color 200ms, border-color 200ms, color 200ms, opacity 200ms, box-shadow 200ms",
    },
    ".Input:hover": {
      // Disabled because it overrides Input:focus styles...
      // backgroundColor: "#E2DFE7",
    },
    ".Input:focus": {
      backgroundColor: "transparent",
      borderColor: "#3C8BB8",
      outline: "none",
      boxShadow: "none",
    },
    ".Input--invalid": {
      borderColor: "#E53E3E",
      outline: "none",
      boxShadow: "none",
    },
    ".Error": {
      marginTop: "0.5rem",
    },
  },
};
