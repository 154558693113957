import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services/campaign-service";
import {
  UseInfiniteQuerySimpleOptions,
  useInfiniteQuery,
} from "@/services/hooks";
import { gen, repeat } from "@/utils";

const PAGINATION_LIMIT = 10;

type QueryArgs = Parameters<typeof CampaignService.getReach>[0];
type QueryFnData = AsyncReturnType<typeof CampaignService.getReach>;
type Error = unknown;

const queryKey = (args: QueryArgs) => ["campaigns", args.id, "reach"];

export const useReach = <TData = QueryFnData, TFlatData = TData>(
  args: QueryArgs,
  options?: UseInfiniteQuerySimpleOptions<QueryFnData, Error, TData, TFlatData>
) => {
  const response = useInfiniteQuery<QueryFnData, Error, TData, TFlatData>(
    queryKey(args),
    ({ pageParam = 0 }) =>
      CampaignService.getReach({
        ...args,
        skip: pageParam,
        limit: PAGINATION_LIMIT,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        let { skip, limit, total } = pagination;
        skip ??= 0;
        limit ??= 10;
        total ??= 0;

        const hasMorePages = skip + limit < total;

        return hasMorePages ? skip + PAGINATION_LIMIT : undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const { pagination } = firstPage;
        let { skip } = pagination;
        skip ??= 0;

        const hasPreviousPages = !!pagination.skip;

        return hasPreviousPages
          ? Math.max(skip - PAGINATION_LIMIT, 0)
          : undefined;
      },
      skeletonData: useConst({
        pageParams: [],
        pages: [
          {
            elements: repeat(gen.profiles, 3),
            pagination: {
              skip: 0,
              limit: 10,
              total: 3,
            },
          },
        ],
      }),
      ...options,
    }
  );

  return response;
};

useReach.queryKey = queryKey;
