import { KeyboardEvent, useCallback, useState } from "react";

import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Grid,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MdContentCopy, MdDeleteOutline, MdMoreHoriz } from "react-icons/md";

import { Badge, Heading, Tag, Text } from "@/components/display";
import { IconButton } from "@/components/form";
import { KEY_CODE } from "@/dom";
import { Campaign } from "@/services/types";

export interface CampaignGridProps extends Omit<BoxProps, "onSelect"> {
  campaigns?: Campaign[];
  onSelect: (campaign: Campaign) => void;
  onClone?: (campaign: Campaign) => void;
  onRemove?: (campaign: Campaign) => void;
}

export const CampaignGrid = ({
  campaigns,
  onSelect,
  onClone,
  onRemove,
  ...props
}: CampaignGridProps) => {
  return (
    <Box {...props}>
      {!!campaigns?.length && (
        <Grid
          w="full"
          maxW="inherit"
          gap={4}
          gridAutoRows="1fr"
          gridTemplateColumns="repeat(auto-fill, minmax(var(--cosiall-sizes-2xs), 1fr))"
        >
          {campaigns.map((campaign) => (
            <CampaignGridItem
              key={campaign.id}
              data-tour-step="campaign-grid__card"
              campaign={campaign}
              onSelect={() => onSelect(campaign)}
              onClone={() => onClone?.(campaign)}
              onRemove={() => onRemove?.(campaign)}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export interface CampaignGridItemProps extends Omit<CardProps, "onSelect"> {
  campaign: Campaign;
  onSelect: () => void;
  onClone?: () => void;
  onRemove?: () => void;
}

const CampaignGridItem = ({
  campaign,
  onSelect,
  onClone,
  onRemove,
  ...props
}: CampaignGridItemProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === KEY_CODE.ENTER) {
        onSelect();
      }
    },
    [onSelect]
  );

  return (
    <Card
      tabIndex={0}
      data-tour-step="campaign-grid__item"
      variant="outline"
      size="sm"
      p={2}
      borderRadius="md"
      transitionProperty="common"
      transitionDuration="normal"
      _hover={{
        cursor: "pointer",
        boxShadow: "outline !important",
        borderColor: "transparent",
      }}
      _focusVisible={{
        cursor: "pointer",
        boxShadow: "outline",
        borderColor: "transparent",
        outline: "none",
      }}
      onClick={onSelect}
      onKeyDown={handleKeyDown}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      {...props}
    >
      <CardHeader
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading as="h3" size="sm" noOfLines={1}>
          {campaign.title}
        </Heading>

        {!!campaign.group && (
          <Badge colorScheme="purple" ml={1}>
            {campaign.group?.name}
          </Badge>
        )}
      </CardHeader>

      <CardBody>
        <Box minH={16}>
          <Text noOfLines={3}>{campaign.description}</Text>
        </Box>
      </CardBody>

      <CardFooter minH={12}>
        <Wrap spacing={1}>
          {campaign.categories?.map((category) => (
            <WrapItem key={category.id}>
              <Tag>{category.label}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      </CardFooter>

      {(isMouseOver || isMenuOpen) && (
        <Menu
          placement="bottom-end"
          isOpen={isMenuOpen}
          onOpen={() => setIsMenuOpen(true)}
          onClose={() => setIsMenuOpen(false)}
        >
          <MenuButton
            as={IconButton}
            icon={<Icon as={MdMoreHoriz} />}
            size="xs"
            variant="outline"
            position="absolute"
            right={4}
            bottom={4}
            background="white"
            borderRadius="full"
            onClick={(ev) => {
              setIsMenuOpen(true);

              ev.stopPropagation();
              return false;
            }}
          />

          <MenuList fontSize="sm">
            <MenuItem
              icon={<Icon as={MdContentCopy} />}
              onClick={(ev) => {
                onClone?.();

                ev.stopPropagation();
                return false;
              }}
            >
              Clone
            </MenuItem>

            {(!campaign.status || campaign.status === "draft") && (
              <MenuItem
                icon={<Icon as={MdDeleteOutline} />}
                onClick={(ev) => {
                  onRemove?.();

                  ev.stopPropagation();
                  return false;
                }}
              >
                Remove
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </Card>
  );
};
