import { useCallback, useEffect, useMemo } from "react";

import { Box } from "@chakra-ui/layout";
import { BoxProps, HStack, Icon } from "@chakra-ui/react";
import { toInteger } from "lodash";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

import { Button, IconButton } from "@/components/form";

import { usePagination } from "./use-pagination";

export interface PaginatorProps extends BoxProps {
  variant?: "default" | "unstyled";
  totalCount: number;
  currentPage: number;
  pageSize: number;
  siblingCount?: number;
  onPageChange: (page: number) => void;
}

export const Paginator = ({
  variant = "default",
  totalCount,
  currentPage,
  pageSize,
  siblingCount,
  onPageChange,
  ...props
}: PaginatorProps) => {
  const separator = "|";

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    separator,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  const variantStyles = useMemo(() => variants[variant], [variant]);

  useEffect(() => {
    if (toInteger(currentPage) > toInteger(lastPage)) {
      // Make sure currentPage does not exceed lastPage
      onPageChange(toInteger(lastPage));
    }
  }, [lastPage, currentPage, onPageChange]);

  const handleNextBtnClick = useCallback(() => {
    onPageChange(currentPage + 1);
  }, [currentPage, onPageChange]);

  const handlePreviousBtnClick = useCallback(() => {
    onPageChange(currentPage - 1);
  }, [currentPage, onPageChange]);

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  return (
    <Box {...variantStyles} {...props}>
      <HStack>
        <IconButton
          icon={<Icon as={MdNavigateBefore} />}
          size="xs"
          aria-label="Previous page"
          isDisabled={currentPage === 1}
          onClick={handlePreviousBtnClick}
        />

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === separator) {
            return (
              <Box
                key={index}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                userSelect="none"
                minW="20px"
                textAlign="center"
              >
                ···
              </Box>
            );
          }

          return (
            <Button
              key={index}
              size="xs"
              background={currentPage === pageNumber ? "gray.500" : undefined}
              pointerEvents={currentPage === pageNumber ? "none" : undefined}
              color={currentPage === pageNumber ? "white" : undefined}
              onClick={() => onPageChange(toInteger(pageNumber))}
            >
              {pageNumber}
            </Button>
          );
        })}

        <IconButton
          icon={<Icon as={MdNavigateNext} />}
          size="xs"
          aria-label="Next page"
          isDisabled={currentPage === lastPage}
          onClick={handleNextBtnClick}
        />
      </HStack>
    </Box>
  );
};

const variants: Record<
  NonNullable<PaginatorProps["variant"]>,
  Partial<BoxProps>
> = {
  default: {
    background: "gray.100",
    borderRadius: "md",
    w: "fit-content",
    p: 1,
    mx: {
      base: "auto",
      md: "unset",
    },
  },
  unstyled: {},
};
