import { createContext } from "react";

import { HealthOverlay } from "@/screens";
import { useHealth } from "@/services/hooks/app";
import { WithChildren } from "@/ui/types";

export interface HealthContextProps {
  isHealthy: boolean;
}

export const HealthContext = createContext<HealthContextProps>({
  isHealthy: true,
});

export interface HealthProviderProps extends WithChildren {}

export const HealthProvider = ({ children }: HealthProviderProps) => {
  const { data: isHealthy, failureCount } = useHealth({
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    retry: true,
  });

  return (
    <HealthContext.Provider value={{ isHealthy: isHealthy! }}>
      {!isHealthy && failureCount > 3 && <HealthOverlay />}
      {children}
    </HealthContext.Provider>
  );
};
