import qs from "qs";

import { Notification } from "@/services/types";

export const NOTIFICATION_TYPE = Object.freeze({
  CAMPAIGN_CREATED: "campaignCreated",
  CAMPAIGN_PUBLISHED: "campaignPublished",
  CAMPAIGN_PAUSED: "campaignPaused",
  CAMPAIGN_ENDED: "campaignEnded",
  CAMPAIGN_PAYMENT_SUCCEEDED: "campaignPaymentSucceeded",
  CAMPAIGN_PAYMENT_FAILED: "campaignPaymentFailed",
  MESSAGE_RECEIVED: "messageReceived",
});

export const parseNotification = (notification: Notification) => {
  const payload = JSON.parse(notification.payload);

  switch (notification.type) {
    case NOTIFICATION_TYPE.CAMPAIGN_CREATED: {
      const { id, title } = payload;

      return {
        id: notification.id,
        payload,
        link: {
          pathname: `/campaigns/${id}`,
        },
        date: notification.createdDate,
        state: notification.state,
        persistent: false,
        message: (
          <>
            Your campaign <b>{title}</b> has been created and is now awaiting
            review.
          </>
        ),
      };
    }

    case NOTIFICATION_TYPE.CAMPAIGN_PUBLISHED: {
      const { id, title } = payload;

      return {
        id: notification.id,
        payload,
        link: {
          pathname: `/campaigns/${id}`,
        },
        date: notification.createdDate,
        state: notification.state,
        persistent: true,
        message: (
          <>
            Your campaign <b>{title}</b> has been published.
          </>
        ),
      };
    }

    case NOTIFICATION_TYPE.CAMPAIGN_PAUSED: {
      const { id, title } = payload;

      return {
        id: notification.id,
        payload,
        link: {
          pathname: `/campaigns/${id}`,
        },
        date: notification.createdDate,
        state: notification.state,
        persistent: true,
        message: (
          <>
            Your campaign <b>{title}</b> has been paused.
          </>
        ),
      };
    }

    case NOTIFICATION_TYPE.CAMPAIGN_ENDED: {
      const { id, title } = payload;

      return {
        id: notification.id,
        payload,
        link: {
          pathname: `/campaigns/${id}`,
        },
        date: notification.createdDate,
        state: notification.state,
        persistent: true,
        message: (
          <>
            Your campaign <b>{title}</b> has ended.
          </>
        ),
      };
    }

    case NOTIFICATION_TYPE.CAMPAIGN_PAYMENT_FAILED: {
      const { id, title } = payload;

      return {
        id: notification.id,
        payload,
        link: {
          pathname: `/campaigns/${id}`,
        },
        date: notification.createdDate,
        state: notification.state,
        persistent: false,
        message: (
          <>
            Payment for campaign <b>{title}</b> was declined. Please review your
            payment method and try again.
          </>
        ),
      };
    }

    case NOTIFICATION_TYPE.CAMPAIGN_PAYMENT_SUCCEEDED: {
      const { id, title } = payload;

      return {
        id: notification.id,
        payload,
        link: {
          pathname: `/campaigns/${id}`,
        },
        date: notification.createdDate,
        state: notification.state,
        persistent: true,
        message: (
          <>
            Payment for campaign <b>{title}</b> was successful and is now
            awaiting review.
          </>
        ),
      };
    }

    case NOTIFICATION_TYPE.MESSAGE_RECEIVED: {
      const { conversationId, lastMessage, sender, sentDate } = payload;

      return {
        id: notification.id,
        payload,
        title: "You've got a new message",
        link: {
          pathname: "/conversations",
          search: qs.stringify({ conversation: conversationId }),
        },
        date: sentDate,
        state: notification.state,
        audible: true,
        persistent: false,
        message: (
          <>
            <b>{sender}:</b> {lastMessage || "File"}
          </>
        ),
      };
    }

    default:
      break;
  }
};
