import { isNil } from "lodash";
import { useLocation } from "react-router-dom";

export interface UseRefererProps {
  defaultUri: string;
  defaultDisplayName: string;
}

export const useReferer = ({
  defaultUri,
  defaultDisplayName,
}: UseRefererProps) => {
  const { state } = useLocation();

  const {
    refererUri,
    refererDisplayName,
  }: { refererUri: string; refererDisplayName: string } = state || {};

  return {
    hasReferer: !isNil(refererUri),
    refererUri: refererUri ?? defaultUri,
    refererDisplayName: refererDisplayName ?? defaultDisplayName,
  };
};
