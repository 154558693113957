import { useRef } from "react";

import { FocusLock, Portal, useOutsideClick } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { RemoveScroll } from "react-remove-scroll";

import { APP_ROOT_ID, PAGE_HEADER_ID } from "@/components/layout";
import { useElementSize } from "@/hooks";
import { MotionBox } from "@/styles/motion";
import { useMediaQuery } from "@/ui/hooks";
import { WithChildren } from "@/ui/types";

import { DrawerProvider } from "./drawer-context";

export interface DrawerProps extends WithChildren {
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer = ({ isOpen, onClose, children }: DrawerProps) => {
  const [isMdScreen] = useMediaQuery("md");

  const containerRef = useRef<HTMLDivElement>(null);

  const { height: headerHeight } = useElementSize(`#${PAGE_HEADER_ID}`);

  useOutsideClick({
    ref: containerRef,
    handler: (ev) => {
      const target = ev.target as HTMLElement;

      // Target is not within another portal
      if (target.closest(APP_ROOT_ID)) {
        onClose();
      }
    },
    enabled: isOpen,
  });

  return (
    <Portal>
      <DrawerProvider value={{ isOpen, onClose }}>
        <AnimatePresence>
          {isOpen && (
            <FocusLock autoFocus={false} isDisabled={isMdScreen}>
              <RemoveScroll enabled={!isMdScreen} removeScrollBar={false}>
                <MotionBox
                  ref={containerRef}
                  position="fixed"
                  top={`${headerHeight}px`}
                  right={0}
                  w="full"
                  maxW="md"
                  h={`calc(100vh - ${headerHeight}px)`}
                  overflowY="auto"
                  zIndex="drawer"
                  background="whiteAlpha.800"
                  borderLeft="1px solid"
                  borderColor="gray.200"
                  boxShadow="lg"
                  backdropFilter="blur(8px)"
                  overscrollBehavior="contain"
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{
                    type: "tween",
                    ease: "easeOut",
                  }}
                >
                  {children}
                </MotionBox>
              </RemoveScroll>
            </FocusLock>
          )}
        </AnimatePresence>
      </DrawerProvider>
    </Portal>
  );
};
