import { useCallback, useState } from "react";

import { CloseButton } from "@chakra-ui/close-button";
import { Box, HStack, LinkBox, LinkOverlay } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { SlideFade } from "@chakra-ui/transition";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { useInterval } from "@/hooks";
import { NotificationService } from "@/services";
import { useQueryClient } from "@/services/hooks";
import { useNotifications } from "@/services/hooks/notifications";
import { DateUtils } from "@/utils";

export const NotificationToast = ({
  id,
  toastId,
  title,
  message,
  link,
  date,
  visible,
}: any) => {
  const queryClient = useQueryClient();

  const [relativeDate, setRelativeDate] = useState<string | null>();

  useInterval(
    () => {
      const relativeDate = DateUtils.toRelative(date);
      setRelativeDate(relativeDate);
    },
    visible ? 1000 : null
  );

  const handleClick = useCallback(async () => {
    toast.dismiss(toastId);

    await NotificationService.markAsRead({
      notifications: [id],
      state: "read",
    });

    queryClient.invalidateQueries(useNotifications.queryKey());
  }, [id, toastId, queryClient]);

  return (
    <SlideFade in={visible}>
      <Box fontSize="16px">
        <Box
          w="50ch"
          maxW="90vw"
          pb={2}
          borderRadius="md"
          boxShadow="md"
          border="1px solid"
          borderColor="gray.200"
          bgColor="white"
          fontSize="md"
        >
          <HStack justifyContent="space-between" mb={2} pt={4} px={4}>
            <Text as="span" fontWeight="semibold" fontFamily="heading">
              {title || "New  notification"}
            </Text>

            <CloseButton
              size="sm"
              onClick={() => {
                toast.dismiss(toastId);
              }}
            />
          </HStack>

          <LinkBox
            px={4}
            py={2}
            transitionProperty="background-color"
            transitionDuration="ultra-fast"
            transitionTimingFunction="linear"
            _hover={{
              bgColor: "gray.50",
            }}
            onClick={handleClick}
          >
            <LinkOverlay as={Link} to={link}>
              <Box fontSize="sm">
                <Text mb={2} color="gray.600" noOfLines={3}>
                  {message}
                </Text>

                <Text as="span" color="blue.500">
                  {relativeDate ?? DateUtils.toRelative(date)}
                </Text>
              </Box>
            </LinkOverlay>
          </LinkBox>
        </Box>
      </Box>
    </SlideFade>
  );
};
