import { Box, BoxProps } from "@chakra-ui/react";
import { Reorder } from "framer-motion";
import { toInteger } from "lodash";

import { Text } from "@/components/display";
import { NumberInput } from "@/components/form";
import { SequenceMessagePreview } from "@/partials/campaigns";

import { SequenceMessage } from "./types";

export interface SequenceMessageListItemProps
  extends Omit<BoxProps, "onChange"> {
  value: SequenceMessage;
  hasOffset?: boolean;
  isDraggable?: boolean;
  isReadOnly?: boolean;
  onChange: (value: SequenceMessage) => void;
  onRemove: () => void;
}

export const SequenceMessageListItem = ({
  value: message,
  hasOffset = true,
  isDraggable = true,
  isReadOnly = false,
  onChange,
  onRemove,
}: SequenceMessageListItemProps) => {
  return (
    <Box
      as={Reorder.Item}
      value={message}
      dragListener={isDraggable ? undefined : false}
      cursor={isDraggable ? "grab" : "default"}
      borderRadius="md"
    >
      {hasOffset && (
        <Box display="inline-block" mb={4}>
          <Text as="span" display="inline" fontSize="sm">
            Send next message
          </Text>

          {isReadOnly ? (
            <Text
              as="span"
              display="inline"
              fontSize="sm"
              fontWeight="semibold"
            >
              {" "}
              {message.offset}{" "}
            </Text>
          ) : (
            <NumberInput
              value={message.offset}
              isInvalid={false}
              buttonPlacement="around"
              size="xs"
              allowMouseWheel
              display="inline-flex"
              min={1}
              max={14}
              maxW={20}
              mx={1}
              borderRadius="xl"
              textAlign="center"
              onChange={(value) => {
                onChange({ ...message, offset: toInteger(value) });
              }}
            />
          )}

          <Text as="span" display="inline" fontSize="sm">
            days after previous message
          </Text>
        </Box>
      )}

      <SequenceMessagePreview
        value={message.content}
        isReadOnly={isReadOnly}
        onChange={(value) => {
          onChange({ ...message, content: value });
        }}
        onRemove={() => {
          onRemove();
        }}
      />
    </Box>
  );
};
