import { useEffect, useRef } from "react";

import {
  FieldValues,
  UseFormProps,
  useForm as useReactHookForm,
} from "react-hook-form";

import { useQuerySuspense } from "@/components/disclosure";

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues = TFieldValues
>({
  suspended = false,
  ...props
}: UseFormProps<TFieldValues, TContext> & { suspended?: boolean }) => {
  const { defaultValues } = props;

  const { isSuspended } = useQuerySuspense();

  const hasSetDefaultValues = useRef(false);

  const methods = useReactHookForm<TFieldValues, TContext, TTransformedValues>({
    mode: "all",
    ...props,
  });

  const { reset } = methods;

  // Fixes default values after suspense
  useEffect(() => {
    if (!hasSetDefaultValues.current && !suspended && !isSuspended) {
      reset(defaultValues as any, { keepDirtyValues: true });
      hasSetDefaultValues.current = true;
    }
  }, [reset, suspended, isSuspended, defaultValues]);

  return methods;
};
