import { KeyboardEvent, useCallback, useMemo } from "react";

import { Box, HStack } from "@chakra-ui/layout";
import { BoxProps, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FaLinkedinIn } from "react-icons/fa";

import { Badge, Text } from "@/components/display";
import { IconButton } from "@/components/form";
import { Avatar } from "@/components/media";
import { KEY_CODE } from "@/dom";
import { useNavigate } from "@/router";
import { ContactRequest } from "@/services/openapi";
import { Contact } from "@/services/types";
import { DateUtils } from "@/utils";

import { EmailPopoverField, PhonePopoverField } from "./form/fields";

export interface ContactTableProps extends BoxProps {}

export const ContactTable = ({ children, ...props }: ContactTableProps) => {
  return (
    <Box overflowX="auto" px={1} {...props}>
      <Table
        variant="simple"
        w="full"
        mt={0}
        __css={{
          "& *": {
            overflowWrap: "normal !important",
          },
        }}
      >
        <Thead>
          <Tr>
            <Th px={2}>Name</Th>
            <Th px={2}>Email</Th>
            <Th px={2}>Phone</Th>
            <Th w="10%" px={2}>
              Social
            </Th>
            <Th w="10%" px={2}>
              Connected
            </Th>
          </Tr>
        </Thead>

        <Tbody>{children}</Tbody>
      </Table>
    </Box>
  );
};

export interface ContactRowProps extends BoxProps {
  contact: Contact;
  onPropertyChange: <TKey extends keyof ContactRequest>(
    property: TKey,
    value: ContactRequest[TKey]
  ) => void;
}

export const ContactRow = ({
  contact,
  onPropertyChange,
  ...props
}: ContactRowProps) => {
  const navigate = useNavigate();

  const profilePicture = contact.linkedInProfile.pictures.sort(
    (x, y) => y.width - x.width
  )[0];

  const navigateToContactDetailScreen = useCallback(() => {
    navigate(`/contacts/${contact.id}`);
  }, [contact.id, navigate]);

  const handleRowClick = useCallback(() => {
    navigateToContactDetailScreen();
  }, [navigateToContactDetailScreen]);

  const handleRowKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === KEY_CODE.ENTER) {
        navigateToContactDetailScreen();
      }
    },
    [navigateToContactDetailScreen]
  );

  const isRecentContact = useMemo(
    () =>
      DateUtils.areSameDay(
        DateTime.now().toUTC().toJSDate(),
        DateTime.fromISO(contact.createdDate, { zone: "utc" }).toJSDate()
      ),
    [contact.createdDate]
  );

  return (
    <Tr
      tabIndex={0}
      cursor="pointer"
      borderRadius="md"
      _focusVisible={{
        boxShadow: "outline",
        outline: "none",
      }}
      _hover={{
        background: "gray.50",
      }}
      transition="background var(--transition-very-fast) linear"
      onClick={handleRowClick}
      onKeyDown={handleRowKeyDown}
      {...props}
    >
      <Td px={2}>
        <HStack spacing={4}>
          <Avatar src={profilePicture?.url} name={contact.fullName} />

          <HStack spacing={2}>
            <Text as="span" fontWeight="semibold">
              {contact.fullName}
            </Text>

            {isRecentContact && (
              <Badge colorScheme="green" hideOnSuspense>
                New
              </Badge>
            )}
          </HStack>
        </HStack>
      </Td>

      <Td px={2}>
        <EmailPopoverField
          id={`email-form-field-${contact.id}`}
          defaultValue={contact.email}
          orientation="horizontal"
          hidePreviewLabel
          onChange={(value) => onPropertyChange("email", value)}
        />
      </Td>

      <Td px={2}>
        <PhonePopoverField
          id={`phone-form-field-${contact.id}`}
          defaultValue={contact.phoneNumber}
          orientation="horizontal"
          hidePreviewLabel
          onChange={(value) => onPropertyChange("phoneNumber", value)}
        />
      </Td>

      <Td px={2}>
        <HStack spacing={2}>
          <IconButton
            as="a"
            href={contact.linkedInProfile.flagshipProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
            size="xs"
            icon={<FaLinkedinIn />}
            aria-label="LinkedIn profile link"
            onClick={(ev) => ev.stopPropagation()}
            onKeyDown={(ev) => ev.stopPropagation()}
          />
        </HStack>
      </Td>

      <Td px={2}>
        <Badge>{DateUtils.toRelative(contact.createdDate)}</Badge>
      </Td>
    </Tr>
  );
};
