import {
  Skeleton as ChakraSkeleton,
  SkeletonProps as ChakraSkeletonProps,
} from "@chakra-ui/react";

export interface SkeletonProps extends ChakraSkeletonProps {
  pulse?: boolean;
}

export const Skeleton = ({ pulse = true, ...props }: SkeletonProps) => {
  return (
    <ChakraSkeleton
      startColor={pulse ? "gray.100" : "gray.400"}
      endColor="gray.400"
      {...props}
    />
  );
};
