import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AsyncReturnType } from "type-fest";

import { AuthService } from "@/services";

type QueryFnData = AsyncReturnType<typeof AuthService.getUser>;

const queryKey = () => ["account"];

// This hook is intentionally using the React Query's native useQuery hook
// As this hook is used within our app's useQuery hook
export const useAccount = <TData = QueryFnData>(
  options?: Omit<
    UseQueryOptions<QueryFnData, unknown, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const result = useQuery<QueryFnData, unknown, TData>(
    queryKey(),
    AuthService.getUser,
    options
  );

  return result;
};

useAccount.queryKey = queryKey;
