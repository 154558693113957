import { Box, BoxProps, Icon } from "@chakra-ui/react";
import { DayPicker, DayPickerProps } from "react-day-picker";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export type CalendarProps = BoxProps & DayPickerProps;

export const Calendar = (props: CalendarProps) => {
  return (
    <Box
      as={DayPicker}
      showOutsideDays
      fixedWeeks
      numberOfMonths={props.mode === "range" ? 2 : 1}
      components={{
        IconLeft: () => <Icon as={MdChevronLeft} />,
        IconRight: () => <Icon as={MdChevronRight} />,
      }}
      position="relative"
      sx={{
        ".rdp-caption_label": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 6,
          fontWeight: "semibold",
        },

        ".rdp-nav": {
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "full",
          height: 6,
        },

        ".rdp-nav_button": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 6,
          height: 6,
          zIndex: 1,
          border: "1px solid",
          borderColor: "inherit",
          borderRadius: "md",
          transitionProperty: "common",
          transitionDuration: "normal",

          ":hover": {
            background: "gray.100",
          },

          ":focus-visible": {
            outline: "none",
            boxShadow: "outline",
          },
        },

        "rdp-nav_button_prev": {
          position: "absolute",
          left: 0,
        },

        ".rdp-nav_button_next": {
          position: "absolute",
          right: 0,
        },

        ".rdp-months": {
          base: {
            display: "flex",
            flexDirection: "column",
            gap: 4,
          },
          md: {
            flexDirection: "row",
          },
        },

        ".rdp-month": {},

        ".rdp-tbody": {},

        ".rdp-row": {
          display: "block",
          marginTop: 2,
        },

        ".rdp-table": {
          display: "flex",
          flexDirection: "column",
          alignItems: " center",
          justifyContent: "center",
          width: "full",
          marginTop: 2,
          borderCollapse: "collapse",
        },

        ".rdp-head_cell": {
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: 7,
          color: "gray.400",
          fontSize: "sm",
          fontWeight: "bold",
        },

        ".rdp-cell": {
          display: "inline-flex",
          width: 7,
          height: 7,

          "&:first-of-type": {
            borderLeftRadius: "md",
          },

          "&:last-of-type": {
            borderRightRadius: "md",
          },

          "&:has(.rdp-day_range_start)": {
            borderLeftRadius: "md",
            borderRightRadius: 0,
            background: "gray.100",
          },

          "&:has(.rdp-day_range_end)": {
            borderLeftRadius: 0,
            borderRightRadius: "md",
            background: "gray.100",
          },
        },

        ".rdp-day": {
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "inherit",
          height: "inherit",
          borderRadius: "md",
          transitionProperty: "common",
          transitionDuration: "fast",

          ":hover:not(.rdp-day_selected)": {
            background: "gray.100",
          },

          ":focus-visible": {
            outline: "none",
            boxShadow: "outline",
          },
        },

        ".rdp-day_selected": {
          background: "gray.800",
          color: "white",
        },

        ".rdp-day_range_middle": {
          borderRadius: 0,
          background: "gray.100",
          color: "gray.800",
        },

        ".rdp-day_outside, .rdp-day_disabled": {
          opacity: 0.3,
          pointerEvents: "none",
          cursor: "default",
        },
      }}
      {...props}
    />
  );
};
