import { Fragment, useEffect, useRef } from "react";

import { isNil } from "lodash";

import { useAuth } from "@/contexts";
import { useServerState } from "@/hooks";

export interface SurveyProps {
  id: string;
}

export const Survey = ({ id }: SurveyProps) => {
  const { user } = useAuth();

  const hasOpenedPopup = useRef(false);

  const [surveyStatus, setSurveyStatus] = useServerState<SurveyStatus>(
    `survey-${id}`
  );

  useEffect(() => {
    if (hasOpenedPopup.current) {
      return;
    }

    if (typeof Tally === "undefined" || isNil(Tally) || isNil(user)) {
      return;
    }

    if (surveyStatus === "completed" || surveyStatus === "closed") {
      return;
    }

    // Using an IIFE since both onSubmit and onClose are called when submitting the form
    // resulting in onClose handler overriding the onSubmit handler logic
    (() => {
      let hasSetStatus = false;

      Tally.openPopup(id, {
        width: 380,
        overlay: true,
        alignLeft: true,
        hideTitle: true,
        autoClose: 0,
        hiddenFields: {
          sub: user.sub,
          email: user.email,
        },
        onSubmit: () => {
          if (!hasSetStatus) {
            setSurveyStatus("completed");
            hasSetStatus = true;
          }
        },
        onClose: () => {
          if (!hasSetStatus) {
            setSurveyStatus((x) => x ?? "closed");
            hasSetStatus = true;
          }
        },
      });
    })();

    hasOpenedPopup.current = true;
  }, [id, setSurveyStatus, surveyStatus, user]);

  return <Fragment />;
};

type SurveyStatus = "completed" | "closed";
