import { ReactNode } from "react";

import { isString } from "lodash";
import toast from "react-hot-toast";

import { Id } from "@/services/types";
import { hash } from "@/utils";

import { NotificationToast } from "./notification-toast";
import { Toast, ToastSemantic } from "./toast";

const DEFAULT_DURATION_MS = 3500;

export type ToastOptions = Parameters<typeof toast.custom>[1];

interface ToastProps {
  title?: ReactNode;
  message?: ReactNode;
  closeable?: boolean;
  options?: ToastOptions;
}

export interface CustomToastProps extends ToastProps {
  semantic: ToastSemantic;
}

export interface NotificationToastProps extends Omit<ToastProps, "closeable"> {
  id: Id;
  link: string;
  date?: Date | string;
}

const custom = ({
  title,
  message,
  semantic,
  closeable,
  options,
}: CustomToastProps) => {
  toast.custom(
    (data) => (
      <Toast
        {...data}
        title={title}
        message={message}
        semantic={semantic}
        closeable={closeable}
      />
    ),
    {
      id: hash(
        (isString(title) ? title : "") + (isString(message) ? message : "")
      ).toString(),
      duration: DEFAULT_DURATION_MS,
      ...options,
    }
  );
};

const success = ({ title, message, closeable, options }: ToastProps) => {
  custom({ title, message, semantic: "success", closeable, options });
};

const error = ({
  title = "Something went wrong.",
  message,
  closeable,
  options,
}: ToastProps) => {
  custom({ title, message, semantic: "danger", closeable, options });
};

const info = ({ title, message, closeable, options }: ToastProps) => {
  custom({ title, message, semantic: "info", closeable, options });
};

const notification = ({
  id,
  title = "New notification",
  message,
  link,
  date,
  options,
}: NotificationToastProps) => {
  toast.custom(
    (data) => (
      <NotificationToast
        {...data}
        toastId={data.id}
        id={id}
        title={title}
        message={message}
        link={link}
        date={date}
      />
    ),
    {
      id: id.toString(),
      duration: DEFAULT_DURATION_MS,
      ...options,
    }
  );
};

const dismiss = (id: string) => {
  toast.dismiss(id);
};

export const ToasterManager = Object.freeze({
  success,
  error,
  info,
  notification,
  dismiss,
});
