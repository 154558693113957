import { ComponentProps, memo } from "react";

export interface VideoProps extends ComponentProps<"video"> {}

// TODO: Custom styling
const VideoRaw = (props: VideoProps) => {
  return <video {...props} />;
};

export const Video = memo(VideoRaw);
