import { HTTP_STATUS_CODES, isAxiosError } from "@/http";

import { RequestData, fetchApi } from "./openapi";
import { RequestParams } from "./openapi/types";

const getCustomer = async () => {
  try {
    const result = await fetchApi("/billing/customer", "get");

    return result;
  } catch (error) {
    if (
      isAxiosError(error) &&
      error?.response?.status === HTTP_STATUS_CODES.NOT_FOUND
    ) {
      return null;
    }

    throw error;
  }
};

const getPaymentMethods = () => {
  const result = fetchApi("/billing/payment-methods", "get");

  return result;
};

const createPaymentMethod = (
  data: RequestData<"/billing/payment-methods", "post">
) => {
  const result = fetchApi("/billing/payment-methods", "post", { data });

  return result;
};

const updatePaymentMethod = ({
  stripeId,
}: RequestParams<"/billing/payment-methods/{stripeId}", "put">) => {
  const result = fetchApi("/billing/payment-methods/{stripeId}", "put", {
    params: { path: { stripeId } },
  });

  return result;
};

const deletePaymentMethod = ({
  stripeId,
}: RequestParams<"/billing/payment-methods/{stripeId}", "delete">) => {
  const result = fetchApi("/billing/payment-methods/{stripeId}", "delete", {
    params: { path: { stripeId } },
  });

  return result;
};

const createPaymentIntent = (
  data: RequestData<"/billing/payment-intent", "post">
) => {
  const result = fetchApi("/billing/payment-intent", "post", { data });

  return result;
};

const getInvoice = ({
  stripeId,
}: RequestParams<"/billing/invoices/{stripeId}", "get">) => {
  const result = fetchApi("/billing/invoices/{stripeId}", "get", {
    params: { path: { stripeId } },
  });

  return result;
};

const voidInvoice = ({
  stripeId,
}: RequestParams<"/billing/invoices/{stripeId}/void", "post">) => {
  const result = fetchApi("/billing/invoices/{stripeId}/void", "post", {
    params: { path: { stripeId } },
  });

  return result;
};

const createInvoicePreview = (
  data: RequestData<"/billing/invoices/preview", "post">
) => {
  const result = fetchApi("/billing/invoices/preview", "post", { data });

  return result;
};

const createBillingPortal = ({
  returnUrl,
}: RequestParams<"/billing/portal", "post">) => {
  const result = fetchApi("/billing/portal", "post", {
    params: { query: { returnUrl } },
  });

  return result;
};

export const BillingService = Object.freeze({
  getCustomer,
  getInvoice,
  voidInvoice,
  createInvoicePreview,
  getPaymentMethods,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  createPaymentIntent,
  createBillingPortal,
});
