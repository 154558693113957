import { Box, BoxProps, Divider, HStack } from "@chakra-ui/react";

import { Text, TextProps } from "@/components/display";

export interface InvoicePreviewItemProps extends BoxProps {
  showDivider?: boolean;
}

export const InvoicePreviewItem = ({
  showDivider = true,
  ...props
}: InvoicePreviewItemProps) => {
  return (
    <>
      <HStack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        w="full"
        py={2}
        {...props}
      />

      {showDivider && <Divider />}
    </>
  );
};

export interface InvoicePreviewItemLeftTextProps extends TextProps {}

export const InvoicePreviewItemLeftText = (
  props: InvoicePreviewItemLeftTextProps
) => {
  return <Text suspendable={false} {...props} />;
};

export interface InvoicePreviewItemRightTextProps extends TextProps {}

export const InvoicePreviewItemRightText = (
  props: InvoicePreviewItemRightTextProps
) => {
  return <Text {...props} />;
};

export interface InvoicePreviewItemDividerProps extends BoxProps {}

export const InvoicePreviewItemDivider = (
  props: InvoicePreviewItemDividerProps
) => {
  return (
    <Box flex="1" borderTop="1px dashed" borderColor="transparent" {...props} />
  );
};
