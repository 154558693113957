import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AsyncReturnType } from "type-fest";

import { AuthService } from "@/services";

type QueryFnData = AsyncReturnType<typeof AuthService.isAuthenticated>;

const queryKey = () => ["account", "status"];

// This hook is intentionally using the React Query's native useQuery hook
// as this hook is used within our app's useQuery hook
export const useAuthStatus = <TData = QueryFnData>(
  options?: Omit<
    UseQueryOptions<QueryFnData, unknown, TData>,
    "queryKey" | "queryFn"
  >
) => {
  const result = useQuery<QueryFnData, unknown, TData>(
    queryKey(),
    AuthService.isAuthenticated,
    {
      retry: false,
      ...options,
    }
  );

  return result;
};

useAuthStatus.queryKey = queryKey;
