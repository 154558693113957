import { createContext, useContext } from "react";

import { UserInfoResponse } from "@logto/browser";

import { Nullish } from "@/common";
import { FullPageSpinner } from "@/components/feedback";
import { useAccount, useAuthStatus } from "@/services/hooks/account";
import { WithOptionalChildren } from "@/ui/types";

export type User = UserInfoResponse;

export interface AuthContextProps {
  user?: Nullish<User>;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  isAuthenticated: false,
});

export interface AuthProviderProps extends WithOptionalChildren {}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  // We use that here so it goes into React Query's cache
  const { isLoading: isLoadingAuthStatus } = useAuthStatus();
  const { data: user, isLoading: isLoadingUser } = useAccount();

  if (isLoadingAuthStatus || isLoadingUser) {
    return <FullPageSpinner />;
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated: !!user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = <T extends boolean = false>(): T extends true
  ? { user: User; isAuthenticated: boolean }
  : AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw Error(`"useAuthContext" must used within "${AuthProvider.name}".`);
  }

  return context as any;
};
