import { AsyncReturnType } from "type-fest";

import { TypeaheadService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof TypeaheadService.createCompany>[0];
type Data = AsyncReturnType<typeof TypeaheadService.createCompany>;
type Error = unknown;

export const useCreateCompany = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    TypeaheadService.createCompany,
    options
  );

  return result;
};
