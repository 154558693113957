export const typography = Object.freeze({
  fonts: {
    body: `"Inter", sans-serif`,
    heading: `"Inter", sans-serif`,
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75em",
    sm: "0.875em",
    md: "1em",
    lg: "1.125em",
    xl: "1.25em",
    "2xl": "1.5em",
    "3xl": "1.875em",
    "4xl": "2.25em",
    "5xl": "3em",
    "6xl": "3.75em",
    "7xl": "4.5em",
    "8xl": "6em",
    "9xl": "8em",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    3: ".75em",
    4: "1em",
    5: "1.25em",
    6: "1.5em",
    7: "1.75em",
    8: "2em",
    9: "2.25em",
    10: "2.5em",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
});
