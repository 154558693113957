import { camelCase } from "lodash";

import { API_ERROR_CODES, getApiErrorMessage } from "@/http";

import { ClientError, Property, PropertyErrors } from "./types";

const parseResult = (
  propertyErrors: PropertyErrors,
  mappings: Record<string, string> = {}
) => {
  const propertyNames = Object.keys(propertyErrors);
  if (!propertyNames?.length) return;

  return propertyNames.flatMap((property) =>
    propertyErrors[property].map((error) => ({
      name: propertyResolver(property, mappings),

      error: {
        type: "server",
        message:
          getApiErrorMessage(error.code as keyof typeof API_ERROR_CODES) ??
          "The provided value is incorrect.",
      } as ClientError,
    }))
  );
};

/**
 * Resolves the given property by the provided mappings.
 * Nested and array properties are allowed.
 * The mapping should be provided as follows, e.g.:
 * {
 *    "entities": "entities2",
 *    "entities.property": "property2"
 * }
 */
const propertyResolver = (
  property: Property,
  mappings: Record<string, string> = {}
) => {
  // We split the property since it can be a
  // nested property e.g. entities[0].property
  const propertySegments = property.split(".");

  const mappedPropertySegments = propertySegments.map(
    (segment, index, array) => {
      // We split the segment and take the first bit
      // since it can be an array e.g. entities[0]
      const propertyName = segment.split("[")[0];
      const propertyNameCamelCase = camelCase(propertyName);

      const propertyArrayOpeningIndex = segment.indexOf("[");
      const propertyArrayIndex =
        propertyArrayOpeningIndex > 0
          ? segment.slice(propertyArrayOpeningIndex)
          : "";

      // We get all the preceding properties so we can
      // retrieve the appropriate property mapping
      const fullPropertyArray = array
        .slice(0, index)
        .map((x) => x.split("[")[0]);

      fullPropertyArray.push(propertyNameCamelCase);
      const fullPropertyName = fullPropertyArray.join(".");

      const mappedPropertyName =
        mappings[fullPropertyName] ?? propertyNameCamelCase;
      const mappedPropertyNameWithArray =
        mappedPropertyName + propertyArrayIndex;

      return mappedPropertyNameWithArray;
    }
  );

  return mappedPropertySegments.join(".");
};

export const ValidationService = Object.freeze({
  parseResult,
});
