import { useFlags } from "flagsmith/react";

import { useAuth } from "@/contexts";

export type Feature = "campaigns";

export const useFeature = (feature: Feature) => {
  const { user } = useAuth();
  const flags = useFlags([feature]);

  const featureFlag = flags?.[feature];

  if (feature === "campaigns") {
    const enabled = featureFlag?.enabled;
    const value = featureFlag?.value as string;

    return enabled || value?.includes(user?.email?.toLowerCase() ?? "@@");
  }

  return featureFlag?.enabled ?? true;
};
