import { useCallback } from "react";

import { useDisclosure } from "@chakra-ui/hooks";
import { BoxProps, Flex, FormLabel } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

import { MaybePromise, Nullish } from "@/common";
import {
  EditablePopover,
  EditablePopoverProps,
  Form,
  FormFieldControl,
  InputFormField,
  useForm,
} from "@/components/form";
import { StringUtils } from "@/utils";

export interface PhonePopoverFieldProps
  extends Omit<BoxProps, "defaultValue" | "onChange"> {
  defaultValue?: Nullish<string>;
  hidePreviewLabel?: boolean;
  orientation?: EditablePopoverProps["orientation"];
  onChange: (value: Nullish<string>) => MaybePromise<void>;
}

export const PhonePopoverField = ({
  id,
  defaultValue = "",
  hidePreviewLabel = false,
  orientation = "vertical",
  onChange,
  ...props
}: PhonePopoverFieldProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const methods = useForm<PhonePopoverFieldValues>({
    defaultValues: { phoneNumber: defaultValue ?? "" },
    resolver: zodResolver(schema),
  });

  const {
    formState: { isValid, isSubmitting },
  } = methods;

  const handleSubmit = useCallback(
    async (data: PhonePopoverFieldValues) => {
      await onChange?.(data.phoneNumber);
      onClose();
    },
    [onChange, onClose]
  );

  return (
    <Flex
      maxW="full"
      direction={orientation === "vertical" ? "column" : "row"}
      alignItems={orientation === "vertical" ? "normal" : "center"}
      justifyContent={orientation === "vertical" ? "normal" : "space-between"}
      gap={1}
      {...props}
    >
      {!hidePreviewLabel && <FormLabel m={0}>Phone number</FormLabel>}

      <EditablePopover
        isOpen={isOpen}
        defaultValue={defaultValue ?? ""}
        emptyLabel="Add a phone number"
        orientation={orientation}
        isDisabled={!isValid}
        isLoading={isSubmitting}
        onOpen={onOpen}
        onClose={onClose}
        onSave={methods.handleSubmit(handleSubmit)}
      >
        <FormProvider {...methods}>
          <Form id={id} onSubmit={methods.handleSubmit(handleSubmit)}>
            <FormFieldControl name="phoneNumber" mb={2}>
              <FormLabel>Phone number</FormLabel>

              <InputFormField />
            </FormFieldControl>
          </Form>
        </FormProvider>
      </EditablePopover>
    </Flex>
  );
};

type PhonePopoverFieldValues = { phoneNumber: string };

const schema = z.object({
  phoneNumber: z
    .string()
    .refine(StringUtils.isPhoneNumber, "The value must be a phone number")
    .or(z.literal("")),
});
