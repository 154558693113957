import { AxiosRequestConfig } from "axios";

import { AnyObject, OmitNullish } from "@/common";
import { axios } from "@/http";

import {
  Path,
  PathMethod,
  RequestData,
  RequestPathParams,
  RequestQueryParams,
  ResponseType,
} from "./types";

type FetchApiOptions<P extends Path, M extends PathMethod<P>> = OmitNullish<{
  params?:
    | OmitNullish<{
        path: RequestPathParams<P, M>;
        query: RequestQueryParams<P, M>;
      }>
    | undefined;
  data?: RequestData<P, M>;
  headers?: AxiosRequestConfig["headers"];
}>;

export const fetchApi = async <P extends Path, M extends PathMethod<P>>(
  url: P,
  method: M,
  options?: FetchApiOptions<P, M>
): Promise<ResponseType<P, M>> => {
  // @ts-ignore
  const pathParams = options?.params?.path as Record<string, string>;

  // @ts-ignore
  const queryParams = options?.params?.query as Record<string, string>;

  // @ts-ignore
  const data = options?.data as AnyObject;

  const result = await axios({
    url: interpolateUrl(url, pathParams),
    method: method as string,
    params: queryParams,
    headers: options?.headers,
    data,
  });

  return result.data;
};

const interpolateUrl = (url: string, params: Record<string, string> = {}) => {
  return Object.entries(params).reduce((currentUrl, [key, value]) => {
    return currentUrl.replaceAll(`{${key}}`, value);
  }, url);
};
