import {
  Box,
  TabList as ChakraTabList,
  TabListProps as ChakraTabListProps,
} from "@chakra-ui/react";
import { LayoutGroup } from "framer-motion";

import { useMediaQuery } from "@/ui/hooks";

export const TabList = ({ children, ...props }: ChakraTabListProps) => {
  const [isMdScreen] = useMediaQuery("md");

  return (
    // Overflow is hidden on larger screens in order to prevent hover animation
    // from expanding <Tabs isFitted /> thus overflowing and showing a scrollbar for a brief second
    <Box overflowX={isMdScreen ? "hidden" : "auto"} p={1} {...props}>
      <ChakraTabList minW="fit-content">
        <LayoutGroup>{children}</LayoutGroup>
      </ChakraTabList>
    </Box>
  );
};
