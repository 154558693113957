import { toNumber } from "lodash";
import { customAlphabet } from "nanoid";
import type { ReportHandler } from "web-vitals";

import { PromiseUtils } from "./promise-utils";

export const reportWebVitals = (onPerfEntry?: ReportHandler | null) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any) => {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noopPromise = (...args: any) => PromiseUtils.createEmptyPromise();

export const createId = () => toNumber(customAlphabet("0123456789", 14)());

type MergeFunc<T extends any[]> = (...args: T) => void;

export const mergeFunctions = <T extends any[]>(
  ...funcs: MergeFunc<T>[]
): MergeFunc<T> => {
  return (...args: T) => {
    funcs.forEach((func) => func(...args));
  };
};

export const createKeys = <T>(keyRecord: Record<keyof T, "">): (keyof T)[] => {
  return Object.keys(keyRecord) as any;
};
