import {
  Box,
  Flex,
  FormHelperText,
  FormLabel,
  ModalBody,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

import {
  Button,
  Form,
  FormFieldControl,
  RichTextAreaFormField,
  SwitchFormField,
  useForm,
} from "@/components/form";
import { useCreateTemplate } from "@/services/hooks/campaigns";
import { RichTextValue } from "@/types";
import { RichText } from "@/utils";

export interface CreateStepProps {
  defaultValue?: RichTextValue;
  onCreate: (value: RichTextValue) => void;
  onBack?: () => void;
}

export const CreateStep = ({
  defaultValue = RichText.Empty,
  onCreate,
  onBack,
}: CreateStepProps) => {
  const { mutateAsync: createTemplate } = useCreateTemplate();

  const methods = useForm<CreateStepFieldValues>({
    defaultValues: {
      message: defaultValue,
      saveAsTemplate: false,
    },
    resolver: zodResolver(createStepSchema),
  });

  const {
    formState: { isValid, isSubmitting, dirtyFields },
  } = methods;

  const handleSubmit = async (data: CreateStepFieldValues) => {
    if (data.saveAsTemplate) {
      await createTemplate({
        content: RichText.serializeText(data.message),
        contentHtml: RichText.serializeHtml(data.message),
        contentJson: RichText.serializeJson(data.message),
      });
    }

    onCreate(data.message);
  };

  return (
    <ModalBody>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
          <VStack spacing={4} alignItems="normal">
            <FormFieldControl name="message" isRequired>
              <RichTextAreaFormField charLimit={2000} />
            </FormFieldControl>

            <FormFieldControl
              name="saveAsTemplate"
              isDisabled={!dirtyFields["message"]}
            >
              <FormLabel>Save as a template?</FormLabel>

              <SwitchFormField />

              <FormHelperText>
                If you save this as a template you'll be able to reuse this
                message in other campaigns.
              </FormHelperText>
            </FormFieldControl>

            <Flex py={4} alignItems="center" justifyContent="space-between">
              {onBack ? (
                <Button variant="outline" onClick={onBack}>
                  Back
                </Button>
              ) : (
                <Box />
              )}

              <Button
                type="submit"
                colorScheme="primary"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                Continue
              </Button>
            </Flex>
          </VStack>
        </Form>
      </FormProvider>
    </ModalBody>
  );
};

interface CreateStepFieldValues {
  message: RichTextValue;
  saveAsTemplate: boolean;
}

const createStepSchema = z.object({
  message: z.array(z.unknown()).refine(
    (value) => {
      const serializedValue = RichText.serializeText(value as RichTextValue);
      return serializedValue.length >= 1 && serializedValue.length <= 2000;
    },
    {
      message: "The value must be between 1 and 2000 characters.",
    }
  ),
  saveAsTemplate: z.boolean(),
});
