import { Tag as ChakraTag, TagProps as ChakraTagProps } from "@chakra-ui/react";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";

export const Tag = withSkeleton<ChakraTagProps, "span">(
  ChakraTag,
  ({ pulse, ...props }) => (
    <Skeleton pulse={pulse} borderRadius="md">
      <ChakraTag w="fit-content" {...props} />
    </Skeleton>
  )
);
