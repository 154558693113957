import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof CrmService.getContacts>[0];
type QueryFnData = AsyncReturnType<typeof CrmService.getContacts>;
type Error = unknown;

const queryKey = (args?: QueryArgs) => ["crm", "contacts", args];

export const useContacts = <TData = QueryFnData>(
  args?: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CrmService.getContacts(args),
    {
      skeletonData: useConst({
        elements: repeat(gen.contact, 3),
        pagination: {
          skip: 0,
          limit: 10,
          total: 3,
        },
      }),
      ...options,
    }
  );

  return response;
};

useContacts.queryKey = queryKey;
