import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services/conversation-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";

type QueryArgs = Parameters<typeof ConversationService.pollMessages>[0];
type QueryFnData = AsyncReturnType<typeof ConversationService.pollMessages>;
type Error = unknown;

const queryKey = ({ id }: QueryArgs) => [
  "conversations",
  id,
  "messages",
  "poll",
];

export const usePollMessages = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConversationService.pollMessages(args),
    options
  );

  return response;
};
