import { forwardRef, useRef } from "react";

import {
  Box,
  BoxProps,
  HStack,
  Icon,
  VStack,
  VisuallyHidden,
  useMergeRefs,
} from "@chakra-ui/react";
import { Reorder } from "framer-motion";
import { noop } from "lodash";
import { GiBackwardTime } from "react-icons/gi";

import { QuerySuspense, ScrollArea } from "@/components/disclosure";
import { Text } from "@/components/display";
import { ButtonOverlay } from "@/components/form";
import { Avatar } from "@/components/media";
import { useIntersectionObserver, useServerState } from "@/hooks";

import { RecentConversation } from "./types";

export interface ConversationListProps extends BoxProps {
  hasPrevious: boolean;
  hasMore: boolean;
  isLoadingPrevious: boolean;
  isLoadingMore: boolean;
  onLoadPrevious: () => void;
  onLoadMore: () => void;
  onSelectRecent: (conversation: RecentConversation) => void;
}

export const ConversationList = forwardRef<
  HTMLDivElement,
  ConversationListProps
>(
  (
    {
      hasPrevious,
      hasMore,
      isLoadingPrevious,
      isLoadingMore,
      onLoadPrevious,
      onLoadMore,
      onSelectRecent,
      children,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef<HTMLElement | null>(null);
    const prevLoaderRef = useRef<HTMLElement | null>(null);
    const nextLoaderRef = useRef<HTMLElement | null>(null);

    const mergedRefs = useMergeRefs(ref, rootRef);

    useIntersectionObserver(
      prevLoaderRef,
      {
        root: rootRef.current,
        rootMargin: "25%",
        enabled: hasPrevious && !isLoadingPrevious,
      },
      () => {
        onLoadPrevious();
      }
    );

    useIntersectionObserver(
      nextLoaderRef,
      {
        root: rootRef.current,
        rootMargin: "25%",
        enabled: hasMore && !isLoadingMore,
      },
      () => {
        onLoadMore();
      }
    );

    return (
      <VStack
        ref={mergedRefs}
        position="relative"
        overflowY="scroll"
        h="full"
        spacing={1}
        pr={2}
        {...props}
      >
        <RecentConversationList onSelect={onSelectRecent} />

        <VStack
          spacing={1}
          position="relative"
          w="full"
          sx={{ overflowAnchor: "none" }}
        >
          <VisuallyHidden ref={prevLoaderRef} position="static" />

          {children}

          <VisuallyHidden ref={nextLoaderRef} position="static" />
        </VStack>
      </VStack>
    );
  }
);

ConversationList.displayName = "ConversationList";

interface RecentConversationListProps {
  onSelect: (conversation: RecentConversation) => void;
}

const RecentConversationList = ({ onSelect }: RecentConversationListProps) => {
  const [recentConversations = []] = useServerState<RecentConversation[]>(
    "recent-conversations"
  );

  if (!recentConversations.length) {
    return null;
  }

  return (
    <QuerySuspense suspended={false}>
      <Box
        w="full"
        position="sticky"
        top={0}
        zIndex={1}
        background="gray.50"
        borderRadius="md"
        border="1px solid"
        borderColor="transparent"
      >
        <ScrollArea>
          <HStack
            as={Reorder.Group}
            values={recentConversations}
            axis="x"
            w="full"
            p={1}
            spacing={1}
            onReorder={noop}
          >
            {recentConversations.map((conversation) => (
              <Reorder.Item
                key={conversation.id}
                value={conversation}
                dragListener={undefined}
                drag={false}
              >
                <ButtonOverlay
                  onClick={() => {
                    onSelect?.(conversation);
                  }}
                >
                  <VStack borderRadius="md" p={1} w={12} h="full" fontSize="xs">
                    <Avatar
                      src={conversation.recipient.image ?? undefined}
                      name={conversation.recipient.name}
                      size="sm"
                      overflow="hidden"
                    />

                    <Text noOfLines={1} textAlign="center">
                      {conversation.recipient.name.split(" ")[0]}
                    </Text>
                  </VStack>
                </ButtonOverlay>
              </Reorder.Item>
            ))}
          </HStack>
        </ScrollArea>

        <Icon
          as={GiBackwardTime}
          position="absolute"
          top={1}
          right={1}
          background="gray.50"
          borderRadius="full"
          color="gray.400"
        />
      </Box>
    </QuerySuspense>
  );
};
