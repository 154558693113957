import { default as axiosStatic } from "axios";
import qs from "qs";

import { API } from "@/config";

import { addAuthInterceptors } from "./interceptors";

export const axios = axiosStatic.create({
  baseURL: API.ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: (params) => qs.stringify(params),
});

addAuthInterceptors(axios);

export const createCancelToken = () => {
  const source = axiosStatic.CancelToken.source();
  return source;
};
