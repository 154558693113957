import LogtoClient from "@logto/browser";
import { isNil } from "lodash";
import { AsyncReturnType } from "type-fest";

import { API, OIDC } from "@/config";

import { NotificationService } from "./notification-service";

const logtoClient = new LogtoClient({
  endpoint: OIDC.AUTHORITY,
  appId: OIDC.CLIENT_ID,
  scopes: OIDC.SCOPES,
  resources: [API.ENDPOINT],
});

interface CreateAuthServiceResult {
  isAuthenticated: () => ReturnType<typeof logtoClient.isAuthenticated>;
  getUser: () => Promise<AsyncReturnType<
    typeof logtoClient.fetchUserInfo
  > | null>;
  getAccessToken: () => Promise<AsyncReturnType<
    typeof logtoClient.getAccessToken
  > | null>;
  login: () => ReturnType<typeof logtoClient.signIn>;
  loginCallback: (
    url: string
  ) => ReturnType<typeof logtoClient.handleSignInCallback>;
  logout: (redirectUri?: string) => ReturnType<typeof logtoClient.signOut>;
}

const createAuthService = (): CreateAuthServiceResult => {
  if (!isNil(window.AuthService)) {
    return window.AuthService;
  }

  const isAuthenticated = () => {
    return logtoClient.isAuthenticated();
  };

  const getUser = async () => {
    const isAuthenticated = await logtoClient.isAuthenticated();

    if (isAuthenticated) {
      return logtoClient.fetchUserInfo();
    }

    return null;
  };

  const getAccessToken = async () => {
    const isAuthenticated = await logtoClient.isAuthenticated();

    if (isAuthenticated) {
      try {
        const accessToken = await logtoClient.getAccessToken(API.ENDPOINT);
        return accessToken;
      } catch {
        // Clear LogTo local storage cache
        Object.keys(localStorage)
          .filter((x) => x.startsWith("logto:"))
          .forEach(localStorage.removeItem);

        // Force relog, refresh token has probably expired
        await login();
      }
    }

    return null;
  };

  const login = () => {
    return logtoClient.signIn(OIDC.LOGIN_REDIRECT_URI);
  };

  const loginCallback = async (url: string) => {
    const isRedirectCallback = await logtoClient.isSignInRedirected(url);

    if (isRedirectCallback) {
      await logtoClient.handleSignInCallback(url);
    }
  };

  const logout = async (redirectUri: string = OIDC.LOGOUT_REDIRECT_URI) => {
    try {
      await NotificationService.deletePushToken();
    } catch {
      /* empty */
    }

    return logtoClient.signOut(redirectUri);
  };

  const authService = {
    isAuthenticated,
    getUser,
    getAccessToken,
    login,
    loginCallback,
    logout,
  };

  window.AuthService = authService;

  return authService;
};

export const AuthService = createAuthService();
