import { Box, BoxProps, useToken } from "@chakra-ui/react";

import { ScrollToTop } from "@/router";

import { PAGE_CONTENT_ID } from "./constants";
import { PageContainer } from "./container";

export interface PageContentProps extends BoxProps {}

export const PageContent = ({
  id = PAGE_CONTENT_ID,
  children,
  ...props
}: PageContentProps) => {
  const py = useToken("space", 4);

  return (
    <ScrollToTop>
      <Box
        id={id}
        as="main"
        w="full"
        py={py}
        px={0}
        sx={{
          "--cosiall-sizes-content-height": `calc(var(--cosiall-sizes-layout-height) - (${py} * 2))`,
        }}
        {...props}
      >
        <PageContainer>{children}</PageContainer>
      </Box>
    </ScrollToTop>
  );
};
