import { axios } from "@/http";

const check = async () => {
  try {
    await axios.get("health");
    return true;
  } catch {
    return false;
  }
};

export const HealthCheckService = Object.freeze({
  check,
});
