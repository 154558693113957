import {
  Stat as ChakraStat,
  StatProps as ChakraStatProps,
} from "@chakra-ui/react";

import { SkeletonText } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";

export const Stat = withSkeleton<ChakraStatProps, "div">(
  ChakraStat,
  ({ pulse, children, ...props }) => {
    return (
      <ChakraStat {...props}>
        <SkeletonText pulse={pulse} noOfLines={3} spacing={2} />
      </ChakraStat>
    );
  }
);
