import { ReactNode } from "react";

import { Box, BoxProps, Flex, Icon } from "@chakra-ui/react";
import {
  MdCampaign,
  MdMessage,
  MdOutlineCampaign,
  MdOutlineMessage,
  MdPeople,
  MdPeopleOutline,
} from "react-icons/md";
import { NavLink, useMatch } from "react-router-dom";

import { PAGE_NAVIGATION_ID } from "@/components/layout";
import { useAuth } from "@/contexts";
import { useMediaQuery } from "@/ui/hooks";

export interface BottomNavigationProps extends BoxProps {}

export const BottomNavigation = ({
  id = PAGE_NAVIGATION_ID,
  ...props
}: BottomNavigationProps) => {
  const [isLargeScreen] = useMediaQuery("lg");

  if (isLargeScreen) {
    return null;
  }

  return (
    <Box
      id={id}
      position="sticky"
      bottom={0}
      w="full"
      px={8}
      py={2}
      bg="white"
      background="whiteAlpha.800"
      borderTop="1px"
      borderColor="gray.200"
      backdropFilter="blur(8px)"
      zIndex="sticky"
      {...props}
    >
      <Flex justifyContent="space-around">
        {MAIN_NAVIGATION.map((item) => (
          <Box key={item.displayName}>
            <NavgitaionLink authenticated to={item.pathname}>
              {(isActive: boolean) => (
                <Icon
                  as={isActive ? item.Icon : item.IconOutline}
                  fill={isActive ? "primary.500" : "gray.500"}
                  width={6}
                  height={6}
                />
              )}
            </NavgitaionLink>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

interface NavigationLinkProps {
  to: string;
  authenticated: boolean;
  children: (match: boolean) => ReactNode;
}

const NavgitaionLink = ({
  to,
  authenticated = false,
  children,
}: NavigationLinkProps) => {
  const match = useMatch({
    path: to,
    end: false,
    caseSensitive: false,
  });

  const { isAuthenticated } = useAuth();

  if (authenticated && !isAuthenticated) {
    return null;
  }

  return (
    <Box as={NavLink} to={to}>
      {children(!!match)}
    </Box>
  );
};

const MAIN_NAVIGATION = Object.freeze([
  {
    displayName: "Campaigns",
    Icon: MdCampaign,
    IconOutline: MdOutlineCampaign,
    pathname: "/campaigns",
  },
  {
    displayName: "Contacts",
    Icon: MdPeople,
    IconOutline: MdPeopleOutline,
    pathname: "/contacts",
  },
  {
    displayName: "Conversations",
    Icon: MdMessage,
    IconOutline: MdOutlineMessage,
    pathname: "/conversations",
  },
]);
