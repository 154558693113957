import { useCallback, useEffect, useRef, useState } from "react";

export const useIntersectionObserver = (
  element: any,
  {
    root = null,
    threshold = 0,
    rootMargin = "0%",
    enabled = true,
  }: IntersectionObserverInit & { enabled?: boolean } = {},
  callback: (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => void
) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const callbackRef = useRef<typeof callback>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const updateEntry: IntersectionObserverCallback = useCallback(
    (entries, observer) => {
      const [entry] = entries;
      setEntry(entry);

      if (entry.isIntersecting) {
        callbackRef.current?.(entries, observer);
      }
    },
    []
  );

  useEffect(() => {
    if (!enabled) return;

    const isSupported = !!window.IntersectionObserver;

    if (!element) return;

    const node = "current" in element ? element.current : element;

    if (!isSupported || !node) return;

    const observerOptions = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerOptions);

    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, [enabled, element, threshold, root, rootMargin, updateEntry]);

  return entry;
};
