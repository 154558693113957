import { Box, BoxProps, Flex, Icon, Portal } from "@chakra-ui/react";
import { MdLockOutline } from "react-icons/md";

import {
  useWizardContext,
  useWizardStepContext,
} from "@/components/disclosure";
import { Button } from "@/components/form";
import { Link } from "@/components/navigation";
import { useElementRef, useReferer } from "@/hooks";
import { useMediaQuery } from "@/ui/hooks";

export interface WizardStepActionBarProps extends BoxProps {}

export const WizardStepActionBar = ({
  children,
  ...props
}: WizardStepActionBarProps) => {
  const [isMdScreen] = useMediaQuery("md");

  const {
    state: { isFinalStep },
  } = useWizardContext();

  const {
    name,
    state: { isValid, isSubmitting },
  } = useWizardStepContext();

  const { refererUri } = useReferer({
    defaultUri: "/campaigns",
    defaultDisplayName: "Campaigns",
  });

  const actionBarPortalRef = useElementRef(
    "#campaign-wizard__action-bar-portal-ref"
  );

  return (
    <Portal containerRef={actionBarPortalRef}>
      {isMdScreen ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          {...props}
        >
          <Flex flex={1} justifyContent="flex-start">
            <Button as={Link} to={refererUri} variant="outline">
              Cancel
            </Button>
          </Flex>

          <Flex flex={2} alignItems="normal" justifyContent="center">
            {children}
          </Flex>

          <Flex flex={1} justifyContent="flex-end">
            <Button
              type="submit"
              form={name}
              colorScheme="primary"
              loadingText={isFinalStep ? "Pay securely" : "Continue"}
              spinnerPlacement="end"
              rightIcon={isFinalStep ? <Icon as={MdLockOutline} /> : undefined}
              isDisabled={!isValid}
              isLoading={isSubmitting}
            >
              {isFinalStep ? "Pay securely" : "Continue"}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center" gap={4} {...props}>
          <Box w="full">{children}</Box>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            w="full"
          >
            <Button as={Link} to={refererUri} variant="outline">
              Cancel
            </Button>

            <Button
              type="submit"
              form={name}
              colorScheme="primary"
              loadingText={isFinalStep ? "Pay securely" : "Continue"}
              rightIcon={isFinalStep ? <Icon as={MdLockOutline} /> : undefined}
              isDisabled={!isValid}
              isLoading={isSubmitting}
            >
              {isFinalStep ? "Pay securely" : "Continue"}
            </Button>
          </Flex>
        </Flex>
      )}
    </Portal>
  );
};
