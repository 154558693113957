import { useCallback, useState } from "react";

import {
  AspectRatio,
  Box,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { isNumber } from "lodash";
import {
  MdFileDownload,
  MdMoreVert,
  MdOutlineInsertDriveFile,
} from "react-icons/md";

import { Modal, ModalProps } from "@/components/disclosure";
import { Text } from "@/components/display";
import { useElementSize } from "@/hooks";
import { formatBytes } from "@/utils";

import { MimeType, isOfType } from "./file-utils";
import { PdfPreview } from "./pdf-preview";

export interface FilePreviewModalProps
  extends Omit<ModalProps, "children" | "size"> {
  name: string;
  url: string;
  type?: string;
  size?: number;
}

export const FilePreviewModal = ({
  name,
  url,
  type,
  size,
  isOpen,
  onClose,
}: FilePreviewModalProps) => {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>();
  const { width: containerWidth } = useElementSize(containerRef);

  const renderFilePreview = useCallback(() => {
    // TODO: Add previews for video and audio

    if (isOfType(type, MimeType.image)) {
      return (
        <Image
          src={url}
          alt={name}
          background="gray.50"
          sx={{
            objectFit: "contain !important",
          }}
        />
      );
    }

    if (isOfType(type, MimeType.pdf)) {
      return (
        <PdfPreview
          showScroll
          showPaginator
          name={name}
          src={url}
          width={containerWidth}
        />
      );
    }

    return (
      <VStack spacing={2} w="full" h="full" background="gray.50">
        <Icon as={MdOutlineInsertDriveFile} w={8} h={8} />

        <Box display="inline-block" fontSize="xs" lineHeight={1}>
          <Text display="inline" lineHeight="inherit">
            Cannot preview this file.
          </Text>

          <Link
            isExternal
            download={name}
            href={url}
            display="inline"
            lineHeight="inherit"
            ml=".5ch"
          >
            Download instead?
          </Link>
        </Box>
      </VStack>
    );
  }, [type, name, url, containerWidth]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{name}</ModalHeader>

        <ModalCloseButton top={1} />

        <AspectRatio ref={setContainerRef} ratio={1} p={2}>
          {renderFilePreview()}
        </AspectRatio>

        <ModalFooter justifyContent="space-between">
          <Text fontSize="sm" color="gray.500">
            {isNumber(size) && formatBytes(size)}
          </Text>

          <Menu placement="bottom-start">
            <MenuButton
              as={IconButton}
              icon={<Icon as={MdMoreVert} />}
              size="xs"
              variant="ghost"
            />

            <MenuList minW={32} py={1} fontSize="sm">
              <MenuItem
                as={Link}
                isExternal
                download={name}
                href={url}
                icon={<Icon as={MdFileDownload} />}
              >
                Download
              </MenuItem>
            </MenuList>
          </Menu>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
