import { useCallback, useEffect, useRef } from "react";

import { Box, CloseButton, Container, Flex, Portal } from "@chakra-ui/react";

import { PAGE_BANNER_ID } from "@/components/layout";
import { useLocalStorage } from "@/hooks";
import { WithOptionalChildren } from "@/ui/types";

export interface BannerProps extends WithOptionalChildren {
  id: string | number;
  onClose?: () => void;
}

export const Banner = ({ id, onClose, children }: BannerProps) => {
  const bannerPortalRef = useRef<HTMLElement | null>(null);
  const [showBanner, setShowBanner] = useLocalStorage(`banner-${id}`, true);

  useEffect(() => {
    bannerPortalRef.current = document.querySelector(`#${PAGE_BANNER_ID}`);
  }, []);

  const handleClose = useCallback(() => {
    setShowBanner(false);
    onClose?.();
  }, [setShowBanner, onClose]);

  if (!showBanner) {
    return null;
  }

  return (
    <Portal containerRef={bannerPortalRef}>
      <Box as="section" background="blue.900" color="white" fontSize="sm">
        <Container
          py={{ base: 2, sm: 2 }}
          position="relative"
          w="1644px"
          maxW="100%"
        >
          <CloseButton
            display={{ sm: "none" }}
            position="absolute"
            right={1}
            top={1}
            onClick={handleClose}
          />

          <Flex alignItems="center" justifyContent="space-between">
            <Box flex="1" fontWeight="medium">
              {children}
            </Box>

            <CloseButton
              size="sm"
              display={{ base: "none", sm: "inline" }}
              onClick={handleClose}
            />
          </Flex>
        </Container>
      </Box>
    </Portal>
  );
};
