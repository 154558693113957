import { isNil } from "lodash";

import { Option } from "@/components/form";
import { TypeaheadResult } from "@/services/openapi/outbound";

export const asOption = <
  T extends { id: number; label: string; image?: string | null }
>({
  id,
  label,
  image,
}: T): Option => ({
  value: id.toString(),
  label,
  image,
});

export const asOptions = (result: TypeaheadResult): Option[] =>
  isNil(result.elements) ? [] : result.elements.map(asOption);
