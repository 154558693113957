import { Editor } from "slate";

export const withPlaceholders = <T extends Editor>(editor: T) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) =>
    element.type === "placeholder" ? true : isInline(element);

  editor.isVoid = (element) =>
    element.type === "placeholder" ? true : isVoid(element);

  return editor;
};
