import { useRadioGroup as useChakraRadioGroup } from "@chakra-ui/react";

import { createContext } from "@/ui/utils";

export interface RadioGroupContextProps {
  getRadioProps?:
    | ReturnType<typeof useChakraRadioGroup>["getRadioProps"]
    | null;
}

export const [RadioGroupProvider, useRadioGroupContext] =
  createContext<RadioGroupContextProps>({
    name: "RadioGroupContext",
    strict: false,
    defaultValue: {
      getRadioProps: null,
    },
  });
