import { ComponentProps, memo } from "react";

export interface AudioProps extends ComponentProps<"audio"> {}

// TODO: Custom styling
const AudioRaw = (props: AudioProps) => {
  return <audio {...props} />;
};

export const Audio = memo(AudioRaw);
