import { ComponentType } from "react";

import { As, forwardRef } from "@chakra-ui/react";

import {
  QuerySuspense,
  SkeletonProps,
  useQuerySuspense,
} from "@/components/disclosure";
import { getDisplayName } from "@/utils";

export interface WithSkeletonProps {
  suspendable?: boolean;
  hideOnSuspense?: boolean;
}

export const withSkeleton = <TProps, TComponent extends As = "div">(
  Component: ComponentType<TProps>,
  SkeletonComponent: ComponentType<TProps & SkeletonProps>
) => {
  const WithSkeleton = forwardRef<WithSkeletonProps & TProps, TComponent>(
    ({ suspendable = true, hideOnSuspense = false, ...props }, ref) => {
      const { isIdle, isLoading, isSuspended } = useQuerySuspense();

      if (!suspendable || !isSuspended) {
        return <Component ref={ref} {...(props as TProps)} />;
      }

      if (hideOnSuspense) {
        return null;
      }

      return (
        // We wrap it in <QuerySuspense status="success" />
        // so that nested skeletons don't get rendered
        <QuerySuspense status="success">
          <SkeletonComponent
            pulse={isIdle || isLoading}
            pointerEvents="none"
            {...(props as TProps)}
          />
        </QuerySuspense>
      );
    }
  );

  WithSkeleton.displayName = `WithSkeleton(${getDisplayName(Component)})`;

  return WithSkeleton;
};
