import { Box, BoxProps } from "@chakra-ui/react";

import { useEditableContext } from "./editable-context";

export interface EditablePreviewProps extends BoxProps {}

export const EditablePreview = (props: EditablePreviewProps) => {
  const { isEditing, hasValue } = useEditableContext();

  if (isEditing || !hasValue) {
    return null;
  }

  return <Box {...props} />;
};
