import { RequestData, fetchApi } from "./openapi";

const get = () => {
  const result = fetchApi("/server-storage", "get");

  return result;
};

const patch = (data: RequestData<"/server-storage", "patch">) => {
  const result = fetchApi("/server-storage", "patch", { data });

  return result;
};

export const ServerStorage = Object.freeze({
  get,
  patch,
});
