import { ParagraphElement, PlaceholderElement } from "@/slate-ext";

const createParagraph = (): ParagraphElement => ({
  type: "paragraph",
  children: [
    {
      text: "",
    },
  ],
});

const createPlaceholder = (label: string): PlaceholderElement => ({
  type: "placeholder",
  label,
  children: [
    {
      text: `{{${label}}}`,
    },
  ],
});

export const Factory = Object.freeze({
  createParagraph,
  createPlaceholder,
});
