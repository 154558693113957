import { useCallback } from "react";

import {
  FormHelperText,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty, isNil, noop, pick, toNumber } from "lodash";
import { FieldPath, FormProvider } from "react-hook-form";
import { NumberParam, useQueryParam } from "use-query-params";
import { z } from "zod";

import { Nullish } from "@/common";
import { Modal, ModalProps, QuerySuspense } from "@/components/disclosure";
import {
  Button,
  CreatableSelectFormField,
  Form,
  FormFieldControl,
  InputFormField,
  TextAreaFormField,
  useForm,
} from "@/components/form";
import { createSelectFieldTransform } from "@/components/form/utils";
import {
  asCampaignViewModel,
  useCampaign,
  useCategories,
  useCreateCategory,
  useCreateGroup,
  useGroups,
  useUpdateCampaign,
  useValidateCampaign,
} from "@/services/hooks/campaigns";
import { asCampaignRequest } from "@/services/hooks/campaigns";
import { asOption } from "@/services/hooks/typeahead";
import { CampaignGroup, Category } from "@/services/types";
import { categorySchema, groupSchema } from "@/ui/schemas";
import { createId } from "@/utils";

export interface CampaignWizardCompleteModalProps
  extends Omit<ModalProps, "isOpen" | "children" | "onClose"> {
  onSubmit: () => void;
}

export const CampaignWizardCompleteModal = ({
  onSubmit,
  ...props
}: CampaignWizardCompleteModalProps) => {
  const [campaignId] = useQueryParam("ref", NumberParam);

  const { data: campaign, isLoading: isLoadingCampaign } = useCampaign(
    { id: campaignId! },
    { enabled: !isNil(campaignId), select: asCampaignViewModel }
  );

  const { mutateAsync: validateCampaign } = useValidateCampaign();
  const { mutateAsync: updateCampaign } = useUpdateCampaign();

  const {
    data: groups = [],
    setInterimData: setInterimGroups,
    isLoading: isLoadingGroups,
  } = useGroups({
    select: (x) =>
      x.elements.map((y) => ({ value: y.id.toString(), label: y.name })),
  });

  const { mutateAsync: createGroup } = useCreateGroup({
    onMutate: ({ name }) => {
      return setInterimGroups((draft) => {
        draft?.elements.push({ id: createId(), name });
      });
    },
  });

  const {
    data: categories = [],
    setInterimData: setInterimCategories,
    isLoading: isLoadingCategories,
  } = useCategories({
    select: (x) => x.elements.map(asOption),
  });

  const { mutateAsync: createCategory } = useCreateCategory({
    onMutate: ({ label }) => {
      return setInterimCategories((draft) => {
        draft?.elements.push({ id: createId(), label });
      });
    },
  });

  const methods = useForm<FieldValues>({
    suspended: isNil(campaign),
    defaultValues: pick(
      campaign ?? defaultValues,
      Object.keys(defaultValues)
    ) as FieldValues,
    resolver: zodResolver(schema),
  });

  const handleSubmit = useCallback(
    async ({ title, description, categories, group }: FieldValues) => {
      const campaignRequest = asCampaignRequest({
        ...campaign!,
        title,
        description,
        categories,
        group,
      });

      const errors = await validateCampaign({
        campaign: campaignRequest,
        editMode: true,
      });

      if (!isNil(errors) && !isEmpty(errors)) {
        errors.forEach((x) =>
          methods.setError(x.name as FieldPath<FieldValues>, x.error)
        );

        return;
      }

      await updateCampaign({
        id: campaign!.id,
        campaign: campaignRequest,
      });

      onSubmit();
    },
    [campaign, methods, onSubmit, updateCampaign, validateCampaign]
  );

  return (
    <Modal
      isOpen={!isNil(campaignId)}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={noop}
      {...props}
    >
      <ModalOverlay backdropFilter="blur(8px)" />

      <ModalContent>
        <ModalHeader>Enter your campaign details</ModalHeader>

        <ModalBody overflow="visible">
          <QuerySuspense suspended={isLoadingCampaign}>
            <FormProvider {...methods}>
              <Form
                id="campaign-wizard-complete-modal__form"
                onSubmit={methods.handleSubmit(handleSubmit)}
              >
                <VStack spacing={4}>
                  <FormFieldControl
                    name="title"
                    isRequired
                    isDisabled={isLoadingCampaign}
                  >
                    <FormLabel>Title</FormLabel>
                    <InputFormField />
                  </FormFieldControl>

                  <FormFieldControl
                    name="description"
                    isDisabled={isLoadingCampaign}
                  >
                    <FormLabel>Description</FormLabel>
                    <TextAreaFormField />
                  </FormFieldControl>

                  <FormFieldControl
                    name="categories"
                    isDisabled={isLoadingCampaign}
                  >
                    <FormLabel>Categories</FormLabel>
                    <CreatableSelectFormField
                      options={categories}
                      placeholder="Search for categories..."
                      tagVariant="outline"
                      isMulti
                      isLoading={isLoadingCategories}
                      fieldTransform={categoriesSelectFieldTransform}
                      onCreate={async (inputValue) => {
                        const { id, label } = await createCategory({
                          label: inputValue,
                        });

                        return { value: id.toString(), label };
                      }}
                    />
                  </FormFieldControl>

                  <FormFieldControl name="group" isDisabled={isLoadingCampaign}>
                    <FormLabel>Group</FormLabel>

                    <CreatableSelectFormField
                      options={groups}
                      placeholder="Search for groups..."
                      tagVariant="outline"
                      isClearable
                      isLoading={isLoadingGroups}
                      fieldTransform={groupsSelectFieldTransform}
                      onCreate={async (inputValue) => {
                        const { id, name } = await createGroup({
                          name: inputValue,
                        });

                        return { value: id.toString(), label: name };
                      }}
                    />

                    <FormHelperText>
                      Campaigns within the same group will target each prospect
                      only once.
                    </FormHelperText>
                  </FormFieldControl>
                </VStack>
              </Form>
            </FormProvider>
          </QuerySuspense>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            form="campaign-wizard-complete-modal__form"
            colorScheme="primary"
            loadingText="Continue"
            spinnerPlacement="end"
            isLoading={methods.formState.isSubmitting}
            isDisabled={!methods.formState.isValid}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface FieldValues {
  title: string;
  description: string;
  categories: Category[];
  group: Nullish<CampaignGroup>;
}

const defaultValues: FieldValues = {
  title: "",
  description: "",
  categories: [],
  group: null,
};

const schema = z.object({
  title: z
    .string()
    .nonempty("The title cannot be empty")
    .max(100, "The title cannot be longer than 100 characters"),
  description: z
    .string()
    .max(1000, "The description cannot be longer than 1000 characters"),
  categories: z.array(categorySchema),
  group: groupSchema,
});

const categoriesSelectFieldTransform = createSelectFieldTransform<Category>(
  ({ id, label }) => ({
    value: id.toString(),
    label,
  }),
  ({ value, label }) => ({ id: toNumber(value), label })
);

const groupsSelectFieldTransform = createSelectFieldTransform<CampaignGroup>(
  ({ id, name }) => ({ value: id.toString(), label: name }),
  ({ value, label }) => ({ id: toNumber(value), name: label })
);
