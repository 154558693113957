import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";

type QueryArgs = Parameters<typeof CrmService.getTags>[0];
type QueryFnData = AsyncReturnType<typeof CrmService.getTags>;
type Error = unknown;

const queryKey = (args?: QueryArgs) => ["crm", "tags", args];

export const useTags = <TData = QueryFnData>(
  args: QueryArgs = {},
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CrmService.getTags(args),
    options
  );

  return response;
};

useTags.queryKey = queryKey;
