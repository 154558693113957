import { useCallback, useState } from "react";

import produce, { Draft, enableMapSet } from "immer";

enableMapSet();

export type ImmerSetter<T> = T | ((draft: Draft<T>) => void);
export type UseImmerResult<T> = [T, (setter: ImmerSetter<T>) => void];

export const useImmer = <T = any>(initialValue: T): UseImmerResult<T> => {
  const [value, _setValue] = useState(initialValue);

  const setValue = useCallback((setter: ImmerSetter<T>) => {
    if (typeof setter === "function") {
      _setValue(produce<T>(setter as (draft: Draft<T>) => void));
    } else {
      _setValue(setter);
    }
  }, []);

  return [value, setValue];
};
