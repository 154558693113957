import { useEffect, useRef, useState } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { MotionBox } from "@/styles/motion";

export interface ResizeTransitionProps extends BoxProps {
  animate?: "width" | "height" | "both" | "none";
}

export const ResizeTransition = ({
  animate = "both",
  children,
  ...props
}: ResizeTransitionProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState<number | "auto">("auto");
  const [height, setHeight] = useState<number | "auto">("auto");

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(([entry]) => {
        const observedWidth = entry.contentRect.width;
        setWidth(observedWidth);

        const observedHeight = entry.contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const widthToSet = ["width", "both"].includes(animate) ? width : undefined;
  const heightToSet = ["height", "both"].includes(animate) ? height : undefined;

  return (
    <MotionBox
      overflow="auto"
      w={widthToSet}
      h={heightToSet}
      animate={{
        ...(widthToSet ? { width: widthToSet } : {}),
        ...(heightToSet ? { height: heightToSet } : {}),
      }}
      // @ts-ignore
      transition={{ duration: 0.1, ease: "easeOut" }}
      {...props}
    >
      <Box ref={containerRef}>{children}</Box>
    </MotionBox>
  );
};
