import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { Box, ChakraProvider, VStack } from "@chakra-ui/react";
import { ErrorBoundary, init as initializeSentry } from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { AnimatePresence } from "framer-motion";
import { useAtom } from "jotai";
import qs from "qs";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { EncodedQuery, QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { ApplicationRoutes } from "./application-routes";
import { AnyObject } from "./common";
import { Survey } from "./components/disclosure";
import { Text } from "./components/display";
import { NotificationProvider, ToasterManager } from "./components/feedback";
import { Button } from "./components/form";
import { PAGE_BANNER_ID } from "./components/layout";
import { FLAGSMITH, SENTRY, STRIPE } from "./config";
import {
  AuthProvider,
  HealthProvider,
  OnboardingProvider,
  ServerStateProvider,
  TrackingProvider,
} from "./contexts";
import { useEventListener } from "./hooks";
import { queryClient } from "./http";
import { ts } from "./locales";
import { FallbackErrorScreen } from "./screens";
import { FONT_SRC, STRIPE_THEME, THEME } from "./styles";
import { globalQueryErrorsAtom } from "./ui/state";
import { noop, noopPromise } from "./utils";
import "./worker-registration";

initializeSentry({
  dsn: SENTRY.DSN,
  environment: SENTRY.ENV,
  integrations: SENTRY.INTEGRATIONS,
  tracesSampleRate: SENTRY.TRACE_SAMPLE_RATE,
  replaysSessionSampleRate: SENTRY.REPLAY_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY.REPLAY_SESSION_ERROR_SAMPLE_RATE,
});

const stripeResolver = loadStripe(STRIPE.PUBLISHABLE_KEY!);

const tCommon = ts("COMMON");

export const Application = () => {
  useEventListener("error", (event) => {
    noop(event);
  });

  useEventListener("unhandledrejection", (event) => {
    if ("reason" in event && axios.isCancel(event.reason)) {
      event.preventDefault();
      return;
    }
  });

  useEventListener("offline", () => {
    showOfflineToast();
  });

  useEventListener("online", () => {
    queryClient.invalidateQueries();
    showOnlineToast();
  });

  const [globalQueryErrors] = useAtom(globalQueryErrorsAtom);
  const [isRetrying, setIsRetrying] = useState(false);

  useEffect(() => {
    const toastId = "global-query-error-id";

    if (!globalQueryErrors?.length && !isRetrying) {
      ToasterManager.dismiss(toastId);
      return;
    }

    const globalRefresh = globalQueryErrors.reduce(
      (acc, error) => async () => {
        await Promise.all([acc(), error.refetch()]);
      },
      noopPromise
    );

    ToasterManager.error({
      title: (
        <VStack spacing={2} alignItems="flex-start">
          <Text as="span">
            There was a problem loading some of the information on this page.
          </Text>

          <Button
            size="sm"
            variant="link"
            colorScheme="blue"
            isLoading={isRetrying}
            spinnerPlacement="end"
            loadingText="Retrying"
            fontSize="inherit"
            onClick={async () => {
              try {
                setIsRetrying(true);
                await globalRefresh();
              } finally {
                setIsRetrying(false);
              }
            }}
          >
            Try again
          </Button>
        </VStack>
      ),
      options: {
        id: toastId,
        duration: Infinity,
      },
    });
  }, [globalQueryErrors, isRetrying]);

  return (
    <ApplicationProvider
      providers={[
        provider(BrowserRouter),
        provider(QueryParamProvider, {
          adapter: ReactRouter6Adapter,
          options: {
            searchStringToObject: (x) => {
              const object = qs.parse(x, { ignoreQueryPrefix: true });
              return object as EncodedQuery;
            },
            objectToSearchString: (x) => {
              const string = qs.stringify(x, { addQueryPrefix: false });
              return string;
            },
          },
        }),
        provider(ChakraProvider, { theme: THEME }),
        provider(ErrorBoundary, {
          fallback: (props) => <FallbackErrorScreen {...props} />,
        }),
        provider(QueryClientProvider, { client: queryClient }),
        provider(FlagsmithProvider, {
          flagsmith,
          options: {
            environmentID: FLAGSMITH.KEY!,
            cacheFlags: true,
          },
        }),
        provider(HelmetProvider),
        provider(HealthProvider),
        provider(AuthProvider),
        provider(ServerStateProvider),
        provider(TrackingProvider),
        provider(OnboardingProvider),
        provider(NotificationProvider),
      ]}
    >
      <ReactQueryDevtools initialIsOpen={false} />

      <Toaster position="bottom-center" containerStyle={{ zIndex: 100000 }} />

      <Survey id="nP97pd" />

      <Elements
        stripe={stripeResolver}
        options={{
          fonts: [{ cssSrc: FONT_SRC }],
          appearance: STRIPE_THEME,
        }}
      >
        <>
          <Box
            id={PAGE_BANNER_ID}
            position="sticky"
            top={0}
            w="full"
            zIndex="banner"
          />

          <AnimatePresence mode="wait">
            <ApplicationRoutes />
          </AnimatePresence>
        </>
      </Elements>
    </ApplicationProvider>
  );
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const showGlobalErrorToast = () => {
  ToasterManager.error({
    title: tCommon("ERROR.FALLBACK_TITLE"),
    message: tCommon("ERROR.FALLBACK_MESSAGE"),
  });
};

const showOnlineToast = () => {
  ToasterManager.success({
    title: "You are back online.",
    options: { id: "application__online-status" },
  });
};

const showOfflineToast = () => {
  ToasterManager.info({
    title: "You have disconnected from the internet.",
    options: {
      id: "application__online-status",
      duration: Infinity,
    },
  });
};

const provider = <T extends ElementType>(
  Element: T,
  props: Omit<ComponentPropsWithoutRef<T>, "children"> | undefined = undefined
) => ({
  Element,
  props,
});

interface ApplicationProviderProps {
  providers: { Element: ElementType; props?: AnyObject }[];
  children: ReactNode;
}

const ApplicationProvider = ({
  providers,
  children,
}: ApplicationProviderProps) => {
  const renderProviders = (
    providers: ApplicationProviderProps["providers"],
    children: ApplicationProviderProps["children"]
  ): ReactNode => {
    const [provider, ...restProviders] = providers;

    if (!provider) {
      return children;
    }

    const { Element, props } = provider;

    return (
      <Element {...props}>{renderProviders(restProviders, children)}</Element>
    );
  };

  return <>{renderProviders(providers, children)}</>;
};
