import { ComponentType } from "react";

import { getDisplayName } from "@/utils";

export const withReset = <TProps,>(
  Component: ComponentType<TProps>,
  resetProp: keyof TProps
) => {
  const WithReset = (props: TProps) => (
    <Component key={props[resetProp] as string} {...props} />
  );

  WithReset.displayName = `WithReset(${getDisplayName(Component)})`;

  return WithReset;
};
