import { Icon } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

import { IconButton, IconButtonProps } from "@/components/form";

import { useDrawerContext } from "./drawer-context";

export interface DrawerCloseButton
  extends Omit<IconButtonProps, "aria-label"> {}

export const DrawerCloseButton = (props: DrawerCloseButton) => {
  const { onClose } = useDrawerContext();

  return (
    <IconButton
      aria-label="Close"
      position="absolute"
      top={2}
      right={2}
      size="xs"
      variant="ghost"
      icon={<Icon as={MdClose} />}
      onClick={onClose}
      {...props}
    />
  );
};
