import { AsyncReturnType } from "type-fest";

import { BillingService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof BillingService.voidInvoice>[0];
type Data = AsyncReturnType<typeof BillingService.voidInvoice>;
type Error = unknown;

export const useVoidInvoice = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    BillingService.voidInvoice,
    options
  );

  return result;
};
