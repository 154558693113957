import { useCallback, useState } from "react";

export type useBooleanResult = [boolean, () => void, () => void];

export const useBoolean = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return [value, setTrue, setFalse] as useBooleanResult;
};
