import { RequestData, RequestParams, fetchApi } from "./openapi";
import { Id } from "./types";

const getConversations = (args: RequestParams<"/conversations", "get">) => {
  const result = fetchApi("/conversations", "get", {
    params: { query: args },
  });

  return result;
};

const getConversation = ({
  id,
}: RequestParams<"/conversations/{id}", "get">) => {
  const result = fetchApi("/conversations/{id}", "get", {
    params: { path: { id } },
  });

  return result;
};

const pollConversations = (
  args: RequestParams<"/conversations/poll", "get"> = {}
) => {
  const result = fetchApi("/conversations/poll", "get", {
    params: { query: args },
  });

  return result;
};

const markRead = ({
  id,
  isRead = true,
}: RequestParams<"/conversations/{id}/read", "put">) => {
  const result = fetchApi("/conversations/{id}/read", "put", {
    params: { path: { id }, query: { isRead } },
  });

  return result;
};

const markImportant = ({
  id,
  isImportant = true,
}: RequestParams<"/conversations/{id}/important", "put">) => {
  const result = fetchApi("/conversations/{id}/important", "put", {
    params: { path: { id }, query: { isImportant } },
  });

  return result;
};

const getMessages = ({
  id,
  cursor,
  limit,
}: RequestParams<"/conversations/{id}/messages", "get">) => {
  const result = fetchApi("/conversations/{id}/messages", "get", {
    params: { path: { id }, query: { cursor, limit } },
  });

  return result;
};

const pollMessages = ({
  id,
}: RequestParams<"/conversations/{id}/messages/poll", "get">) => {
  const result = fetchApi("/conversations/{id}/messages/poll", "get", {
    params: { path: { id } },
  });

  return result;
};

const sendMessage = async ({
  id,
  data,
}: {
  data: Omit<
    RequestData<"/conversations/{id}/messages", "post">,
    "attachments"
  > & {
    attachments: { name: string; size: number; type: string; data: File }[];
  };
} & RequestParams<"/conversations/{id}/messages", "post">) => {
  const uploadAttachmentResult = await Promise.all(
    data.attachments.map(async (attachment) => {
      const metadata = await uploadAttachmentMetadata({
        id,
        data: { filename: attachment.name, fileSize: attachment.size },
      });

      await uploadAttachment({
        id,
        ref: metadata.ref!,
        file: attachment.data,
      });

      return metadata;
    })
  );

  const result = fetchApi("/conversations/{id}/messages", "post", {
    params: {
      path: {
        id,
      },
    },
    data: {
      message: data.message,
      attachments: data.attachments.map((attachment, index) => ({
        id: uploadAttachmentResult[index].id,
        name: attachment.name,
        type: attachment.type,
        size: attachment.size,
      })),
    },
  });

  return result;
};

const getScheduledMessages = async ({
  id,
  skip,
  limit,
}: RequestParams<"/conversations/{id}/scheduled-messages", "get">) => {
  const result = fetchApi("/conversations/{id}/scheduled-messages", "get", {
    params: { path: { id }, query: { skip, limit } },
  });

  return result;
};

const scheduleMessage = async ({
  id,
  data,
}: RequestParams<"/conversations/{id}/scheduled-messages", "post"> & {
  data: RequestData<"/conversations/{id}/scheduled-messages", "post">;
}) => {
  const result = fetchApi("/conversations/{id}/scheduled-messages", "post", {
    params: { path: { id } },
    data,
  });

  return result;
};

const updateScheduledMessage = async ({
  id,
  messageId,
  data,
}: RequestParams<
  "/conversations/{id}/scheduled-messages/{messageId}",
  "put"
> & {
  data: RequestData<
    "/conversations/{id}/scheduled-messages/{messageId}",
    "put"
  >;
}) => {
  const result = fetchApi(
    "/conversations/{id}/scheduled-messages/{messageId}",
    "put",
    {
      params: { path: { id, messageId } },
      data,
    }
  );

  return result;
};

const cancelScheduledMessage = async ({
  id,
  messageId,
}: RequestParams<
  "/conversations/{id}/scheduled-messages/{messageId}",
  "delete"
>) => {
  const result = fetchApi(
    "/conversations/{id}/scheduled-messages/{messageId}",
    "delete",
    {
      params: { path: { id, messageId } },
    }
  );

  return result;
};

const uploadAttachmentMetadata = ({
  id,
  data,
}: {
  data: RequestData<"/conversations/{id}/attachments", "post">;
} & RequestParams<"/conversations/{id}/attachments", "post">) => {
  const result = fetchApi("/conversations/{id}/attachments", "post", {
    params: {
      path: {
        id,
      },
    },
    data,
  });

  return result;
};

const uploadAttachment = ({
  id,
  ref,
  file,
}: {
  id: Id;
  ref: string;
  file: Blob;
}) => {
  const formData = new FormData();
  formData.append("file", file);

  const result = fetchApi("/conversations/{id}/attachments", "put", {
    params: {
      path: {
        id,
      },
      query: {
        ref,
      },
    },
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return result;
};

export const ConversationService = Object.freeze({
  getConversations,
  getConversation,
  pollConversations,
  getMessages,
  pollMessages,
  markRead,
  markImportant,
  sendMessage,
  getScheduledMessages,
  scheduleMessage,
  updateScheduledMessage,
  cancelScheduledMessage,
  uploadAttachmentMetadata,
  uploadAttachment,
});
