import { RequestData, RequestParams, fetchApi } from "./openapi";

const getLeads = (args: RequestParams<"/crm/leads", "get"> = {}) => {
  const result = fetchApi("/crm/leads", "get", { params: { query: args } });

  return result;
};

const getLead = ({
  id,
  campaignId,
  fromLinkedInId = false,
}: RequestParams<"/crm/leads/{id}", "get">) => {
  const result = fetchApi("/crm/leads/{id}", "get", {
    params: { path: { id }, query: { campaignId, fromLinkedInId } },
  });

  return result;
};

const createLead = (data: RequestData<"/crm/leads", "post">) => {
  const result = fetchApi("/crm/leads", "post", { data });

  return result;
};

const removeLead = ({
  id,
  campaignId,
}: RequestParams<"/crm/leads/{id}", "delete">) => {
  const result = fetchApi("/crm/leads/{id}", "delete", {
    params: { path: { id }, query: { campaignId } },
  });

  return result;
};

const getContact = ({
  id,
  fromLinkedInId = false,
}: RequestParams<"/crm/contacts/{id}", "get">) => {
  const result = fetchApi("/crm/contacts/{id}", "get", {
    params: { path: { id }, query: { fromLinkedInId } },
  });

  return result;
};

const getContacts = async (
  args: RequestParams<"/crm/contacts", "get"> = {}
) => {
  const result = fetchApi("/crm/contacts", "get", { params: { query: args } });

  return result;
};

const updateContact = async ({
  id,
  contact,
}: {
  contact: RequestData<"/crm/contacts/{id}", "put">;
} & RequestParams<"/crm/contacts/{id}", "put">) => {
  const result = fetchApi("/crm/contacts/{id}", "put", {
    params: { path: { id } },
    data: contact,
  });

  return result;
};

const getTags = async (args: RequestParams<"/crm/tags", "get">) => {
  const result = fetchApi("/crm/tags", "get", { params: { query: args } });

  return result;
};

const createTag = async (data: RequestData<"/crm/tags", "post">) => {
  const result = fetchApi("/crm/tags", "post", {
    data,
  });

  return result;
};

const linkTag = async ({
  contact,
  tag,
}: {
  contact: RequestParams<"/crm/contacts/{id}/tags", "post">;
  tag: RequestData<"/crm/contacts/{id}/tags", "post">;
}) => {
  const result = fetchApi("/crm/contacts/{id}/tags", "post", {
    params: { path: { id: contact.id } },
    data: {
      id: tag.id,
    },
  });

  return result;
};

const unlinkTag = async ({
  contact,
  tag,
}: {
  contact: RequestParams<"/crm/contacts/{id}/tags", "delete">;
  tag: RequestData<"/crm/contacts/{id}/tags", "delete">;
}) => {
  const result = fetchApi("/crm/contacts/{id}/tags", "delete", {
    params: { path: { id: contact.id } },
    data: {
      id: tag.id,
    },
  });

  return result;
};

export const CrmService = Object.freeze({
  getLeads,
  getLead,
  createLead,
  removeLead,
  getContact,
  getContacts,
  updateContact,
  getTags,
  createTag,
  linkTag,
  unlinkTag,
});
