import { KeyboardEvent, MouseEvent, useCallback, useMemo } from "react";

import { Box, Flex, Tag, TagLabel, Wrap, WrapItem } from "@chakra-ui/react";
import { isNil } from "lodash";
import { Element, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  Slate,
  withReact,
} from "slate-react";

import { Text } from "@/components/display";
import { TextArea, TextAreaProps } from "@/components/form";
import { withController } from "@/components/form/hocs";
import { KEY_CODE } from "@/dom";
import { Placeholder, RichTextValue } from "@/types";
import { RichText } from "@/utils";

import { Factory, PlaceholderElement } from "./elements";
import { withPlaceholders } from "./hocs";

export interface RichTextAreaProps {
  value?: RichTextValue;
  placeholder?: string;
  placeholders?: Placeholder[];
  isReadOnly?: boolean;
  variant?: TextAreaProps["variant"];
  charLimit?: number;
  onChange?: (value: RichTextValue) => void;
}

export const RichTextArea = ({
  value = RichText.Empty,
  placeholder = "",
  placeholders = RichText.Placeholders,
  isReadOnly = false,
  variant = "filled",
  charLimit,
  onChange,
  ...props
}: RichTextAreaProps) => {
  const editor = useMemo(
    () => withPlaceholders(withHistory(withReact(createEditor()))),
    []
  );

  const renderElement = useCallback((props: RenderElementProps) => {
    const { attributes, children, element } = props;

    const resolver = (type: Element["type"]) =>
      ({
        placeholder: <PlaceholderElement {...props} />,
        paragraph: <p {...attributes}>{children}</p>,
      }[type] || <p {...attributes}>{children}</p>);

    return resolver(element.type);
  }, []);

  const handleAddPlaceholderClick = useCallback(
    (event: MouseEvent | KeyboardEvent, definition: Placeholder) => {
      event.preventDefault();

      const selection = editor.selection;
      const placeholder = Factory.createPlaceholder(definition.label);

      if (selection) {
        Transforms.select(editor, selection);
      }

      Transforms.insertNodes(editor, placeholder);
      Transforms.move(editor);

      ReactEditor.focus(editor);
    },
    [editor]
  );

  const charCount = useMemo(() => RichText.length(value), [value]);

  return (
    <Box position="relative" {...props}>
      {!isReadOnly && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          position="absolute"
          bottom="px"
          left="px"
          zIndex="docked"
          w="full"
          px={4}
          py={2}
        >
          <Wrap spacing={1}>
            {placeholders.map((placeholder) => (
              <WrapItem key={placeholder.id}>
                <Tag
                  tabIndex={0}
                  cursor="pointer"
                  border="1px solid"
                  borderColor="gray.200"
                  onClick={(ev) => {
                    handleAddPlaceholderClick(ev, placeholder);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === KEY_CODE.ENTER) {
                      handleAddPlaceholderClick(ev, placeholder);
                    }
                  }}
                >
                  <TagLabel>{placeholder.label}</TagLabel>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>

          {!isNil(charLimit) && (
            <Text
              fontSize="xs"
              fontWeight="semibold"
              color="gray.500"
              userSelect="none"
              whiteSpace="nowrap"
            >
              {charCount} / {charLimit}
            </Text>
          )}
        </Flex>
      )}

      <Slate
        editor={editor}
        // As of current Slate version "value" is actually the default value
        // and the component can't be controlled externally by default
        value={value}
        onChange={onChange}
      >
        <TextArea
          as={Editable}
          variant={variant}
          readOnly={isReadOnly}
          placeholder={placeholder}
          renderElement={renderElement}
          cursor="text"
          minH="1rem"
          pb="50px"
          h={isReadOnly ? "fit-content" : 64}
          overflowY="auto"
        />
      </Slate>
    </Box>
  );
};

export const RichTextAreaFormField = withController(RichTextArea);
