import { useMemo } from "react";

import { Grid } from "@chakra-ui/layout";
import { GridProps } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FaLinkedinIn } from "react-icons/fa";

import { useQuerySuspense } from "@/components/disclosure";
import { Badge, Text } from "@/components/display";
import { IconButton } from "@/components/form";
import { Avatar } from "@/components/media";
import { Contact } from "@/services/types";

export interface ContactProps extends GridProps {
  contact: Omit<Contact, "tags">;
  showLinkedInLink?: boolean;
}

export const ContactCard = ({
  contact,
  showLinkedInLink = true,
  ...props
}: ContactProps) => {
  const { isSuspended } = useQuerySuspense();

  const { fullName, headline, location } = contact;

  const profilePicture = contact.linkedInProfile?.pictures?.sort(
    (x, y) => y.width - x.width
  )[0];

  const isFromToday = useMemo(
    () =>
      DateTime.now()
        .startOf("day")
        .equals(DateTime.fromISO(contact.createdDate).startOf("day")),
    [contact.createdDate]
  );

  return (
    <Grid
      templateColumns="auto minmax(0, 1fr) auto"
      templateRows={isSuspended ? "auto" : "repeat(auto-fit, minmax(0, 1fr))"}
      templateAreas={`
        "avatar name meta"
        "avatar headline headline"
        "avatar location location"
      `}
      alignItems="center"
      columnGap={2}
      rowGap="px"
      p={2}
      border="1px"
      borderColor="inherit"
      borderRadius="md"
      {...props}
    >
      <Avatar
        src={profilePicture?.url}
        name={fullName}
        size="md"
        gridArea="avatar"
      />

      <TruncatedText gridArea="name" fontSize="md" fontWeight="semibold">
        {fullName}
      </TruncatedText>

      {headline && (
        <TruncatedText gridArea="headline">{headline}</TruncatedText>
      )}

      {location && (
        <TruncatedText gridArea="location" fontSize="sm" color="gray.600">
          {location}
        </TruncatedText>
      )}

      {showLinkedInLink ? (
        <IconButton
          as="a"
          gridArea="meta"
          href={contact.linkedInProfile.flagshipProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
          size="xs"
          icon={<FaLinkedinIn />}
          aria-label="LinkedIn profile link"
        />
      ) : (
        isFromToday && (
          <Badge
            gridArea="meta"
            colorScheme="green"
            alignSelf="start"
            hideOnSuspense
          >
            New
          </Badge>
        )
      )}
    </Grid>
  );
};

const TruncatedText = ({ ...props }) => (
  <Text as="span" isTruncated maxW="100%" {...props} />
);
