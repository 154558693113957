import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services/conversation-service";
import {
  UseInfiniteQuerySimpleOptions,
  useInfiniteQuery,
} from "@/services/hooks";

type QueryArgs = Parameters<typeof ConversationService.getMessages>[0];
type QueryFnData = AsyncReturnType<typeof ConversationService.getMessages>;
type Error = unknown;

const queryKey = ({ id, ...restArgs }: QueryArgs) => [
  "conversations",
  id,
  "messages",
  restArgs,
];

export const useMessages = <TData = QueryFnData, TFlatData = TData>(
  args: QueryArgs,
  options?: UseInfiniteQuerySimpleOptions<QueryFnData, Error, TData, TFlatData>
) => {
  const response = useInfiniteQuery<QueryFnData, Error, TData, TFlatData>(
    queryKey(args),
    ({ pageParam }) =>
      ConversationService.getMessages({ ...args, cursor: pageParam }),
    {
      getPreviousPageParam: (firstPage) => firstPage.pagination.previousCursor,
      ...options,
    }
  );

  return response;
};

useMessages.queryKey = queryKey;
