import { DependencyList, useEffect } from "react";

import { MaybePromise } from "@/common";

declare const UNDEFINED_VOID_ONLY: unique symbol;
type Destructor = () => void | { [UNDEFINED_VOID_ONLY]: never };
type EffectCallback = (
  isMounted: () => boolean
) => MaybePromise<void | Destructor>;

export const useAsyncEffect = (
  effect: EffectCallback,
  destroyOrDeps: DependencyList | EffectCallback,
  deps?: DependencyList
) => {
  const hasDestroy = typeof destroyOrDeps === "function";

  useEffect(
    () => {
      let result: any;
      let mounted = true;

      const maybePromise = effect(() => {
        return mounted;
      });

      Promise.resolve(maybePromise).then((value) => {
        result = value;
      });

      return () => {
        mounted = false;

        if (hasDestroy) {
          destroyOrDeps(result);
        } else if (!hasDestroy && typeof result === "function") {
          result();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    hasDestroy ? deps : destroyOrDeps
  );
};
