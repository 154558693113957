import { Box, BoxProps } from "@chakra-ui/react";

import { Text } from "@/components/display";

export interface InvoicePreviewProps extends BoxProps {}

export const InvoicePreview = ({ children, ...props }: InvoicePreviewProps) => {
  return (
    <Box
      w="full"
      border="1px solid"
      borderColor="inherit"
      borderRadius="md"
      {...props}
    >
      <Text
        fontWeight="semibold"
        p={{ base: 4, md: 6 }}
        color="gray.600"
        suspendable={false}
      >
        Invoice summary
      </Text>

      {children}
    </Box>
  );
};
