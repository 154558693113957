import { ReactNode, memo, useState } from "react";

import { Box, BoxProps, Circle, Flex, HStack, Icon } from "@chakra-ui/react";
import { LayoutGroup } from "framer-motion";
import {
  MdCampaign,
  MdMessage,
  MdOutlineCampaign,
  MdOutlineMessage,
  MdPeople,
  MdPeopleOutline,
} from "react-icons/md";
import { useMatch } from "react-router";
import { NavLink } from "react-router-dom";

import logo from "@/assets/logo.svg";
import { NotificationListPopover } from "@/components/feedback";
import { PAGE_HEADER_ID, PageContainer } from "@/components/layout";
import { Link } from "@/components/navigation";
import { useAuth } from "@/contexts";
import { useConversations } from "@/services/hooks/conversations";
import { MotionBox, transitions } from "@/styles/motion";
import { useMediaQuery } from "@/ui/hooks";

import { HeaderMenu } from "./header-menu";

export interface HeaderProps extends BoxProps {}

const HeaderRaw = ({ id = PAGE_HEADER_ID, ...props }: HeaderProps) => {
  const { isAuthenticated } = useAuth();

  const [isLargeScreen] = useMediaQuery("lg");

  const { flatData: unreadConversations } = useConversations(
    {
      unread: true,
      limit: 1,
    },
    {
      refetchInterval: 30000,
      flatSelect: (data) => data?.pages.flatMap((x) => x.elements),
    }
  );

  const hasUnreadConversations =
    !!unreadConversations?.length && 0 < unreadConversations.length;

  return (
    <Box
      as="header"
      id={id}
      position="sticky"
      top={0}
      w="full"
      py={{ base: 1, lg: 2 }}
      zIndex={1000}
      background="whiteAlpha.800"
      borderBottom="1px solid"
      borderColor="inherit"
      boxShadow="sm"
      backdropFilter="blur(8px)"
      {...props}
    >
      <PageContainer display="flex" justifyContent="space-between">
        <Flex flexBasis="33%" alignItems="center" justifyContent="flex-start">
          <Link to="/campaigns" suspendable={false}>
            <Box as="img" src={logo} alt="logo" w={28} />
          </Link>
        </Flex>

        {isLargeScreen && (
          <Flex flexBasis="33%" alignItems="center" justifyContent="center">
            <Box h="full">
              <HStack spacing={0} h="full">
                <LayoutGroup>
                  {MAIN_NAVIGATION.map((item, index) => (
                    <Box key={index} w={20} h="full">
                      <NavigationLink authenticated to={item.pathname}>
                        {(isActive) => (
                          <>
                            <Icon
                              as={isActive ? item.Icon : item.IconOutline}
                              fill={isActive ? "primary.500" : "gray.600"}
                              width={6}
                              height={6}
                              // SVG pointer event dropping onMouseLeave...
                              // https://github.com/facebook/react/issues/4492#issuecomment-426356566
                              pointerEvents="none"
                            />

                            {hasUnreadConversations &&
                              item.pathname === "/conversations" && (
                                <Circle
                                  size={2}
                                  position="absolute"
                                  top="1px"
                                  right={5}
                                  background="blue.500"
                                />
                              )}
                          </>
                        )}
                      </NavigationLink>
                    </Box>
                  ))}
                </LayoutGroup>
              </HStack>
            </Box>
          </Flex>
        )}

        <Flex flexBasis="33%" alignItems="center" justifyContent="flex-end">
          {isAuthenticated && (
            <Box mr={2}>
              <NotificationListPopover />
            </Box>
          )}

          {isAuthenticated && <HeaderMenu />}
        </Flex>
      </PageContainer>
    </Box>
  );
};

export const Header = memo(HeaderRaw);

interface NavigationLinkProps {
  to: string;
  authenticated: boolean;
  children: (match: boolean) => ReactNode;
}

const NavigationLinkRaw = ({
  to,
  authenticated = false,
  children,
}: NavigationLinkProps) => {
  const match = useMatch({
    path: to,
    end: false,
    caseSensitive: false,
  });

  const { isAuthenticated } = useAuth();

  const [isMouseOver, setIsMouseOver] = useState(false);

  if (authenticated && !isAuthenticated) {
    return null;
  }

  return (
    <Link
      as={NavLink}
      to={to}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="full"
      h="full"
      borderRadius="md"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      _activeLink={{
        _after: {
          content: `""`,
          position: "absolute",
          bottom: "calc(-1 * var(--cosiall-space-2))",
          left: 0,
          display: "block",
          width: "full",
          height: "3px",
          background: "primary.500",
          borderRadius: "xl",
        },
      }}
    >
      {isMouseOver && (
        <MotionBox
          layoutId="header__menu-item"
          initial={false}
          // @ts-ignore
          transition={transitions.spring}
          position="absolute"
          inset={0}
          background="gray.50"
          borderRadius="md"
          zIndex="-1"
        />
      )}

      {children(!!match)}
    </Link>
  );
};

const NavigationLink = memo(NavigationLinkRaw);

const MAIN_NAVIGATION = Object.freeze([
  {
    displayName: "Campaigns",
    Icon: MdCampaign,
    IconOutline: MdOutlineCampaign,
    pathname: "/campaigns",
  },
  {
    displayName: "Contacts",
    Icon: MdPeople,
    IconOutline: MdPeopleOutline,
    pathname: "/contacts",
  },
  {
    displayName: "Conversations",
    Icon: MdMessage,
    IconOutline: MdOutlineMessage,
    pathname: "/conversations",
  },
]);
