export const colors = Object.freeze({
  primary: {
    50: "#FFFAFB",
    100: "#FFFAFB",
    200: "#FFDBE4",
    300: "#FFB8C8",
    400: "#FF94AD",
    500: "#FF7092",
    600: "#D35F7A",
    700: "#A7495F",
    800: "#793545",
    900: "#4A212A",
  },
  gray: {
    50: "#F7F6F9",
    100: "#F4F2F6",
    200: "#E2DFE7",
    300: "#C0B9CA",
    400: "#ACA3B8",
    500: "#948AA1",
    600: "#81778F",
    700: "#6E637C",
    800: "#584E65",
    900: "#2F263A",
  },
  blue: {
    50: "#C5EAF7",
    100: "#A3D9EC",
    200: "#85C8E0",
    300: "#6BB7D4",
    400: "#559DC6",
    500: "#3C8BB8",
    600: "#297CA7",
    700: "#196B94",
    800: "#0B587F",
    900: "#0B587F",
  },
});
