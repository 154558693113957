import { ReactComponent as AmexIcon } from "@/assets/icons/cards/amex.svg";
import { ReactComponent as DefaultIcon } from "@/assets/icons/cards/default.svg";
import { ReactComponent as MastercardIcon } from "@/assets/icons/cards/mastercard.svg";
import { ReactComponent as VisaIcon } from "@/assets/icons/cards/visa.svg";

export interface PaymentTypeIconProps {
  type: "mastercard" | "visa" | "amex" | string;
}

export const PaymentTypeIcon = ({ type }: PaymentTypeIconProps) => {
  switch (type) {
    case "mastercard":
      return <MastercardIcon />;

    case "visa":
      return <VisaIcon />;

    case "amex":
      return <AmexIcon />;

    default:
      return <DefaultIcon />;
  }
};
