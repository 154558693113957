import { Box, Center, Spinner, VStack } from "@chakra-ui/react";

import { SkeletonText } from "@/components/disclosure";
import { Text } from "@/components/display";
import { Avatar } from "@/components/media";
import { gen, repeat } from "@/utils";

import { SelectProps } from "./select";
import { Option } from "./types";
import { fuzzyFilterOption } from "./utils";

const loadingOptions = repeat(() => gen.string(), 3);

export const defaultProps: Partial<SelectProps<Option>> = {
  menuPortalTarget: document.body,
  blurInputOnSelect: false,
  isClearable: false,
  filterOption: fuzzyFilterOption,
  components: {
    MultiValueLabel: ({ data }) => (
      <Box display="flex" alignItems="center">
        {data.image && (
          <Avatar src={data.image} name={data.label} size="2xs" mr={1} />
        )}

        <Text as="span" suspendable={false}>
          {data.label}
        </Text>
      </Box>
    ),
    LoadingIndicator: () => (
      <Center px={2}>
        <Spinner size="sm" />
      </Center>
    ),
    LoadingMessage: () => (
      <VStack spacing={2} alignItems="normal">
        {loadingOptions.map((text, index) => (
          <SkeletonText key={index} noOfLines={1} w="fit-content" py={1} px={2}>
            {text}
          </SkeletonText>
        ))}
      </VStack>
    ),
  },
  styles: {
    menuPortal: (styles) => ({ ...styles, zIndex: 1500 }),
  },
  noOptionsMessage: ({ inputValue }) => (
    <Text as="span" display="block" px={1} lineHeight="normal">
      {!!inputValue?.length ? (
        <>
          None of the options match the search term <b>{inputValue}</b>
        </>
      ) : (
        <>No options</>
      )}
    </Text>
  ),
  formatOptionLabel: (option) => (
    <Box display="flex" alignItems="center">
      {option.image && (
        <Avatar src={option.image} name={option.label} size="2xs" mr={2} />
      )}

      {option.icon && (
        <Box display="flex" alignItems="center" justifyContent="center" mr={2}>
          {option.icon}
        </Box>
      )}

      <Text as="span">{option.label}</Text>
    </Box>
  ),
};
