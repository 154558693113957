import { useCallback, useMemo, useState } from "react";

import { Box, BoxProps, Spinner, useToken } from "@chakra-ui/react";
import {
  CardElement,
  CardElementProps,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

export interface CardInputProps
  extends Omit<BoxProps, "children" | "onChange"> {
  variant?: "outline" | "filled";
  isDisabled?: boolean;
  onChange?: CardElementProps["onChange"];
  onReady?: CardElementProps["onReady"];
}

export const CardInput = ({
  variant = "filled",
  isDisabled = false,
  onChange,
  onReady,
  ...props
}: CardInputProps) => {
  const elements = useElements();

  const [isReady, setIsReady] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [textColor, placeholderTextColor] = useToken("colors", [
    "gray.800",
    "gray.500",
  ]);

  const variantStyles = useMemo(
    () => variants[variant]({ isFocused }),
    [variant, isFocused]
  );

  const handleClick = useCallback(() => {
    if (isReady) {
      elements?.getElement("card")?.focus();
    }
  }, [elements, isReady]);

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);
  const handleReady = useCallback(
    (element: StripeCardElement) => {
      setIsReady(true);
      onReady?.(element);
    },
    [onReady]
  );

  return (
    <Box
      display="grid"
      alignItems="center"
      w="full"
      h={10}
      px={4}
      border="1px solid"
      borderColor="transparent"
      borderRadius="md"
      boxShadow={isFocused ? "outline" : undefined}
      outline="2px solid transparent"
      outlineOffset="2px"
      cursor="text"
      pointerEvents={isReady ? "unset" : "none"}
      transitionProperty="common"
      transitionDuration="normal"
      onClick={handleClick}
      {...variantStyles}
      {...props}
    >
      <Box
        w="full"
        position={isReady ? "static" : "absolute"}
        visibility={isReady ? "visible" : "hidden"}
      >
        <CardElement
          onFocus={handleFocus}
          onBlur={handleBlur}
          options={{
            disabled: isDisabled,
            iconStyle: "default",
            hidePostalCode: true,
            style: {
              base: {
                color: textColor,
                fontSize: "16px",
                fontFamily: `"Inter", sans-serif`,
                fontSmoothing: "antialiased",
                "::placeholder": {
                  color: placeholderTextColor,
                },
              },
            },
          }}
          onChange={onChange}
          onReady={handleReady}
        />
      </Box>

      {!isReady && <Spinner size="sm" ml="auto" />}
    </Box>
  );
};

const variants: Record<
  NonNullable<CardInputProps["variant"]>,
  ({ isFocused }: { isFocused: boolean }) => Partial<BoxProps>
> = {
  outline: ({ isFocused }) => ({
    borderColor: isFocused ? "transparent" : "gray.200",
    _hover: {
      borderColor: isFocused ? "transparent" : "gray.300",
    },
  }),
  filled: ({ isFocused }) => ({
    background: isFocused ? "transparent" : "gray.100",
    _hover: {
      background: isFocused ? "transparent" : "gray.200",
    },
  }),
};
