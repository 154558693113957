import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Box, VStack, useMergeRefs } from "@chakra-ui/react";
import produce from "immer";
import { isNil, range } from "lodash";

import { MaybePromise, Nullish } from "@/common";
import { QuerySuspense, useQuerySuspense } from "@/components/disclosure";
import { Text } from "@/components/display";
import { Button } from "@/components/form";
import { useIntersectionObserver, useLocalForage } from "@/hooks";
import { ChatBubble } from "@/partials/conversations";
import { ScheduleMessageModal } from "@/screens/conversations/conversation-schedule";
import { Conversation, Message } from "@/services/types";
import { ArrayUtils, DateUtils } from "@/utils";

export type ConversationChatBoxElement = HTMLDivElement & {
  scrollToBottom: () => void;
};

// TODO: Add these props inside the component's state
export interface ConversationChatBoxProps {
  conversation: Conversation;
  messages: Message[];
  hasMore: boolean;
  isLoadingMore: boolean;
  onLoadMore: () => void;
  onResend: (message: Message) => MaybePromise<void>;
  onSchedule: (args: { message: Message; date: Date }) => MaybePromise<void>;
  onDiscard: (message: Message) => MaybePromise<void>;
}

export const ConversationChatBox = forwardRef<
  ConversationChatBoxElement,
  ConversationChatBoxProps
>(
  (
    {
      conversation,
      messages,
      hasMore,
      isLoadingMore,
      onLoadMore,
      onResend,
      onSchedule,
      onDiscard,
    },
    ref
  ) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const mergedRootRefs = useMergeRefs(ref, rootRef);

    const chatboxEndRef = useRef<HTMLDivElement>(null);
    const [chatboxStartElement, setChatboxStartElement] =
      useState<HTMLElement | null>();

    const scrollToBottom = useCallback(() => {
      setTimeout(() => {
        chatboxEndRef.current?.scrollIntoView();
      });
    }, []);

    useImperativeHandle(ref, () => ({
      ...((rootRef.current as HTMLDivElement) ?? {}),
      scrollToBottom,
    }));

    const { isSuspended } = useQuerySuspense();

    const [, setFailedMessagesByConversation] = useLocalForage<
      Record<number, Message[]>
    >("failed-messages", {});

    const [scheduledMessageModalState, setScheduledMessageModalState] =
      useState<{
        isOpen: boolean;
        message: Nullish<Message>;
      }>({ isOpen: false, message: null });

    const [isReady, setIsReady] = useState(false);
    const [lastMessage, setLastMessage] = useState<Message | null>(null);

    const sortedMessages = useMemo(
      () =>
        messages.sort(
          (x, y) =>
            new Date(x.sentDate).getTime() - new Date(y.sentDate).getTime()
        ),
      [messages]
    );

    useEffect(() => {
      if (sortedMessages.at(-1)?.id !== lastMessage?.id) {
        scrollToBottom();
        setLastMessage(sortedMessages.at(-1) ?? null);
      }
    }, [sortedMessages, lastMessage, scrollToBottom]);

    useLayoutEffect(() => {
      setIsReady(false);
    }, [conversation]);

    useLayoutEffect(() => {
      if (!isSuspended) {
        scrollToBottom();
        setTimeout(() => {
          setIsReady(true);
        }, 100);
      }
    }, [conversation, isSuspended, scrollToBottom]);

    useIntersectionObserver(
      chatboxStartElement,
      { root: rootRef.current, rootMargin: "50%" },
      () => {
        if (isReady && hasMore && !isLoadingMore) {
          onLoadMore();
        }
      }
    );

    return (
      <VStack
        ref={mergedRootRefs}
        position="relative"
        alignItems="flex-start"
        spacing={2}
        p={{ base: 2, lg: 8 }}
        px={{ base: 0, lg: 8 }}
        overflowY="scroll"
      >
        <ScheduleMessageModal
          isOpen={scheduledMessageModalState.isOpen}
          onSchedule={async (date) => {
            if (isNil(scheduledMessageModalState.message)) return;

            await onSchedule({
              message: scheduledMessageModalState.message,
              date,
            });

            await setFailedMessagesByConversation((prev) =>
              produce(prev, (draftState) => {
                ArrayUtils.spliceWhere(
                  draftState[conversation.id] || [],
                  (x) => x.id === scheduledMessageModalState.message!.id
                );
              })
            );
          }}
          onClose={() => {
            setScheduledMessageModalState({ isOpen: false, message: null });
          }}
        />

        {!isSuspended && !isLoadingMore && (
          <Box
            ref={setChatboxStartElement}
            w={0}
            h={0}
            opacity={0}
            visibility="hidden"
          />
        )}

        {(isSuspended || isLoadingMore) && (
          <QuerySuspense suspended={isSuspended || isLoadingMore}>
            <VStack
              position="relative"
              alignItems="flex-start"
              spacing={4}
              w="full"
            >
              {range(0, 3).map((x) => (
                <ChatBubble
                  key={x}
                  conversation={conversation}
                  message={{
                    id: x,
                    isOurs: x % 2 === 0,
                    sentDate: new Date().toISOString(),
                  }}
                />
              ))}
            </VStack>
          </QuerySuspense>
        )}

        {!isSuspended &&
          sortedMessages.map((message, messageIndex) => (
            <VStack key={message.id} w="full" alignItems="flex-start">
              <VStack w="full">
                {DateUtils.areSameDay(
                  message.sentDate,
                  messages[messageIndex - 1]?.sentDate
                ) ? (
                  DateUtils.areSameHour(
                    message.sentDate,
                    messages[messageIndex - 1]?.sentDate
                  ) ? null : (
                    <Text
                      mx="auto"
                      fontSize="xs"
                      fontWeight="bold"
                      color="gray.500"
                    >
                      {DateUtils.toSimple(message.sentDate)}
                    </Text>
                  )
                ) : (
                  <Text
                    mx="auto"
                    fontSize="xs"
                    fontWeight="bold"
                    color="gray.500"
                  >
                    {DateUtils.toShort(message.sentDate)}
                  </Text>
                )}

                <ChatBubble
                  conversation={conversation}
                  message={message}
                  showAvatar={
                    messages?.[messageIndex - 1]?.isOurs !== message.isOurs
                  }
                />

                {message.status === "failed" && (
                  <Box w="full" mt="0 !important" pl={8}>
                    <Text display="inline" color="gray.500" fontSize="sm">
                      Message could not be sent.
                    </Text>
                    &nbsp;
                    <Box display="inline-flex" color="gray.500" fontSize="sm">
                      <Button
                        variant="link"
                        color="blue.500"
                        fontSize="1em"
                        onClick={() => onResend(message)}
                      >
                        Try again
                      </Button>
                      {!message.attachments?.length && (
                        <>
                          ,&nbsp;
                          <Button
                            variant="link"
                            color="blue.500"
                            fontSize="1em"
                            onClick={() => {
                              setScheduledMessageModalState({
                                isOpen: true,
                                message,
                              });
                            }}
                          >
                            Schedule
                          </Button>
                        </>
                      )}
                      &nbsp;or&nbsp;
                      <Button
                        variant="link"
                        fontSize="1em"
                        onClick={() => onDiscard(message)}
                      >
                        Discard
                      </Button>
                      .
                    </Box>
                  </Box>
                )}
              </VStack>
            </VStack>
          ))}

        <Box ref={chatboxEndRef} w={0} h={0} opacity={0} visibility="hidden" />
      </VStack>
    );
  }
);

ConversationChatBox.displayName = "ConversationChatBox";
