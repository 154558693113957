import { ElementType } from "react";

import { MaybePromise } from "@/common/types";
import { createContext } from "@/ui/utils";

export interface EditableTagContextProps {
  label?: string;
  icon?: ElementType;
  isReadOnly: boolean;
  isEditing: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onEdit: () => MaybePromise<void>;
  onDone: () => MaybePromise<void>;
}

export const [EditableTagProvider, useEditableTagContext] =
  createContext<EditableTagContextProps>({
    name: "EditableTagContext",
  });
