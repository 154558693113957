import { useMemo } from "react";

import { pick } from "lodash";

export type ValuesOf<T extends any[]> = T[number];

export const usePickProps = <T extends object, K extends keyof T>(
  props: T,
  keys: K[],
  defaultValues: Partial<T> = {}
) => {
  return useMemo(
    () => ({ ...defaultValues, ...pick(props, keys) } as Pick<T, K>),
    [props, keys, defaultValues]
  );
};
