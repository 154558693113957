import {
  As,
  ChakraStyledOptions,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import {
  Transition,
  Variant,
  motion as fm,
  isValidMotionProp,
} from "framer-motion";

const motion = <T extends As>(component: T) => {
  const options: ChakraStyledOptions = {
    shouldForwardProp: (prop) =>
      isValidMotionProp(prop) || shouldForwardProp(prop),
  };

  return chakra<T>(component, options);
};

export const MotionBox = motion(fm.div);
export const MotionImg = motion(fm.img);
export const MotionHeader = motion(fm.header);

const spring: Transition = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};

const easeOut: Transition = {
  type: "tween",
  ease: "easeOut",
};

export const transitions = Object.freeze({
  spring,
  easeOut,
});

export type Animation<T extends string> = Record<T, Variant>;

const fadeIn: Animation<"initial" | "visible"> = {
  initial: { opacity: 0.5 },
  visible: { opacity: 1 },
};

const slideLeft: Animation<"initial" | "visible"> = {
  initial: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0 },
};

const wobble: Animation<"initial" | "visible"> = {
  initial: { rotate: 0 },
  visible: {
    rotate: [-2, 2, -2, 2],
    transition: { repeat: Infinity, repeatType: "mirror", duration: 0.375 },
  },
};

export const animations = Object.freeze({
  fadeIn,
  slideLeft,
  wobble,
});
