import { KeyboardEvent, useCallback, useState } from "react";

import { Box, BoxProps, HStack, useToken } from "@chakra-ui/react";
import { LayoutGroup } from "framer-motion";

import { KEY_CODE } from "@/dom";
import { MotionBox, transitions } from "@/styles/motion";

export interface ActionTabsProps extends BoxProps {}
export const ActionTabs = ({ children, ...props }: ActionTabsProps) => (
  <Box
    overflowX="auto"
    px={1}
    _after={{
      content: `""`,
      display: "block",

      width: "full",
      height: "1px",

      background: "gray.200",
      borderRadius: "xl",
    }}
    {...props}
  >
    <LayoutGroup>
      <HStack spacing={0} py={2}>
        {children}
      </HStack>
    </LayoutGroup>
  </Box>
);

export interface ActionTabItemProps {
  active?: boolean;
  tabKey: string;
  children: any;
  onSelect: (value: string) => void;
}

export const ActionTabItem = ({
  active = false,
  tabKey,
  children,
  onSelect,
  ...props
}: ActionTabItemProps) => {
  const parentPaddingY = useToken("sizes", 2);

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleClick = useCallback(() => {
    onSelect(tabKey);
  }, [tabKey, onSelect]);

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === KEY_CODE.ENTER) {
        onSelect(tabKey);
      }
    },
    [tabKey, onSelect]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      tabIndex={0}
      cursor="pointer"
      position="relative"
      px={4}
      outline="none"
      borderRadius="md"
      textTransform="capitalize"
      fontFamily="heading"
      fontWeight="semibold"
      fontSize="md"
      color={active ? "gray.900" : "gray.500"}
      transition="color var(--transition-fast) linear, background var(--transition-fast) linear"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      sx={{
        overflowWrap: "normal !important",
      }}
      _focusVisible={{
        boxShadow: "outline",
      }}
      _after={{
        content: `""`,
        display: active ? "block" : "none",
        position: "absolute",

        bottom: `calc(-1 * ${parentPaddingY} - 1px)`,
        left: 0,
        width: "full",
        height: "2px",
        background: "gray.900",
        borderRadius: "xl",
      }}
      {...props}
    >
      {isMouseOver && (
        <MotionBox
          layoutId="action-tabs__tab"
          initial={false}
          // @ts-ignore
          transition={transitions.spring}
          position="absolute"
          inset={0}
          background="gray.50"
          borderRadius="md"
          zIndex="-1"
        />
      )}

      {children}
    </Box>
  );
};
