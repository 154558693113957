import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { MdArrowDropDown, MdOutlineScheduleSend, MdSend } from "react-icons/md";

import { MaybePromise } from "@/common";
import { FilePreview, FilePreviewGrid, Text } from "@/components/display";
import {
  Button,
  EmojiPicker,
  FilePicker,
  IconButton,
  PickedFile,
  TextAreaResizable,
} from "@/components/form";
import { KEY_CODE } from "@/dom";
import { useBoolean } from "@/hooks";
import { ScheduleMessageModal } from "@/screens/conversations/conversation-schedule";
import { Conversation } from "@/services/types";
import { useMediaQuery } from "@/ui/hooks";
import { ComponentProp } from "@/ui/types";

export interface ConversationChatInputProps {
  conversation: Conversation;
  onSend: (message: {
    text: string;
    attachments: PickedFile[];
  }) => MaybePromise<void>;
  onSchedule: (message: { text: string; date: Date }) => MaybePromise<void>;
}

export const ConversationChatInput = ({
  conversation,
  onSend,
  onSchedule,
}: ConversationChatInputProps) => {
  const [isMdScreen] = useMediaQuery("md");

  const attachmentUrlCacheRef = useRef<Record<number, string>>({});
  const getAttachmentUrlFromCache = useCallback((id: number, data: File) => {
    if (!attachmentUrlCacheRef.current[id]) {
      attachmentUrlCacheRef.current[id] = URL.createObjectURL(data);
    }

    return attachmentUrlCacheRef.current[id];
  }, []);

  const [
    isScheduleMessageModalOpen,
    openScheduleMessageModal,
    closeScheduleMessageModal,
  ] = useBoolean(false);

  const [text, setText] = useState("");
  const [attachments, setAttachments] = useState<PickedFile[]>([]);

  const attachmentsPreviews = useMemo(
    () =>
      attachments.map((attachment) => ({
        ...attachment,
        url: getAttachmentUrlFromCache(attachment.id, attachment.data),
      })),
    [attachments, getAttachmentUrlFromCache]
  );

  const isSendButtonDisabled = useMemo(
    () => !text?.trim()?.length && !attachments?.length,
    [text, attachments]
  );

  const isScheduleButtonDisabled = useMemo(
    () => !text?.trim()?.length || !!attachments?.length,
    [attachments?.length, text]
  );

  const [isSendMenuOpen, openSendMenu, closeSendMenu] = useBoolean(false);

  const onSendHandler = useCallback(() => {
    if (!isSendButtonDisabled) {
      onSend({ text, attachments });
      setText("");
      setAttachments([]);
    }
  }, [text, attachments, isSendButtonDisabled, onSend]);

  const onScheduleHandler: ComponentProp<
    typeof ScheduleMessageModal,
    "onSchedule"
  > = useCallback(
    (date) => {
      if (!isScheduleButtonDisabled) {
        onSchedule({ text, date });
      }
    },
    [isScheduleButtonDisabled, text, onSchedule]
  );

  useLayoutEffect(() => {
    setText("");
    setAttachments([]);
  }, [conversation]);

  return (
    <Box w="full">
      <ScheduleMessageModal
        isOpen={isScheduleMessageModalOpen}
        onSchedule={onScheduleHandler}
        onClose={closeScheduleMessageModal}
      />

      {!!attachments?.length && (
        <FilePreviewGrid mb={2} px={2}>
          {attachmentsPreviews.map((file) => {
            return (
              <FilePreview
                key={file.id}
                name={file.name}
                type={file.type}
                size={file.size}
                url={file.url}
                onRemove={() => {
                  const nextAttachments = attachments.filter(
                    (x) => x.id !== file.id
                  );

                  setAttachments(nextAttachments);
                }}
              />
            );
          })}
        </FilePreviewGrid>
      )}

      <HStack spacing={{ base: 1, md: 2 }} w="full" p={1}>
        {conversation.isReadOnly ? (
          <Box flex={1} px={4} py={2} background="blue.600" borderRadius="md">
            <Text suspendable={false} color="white">
              You can't reply to this conversation.
            </Text>
          </Box>
        ) : (
          <>
            <Box flex={1} position="relative">
              <TextAreaResizable
                value={text}
                placeholder="Type a message"
                pl={4}
                pr={16}
                maxRows={10}
                onChange={(ev) => setText(ev.target.value)}
                onKeyDown={(ev) => {
                  if (ev.key === KEY_CODE.ENTER) {
                    const hasModifiers = ev.shiftKey || ev.ctrlKey;
                    if (hasModifiers) {
                      return;
                    }

                    ev.preventDefault();

                    onSendHandler();

                    return false;
                  }
                }}
              />

              <Flex
                gap={1}
                position="absolute"
                top="50%"
                right={2}
                transform="translateY(-50%)"
                zIndex="1"
              >
                <FilePicker
                  onSelect={(newAttachments) =>
                    setAttachments([...attachments, ...newAttachments])
                  }
                />

                <EmojiPicker
                  onSelect={(emoji) => setText((message) => message + emoji)}
                />
              </Flex>
            </Box>

            {isMdScreen ? (
              <Flex>
                <Button
                  rightIcon={<MdSend />}
                  isDisabled={isSendButtonDisabled}
                  borderRightRadius={0}
                  px={2}
                  onClick={onSendHandler}
                >
                  Send
                </Button>

                <Divider orientation="vertical" />

                <Menu
                  isOpen={isSendMenuOpen}
                  onOpen={openSendMenu}
                  onClose={closeSendMenu}
                >
                  <MenuButton
                    as={IconButton}
                    icon={<Icon as={MdArrowDropDown} />}
                    isDisabled={isSendButtonDisabled}
                    minW="unset"
                    borderLeftRadius={0}
                  />

                  <MenuList minW={32} py={1} fontSize="sm">
                    <MenuItem
                      icon={<Icon as={MdOutlineScheduleSend} />}
                      isDisabled={isScheduleButtonDisabled}
                      onClick={openScheduleMessageModal}
                    >
                      Schedule Message
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ) : (
              <Menu isOpen={isSendMenuOpen} onClose={closeSendMenu}>
                <MenuButton
                  as={IconButton}
                  size="md"
                  variant="subtle"
                  icon={<Icon as={MdSend} />}
                  isDisabled={isSendButtonDisabled}
                  aria-label="Send message"
                  onClick={onSendHandler}
                  onLongPress={openSendMenu}
                />

                <MenuList minW={32} py={1} fontSize="sm">
                  <MenuItem
                    icon={<Icon as={MdOutlineScheduleSend} />}
                    isDisabled={isScheduleButtonDisabled}
                    onClick={openScheduleMessageModal}
                  >
                    Schedule Message
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </>
        )}
      </HStack>
    </Box>
  );
};
