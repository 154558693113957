import { useCallback, useEffect, useMemo, useRef } from "react";

import { debounce, isFunction, isNil } from "lodash";

import { Nullish } from "@/common";
import {
  usePatchServerStorage,
  useServerStorage,
} from "@/services/hooks/common";

import { useLocalStorage } from "./use-local-storage";

export interface UseServerStateOptions<T> {
  parser?: Parser<T>;
}

export const useServerState = <T>(
  key: string,
  {
    parser = {
      parse: (x) => (isNil(x) ? x : JSON.parse(x)),
      serialize: (x) => JSON.stringify(x),
    },
  }: UseServerStateOptions<T> = {}
): [T, (setter: SetAction<T>) => Promise<void>] => {
  const [localServerStorage, setLocalServerStorage] = useLocalStorage<
    Record<string, string>
  >("server-storage", {});

  const {
    data: serverStorage = localServerStorage,
    isFetched: hasFetchedServerStorage,
    setInterimData: setInterimServerStorage,
  } = useServerStorage({
    initialData: localServerStorage,
  });

  const { mutateAsync: patchServerStorage } = usePatchServerStorage();

  const patchServerStorageDebounced = useRef(
    debounce(patchServerStorage, 500)
  ).current;

  useEffect(() => {
    if (hasFetchedServerStorage) {
      setLocalServerStorage(serverStorage);
    }
  }, [hasFetchedServerStorage, serverStorage, setLocalServerStorage]);

  const state = useMemo(() => {
    return parser?.parse(serverStorage[key]) ?? (serverStorage[key] as T);
  }, [key, parser, serverStorage]);

  const setState = useCallback(
    async (setter: SetAction<T>) => {
      const nextState = isFunction(setter) ? setter(state) : setter;
      const nextStateSerialized =
        parser?.serialize(nextState) ?? (nextState as string);

      const serverStorageRequestData = {
        [key]: nextStateSerialized,
      };

      setInterimServerStorage(
        (draft) => {
          if (!draft) return serverStorageRequestData;
          Object.assign(draft, serverStorageRequestData);
        },
        { shouldPause: true }
      );

      await patchServerStorageDebounced(serverStorageRequestData);
    },
    [key, parser, patchServerStorageDebounced, setInterimServerStorage, state]
  );

  return [state, setState];
};

type SetAction<T> = T | ((prevState: T) => T);

type Parser<T> = {
  parse: (value: Nullish<string>) => Nullish<T>;
  serialize: (value: Nullish<T>) => string;
};
