import { useCallback, useLayoutEffect, useState } from "react";

import { isString } from "lodash";

import { useEventListener } from "./use-event-listener";

export const useElementSize = (element: any) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(() => {
    const node = isString(element)
      ? document.querySelector(element)
      : element?.current || element;

    setSize({
      width: node?.offsetWidth || 0,
      height: node?.offsetHeight || 0,
    });
  }, [element]);

  useLayoutEffect(() => {
    updateSize();
  }, [element, updateSize]);

  useEventListener("resize", updateSize);

  return size;
};
