import { isNil } from "lodash";

import { ENV } from "./config";

const workerUrl = (
  ENV.IS_PRODUCTION ? `/worker.js` : "/dev-sw.js?dev-sw"
) satisfies string;

export const registerWorker = async () => {
  const registration = await navigator.serviceWorker.getRegistration(workerUrl);

  if (!isNil(registration)) {
    return registration;
  }

  return navigator.serviceWorker.register(workerUrl, {
    type: ENV.IS_PRODUCTION ? "classic" : "module",
  });
};

const handleWindowLoad = async () => {
  if (!("serviceWorker" in navigator)) {
    return;
  }

  try {
    const registration = await registerWorker();
    console.log("Service Worker registered with scope:", registration.scope);
  } catch (error) {
    console.log("Service Worker registration failed:", error);
  }
};

window.addEventListener("load", handleWindowLoad);
