import { useState } from "react";

import { Box } from "@chakra-ui/react";
import { isEmpty, isNil } from "lodash";
import { z } from "zod";

import {
  Editable,
  EditableField,
  EditablePreview,
  useIsDirty,
} from "@/components/form";
import { CustomAudiencePicker } from "@/partials/campaigns";
import { Campaign } from "@/services/types";
import { profileLinkSchema } from "@/ui/schemas";

import { EditableFieldError } from "./editable-field-error";
import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const CustomAudienceEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<Campaign["customAudienceList"]>) => {
  const [nextValue, setNextValue] = useState(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const isDirty = useIsDirty(
    {
      fileName: defaultValue?.fileName,
      profiles: defaultValue?.profiles.sort(),
    },
    {
      fileName: nextValue?.fileName,
      profiles: nextValue?.profiles.sort(),
    }
  );

  const hasValue = !isEmpty(defaultValue?.fileName);

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Audience</EditableFieldLabel>

      <Editable
        label="Audience"
        isReadOnly={isReadOnly}
        isDisabled={!isNil(error)}
        hasValue={hasValue}
        onSave={async () => {
          if (!isNil(error)) return;
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setError(null);
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>
          <CustomAudiencePicker
            isReadOnly
            variant="compact"
            defaultValue={nextValue}
          />
        </EditablePreview>

        <EditableField>
          <CustomAudiencePicker
            variant="compact"
            defaultValue={nextValue}
            onPick={(value) => {
              schema.safeParseAsync(value).then((result) => {
                if (result.success) {
                  setError(null);
                } else {
                  setError(result.error.issues[0].message);
                }
              });

              setNextValue(value);
              onChange?.(value);
            }}
            onPickError={(error) => {
              setError(error);
            }}
            onRemove={() => {
              setNextValue(null);
              onChange?.(null);
            }}
          />
        </EditableField>

        <EditableFieldError error={error} />
      </Editable>
    </Box>
  );
};

const schema = z
  .object({
    fileName: z.string(),
    profiles: z
      .array(profileLinkSchema)
      .nonempty("The uploaded file does not contain any profile URLs"),
  })
  .nullable();
