import { ReactElement, memo, useState } from "react";

import { Box, Fade, HStack, Icon, Tooltip, VStack } from "@chakra-ui/react";
import * as HoverCard from "@radix-ui/react-hover-card";
import { MdOutlinePriorityHigh } from "react-icons/md";

import { SkeletonText } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { FilePreview, FilePreviewGrid, Text } from "@/components/display";
import { Avatar } from "@/components/media";
import { useAuth } from "@/contexts";
import { Conversation, Message } from "@/services/types";
import { DateUtils } from "@/utils";

export interface ChatBubbleProps {
  conversation: Conversation;
  message: Message;
  showAvatar?: boolean;
  menu?: (isVisible: boolean) => ReactElement;
}

const ChatBubbleRaw = ({
  conversation,
  message,
  showAvatar = true,
  menu,
}: ChatBubbleProps) => {
  const { user } = useAuth<true>();

  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isHoverCardOpen, setIsHoverCardOpen] = useState(false);

  return (
    <HStack
      position="relative"
      w="full"
      onMouseEnter={() => setIsMouseEnter(true)}
      onMouseLeave={() => setIsMouseEnter(false)}
    >
      {showAvatar ? (
        message.isOurs ? (
          <Box position="relative">
            <HoverCard.Root
              open={isHoverCardOpen}
              onOpenChange={(x) => setIsHoverCardOpen(x)}
            >
              <HoverCard.Trigger>
                <Box onClick={() => setIsHoverCardOpen(true)}>
                  <Avatar
                    src={user.picture ?? undefined}
                    size="xs"
                    name={(user.name || user.email) ?? undefined}
                    background="primary.500"
                    color="primary.50"
                  />

                  <Avatar
                    position="absolute"
                    top={0}
                    right={0}
                    width={3}
                    height={3}
                    transform="translate(25%, -25%)"
                    showBorder
                    src={conversation.sender.image ?? undefined}
                    hideOnSuspense
                  />
                </Box>
              </HoverCard.Trigger>

              <HoverCard.Portal>
                <HoverCard.Content sideOffset={12}>
                  <Fade in={isHoverCardOpen}>
                    <Box
                      background="white"
                      borderRadius="lg"
                      border="1px solid"
                      borderColor="inherit"
                      boxShadow="sm"
                      p={2}
                    >
                      <HStack alignItems="center" fontSize="sm">
                        <Avatar
                          size="sm"
                          src={conversation.sender.image ?? undefined}
                        />

                        <VStack spacing={0} alignItems="flex-start">
                          <Text as="span" fontWeight="medium">
                            {conversation.sender.fullName}
                          </Text>

                          <Text
                            as="span"
                            color="gray.600"
                            textTransform="capitalize"
                          >
                            {conversation.sender.gender}
                          </Text>
                        </VStack>
                      </HStack>
                    </Box>
                  </Fade>
                </HoverCard.Content>
              </HoverCard.Portal>
            </HoverCard.Root>
          </Box>
        ) : (
          <Avatar
            size="xs"
            src={conversation.recipient.image ?? undefined}
            name={conversation.recipient.fullName}
          />
        )
      ) : (
        <Avatar
          size="xs"
          src={conversation.recipient.image ?? undefined}
          name={conversation.recipient.fullName}
          visibility="hidden"
        />
      )}

      <VStack alignItems="flex-start" spacing={1} w="fit-content">
        {!!message.text && (
          <Tooltip
            label={`${message.status === "sending" ? "Sending..." : ""}${
              message.status === "failed" ? "Sending failed" : ""
            }${
              !message.status || message.status === "sent"
                ? DateUtils.toShort(message.sentDate)
                : ""
            }`}
            placement="right"
            fontSize="sm"
          >
            <Box position="relative">
              <Box
                as={Text}
                maxW="75ch"
                p={2}
                bg={message.isOurs ? "blue.50" : "gray.100"}
                borderRadius="md"
                whiteSpace="pre-line"
                opacity={
                  message.status === "sending" || message.status === "failed"
                    ? 0.6
                    : 1
                }
              >
                {message.text}
              </Box>

              {message.status === "failed" && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  w={3}
                  h={3}
                  borderRadius="full"
                  background="white"
                  border="1px solid"
                  borderColor="red.300"
                  transform="translate(-25%, -25%)"
                >
                  <Icon
                    as={MdOutlinePriorityHigh}
                    w={2}
                    h={2}
                    color="red.300"
                  />
                </Box>
              )}
            </Box>
          </Tooltip>
        )}

        {!!message.attachments?.length && (
          <Box
            w="full"
            maxW="container.sm"
            opacity={
              message.status === "sending" || message.status === "failed"
                ? 0.6
                : 1
            }
          >
            <FilePreviewGrid>
              {message.attachments?.map((attachment) => (
                <FilePreview
                  key={attachment.id}
                  name={attachment.name}
                  type={attachment.type ?? undefined}
                  size={attachment.size ?? undefined}
                  url={attachment.url}
                />
              ))}
            </FilePreviewGrid>
          </Box>
        )}
      </VStack>

      {!!menu && menu(isMouseEnter)}
    </HStack>
  );
};

const ChatBubbleWithSkeleton = withSkeleton<ChatBubbleProps, "div">(
  ChatBubbleRaw,
  ({ pulse, message }) => (
    <HStack w="full">
      <Avatar size="xs" />

      <Box
        w={message.isOurs ? "40ch" : "20ch"}
        p={2}
        bg={message.isOurs ? "blue.50" : "gray.100"}
        borderRadius="md"
      >
        <SkeletonText pulse={pulse} noOfLines={3} />
      </Box>
    </HStack>
  )
);

export const ChatBubble = memo(ChatBubbleWithSkeleton);
