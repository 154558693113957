import { forwardRef, useState } from "react";

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";

import { ControlledFieldProps } from "@/components/form";
import { withController } from "@/components/form/hocs";

export interface InputProps
  extends Omit<ChakraInputProps, keyof ControlledFieldProps>,
    ControlledFieldProps<ChakraInputProps["value"], (value: string) => void> {
  changeOnBlur?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ value, changeOnBlur = false, onChange, ...props }: InputProps, ref) => {
    const [innerValue, setInnerValue] = useState(value);

    return (
      <ChakraInput
        ref={ref}
        value={changeOnBlur ? innerValue : value}
        borderRadius="md"
        onChange={(ev) => {
          if (changeOnBlur) {
            setInnerValue(ev.target.value);
          } else {
            onChange?.(ev.target.value);
          }
        }}
        onBlur={(ev) => {
          if (changeOnBlur) {
            onChange?.(ev.target.value);
          }
        }}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

export const InputFormField = withController(Input);
