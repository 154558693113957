import { useMemo, useState } from "react";

import { useConst } from "@chakra-ui/react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { AsyncReturnType } from "type-fest";

import { MaybePromise } from "@/common";
import { API_HUB, ENV } from "@/config";
import { useAsyncEffect, useCallbackRef } from "@/hooks";
import { AuthService, NotificationService } from "@/services";
import {
  UseInfiniteQuerySimpleOptions,
  useInfiniteQuery,
} from "@/services/hooks";
import { Notification } from "@/services/types";
import { gen, repeat } from "@/utils";

const PAGINATION_LIMIT = 25;

type QueryArgs = {
  state?: string;
  onReceiveNotification?: (notification: Notification) => MaybePromise<void>;
};

type QueryFnData = AsyncReturnType<typeof NotificationService.getAll>;

const queryKey = (args: Omit<QueryArgs, "onReceiveNotification"> = {}) => [
  "notifications",
  args,
];

export const useNotifications = (
  { state, onReceiveNotification }: QueryArgs = {},
  options?: UseInfiniteQuerySimpleOptions<QueryFnData>
) => {
  const onReceiveNotificationRef = useCallbackRef(onReceiveNotification);

  const queryFunction = ({ pageParam = 0 }) =>
    NotificationService.getAll({
      skip: pageParam,
      limit: PAGINATION_LIMIT,
    });

  const response = useInfiniteQuery<QueryFnData>(
    queryKey({ state }),
    queryFunction,
    {
      promptRetryOnError: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        let { skip, limit, total } = pagination;
        skip ??= 0;
        limit ??= 10;
        total ??= 0;

        const hasMorePages = skip + limit < total;

        return hasMorePages ? skip + PAGINATION_LIMIT : undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const { pagination } = firstPage;
        let { skip } = pagination;
        skip ??= 0;

        const hasPreviousPages = !!pagination.skip;

        return hasPreviousPages
          ? Math.max(skip - PAGINATION_LIMIT, 0)
          : undefined;
      },
      skeletonData: useConst({
        pageParams: [],
        pages: [
          {
            elements: repeat(gen.notification, 3),
            pagination: {
              skip: 0,
              limit: 10,
              total: 3,
            },
          },
        ],
      }),
      ...options,
    }
  );

  const { data, refetch } = response;

  const elements = useMemo(
    () => data?.pages.flatMap((x) => x.elements),
    [data]
  );

  const pagination = data?.pages?.at(-1)?.pagination;

  const [connection, setConnection] = useState<HubConnection | null>(null);

  useAsyncEffect(async () => {
    if (connection) return;

    const isAuthenticated = await AuthService.isAuthenticated();
    if (!isAuthenticated) return;

    const url = `${API_HUB.ENDPOINT}/notifications`;

    const newConnection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async () => {
          const accessToken = await AuthService.getAccessToken();
          return accessToken!;
        },
      })
      .withAutomaticReconnect()
      .configureLogging(
        ENV.IS_DEVELOPMENT ? LogLevel.Information : LogLevel.None
      )
      .build();

    setConnection(newConnection);
  }, []);

  useAsyncEffect(
    async (isMounted) => {
      if (!connection) return;

      if (
        [
          HubConnectionState.Connected,
          HubConnectionState.Connecting,
          HubConnectionState.Reconnecting,
        ].includes(connection.state)
      ) {
        return;
      }

      await connection.start();

      if (isMounted()) {
        connection.on("receiveNotification", (message) => {
          refetch();
          onReceiveNotificationRef?.(message);
        });
      }

      return () => {
        connection.stop();
      };
    },
    [connection]
  );

  return { ...response, data: { elements, pagination } };
};

useNotifications.queryKey = queryKey;
