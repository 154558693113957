import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { TypeaheadService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof TypeaheadService.getOccupations>[0];
type QueryFnData = AsyncReturnType<typeof TypeaheadService.getOccupations>;
type Error = unknown;

const queryKey = (args: QueryArgs = {}) => ["typeahead", "occupations", args];

export const useOccupations = <TData = QueryFnData>(
  args: QueryArgs = {},
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => TypeaheadService.getOccupations(args),
    {
      skeletonData: useConst({
        elements: repeat(gen.typeahead),
        pagination: {
          skip: 0,
          limit: 10,
          total: 2,
        },
      }),
      ...options,
    }
  );

  return result;
};

useOccupations.queryKey = queryKey;
