import { useMemo } from "react";

import qs from "qs";
import { useLocation } from "react-router-dom";

export const useQueryString = () => {
  const location = useLocation();

  const queryString = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location.search]
  );

  return queryString;
};
