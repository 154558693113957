import axios, { AxiosError } from "axios";

import { API_ERROR_CODES, HTTP_STATUS_CODES } from "./constants";

export const getApiErrorMessage = (code: keyof typeof API_ERROR_CODES) =>
  API_ERROR_CODES[code] ?? null;

export const isAxiosError = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error);

export const isNetworkError = (error: unknown) => {
  if (isAxiosError(error)) {
    return error.code === "ERR_NETWORK";
  }

  return false;
};

export const isInternalServerError = (error: unknown) => {
  if (isAxiosError(error) && error.response) {
    return HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR <= error.response.status;
  }

  return false;
};
