import { createContext, useContext } from "react";

import { FullPageSpinner } from "@/components/feedback";
import { useOnboarding } from "@/services/hooks/account";
import { OnboardingResult } from "@/services/openapi";
import { WithChildren } from "@/ui/types";

type OnboardingContextProps = OnboardingResult;

const OnboardingContext = createContext<OnboardingContextProps | null>(null);

export interface OnboardingProviderProps extends WithChildren {}

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const { data = null, isLoading } = useOnboarding();

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <OnboardingContext.Provider value={data}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useTour = (name: keyof OnboardingContextProps) => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw Error(`"useTour" must used within "${OnboardingProvider.name}".`);
  }

  return context?.[name];
};
