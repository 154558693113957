import {
  Flex,
  FlexProps,
  UseCheckboxGroupProps,
  useCheckboxGroup,
} from "@chakra-ui/react";

import { withController } from "@/components/form/hocs";

import { CheckboxGroupProvider } from "./checkbox-group-context";

export interface CheckboxGroupProps
  extends Omit<FlexProps, keyof UseCheckboxGroupProps>,
    UseCheckboxGroupProps {}

export const CheckboxGroup = ({ children, ...props }: CheckboxGroupProps) => {
  const { getCheckboxProps } = useCheckboxGroup(props);

  return (
    <CheckboxGroupProvider value={{ getCheckboxProps }}>
      <Flex wrap="wrap" gap={1}>
        {children}
      </Flex>
    </CheckboxGroupProvider>
  );
};

export const CheckboxGroupFormField = withController(CheckboxGroup);
