import { useCallback, useMemo } from "react";

import { isFunction } from "lodash";
import { useLocation } from "react-router-dom";

import { useNavigate } from "@/router";

type SetAction<T> = T | ((prevState: T) => T);

export const useHashState = <T extends string = string>(
  defaultValue?: T,
  parser: (value: string | string[] | undefined, defaultValue: T) => T = (
    value
  ) => value as T
): [T, (setter: SetAction<T>, options?: { replace?: boolean }) => void] => {
  const location = useLocation();
  const navigate = useNavigate();

  const rawState = location.hash.substring(1);
  const parsedState = useMemo(
    () => parser(rawState, defaultValue as T) ?? (defaultValue as T),
    [rawState, defaultValue, parser]
  );

  const setState = useCallback(
    (setter: SetAction<T>, { replace = false } = {}) => {
      const value = isFunction(setter) ? setter(parsedState) : setter;
      navigate({ hash: value, search: location.search }, { replace });
    },
    [parsedState, location, navigate]
  );

  return [parsedState, setState];
};
