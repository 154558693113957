import { useState } from "react";

import { Tab as ChakraTab, TabProps as ChakraTabProps } from "@chakra-ui/react";

import { MotionBox, transitions } from "@/styles/motion";

export const Tab = ({ children, ...props }: ChakraTabProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <ChakraTab
      position="relative"
      whiteSpace="nowrap"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      {...props}
    >
      {isMouseOver && (
        <MotionBox
          layoutId="tabs__tab"
          initial={false}
          // @ts-ignore
          transition={transitions.spring}
          position="absolute"
          inset={0}
          background="gray.50"
          borderRadius="md"
          zIndex="-1"
        />
      )}

      {children}
    </ChakraTab>
  );
};
