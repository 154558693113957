import { keyframes } from "@chakra-ui/react";

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.4);
  }
`;
