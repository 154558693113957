import { useMemo } from "react";

import { Box, BoxProps, UseRadioProps, useRadio } from "@chakra-ui/react";
import { isNil } from "lodash";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { ControlledFieldProps } from "@/components/form";
import { withController } from "@/components/form/hocs";
import { useStyleProps } from "@/ui/hooks";

import { useRadioGroupContext } from "./radio-group-context";

export interface RadioCardProps
  extends Omit<BoxProps, keyof UseRadioProps | keyof ControlledFieldProps>,
    Omit<
      UseRadioProps,
      keyof ControlledFieldProps | "checked" | "isChecked" | "defaultChecked"
    >,
    ControlledFieldProps<boolean, (checked: boolean, value: string) => void> {
  variant?: "outline" | "filled";
  htmlValue?: string;
}

const RadioCardRaw = ({
  variant = "filled",
  value,
  defaultValue,
  htmlValue,
  children,
  onChange,
  ...props
}: RadioCardProps) => {
  const { getRadioProps: getOuterRadioProps } = useRadioGroupContext();

  const { getRadioProps, getInputProps, getLabelProps, htmlProps } = useRadio(
    isNil(getOuterRadioProps)
      ? {
          value: htmlValue,
          isChecked: value,
          defaultChecked: defaultValue,
          onChange: (ev) => onChange?.(ev.target.checked, ev.target.value),
          ...props,
        }
      : getOuterRadioProps({ value: htmlValue })
  );

  const variantStyles = useMemo(() => variants[variant], [variant]);

  return (
    <Box
      as="label"
      p={4}
      border="2px solid"
      borderRadius="md"
      cursor="pointer"
      tabIndex={1}
      transitionProperty="common"
      transitionDuration="normal"
      _focusVisible={{
        shadow: "outline",
        outline: "none",
      }}
      {...variantStyles}
      _disabled={{
        opacity: 0.4,
        cursor: "not-allowed",
        ...variantStyles._disabled,
      }}
      {...htmlProps}
      {...getLabelProps()}
      {...getRadioProps()}
    >
      <input {...getInputProps()} hidden />

      {children}
    </Box>
  );
};

export const RadioCard = withSkeleton<RadioCardProps, "div">(
  RadioCardRaw,
  ({ pulse, children, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return (
      <Skeleton
        display="inline-flex"
        pulse={pulse}
        borderRadius="md"
        {...styleProps}
      >
        <RadioCardRaw {...restProps}>{children}</RadioCardRaw>
      </Skeleton>
    );
  }
);

export const RadioCardFormField = withController(RadioCard);

const variants: Record<
  NonNullable<RadioCardProps["variant"]>,
  Partial<BoxProps>
> = {
  outline: {
    borderColor: "gray.200",
    _hover: {
      borderColor: "gray.300",
    },
    _checked: {
      borderColor: "blue.500",
      color: "blue.600",
    },
    _disabled: {
      borderColor: "gray.200",
    },
  },
  filled: {
    borderColor: "transparent",
    background: "gray.100",
    _hover: {
      background: "gray.200",
    },
    _checked: {
      background: "blue.500",
      color: "white",
    },
    _disabled: {
      background: "gray.100",
    },
  },
};
