import { useRef, useState } from "react";

import { Box, BoxProps, useOutsideClick } from "@chakra-ui/react";
import { noop } from "lodash";

import { MaybePromise } from "@/common";

import { EditableProvider } from "./editable-context";
import { EditableControls } from "./editable-controls";

export interface EditableProps extends BoxProps {
  label?: string | null;
  variant?: "default" | "subtle";
  startWithEditView?: boolean;
  closeOnOutsideClick?: boolean;
  showControls?: boolean;
  hasValue?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  onEdit?: () => MaybePromise<void>;
  onSave?: () => MaybePromise<void>;
  onCancel?: () => MaybePromise<void>;
}

export const Editable = ({
  label = null,
  variant = "default",
  startWithEditView = false,
  closeOnOutsideClick = false,
  showControls = true,
  hasValue = false,
  isReadOnly = false,
  isDisabled = false,
  children,
  onEdit: onEditExternal,
  onSave: onSaveExternal,
  onCancel: onCancelExternal,
  ...props
}: EditableProps) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(startWithEditView);
  const [isSaving, setIsSaving] = useState(false);

  const onEdit = () => {
    if (!isEditing && !isReadOnly) {
      onEditExternal?.();
      setIsEditing(true);
    }
  };

  const onSave = async () => {
    try {
      setIsSaving(true);
      await onSaveExternal?.();
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const onCancel = () => {
    if (isEditing) {
      onCancelExternal?.();
      setIsEditing(false);
    }
  };

  useOutsideClick({
    ref: rootRef,
    handler: onCancel,
    enabled: closeOnOutsideClick,
  });

  return (
    <EditableProvider
      value={{
        label,
        variant,
        isReadOnly,
        hasValue,
        isEditing,
        isSaving,
        isDisabled,
        onEdit,
        onSave,
        onCancel,
      }}
    >
      <Box
        ref={rootRef}
        cursor={variant === "subtle" && !isReadOnly ? "pointer" : "unset"}
        {...props}
        onClick={variant === "subtle" ? onEdit : noop}
      >
        {children}

        {showControls && <EditableControls />}
      </Box>
    </EditableProvider>
  );
};
