import { Box, BoxProps } from "@chakra-ui/react";

export interface PageOverlayProps extends BoxProps {}

export const PageOverlay = (props: PageOverlayProps) => (
  <Box
    position="fixed"
    display="flex"
    alignItems="center"
    justifyContent="center"
    zIndex="overlay"
    top={0}
    left={0}
    w="full"
    h="100vh"
    my={0}
    mx="auto"
    {...props}
  />
);
