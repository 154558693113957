import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { useStyleProps } from "@/ui/hooks";

export const Avatar = withSkeleton<ChakraAvatarProps, "span">(
  ChakraAvatar,
  ({ pulse, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return (
      <Skeleton
        pulse={pulse}
        w="fit-content"
        h="fit-content"
        borderRadius="50%"
        {...styleProps}
      >
        <ChakraAvatar {...restProps} src={undefined} />
      </Skeleton>
    );
  }
);
