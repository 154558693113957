import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services/campaign-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";

type QueryArgs = Parameters<typeof CampaignService.getPotentialReach>[0] & {
  key: string | number;
};
type QueryFnData = AsyncReturnType<typeof CampaignService.getPotentialReach>;
type Error = unknown;

const queryKey = (args?: QueryArgs) => ["campaigns", "potential-reach", args];

export const usePotentialReach = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CampaignService.getPotentialReach(args),
    {
      retry: false,
      promptRetryOnError: false,
      ...options,
    }
  );

  return response;
};

usePotentialReach.queryKey = queryKey;
