import { MaybePromise } from "@/common/types";
import { createContext } from "@/ui/utils";

export interface EditableContextProps {
  label?: string | null;
  variant: "default" | "subtle";
  isReadOnly: boolean;
  hasValue: boolean;
  isEditing: boolean;
  isSaving: boolean;
  isDisabled: boolean;
  onEdit: () => MaybePromise<void>;
  onSave: () => MaybePromise<void>;
  onCancel: () => MaybePromise<void>;
}

export const [EditableProvider, useEditableContext] =
  createContext<EditableContextProps>({
    name: "EditableContext",
  });
