import { useCallback, useMemo, useState } from "react";

import {
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  VStack,
  useConst,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";

import { MaybePromise } from "@/common";
import { Modal, ModalProps } from "@/components/disclosure";
import { Text } from "@/components/display";
import { ToasterManager } from "@/components/feedback";
import { Button } from "@/components/form";
import { withReset } from "@/hocs";
import { useAsyncEffect } from "@/hooks";
import { ContactResult } from "@/services/openapi";

export interface ContactExportModalProps extends Omit<ModalProps, "children"> {
  onPrepare: () => MaybePromise<ContactResult[]>;
}

const ContactExportModalRaw = ({
  isOpen,
  onPrepare,
  onClose,
}: ContactExportModalProps) => {
  const availableHeaders = useConst([
    {
      key: "id",
      label: "Id",
    },
    {
      key: "firstName",
      label: "First Name",
    },
    {
      key: "lastName",
      label: "Last Name",
    },
    {
      key: "fullName",
      label: "Full Name",
    },
    {
      key: "headline",
      label: "Headline",
    },
    {
      key: "location",
      label: "Location",
    },
    {
      key: "industry",
      label: "Industry",
    },
    {
      key: "summary",
      label: "Summary",
    },
    {
      key: "phoneNumber",
      label: "Phone Number",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "note",
      label: "Note",
    },
    {
      key: "linkedInProfile.flagshipProfileUrl",
      label: "LinkedIn",
    },
  ]);

  const [isPreparing, setIsPreparing] = useState(false);
  const [hasPrepared, setHasPrepared] = useState(false);

  const [exportOption, setExportOption] = useState("all");

  const [selectedHeaders, setSelectedHeaders] = useState(availableHeaders);
  const selectedHeadersKeys = useMemo(
    () => selectedHeaders.map((x) => x.key),
    [selectedHeaders]
  );

  const [data, setData] = useState<ContactResult[]>([]);

  useAsyncEffect(async () => {
    if (!isOpen || hasPrepared) {
      return;
    }

    try {
      setIsPreparing(true);

      const nextData = await onPrepare();
      setData(nextData);

      setHasPrepared(true);
    } finally {
      setIsPreparing(false);
    }
  }, [isOpen, data, onPrepare]);

  const handleExportOptionChange = useCallback(
    (option: string) => {
      if (option === "all") {
        setSelectedHeaders([...availableHeaders]);
      }

      setExportOption(option);
    },
    [availableHeaders]
  );

  const handleHeadersChange = useCallback(
    (value: string[]) => {
      setSelectedHeaders(() =>
        availableHeaders.filter((h) => value.includes(h.key))
      );
    },
    [availableHeaders]
  );

  const handleExportClick = useCallback(() => {
    ToasterManager.success({
      title: "Your contacts have been exported",
    });

    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Export contacts</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mb={2} fontFamily="heading" fontWeight="semibold">
            Select the columns you'd like to export:
          </Text>

          <RadioGroup value={exportOption} onChange={handleExportOptionChange}>
            <VStack spacing={2} alignItems="flex-start">
              <Radio value="all">Export all columns</Radio>

              <Radio value="some">Export only selected columns</Radio>
            </VStack>
          </RadioGroup>

          {exportOption === "some" && (
            <CheckboxGroup
              value={selectedHeadersKeys}
              onChange={handleHeadersChange}
            >
              <VStack spacing={2} alignItems="flex-start" mt={2} pl={6}>
                {availableHeaders.map((header) => (
                  <Checkbox key={header.key} value={header.key}>
                    {header.label}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              as={CSVLink}
              variant="solid"
              colorScheme="primary"
              filename="cosiall-contacts.csv"
              headers={selectedHeaders}
              data={data}
              isLoading={isPreparing}
              isDisabled={
                !hasPrepared || !selectedHeaders.length || !data?.length
              }
              onClick={handleExportClick}
            >
              Export to CSV
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const ContactExportModal = withReset(ContactExportModalRaw, "isOpen");
