import { Ref, useRef, useState } from "react";

import { Box, Flex, FlexProps, useControllableState } from "@chakra-ui/react";
import { isNil, toNumber } from "lodash";

import { Text } from "@/components/display";
import { Input } from "@/components/form/input";
import { MathUtils } from "@/utils";

export type TimePickerValue = {
  hours: number;
  minutes: number;
  seconds?: number;
};

export interface TimePickerProps
  extends Omit<FlexProps, "defaultValue" | "onChange"> {
  variant?: "outline" | "filled" | "unstyled";
  format?: "hh:mm:ss" | "hh:mm";
  value?: TimePickerValue;
  defaultValue?: TimePickerValue;
  onChange: (value: TimePickerValue) => void;
}

export const TimePicker = ({
  variant = "filled",
  format = "hh:mm:ss",
  value,
  defaultValue,
  onChange,
  ...props
}: TimePickerProps) => {
  const variantStyles = variants[variant];

  const hourPickerUnitRef = useRef<HTMLInputElement>(null);
  const minutePickerUnitRef = useRef<HTMLInputElement>(null);
  const secondPickerUnitRef = useRef<HTMLInputElement>(null);

  const [innerValue, setInnerValue] = useControllableState({
    value,
    defaultValue,
    onChange,
  });

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      gap={1}
      w="fit-content"
      h={8}
      px={3}
      border="1px solid"
      borderRadius="md"
      fontWeight="semibold"
      fontSize="sm"
      userSelect="none"
      {...variantStyles}
      {...props}
    >
      <TimePickerUnit
        inputRef={hourPickerUnitRef}
        type="hour"
        value={innerValue?.hours}
        onChange={(hours) => {
          setInnerValue({ ...(innerValue || {}), hours });
        }}
        onFill={() => {
          minutePickerUnitRef?.current?.focus();
        }}
      />

      <Text
        as="span"
        suspendable={false}
        display="inline-flex"
        alignItems="center"
      >
        :
      </Text>

      <TimePickerUnit
        inputRef={minutePickerUnitRef}
        type="minute"
        value={innerValue?.minutes}
        onChange={(minutes) => {
          setInnerValue({ ...(innerValue || {}), minutes });
        }}
        onFill={() => {
          secondPickerUnitRef?.current?.focus();
        }}
      />

      {format === "hh:mm:ss" && (
        <>
          <Text
            as="span"
            suspendable={false}
            display="inline-flex"
            alignItems="center"
          >
            :
          </Text>

          <TimePickerUnit
            inputRef={secondPickerUnitRef}
            type="second"
            value={innerValue?.seconds}
            onChange={(seconds) => {
              setInnerValue({ ...(innerValue || {}), seconds });
            }}
            onFill={() => {
              hourPickerUnitRef?.current?.focus();
            }}
          />
        </>
      )}
    </Flex>
  );
};

const variants: Record<
  NonNullable<TimePickerProps["variant"]>,
  Partial<FlexProps>
> = {
  outline: {
    borderColor: "inherit",
  },
  filled: {
    borderColor: "transparent",
    background: "gray.100",
  },
  unstyled: {},
};

interface TimePickerUnitProps {
  inputRef?: Ref<HTMLInputElement>;
  value?: number;
  type: "hour" | "minute" | "second";
  onChange: (value: number) => void;
  onFill: (value: number) => void;
}

const timePickerUnitRange: Record<
  TimePickerUnitProps["type"],
  { min: number; max: number }
> = {
  hour: {
    min: 0,
    max: 23,
  },
  minute: {
    min: 0,
    max: 59,
  },
  second: {
    min: 0,
    max: 59,
  },
};

const TimePickerUnit = ({
  inputRef,
  value,
  type,
  onChange,
  onFill,
}: TimePickerUnitProps) => {
  const { min, max } = timePickerUnitRange[type];

  const [innerValue, setInnerValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const displayValue = innerValue || value;

  return (
    <Box as="label" position="relative">
      <Input
        ref={inputRef}
        type="number"
        value={innerValue}
        position="absolute"
        w={0}
        h={0}
        opacity={0}
        inset={0}
        onChange={(value) => {
          const safeValue = MathUtils.minMax(
            toNumber(value),
            min,
            max
          ).toString();

          setInnerValue(safeValue);
          onChange(toNumber(safeValue));

          if (safeValue.length == 2) {
            onFill(toNumber(safeValue));
          }
        }}
        onFocus={() => {
          setInnerValue("");
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setInnerValue("");
        }}
      />

      <Box
        minW={4}
        borderRadius="sm"
        boxShadow={isFocused ? "outline" : "none"}
        textAlign="center"
      >
        {isNil(displayValue) ? (
          <>--</>
        ) : (
          <>{displayValue.toString().padStart(2, "0")}</>
        )}
      </Box>
    </Box>
  );
};
