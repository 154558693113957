import { Box, ButtonGroup, CloseButton, Flex, Icon } from "@chakra-ui/react";
import { MdArrowForwardIos, MdDone } from "react-icons/md";
import { TooltipRenderProps } from "react-joyride";

import { Heading, Text } from "@/components/display";
import { Button } from "@/components/form";

export interface TooltipProps extends TooltipRenderProps {}

export const Tooltip = ({
  continuous,
  index,
  size,
  step,
  tooltipProps,
  primaryProps,
  backProps,
  closeProps,
  skipProps,
  isLastStep,
}: TooltipProps) => (
  <Box
    position="relative"
    p={4}
    w="full"
    maxW="sm"
    background="white"
    borderRadius="md"
    {...tooltipProps}
  >
    <Heading as="span" size="sm" display="block" pr={5} mb={4}>
      {step.title}
    </Heading>

    {!step.hideCloseButton && (
      <CloseButton
        size="sm"
        position="absolute"
        top={3}
        right={3}
        {...closeProps}
      />
    )}

    <Box>{step.content}</Box>

    {!step.hideFooter && (
      <>
        <Box as="footer" position="relative" mt={4}>
          <Flex
            alignItems="center"
            justifyContent={step.showSkipButton ? "space-between" : "flex-end"}
            gap={4}
          >
            {step.showSkipButton && (
              <Button
                variant="link"
                colorScheme="blue"
                size="sm"
                {...skipProps}
              >
                Skip
              </Button>
            )}

            <ButtonGroup spacing={4}>
              {index > 0 && !step.hideBackButton && (
                <Button variant="link" size="sm" {...backProps}>
                  Go back
                </Button>
              )}

              {continuous && (
                <Button
                  size="sm"
                  colorScheme="primary"
                  rightIcon={
                    <Icon
                      as={isLastStep ? MdDone : MdArrowForwardIos}
                      w={3}
                      h={3}
                    />
                  }
                  {...primaryProps}
                >
                  {isLastStep ? "Done" : "Next"}
                </Button>
              )}
            </ButtonGroup>
          </Flex>
        </Box>

        {step.showProgress && (
          <Box
            position="absolute"
            bottom={4}
            left="50%"
            h={8}
            p={1}
            background="white"
            color="gray.400"
            borderRadius="md"
            transform="translate(-50%, 0)"
          >
            <Text as="span" fontSize="sm">
              {`${index + 1} of ${size}`}
            </Text>
          </Box>
        )}
      </>
    )}
  </Box>
);
