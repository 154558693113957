import { KeyboardEvent, useCallback } from "react";

import {
  Avatar,
  Box,
  ComponentWithAs,
  Divider,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { MdMenu } from "react-icons/md";

import { Button, ButtonProps } from "@/components/form";
import { Link } from "@/components/navigation";
import { useAuth } from "@/contexts";
import { KEY_CODE } from "@/dom";
import { AuthService } from "@/services";
import { useBillingPortal } from "@/services/hooks/billing";

export const HeaderMenu = () => {
  const { user } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: billingPortalUrl } = useBillingPortal();

  const handleLogoutBtnClick = useCallback(async () => {
    await AuthService.logout();
  }, []);

  const handleLogoutBtnKeyDown = useCallback(async (ev: KeyboardEvent) => {
    if (ev.key === KEY_CODE.ENTER) {
      await AuthService.logout();
    }
  }, []);

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          display="flex"
          h={8}
          pl={4}
          pr={1}
          borderRadius="full"
          w="fit-content"
          suspendable={false}
        >
          <Icon as={MdMenu} mr={1} />

          {!isNil(user) && (
            <Avatar
              src={user.picture ?? undefined}
              name={(user.name || user.email) ?? undefined}
              width={7}
              height={7}
              ml={2}
              showBorder
              background="primary.500"
              color="primary.50"
              overflow="hidden"
            />
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent w="25ch" overflow="hidden">
        <Box py={2}>
          <MenuButton
            as={Link}
            to="/account"
            fontWeight="semibold"
            onClick={onClose}
          >
            Account
          </MenuButton>

          <MenuButton
            as="a"
            href={billingPortalUrl}
            fontWeight="semibold"
            onClick={onClose}
          >
            Billing
          </MenuButton>

          <MenuButton
            as={Link}
            to="/account/integrations"
            fontWeight="semibold"
            onClick={onClose}
          >
            Integrations
          </MenuButton>

          <Divider my={2} />

          <MenuButton
            as="a"
            href="https://cosiall.canny.io/feature-requests"
            target="_blank"
            onClick={onClose}
          >
            Request a feature
          </MenuButton>

          <MenuButton
            as="a"
            href="mailto:support@cosiall.com"
            onClick={onClose}
          >
            Help
          </MenuButton>

          <MenuButton
            onClick={handleLogoutBtnClick}
            onKeyDown={handleLogoutBtnKeyDown}
          >
            Log out
          </MenuButton>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export const MenuButton: ComponentWithAs<"button", ButtonProps> = (props) => {
  return (
    <Button
      size="md"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      w="full"
      background="transparent"
      borderRadius="none"
      textAlign="left"
      color="inherit"
      _hover={{
        background: "gray.50",
        textDecoration: "none",
      }}
      _focusVisible={{
        background: "gray.50",
        boxShadow: "outline",
      }}
      {...props}
    />
  );
};
