import { FC, memo, useCallback, useRef, useState } from "react";

import { Box, Center, Link, Spinner } from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { Paginator } from "@/components/disclosure";
import { Text } from "@/components/display";
import { ASSET } from "@/config";

pdfjs.GlobalWorkerOptions.workerSrc = ASSET.PDF_WORKER;

interface PdfDocument {
  numPages: number;
}

export interface PdfPreviewProps {
  src: string;
  name: string;
  width: number;
  showPaginator?: boolean;
  showScroll?: boolean;
}

const PdfPreviewRaw: FC<PdfPreviewProps> = ({
  src,
  name,
  width,
  showPaginator = false,
  showScroll = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleDocumentLoadProgress = useCallback(() => {
    setIsError(false);
    setIsLoading(true);
  }, []);

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages: totalPages }: PdfDocument = { numPages: 0 }) => {
      setTotalPages(totalPages);
      setIsError(false);
      setIsLoading(false);
    },
    []
  );

  const handleDocumentLoadError = useCallback(() => {
    setIsError(true);
    setIsLoading(false);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    containerRef.current?.scrollTo(0, 0);
  }, []);

  return (
    <Box
      ref={containerRef}
      display="block !important"
      position="relative"
      w="ful"
      sx={{
        overflowY: showScroll ? "auto !important" : undefined,
      }}
    >
      {isLoading && (
        <Center w="full" h="full" margin="auto" p={2}>
          <Spinner size="sm" />
        </Center>
      )}

      {isError && (
        <Center w="full" h="full" margin="auto" p={2}>
          <Text
            noOfLines={1}
            display="inline-block"
            w="full"
            lineHeight={1}
            fontSize="xs"
            textAlign="center"
          >
            Cannot preview this file.
            <Link
              isExternal
              download={name}
              href={src}
              display="inline"
              lineHeight="inherit"
              ml=".5ch"
            >
              Download instead?
            </Link>
          </Text>
        </Center>
      )}

      <Box display={isLoading || isError ? "none" : "block"}>
        <Document
          file={src}
          loading={<></>}
          onLoadProgress={handleDocumentLoadProgress}
          onLoadSuccess={handleDocumentLoadSuccess}
          onLoadError={handleDocumentLoadError}
        >
          <Page
            pageNumber={currentPage}
            width={width}
            renderTextLayer={false}
          />
        </Document>

        {showPaginator && (
          <Box position="sticky" bottom={0} w="fit-content" margin="auto">
            <Box
              position="absolute"
              w="full"
              h="full"
              background="whiteAlpha.800"
              borderRadius="md"
              backdropFilter="blur(4px)"
            />

            <Paginator
              variant="unstyled"
              position="relative"
              padding={1}
              pageSize={1}
              totalCount={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const PdfPreview = memo(PdfPreviewRaw);
PdfPreview.displayName = "PdfPreview";
