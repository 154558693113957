import { ElementType } from "react";

import { Box, Icon, TagLeftIcon } from "@chakra-ui/react";

import { MaybePromise } from "@/common";
import { Tag } from "@/components/display/tag";
import { Button } from "@/components/form/button";
import { WithChildren } from "@/ui/types";

import { EditableTagProvider } from "./tag-editable-context";

export interface TagEditableProps extends WithChildren {
  label: string;
  icon?: ElementType;
  isReadOnly?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onEdit: () => MaybePromise<void>;
  onDone: () => MaybePromise<void>;
}

export const TagEditable = ({
  label,
  icon,
  isReadOnly = false,
  isEditing = false,
  isLoading = false,
  isDisabled = false,
  onEdit,
  onDone,
  children,
}: TagEditableProps) => {
  return (
    <EditableTagProvider
      value={{
        label,
        icon,
        isReadOnly,
        isEditing,
        isLoading,
        isDisabled,
        onEdit,
        onDone,
      }}
    >
      <Box w="fit-content">
        {isEditing ? (
          <Tag size="md">
            {icon && <TagLeftIcon as={icon} />}
            {children}
          </Tag>
        ) : (
          <Button
            size="xs"
            fontSize="sm"
            variant="solid"
            leftIcon={<Icon as={icon} />}
            isLoading={isLoading}
            loadingText={label}
            onClick={() => {
              onEdit();
            }}
          >
            {label}
          </Button>
        )}
      </Box>
    </EditableTagProvider>
  );
};
