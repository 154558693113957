import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof CrmService.getLeads>[0];
type QueryFnData = AsyncReturnType<typeof CrmService.getLeads>;

const queryKey = (args?: QueryArgs) => ["crm", "leads", args];

export const useLeads = <TData = QueryFnData>(
  args?: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CrmService.getLeads(args),
    {
      skeletonData: useConst({
        elements: repeat(gen.lead, 3),
        pagination: {
          skip: 0,
          limit: 10,
        },
      }),
      ...options,
    }
  );

  return response;
};

useLeads.queryKey = queryKey;
