import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof ConversationService.getScheduledMessages>[0];
type QueryFnData = AsyncReturnType<
  typeof ConversationService.getScheduledMessages
>;
type Error = unknown;

const queryKey = ({ id, ...restArgs }: QueryArgs) => [
  "conversations",
  id,
  "messages",
  restArgs,
];

export const useScheduledMessages = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConversationService.getScheduledMessages(args),
    {
      skeletonData: useConst({
        elements: repeat(gen.scheduledMessage, 3),
        pagination: {
          skip: 0,
          limit: 10,
          total: 3,
        },
      }),
      ...options,
    }
  );

  return response;
};

useScheduledMessages.queryKey = queryKey;
