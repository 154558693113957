import { ComponentProps } from "react";

import { Tabs, TabsProps, useBreakpointValue } from "@chakra-ui/react";

import { useFilterContext } from "./filter-context";

export interface FilterTabsProps extends TabsProps {}

export const FilterTabs = (props: FilterTabsProps) => {
  const { index } = useFilterContext();

  const orientation = useBreakpointValue<
    ComponentProps<typeof Tabs>["orientation"]
  >({
    base: "horizontal",
    md: "vertical",
  });

  return (
    <Tabs
      index={index}
      variant="unstyled"
      orientation={orientation}
      p={0}
      {...props}
    />
  );
};
