const formatCurrency = (
  amount: number | bigint,
  {
    currency = "usd",
    minFractionDigits = 2,
    maxFractionDigits = 2,
  }: {
    currency?: string;
    minFractionDigits?: number;
    maxFractionDigits?: number;
  } = {}
) => {
  return Intl.NumberFormat("en-us", {
    style: "currency",
    currency,
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(amount);
};

export const CurrencyUtils = Object.freeze({
  formatCurrency,
});
