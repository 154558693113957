import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { BillingService } from "@/services/billing-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen } from "@/utils";

type QueryArgs = Parameters<typeof BillingService.createInvoicePreview>[0];
type QueryFnData = AsyncReturnType<typeof BillingService.createInvoicePreview>;
type Error = unknown;

const queryKey = (args: QueryArgs) => ["billing", "invoice-preview", args];

export const useInvoicePreview = <TData = QueryFnData>(
  args: QueryArgs,
  options: UseQuerySimpleOptions<QueryFnData, Error, TData> = {}
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => BillingService.createInvoicePreview(args),
    {
      retry: false,
      keepPreviousData: true,
      skeletonData: useConst({
        id: gen.guid(),
        status: gen.pickone(["draft"]),
        subtotal: gen.integer({ min: 20000, max: 80000 }),
        taxAmount: 0,
        taxes: [],
        total: gen.integer({ min: 20000, max: 80000 }),
        dueAmount: gen.integer({ min: 0, max: 60000 }),
        discountApplied: gen.pickone([false]),
        discountAmount: gen.integer({ min: 20000, max: 20000 }),
      }),
      ...options,
    }
  );

  return result;
};

useInvoicePreview.queryKey = queryKey;
