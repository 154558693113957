import { Children, useRef } from "react";

import { Box, BoxProps } from "@chakra-ui/react";
import { isNil } from "lodash";

import { useEffectOnce } from "@/hooks";
import { isReactElement } from "@/utils";

import { Step } from "./types";
import { useWizardContext } from "./wizard-context";
import type { WizardStepProps } from "./wizard-step";

export interface WizardStepListProps extends BoxProps {}

export const WizardStepList = ({ children, ...props }: WizardStepListProps) => {
  const {
    state: { currentStep, setSteps },
  } = useWizardContext();

  const hasExecutedRef = useRef(false);

  useEffectOnce(() => {
    if (hasExecutedRef.current) return;

    const steps: Step[] = [];

    Children.forEach(children, (child, index) => {
      if (isReactElement(child) && !isNil(child.props.name)) {
        steps.push({
          index,
          name: (child.props as WizardStepProps).name,
        });
      }
    });

    setSteps(steps);

    hasExecutedRef.current = true;
  });

  if (!hasExecutedRef.current) {
    return null;
  }

  return (
    <Box {...props}>
      {Children.map(children, (child) => {
        if (isReactElement(child) && !isNil(child.props.name)) {
          return (child.props as WizardStepProps).name === currentStep?.name
            ? child
            : null;
        }

        return null;
      })}
    </Box>
  );
};
