import { RefObject, useEffect, useRef } from "react";

export const useEventListener = <
  TWindowEvent extends keyof WindowEventMap,
  TElementEvent extends keyof HTMLElementEventMap,
  TMediaQueryEvent extends keyof MediaQueryListEventMap
>(
  eventName: TWindowEvent | TElementEvent | TMediaQueryEvent | string,
  handler: (
    event:
      | WindowEventMap[TWindowEvent]
      | HTMLElementEventMap[TElementEvent]
      | MediaQueryListEventMap[TMediaQueryEvent]
      | Event
  ) => void,
  element?: RefObject<Element> | Element | Document | Window | null
) => {
  const savedHandler = useRef<any>();

  useEffect(() => {
    // @ts-ignore
    const targetElement = element?.current || element || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    const eventListener = (event: any) => {
      savedHandler?.current?.(event);
    };

    targetElement.addEventListener(eventName, eventListener);

    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
};
