import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

import { Skeleton, SkeletonText } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { useStyleProps } from "@/ui/hooks";

export type ButtonProps = ChakraButtonProps;

export const Button = withSkeleton<ButtonProps, "button">(
  ChakraButton,
  ({ pulse, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return props.variant === "link" ? (
      <SkeletonText pulse={pulse} w="fit-content" noOfLines={1} {...styleProps}>
        <ChakraButton {...restProps} />
      </SkeletonText>
    ) : (
      <Skeleton
        pulse={pulse}
        w="fit-content"
        h="fit-content"
        borderRadius="md"
        {...styleProps}
      >
        <ChakraButton {...restProps} />
      </Skeleton>
    );
  }
);
