import { Box, Portal, Spinner } from "@chakra-ui/react";

import { PageOverlay } from "@/components/layout";

export const FullPageSpinner = () => (
  <Portal>
    <Box as={PageOverlay} background="white" zIndex="999999">
      <Spinner size="sm" />
    </Box>
  </Portal>
);
