import { ReactNode, useMemo } from "react";

import {
  Box,
  CloseButton,
  HStack,
  SlideFade,
  Text,
  useToken,
} from "@chakra-ui/react";
import { isString } from "lodash";
import toast from "react-hot-toast";

export type ToastSemantic = "success" | "danger" | "warning" | "info";

export interface ToastProps {
  id: string;
  visible?: boolean;
  title?: ReactNode;
  message?: ReactNode;
  semantic: ToastSemantic;
  closeable?: boolean;
}

export const Toast = ({
  id,
  visible,
  title,
  message,
  semantic,
  closeable = true,
}: ToastProps) => {
  const [green, red, yellow] = useToken("colors", [
    "green.500",
    "red.500",
    "yellow.500",
  ]);

  const semanticColor = useMemo(() => {
    switch (semantic) {
      case "success":
        return green;
      case "danger":
        return red;
      case "warning":
        return yellow;
      default:
        return null;
    }
  }, [semantic, green, red, yellow]);

  return (
    <SlideFade in={visible}>
      <Box fontSize="16px">
        <Box
          w="50ch"
          maxW="90vw"
          pb={2}
          borderRadius="md"
          boxShadow="md"
          border="1px solid"
          borderColor="gray.200"
          borderTop={!!semanticColor ? `2px solid ${semanticColor}` : undefined}
          bgColor="white"
          fontSize="md"
        >
          <HStack justifyContent="space-between" mb={2} pt={4} px={4}>
            <Text as="span" fontWeight="semibold" fontFamily="heading">
              {title}
            </Text>

            {closeable && (
              <CloseButton
                size="sm"
                onClick={() => {
                  toast.dismiss(id);
                }}
              />
            )}
          </HStack>

          {!!message && (
            <Box px={4} py={2} fontSize="sm">
              {isString(message) ? (
                <Text color="gray.800" noOfLines={3}>
                  {message}
                </Text>
              ) : (
                <Box color="gray.800" noOfLines={3}>
                  {message}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </SlideFade>
  );
};
