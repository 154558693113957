import { DependencyList, useMemo, useRef } from "react";

import { cloneDeep, isEqual } from "lodash";

export const useDeepMemo = <T>(factory: () => T, deps: DependencyList = []) => {
  const isInitialRenderRef = useRef(true);
  const prevDepsRef = useRef(cloneDeep(deps));

  const currentValueRef = useRef<T>();

  const currentValue = useMemo(() => {
    const hasChanged = !isEqual(prevDepsRef.current, deps);

    if (isInitialRenderRef.current || hasChanged) {
      currentValueRef.current = factory();

      isInitialRenderRef.current = false;
      prevDepsRef.current = cloneDeep(deps);
    }

    return currentValueRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return currentValue as T;
};
