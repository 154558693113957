import {
  SkeletonText as ChakraSkeletonText,
  SkeletonTextProps as ChakraSkeletonTextProps,
} from "@chakra-ui/react";

export interface SkeletonTextProps extends ChakraSkeletonTextProps {
  pulse?: boolean;
}

export const SkeletonText = ({ pulse = true, ...props }: SkeletonTextProps) => {
  return (
    <ChakraSkeletonText
      startColor={pulse ? "gray.100" : "gray.400"}
      endColor="gray.400"
      overflow="hidden"
      {...props}
    />
  );
};
