import { useMemo } from "react";

import {
  StyleProps as ChakraStyleProps,
  SystemStyleObject,
} from "@chakra-ui/react";

import { ObjectUtils } from "@/utils";

export type StyleProps = ChakraStyleProps & {
  className?: string;
  sx?: SystemStyleObject;
  __css?: SystemStyleObject;
};

export const useStyleProps = <TProps extends StyleProps>(props: TProps) => {
  const {
    className,
    gridArea,
    display,
    alignItems,
    justifyContent,
    alignSelf,
    justifySelf,
    position,
    width,
    height,
    w,
    h,
    top,
    right,
    bottom,
    left,
    inset,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    border,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    borderRadius,
    borderTopRadius,
    borderRightRadius,
    borderBottomRadius,
    borderLeftRadius,
    borderTopStartRadius,
    borderTopEndRadius,
    borderTopRightRadius,
    borderTopLeftRadius,
    borderTopColor,
    borderTopStyle,
    borderTopWidth,
    borderBottomStartRadius,
    borderBottomEndRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    borderBottomColor,
    borderBottomWidth,
    borderLeftStyle,
    borderLeftColor,
    borderLeftWidth,
    borderRightStyle,
    borderRightColor,
    borderRightWidth,
    transform,
    sx,
    __css,
    ...rest
  } = props;

  const styleProps = useMemo<StyleProps>(
    () => ({
      className,
      gridArea,
      display,
      alignItems,
      justifyContent,
      alignSelf,
      justifySelf,
      position,
      width,
      height,
      w,
      h,
      top,
      right,
      bottom,
      left,
      inset,
      m,
      mx,
      my,
      mt,
      mr,
      mb,
      ml,
      border,
      borderTop,
      borderRight,
      borderBottom,
      borderLeft,
      borderRadius,
      borderTopRadius,
      borderRightRadius,
      borderBottomRadius,
      borderLeftRadius,
      borderTopStartRadius,
      borderTopEndRadius,
      borderTopRightRadius,
      borderTopLeftRadius,
      borderTopColor,
      borderTopStyle,
      borderTopWidth,
      borderBottomStartRadius,
      borderBottomEndRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      borderBottomColor,
      borderBottomWidth,
      borderLeftStyle,
      borderLeftColor,
      borderLeftWidth,
      borderRightStyle,
      borderRightColor,
      borderRightWidth,
      transform,
      sx,
      __css,
    }),
    [
      className,
      gridArea,
      display,
      alignItems,
      justifyContent,
      alignSelf,
      justifySelf,
      position,
      width,
      height,
      w,
      h,
      top,
      right,
      bottom,
      left,
      inset,
      m,
      mx,
      my,
      mt,
      mr,
      mb,
      ml,
      border,
      borderTop,
      borderRight,
      borderBottom,
      borderLeft,
      borderRadius,
      borderTopRadius,
      borderRightRadius,
      borderBottomRadius,
      borderLeftRadius,
      borderTopStartRadius,
      borderTopEndRadius,
      borderTopRightRadius,
      borderTopLeftRadius,
      borderTopColor,
      borderTopStyle,
      borderTopWidth,
      borderBottomStartRadius,
      borderBottomEndRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      borderBottomColor,
      borderBottomWidth,
      borderLeftStyle,
      borderLeftColor,
      borderLeftWidth,
      borderRightStyle,
      borderRightColor,
      borderRightWidth,
      transform,
      sx,
      __css,
    ]
  );

  const definedStyleProps = useMemo<StyleProps>(
    () => ObjectUtils.pickDefined(styleProps),
    [styleProps]
  );

  return [definedStyleProps, rest] as [
    StyleProps,
    Omit<TProps, keyof StyleProps>
  ];
};
