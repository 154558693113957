import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";
import { gen } from "@/utils";

type QueryArgs = Parameters<typeof CrmService.getContact>[0];
type QueryFnData = AsyncReturnType<typeof CrmService.getContact>;
type Error = unknown;

const queryKey = ({ id, ...restArgs }: QueryArgs) => [
  "crm",
  "contacts",
  id,
  restArgs,
];

export const useContact = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CrmService.getContact(args),
    {
      retry: false,
      skeletonData: useConst(gen.contact()),
      ...options,
    }
  );

  return response;
};
