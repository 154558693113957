import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services/crm-service";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof CrmService.unlinkTag>[0];
type Data = AsyncReturnType<typeof CrmService.unlinkTag>;
type Error = unknown;

export const useUnlinkTag = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    CrmService.unlinkTag,
    options
  );

  return result;
};
