import { useMemo, useRef } from "react";

import localForage from "localforage";

import { useAuth } from "@/contexts";
import { StringUtils } from "@/utils";

import { useAsyncEffect } from "./use-async-effect";
import { useSyncedState } from "./use-synced-state";

localForage.config({
  driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
  name: "cosiall",
  version: 1.0,
  storeName: "keyvaluepairs",
  description: "Offline storage for Cosiall.",
});

export const useLocalForage = <T>(
  key: string,
  initialValue: T
): [T, (value: T | ((prev: T) => T)) => Promise<void>] => {
  const { user } = useAuth();

  const hashedKey = useMemo(
    () => `csl-${user?.sub}-${StringUtils.hash(key)}`,
    [key, user]
  );

  const [storedValue, setStoredValue] = useSyncedState<T>(key, initialValue);
  const storedValueRef = useRef<T>(storedValue);

  useAsyncEffect(async () => {
    try {
      const item = await localForage.getItem<T>(hashedKey);
      storedValueRef.current = item ?? initialValue;
      setStoredValue(item ?? initialValue);
    } catch (error) {
      storedValueRef.current = initialValue;
      setStoredValue(initialValue);
    }
  }, []);

  const setValue = async (value: T | ((prev: T) => T)) => {
    const valueToStore =
      value instanceof Function ? value(storedValueRef.current) : value;

    storedValueRef.current = valueToStore;
    await localForage.setItem(hashedKey, valueToStore);

    setStoredValue(valueToStore);
  };

  return [storedValue, setValue];
};
