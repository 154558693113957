import {
  Context,
  Provider,
  createContext as createReactContext,
  useContext as useReactContext,
} from "react";

export interface CreateContextOptions<TContextProps> {
  /**
   * The display name of the context
   */
  name?: string;

  /**
   * Error message to throw if the context is `undefined`
   */
  errorMessage?: string;

  /**
   * If set to true it it will throw if there is no parent provider
   */
  strict?: boolean;

  defaultValue?: TContextProps;
}

export type CreateContextReturn<T> = [Provider<T>, () => T, Context<T>];

/**
 * Creates a named context, provider, and hook.
 *
 * @param options create context options
 */
export const createContext = <TContextProps>(
  options: CreateContextOptions<TContextProps> = {}
): CreateContextReturn<TContextProps> => {
  const {
    name,
    strict = true,
    errorMessage = "useContext: `context` is undefined. Seems you forgot to wrap component within Provider",
    defaultValue,
  } = options;

  const Context = createReactContext<TContextProps | undefined>(defaultValue);

  Context.displayName = name;

  const useContext = () => {
    const context = useReactContext(Context);

    if (!context && strict) {
      const error = new Error(errorMessage);
      error.name = "ContextError";
      Error.captureStackTrace?.(error, useContext);
      throw error;
    }

    return context;
  };

  return [
    Context.Provider,
    useContext,
    Context,
  ] as CreateContextReturn<TContextProps>;
};
