import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services/conversation-service";
import {
  UseInfiniteQuerySimpleOptions,
  useInfiniteQuery,
} from "@/services/hooks/use-infinite-query";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof ConversationService.getConversations>[0];
type QueryFnData = AsyncReturnType<typeof ConversationService.getConversations>;
type Error = unknown;

const queryKey = (args?: QueryArgs) => ["conversations", args];

export const useConversations = <TData = QueryFnData, TFlatData = TData>(
  args?: QueryArgs,
  options?: UseInfiniteQuerySimpleOptions<QueryFnData, Error, TData, TFlatData>
) => {
  const response = useInfiniteQuery<QueryFnData, Error, TData, TFlatData>(
    queryKey(args),
    ({ pageParam }) =>
      ConversationService.getConversations({
        ...args,
        cursor: pageParam,
      }),
    {
      getPreviousPageParam: (firstPage) => firstPage.pagination.previousCursor,
      getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
      skeletonData: useConst({
        pageParams: [],
        pages: [
          {
            elements: repeat(gen.conversation, 3),
            pagination: {
              skip: 0,
              limit: 10,
              total: 3,
            },
          },
        ],
      }),
      ...options,
    }
  );

  return response;
};

useConversations.queryKey = queryKey;
