import React, { ComponentProps } from "react";

import { chakra } from "@chakra-ui/react";
import * as RadixScrollArea from "@radix-ui/react-scroll-area";

import { WithChildren } from "@/ui/types";

export type ScrollAreaProps = WithChildren & ComponentProps<typeof Root>;

export const ScrollArea = ({ children, ...props }: ScrollAreaProps) => (
  <Root {...props} overflow="hidden" borderRadius="inherit">
    <Viewport w="full" h="full" borderRadius="inherit">
      {children}
    </Viewport>

    <Scrollbar
      orientation="vertical"
      zIndex="scrollbar"
      w="scrollbar"
      {...scrollbarSharedProps}
    >
      <Thumb {...thumbSharedProps} />
    </Scrollbar>

    <Scrollbar
      orientation="horizontal"
      zIndex="scrollbar"
      flexDirection="column"
      h="scrollbar"
      {...scrollbarSharedProps}
    >
      <Thumb {...thumbSharedProps} />
    </Scrollbar>

    <Corner background="gray.600" />
  </Root>
);

const Root = chakra(RadixScrollArea.Root);
const Viewport = chakra(RadixScrollArea.Viewport);
const Scrollbar = chakra(RadixScrollArea.Scrollbar);
const Thumb = chakra(RadixScrollArea.Thumb);
const Corner = chakra(RadixScrollArea.Corner);

const scrollbarSharedProps: ComponentProps<typeof Scrollbar> = {
  display: "flex",
  padding: "2px",
  userSelect: "none",
  background: "gray.200",
  transitionProperty: "background",
  transitionDuration: "normal",
};

const thumbSharedProps: ComponentProps<typeof Thumb> = {
  flex: 1,
  position: "relative",
  background: "gray.400",
  borderRadius: "md",
  transitionProperty: "background",
  transitionDuration: "normal",
  _hover: {
    background: "gray.600",
  },
};
