import { memo, useCallback, useRef } from "react";

import {
  AspectRatio,
  Box,
  CloseButton,
  Icon,
  Image,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { isFunction } from "lodash";
import { MdOutlineInsertDriveFile } from "react-icons/md";

import { Text } from "@/components/display";
import { Button } from "@/components/form";
import { useElementSize } from "@/hooks";

import { FilePreviewModal } from "./file-preview-modal";
import { MimeType, isOfType } from "./file-utils";
import { PdfPreview } from "./pdf-preview";

export interface FilePreviewProps {
  name: string;
  type?: string;
  url: string;
  size?: number;
  onRemove?: () => void;
}

const FilePreviewRaw = ({
  name,
  type,
  size,
  url,
  onRemove,
}: FilePreviewProps) => {
  const {
    isOpen: areDetailsOpen,
    onOpen: openDetails,
    onClose: closeDetails,
  } = useDisclosure();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { width: buttonWidth } = useElementSize(buttonRef);

  const renderFilePreview = useCallback(() => {
    // TODO: Add previews for video and audio

    if (isOfType(type, MimeType.image)) {
      return (
        <Image
          src={url}
          alt={name}
          w="full"
          h="full"
          objectFit="cover"
          borderRadius="inherit"
        />
      );
    }

    if (isOfType(type, MimeType.pdf)) {
      return <PdfPreview name={name} src={url} width={buttonWidth} />;
    }

    return (
      <VStack spacing={1} justifyContent="center" w="full" h="full" p={2}>
        <Icon as={MdOutlineInsertDriveFile} />

        <Box w="full">
          <Text
            noOfLines={1}
            display="inline-block"
            w="full"
            fontSize="xs"
            lineHeight={1}
          >
            {name}
          </Text>
        </Box>
      </VStack>
    );
  }, [type, name, url, buttonWidth]);

  return (
    <Box>
      <AspectRatio
        position="relative"
        ratio={1}
        border="1px solid"
        borderColor="gray.100"
        borderRadius="md"
        background="gray.50"
      >
        <Box w="full" h="full" overflow="visible !important">
          <Button
            ref={buttonRef}
            variant="unstyled"
            w="full"
            h="full"
            overflow="hidden"
            onClick={openDetails}
          >
            {renderFilePreview()}
          </Button>

          {isFunction(onRemove) && (
            <CloseButton
              size="sm"
              position="absolute"
              top={1}
              right={1}
              background="white"
              border="1px solid"
              borderColor="gray.200"
              borderRadius="full"
              _hover={{
                background: "gray.100",
              }}
              _active={{
                background: "gray.200",
              }}
              onClick={onRemove}
            />
          )}
        </Box>
      </AspectRatio>

      <FilePreviewModal
        url={url}
        name={name}
        type={type}
        size={size}
        isOpen={areDetailsOpen}
        onClose={closeDetails}
      />
    </Box>
  );
};

export const FilePreview = memo(FilePreviewRaw);
