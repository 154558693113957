import {
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { z } from "zod";

import { Modal, ModalProps } from "@/components/disclosure";
import { Button, FormControl, Input } from "@/components/form";
import { useFormState, useServerState } from "@/hooks";

import { ConversationFilterValue, ConversationFilterView } from "./types";

export interface CreateViewModalProps extends Omit<ModalProps, "children"> {
  filter: ConversationFilterValue;
}

export const CreateViewModal = ({
  isOpen,
  filter,
  onClose,
}: CreateViewModalProps) => {
  const [views = [], setViews] =
    useServerState<ConversationFilterView[]>("conversation-views");

  const {
    state: modalState,
    updateState: updateModalState,
    isValid: isModalStateValid,
    errors: modalStateErrors,
    reset: resetModalState,
  } = useFormState({
    defaultValue: {
      viewName: "",
    },
    schema: z.object({
      viewName: z
        .string()
        .min(1, "The value must at least 1 character long")
        .max(20, "The value cannot be longer than 20 characters")
        .refine((value) => !views.some((x) => x.name === value), {
          message: "View with this name already exists",
        }),
    }),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={resetModalState}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Save view</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl errors={modalStateErrors?.viewName}>
            <FormLabel>Name</FormLabel>

            <Input
              value={modalState.viewName}
              onChange={(value) => {
                updateModalState({ viewName: value });
              }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="primary"
            isDisabled={!isModalStateValid}
            onClick={() => {
              setViews((prevViews) => [
                ...(prevViews ?? []),
                { name: modalState.viewName, filter },
              ]);

              onClose();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
