import { useMemo } from "react";

import {
  Box,
  BoxProps,
  Collapse,
  HStack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

import { Text } from "@/components/display";
import { ButtonBox } from "@/components/form";

import { FormSectionIndicator } from "./form-section-indicator";

export interface FormSectionProps extends BoxProps {
  name: string;
  fields?: string[];
  showIndicator?: boolean;
  isCollapsible?: boolean;
  isDisabled?: boolean;
  defaultIsExpanded?: boolean;
}

export const FormSection = ({
  name,
  fields = [],
  showIndicator = false,
  isCollapsible = false,
  isDisabled = false,
  defaultIsExpanded = false,
  children,
  ...props
}: FormSectionProps) => {
  const { formState } = useFormContext();

  const { isOpen: isToggleOn, onToggle } = useDisclosure({
    defaultIsOpen: defaultIsExpanded || !isCollapsible,
  });

  const isDirty = useMemo(
    () =>
      fields.reduce(
        (acc, field) => acc || (!!formState.dirtyFields[field] ?? false),
        false
      ),
    [fields, formState.dirtyFields]
  );

  const hasErrors = useMemo(
    () =>
      fields.reduce(
        (acc, field) => acc || (!!formState.errors[field] ?? false),
        false
      ),
    [fields, formState.errors]
  );

  const isOpen = !isDisabled && (isToggleOn || hasErrors);

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      pointerEvents={isDisabled ? "none" : undefined}
      cursor={isDisabled ? "not-allowed" : undefined}
      opacity={isDisabled ? 0.4 : 1}
      {...props}
    >
      <ButtonBox
        isDisabled={!isCollapsible || hasErrors}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="full"
        p={{ base: 4, md: 6 }}
        color={isOpen ? "gray.600" : "inherit"}
        onClick={onToggle}
      >
        <HStack>
          {showIndicator && (
            <FormSectionIndicator status={isDirty ? "active" : "incomplete"} />
          )}

          <Text fontWeight="semibold">{name}</Text>
        </HStack>

        <Icon
          as={isOpen ? MdKeyboardArrowDown : MdKeyboardArrowRight}
          w={6}
          h={6}
          visibility={isCollapsible ? "visible" : "hidden"}
        />
      </ButtonBox>

      <Collapse in={isOpen}>
        <Box
          p={{ base: 4, md: 6 }}
          pt={{ base: 0, md: 0 }}
          pointerEvents={formState.isSubmitting ? "none" : undefined}
          opacity={formState.isSubmitting ? 0.6 : 1}
          transitionProperty="common"
          transitionDuration="normal"
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
