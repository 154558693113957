import { Fragment, useCallback, useMemo } from "react";

import {
  Box,
  BoxProps,
  Divider,
  DrawerProps,
  Flex,
  Icon,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  LinkOverlayProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { isNil, noop } from "lodash";
import qs from "qs";
import {
  MdArrowForwardIos,
  MdTrendingDown,
  MdTrendingFlat,
  MdTrendingUp,
} from "react-icons/md";
import { StringParam, useQueryParam } from "use-query-params";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  QuerySuspense,
} from "@/components/disclosure";
import { Modal } from "@/components/disclosure";
import {
  Badge,
  Heading,
  SectionHeading,
  Stat,
  Tag,
  Text,
} from "@/components/display";
import { Button } from "@/components/form";
import { Link } from "@/components/navigation";
import { useQueryString } from "@/hooks";
import { ContactCard } from "@/partials/contacts";
import { CampaignChart } from "@/partials/shared";
import { useReach, useStats } from "@/services/hooks/campaigns";
import { useLeads } from "@/services/hooks/crm";
import * as Models from "@/services/types";
import { NumberUtils } from "@/utils";

export interface CampaignDrawerProps extends Omit<DrawerProps, "children"> {
  campaign: Models.Campaign;
}

export const CampaignDrawer = ({
  campaign,
  isOpen,
  onClose,
}: CampaignDrawerProps) => {
  // TODO: Fix that; drawer shouldn't be closable if there's another overlay on top of it
  const [modal] = useQueryParam("modal", StringParam);

  return (
    <Drawer isOpen={isOpen} onClose={modal === "reach" ? noop : onClose}>
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>
          <Flex alignItems="baseline" justifyContent="space-between">
            <Heading size="sm">{campaign?.title}</Heading>

            <Flex gap={1} ml={1}>
              {!!campaign?.group && (
                <Badge colorScheme="purple">{campaign?.group?.name}</Badge>
              )}

              <Badge
                colorScheme={campaign?.status === "active" ? "green" : "gray"}
              >
                {campaign?.status}
              </Badge>
            </Flex>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          {campaign && (
            <VStack alignItems="flex-start" spacing={4}>
              <DetailSection campaign={campaign} />
              <StatSection campaign={campaign} />
              <LeadListSection campaign={campaign} />
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

interface DetailSectionProps extends BoxProps {
  campaign: Models.Campaign;
}

const DetailSection = ({ campaign, ...props }: DetailSectionProps) => {
  return (
    <VStack alignItems="flex-start" spacing={2} w="full" {...props}>
      <Button
        as={Link}
        variant="link"
        to={`/campaigns/${campaign.id}`}
        rightIcon={<Icon as={MdArrowForwardIos} />}
        colorScheme="blue"
        data-tour-step="campaign-sidebar__details-link"
      >
        View all details
      </Button>

      {campaign.description && <Text>{campaign.description}</Text>}

      {!!campaign.categories?.length && (
        <>
          <Wrap spacing={1}>
            {campaign.categories?.map((category) => (
              <WrapItem key={category.id}>
                <Tag>{category.label}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
    </VStack>
  );
};

interface StatSectionProps extends BoxProps {
  campaign: Models.Campaign;
}

const StatSection = ({ campaign, ...props }: StatSectionProps) => {
  const queryString = useQueryString();

  const hrefByType = useMemo<
    Record<string, { pathname: string; search: string }>
  >(
    () => ({
      reached: {
        pathname: "/campaigns",
        search: qs.stringify({
          ...queryString,
          campaign: campaign.id,
          modal: "reach",
        }),
      },
      connected: {
        pathname: "/conversations",
        search: qs.stringify({
          campaign: campaign.id,
          tab: "pending",
        }),
      },
      responded: {
        pathname: "/conversations",
        search: qs.stringify({
          campaign: campaign.id,
          tab: "all",
        }),
      },
      saved: {
        pathname: "/contacts",
        search: qs.stringify({ campaign: campaign.id }),
      },
    }),
    [campaign.id, queryString]
  );

  const [modal, setModal] = useQueryParam("modal", StringParam);

  const { data: stats, status: statsStatus } = useStats(
    { id: campaign.id },
    {
      enableSkeletonData: true,
      select: (result) => result.stats,
    }
  );

  const {
    data: reachData,
    status: reachDataStatus,
    isFetched: hasFetchedReach,
    isSkeletonData: isSkeletonReach,
    fetchNextPage: loadMoreReach,
    isFetchingNextPage: isLoadingMoreReach,
    hasNextPage: hasMoreReach,
  } = useReach(
    { id: campaign.id },
    {
      enableSkeletonData: true,
    }
  );

  const reach = useMemo(
    () => reachData?.pages.flatMap((x) => x.elements) || [],
    [reachData]
  );

  const renderStat = useCallback(
    (stat: Models.Stat) => {
      const hasHref = !isNil(hrefByType[stat.type]);

      const StatLinkBox = (props: LinkBoxProps) =>
        hasHref ? (
          <LinkBox
            _hover={{
              borderColor: "transparent",
              boxShadow: "outlineSm",
              fill: "blue.600",
            }}
            {...props}
          />
        ) : (
          <Box {...props} />
        );

      const StatLabelLinkOverlay = (props: LinkOverlayProps) =>
        hasHref ? (
          <LinkOverlay
            as={Link}
            to={hrefByType[stat.type]}
            color="inherit"
            _hover={{
              textDecoration: "none",
            }}
            {...props}
          />
        ) : (
          <Box {...props} />
        );

      return (
        <StatLinkBox
          flex="1 1 45%"
          p={2}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          transition={`
      border var(--transition-very-fast) linear, 
      box-shadow var(--transition-very-fast) linear
    `}
        >
          <Stat
            size="sm"
            sx={{
              "& > dl": {
                display: "flex",
                flexDirection: "column",
                height: "100%",
              },
            }}
          >
            <StatLabelLinkOverlay>
              <StatLabel
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {stat.name}
                {hasHref && (
                  <>
                    {" "}
                    <Icon
                      as={MdArrowForwardIos}
                      w={3}
                      h={3}
                      transition="fill var(--transition-very-fast) linear"
                    />
                  </>
                )}
              </StatLabel>
            </StatLabelLinkOverlay>

            <StatNumber>
              {NumberUtils.format(stat.currentAmount)}
              {!!stat.totalAmount && (
                <>
                  {" "}
                  <Text as="span" userSelect="none" color="gray.300">
                    / {NumberUtils.format(stat.totalAmount)}
                  </Text>
                </>
              )}
            </StatNumber>

            <StatHelpText
              display="flex"
              alignItems="center"
              m={0}
              mt="auto"
              zIndex={-1}
            >
              <Icon
                as={
                  stat.trendType === "neutral"
                    ? MdTrendingFlat
                    : stat.trendType === "negative"
                    ? MdTrendingDown
                    : MdTrendingUp
                }
                fill={
                  stat.trendType === "neutral"
                    ? "yellow.500"
                    : stat.trendType === "negative"
                    ? "red.500"
                    : "green.500"
                }
                mr={1}
              />
              {+stat.trendAmount?.toFixed?.(2)}%
            </StatHelpText>
          </Stat>
        </StatLinkBox>
      );
    },
    [hrefByType]
  );

  return (
    <Box data-tour-step="campaign-sidebar__stat-section" w="full" {...props}>
      <Modal
        key={campaign.id}
        scrollBehavior="inside"
        autoFocus={false}
        isOpen={modal === "reach"}
        onClose={() => setModal(undefined)}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>People reached</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <QuerySuspense status={reachDataStatus}>
              <VStack spacing={1}>
                {reach.map((profile, index) => (
                  <ContactCard
                    key={index}
                    contact={{
                      ...profile,
                      linkedInProfile: { ...profile },
                    }}
                    w="full"
                    px={0}
                    border="none"
                    borderBottom="1px solid"
                    borderBottomColor="inherit"
                    borderRadius="none"
                    transition="background var(--transition-very-fast) linear"
                    _last={{
                      borderBottomColor: "transparent",
                    }}
                  />
                ))}

                {hasFetchedReach && !reach.length && (
                  <Text w="full">
                    Your campaign hasn't reached out to anyone yet.
                  </Text>
                )}
              </VStack>

              {hasFetchedReach && hasMoreReach && (
                <Button
                  variant="link"
                  colorScheme="blue"
                  isLoading={isSkeletonReach || isLoadingMoreReach}
                  display="flex"
                  mt={4}
                  mx="auto"
                  onClick={() => loadMoreReach()}
                >
                  Load more
                </Button>
              )}
            </QuerySuspense>
          </ModalBody>
        </ModalContent>
      </Modal>

      <QuerySuspense status={statsStatus}>
        <StatGroup
          display="flex"
          flexWrap="wrap"
          alignItems="stretch"
          gap={2}
          w="full"
        >
          {stats?.map((stat, index) => (
            <Fragment key={index}>{renderStat(stat)}</Fragment>
          ))}
        </StatGroup>
      </QuerySuspense>

      <CampaignChart campaignId={campaign.id} mt={2} />
    </Box>
  );
};

interface LeadListSectionProps extends BoxProps {
  campaign: Models.Campaign;
}

const LeadListSection = ({ campaign, ...props }: LeadListSectionProps) => {
  const { data: { elements: leads } = {}, status: leadsStatus } = useLeads(
    { campaignId: campaign.id, limit: 10 },
    {
      enableSkeletonData: true,
    }
  );

  if (!leads?.length) {
    return null;
  }

  return (
    <Box w="full" {...props}>
      <SectionHeading>Recent contacts</SectionHeading>
      <Divider mb={2} />

      <QuerySuspense status={leadsStatus}>
        <Box>
          <VStack spacing={0}>
            {leads.map((lead) => (
              <LinkBox key={lead.id} position="relative" w="full">
                <LinkOverlay
                  as={Link}
                  to={`/contacts/${lead.contact.id}`}
                  position="absolute"
                  inset={0}
                  display="block"
                  w="full"
                  h="full"
                  suspendable={false}
                  _hover={{
                    "& + *": {
                      background: "gray.50",
                    },
                  }}
                />

                <ContactCard
                  contact={lead.contact}
                  w="full"
                  border="none"
                  borderBottom="1px solid"
                  borderBottomColor="inherit"
                  borderRadius="none"
                  transitionProperty="common"
                  transitionDuration="normal"
                />
              </LinkBox>
            ))}
          </VStack>

          <Button
            as={Link}
            variant="link"
            colorScheme="blue"
            hideOnSuspense
            to={`/contacts?campaign=${campaign.id}`}
            rightIcon={<Icon as={MdArrowForwardIos} />}
            w="fit-content"
            mt={4}
          >
            View all contacts
          </Button>
        </Box>
      </QuerySuspense>
    </Box>
  );
};
