import { RequestQueryParams, fetchApi } from "./openapi";

const getReachReport = (query: RequestQueryParams<"/reports/reach", "get">) => {
  const result = fetchApi("/reports/reach", "get", {
    params: { query },
  });

  return result;
};

const getConnectedReport = (
  query: RequestQueryParams<"/reports/connected", "get">
) => {
  const result = fetchApi("/reports/connected", "get", {
    params: { query },
  });

  return result;
};

const getRespondedReport = (
  query: RequestQueryParams<"/reports/responded", "get">
) => {
  const result = fetchApi("/reports/responded", "get", {
    params: { query },
  });

  return result;
};

export const ReportService = Object.freeze({
  getReachReport,
  getConnectedReport,
  getRespondedReport,
});
