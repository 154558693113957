import { forwardRef, useCallback } from "react";

import { Box, BoxProps, Icon, VisuallyHiddenInput } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { MdClose, MdOutlineFileUpload } from "react-icons/md";

import { Nullish } from "@/common";
import { Text } from "@/components/display";
import { IconButton } from "@/components/form";
import { useFileUpload } from "@/hooks";
import { Campaign } from "@/services/types";

type Audience = Campaign["customAudienceList"];

export interface CustomAudiencePickerProps
  extends Omit<BoxProps, "defaultValue" | "onPick" | "onPickError"> {
  variant?: "default" | "compact";
  defaultValue?: Nullish<Audience>;
  isReadOnly?: boolean;
  onPick?: (audience: Audience) => void;
  onPickError?: (error: string) => void;
  onRemove?: () => void;
}

export const CustomAudiencePicker = forwardRef<
  HTMLDivElement,
  CustomAudiencePickerProps
>(
  (
    {
      variant = "default",
      defaultValue,
      isReadOnly = false,
      onPick,
      onPickError,
      onRemove,
      ...props
    },
    ref
  ) => {
    const hasValue = !isEmpty(defaultValue?.fileName);

    const handleFileUpload = useFileUpload({
      onFileLoad: (file, content) => {
        const profilesUrls = extractLinkedInUrls(content);
        onPick?.({ fileName: file.name, profiles: profilesUrls });
      },
      onFileError: () => {
        onPickError?.("An error occurred while reading the file.");
      },
    });

    const handleFileRemove = useCallback(() => {
      onRemove?.();
    }, [onRemove]);

    return (
      <Box ref={ref} {...props}>
        {variant === "compact" ? (
          <Box
            as="label"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="full"
            p={4}
            bg="gray.100"
            borderRadius="md"
            cursor={isReadOnly ? "default" : "pointer"}
          >
            {!isReadOnly && (
              <VisuallyHiddenInput
                accept=".txt,.csv"
                type="file"
                onChange={handleFileUpload}
              />
            )}

            {hasValue ? (
              <Text as="span">
                Imported from <strong>{defaultValue?.fileName}</strong>
              </Text>
            ) : (
              <Text as="span" fontWeight="medium">
                Import audience
              </Text>
            )}

            {!isReadOnly && <Icon as={MdOutlineFileUpload} />}
          </Box>
        ) : (
          <Box w="full">
            <Box
              as="label"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              w="full"
              px={{ base: 4, md: 6 }}
              py={4}
              bg="gray.100"
              borderRadius="md"
              borderBottomRadius={hasValue ? 0 : "md"}
              cursor={isReadOnly ? "default" : "pointer"}
            >
              {!isReadOnly && (
                <VisuallyHiddenInput
                  accept=".txt,.csv"
                  type="file"
                  onChange={handleFileUpload}
                />
              )}

              <Text as="span" fontWeight="semibold">
                Import Audience
              </Text>

              {!isReadOnly && <Icon as={MdOutlineFileUpload} w={5} h={5} />}
            </Box>

            {hasValue && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                w="full"
                px={{ base: 4, md: 6 }}
                py={2}
                border="1px solid"
                borderTop="none"
                borderColor="gray.200"
                borderRadius="md"
                borderTopRadius={0}
              >
                <Text as="span">
                  Imported from <strong>{defaultValue?.fileName}</strong>
                </Text>

                {!isReadOnly && (
                  <IconButton
                    aria-label="Remove file"
                    icon={<Icon as={MdClose} />}
                    variant="link"
                    minW="fit-content"
                    onClick={handleFileRemove}
                  />
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }
);

CustomAudiencePicker.displayName = "CustomAudiencePicker";

export const extractLinkedInUrls = (content: string): string[] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const matches = content.match(urlRegex);

  if (matches) {
    const urls = matches
      .filter((url) => url.includes("linkedin.com/in/"))
      .map((url) => url.replace(/,+$/, ""));

    const uniqueUrls = [...new Set(urls)];
    return uniqueUrls;
  }

  return [];
};
