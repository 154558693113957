import { Box, BoxProps } from "@chakra-ui/react";

export interface ConversationChatProps extends BoxProps {}

export const ConversationChat = (props: ConversationChatProps) => {
  return (
    <Box
      display="grid"
      gridTemplateRows="auto minmax(0, 1fr) auto"
      w="full"
      h="full"
      borderRadius="md"
      {...props}
    />
  );
};
