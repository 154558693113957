import { atom, useAtom } from "jotai";

const atomsByKey: Record<string, ReturnType<typeof atom<unknown>>> = {};

export const useSyncedState = <T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] => {
  if (!atomsByKey[key]) {
    atomsByKey[key] = atom<T>(defaultValue) as ReturnType<typeof atom<unknown>>;
  }

  const syncedStateAtom = atomsByKey[key];
  const [state, setState] = useAtom(
    syncedStateAtom as ReturnType<typeof atom<T>>
  );

  return [state, setState];
};
