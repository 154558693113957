import {
  CampaignRequest,
  RequestData,
  RequestParams,
  fetchApi,
} from "./openapi";
import { ValidationService } from "./validation-service";

const get = ({ id }: RequestParams<"/campaigns/{id}", "get">) => {
  const result = fetchApi("/campaigns/{id}", "get", {
    params: { path: { id } },
  });

  return result;
};

const getAll = async ({
  status,
  search,
  skip,
  limit,
}: RequestParams<"/campaigns", "get"> = {}) => {
  const result = fetchApi("/campaigns", "get", {
    params: { query: { status, search, skip, limit } },
  });

  return result;
};

const create = async (data: RequestData<"/campaigns", "post">) => {
  const result = fetchApi("/campaigns", "post", { data });

  return result;
};

const update = ({
  id,
  campaign,
}: { campaign: RequestData<"/campaigns/{id}", "put"> } & RequestParams<
  "/campaigns/{id}",
  "put"
>) => {
  const result = fetchApi("/campaigns/{id}", "put", {
    params: { path: { id } },
    data: campaign,
  });

  return result;
};

const $delete = ({ id }: RequestParams<"/campaigns/{id}", "delete">) => {
  const result = fetchApi("/campaigns/{id}", "delete", {
    params: { path: { id } },
  });

  return result;
};

const analytics = ({
  id,
}: RequestParams<"/campaigns/{id}/analytics", "get">) => {
  const result = fetchApi("/campaigns/{id}/analytics", "get", {
    params: { path: { id } },
  });

  return result;
};

const validate = async ({
  campaign,
  properties,
  editMode = false,
}: {
  campaign: Partial<RequestData<"/campaigns/validation", "post">>;
  properties?: (keyof CampaignRequest)[];
  editMode?: boolean;
}) => {
  const result = await fetchApi("/campaigns/validation", "post", {
    params: {
      query: { properties: properties ?? Object.keys(campaign), editMode },
    },
    data: campaign as RequestData<"/campaigns/validation", "post">,
  });

  return ValidationService.parseResult(result);
};

const getPotentialReach = (
  data: RequestData<"/campaigns/potential-reach", "post">
) => {
  const result = fetchApi("/campaigns/potential-reach", "post", { data });

  return result;
};

const getReach = ({
  id,
  skip,
  limit,
}: RequestParams<"/campaigns/{id}/reach", "get">) => {
  const result = fetchApi("/campaigns/{id}/reach", "get", {
    params: { path: { id }, query: { skip, limit } },
  });

  return result;
};

const getGroups = () => {
  const result = fetchApi("/campaigns/groups", "get");

  return result;
};

const createGroup = (data: RequestData<"/campaigns/groups", "post">) => {
  const result = fetchApi("/campaigns/groups", "post", { data });

  return result;
};

const getTemplates = () => {
  const result = fetchApi("/campaigns/templates", "get");

  return result;
};

const createTemplate = (data: RequestData<"/campaigns/templates", "post">) => {
  const result = fetchApi("/campaigns/templates", "post", { data });

  return result;
};

const deleteTemplate = ({
  id,
}: RequestParams<"/campaigns/templates", "delete">) => {
  const result = fetchApi("/campaigns/templates", "delete", {
    params: { query: { id } },
  });

  return result;
};

const getCategories = async () => {
  const result = fetchApi("/campaigns/categories", "get");

  return result;
};

const createCategory = async (
  data: RequestData<"/campaigns/categories", "post">
) => {
  const result = fetchApi("/campaigns/categories", "post", { data });

  return result;
};

export const CampaignService = Object.freeze({
  get,
  getAll,
  create,
  update,
  delete: $delete,
  analytics,
  validate,
  getPotentialReach,
  getReach,
  getGroups,
  createGroup,
  getTemplates,
  createTemplate,
  deleteTemplate,
  getCategories,
  createCategory,
});
