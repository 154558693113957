import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof ConversationService.sendMessage>[0];
type Data = AsyncReturnType<typeof ConversationService.sendMessage>;
type Error = unknown;

const mutationKey = () => ["conversations", "send-message"];

export const useSendMessage = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    ConversationService.sendMessage,
    {
      mutationKey: mutationKey(),
      ...options,
    }
  );

  return result;
};

useSendMessage.mutationKey = mutationKey;
