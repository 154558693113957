import { useState } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { Button } from "@/components/form";
import { MotionBox, transitions } from "@/styles/motion";
import { WithChildren } from "@/ui/types";

import { useWizardContext } from "./wizard-context";

export interface WizardNavigationProps extends BoxProps {}

export const WizardNavigation = (props: WizardNavigationProps) => {
  const {
    state: { steps, currentStep, setCurrentStep },
  } = useWizardContext();

  const currentCompletion =
    (((currentStep?.index ?? 0) + 1) / steps.length) * 100;

  return (
    <Box overflowX="auto" p={1} {...props}>
      <Box
        display="flex"
        flexWrap="nowrap"
        position="relative"
        w="full"
        minW="fit-content"
        pb={1}
        _before={{
          content: `""`,
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "6px",
          borderRadius: "md",
          background: "gray.100",
        }}
        _after={{
          content: `""`,
          position: "absolute",
          bottom: 0,
          left: 0,
          width: `${currentCompletion}%`,
          height: "6px",
          borderRadius: "md",
          background: "blue.400",
          transition: "width var(--transition-fast) var(--ease-out-quad)",
        }}
      >
        {steps.map((step) => (
          <WizardNavigationItem
            key={step.name}
            isActive={step.index === currentStep?.index}
            isDisabled={(currentStep?.index ?? 0) < step.index}
            onClick={() => setCurrentStep(step.name)}
          >
            {step.name}
          </WizardNavigationItem>
        ))}
      </Box>
    </Box>
  );
};

interface WizardNavigationItemProps extends WithChildren {
  isActive?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const WizardNavigationItem = ({
  isActive = false,
  isDisabled = false,
  children,
  onClick,
}: WizardNavigationItemProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Button
      variant="unstyled"
      size="md"
      flex={1}
      minW="fit-content"
      px={4}
      fontWeight="semibold"
      textAlign="center"
      color={isActive ? "gray.900" : "gray.700"}
      transitionProperty="common"
      transitionDuration="normal"
      cursor={isActive ? "default" : "pointer"}
      isDisabled={isDisabled}
      _disabled={{
        background: "unset",
        cursor: "not-allowed",
      }}
      onClick={onClick}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {isMouseOver && !isActive && !isDisabled && (
        <MotionBox
          layoutId="wizard-navigation__item"
          initial={false}
          // @ts-ignore
          transition={transitions.spring}
          position="absolute"
          inset={0}
          background="gray.50"
          borderRadius="md"
          zIndex="-1"
        />
      )}

      {children}
    </Button>
  );
};
