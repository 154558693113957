import { ReactNode } from "react";

import htmr from "htmr";
import { Element, Node, Text } from "slate";

import { Placeholder, RichTextValue } from "@/types";

const Empty: RichTextValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const Placeholders: Placeholder[] = [
  {
    id: "greeting",
    label: "Greeting",
  },
  {
    id: "first-name",
    label: "First Name",
  },
  {
    id: "regards",
    label: "Regards",
  },
];

const isEmpty = (value?: RichTextValue) => {
  if (!value || !value.length) {
    return true;
  }

  const stringifiedValue = serializeText(value);
  return !stringifiedValue || !stringifiedValue.length;
};

const length = (value: RichTextValue) => {
  return serializeText(value).length;
};

const serializeText = (value: RichTextValue) => {
  if (!value) {
    return "";
  }

  return value.map((node) => Node.string(node)).join("\n");
};

const serializeHtml = (value: RichTextValue) => {
  const serializeNodeHtml = (node: Node): ReactNode => {
    if (Text.isText(node)) {
      return htmr(node.text);
    }

    if (!Element.isElement(node)) {
      return "";
    }

    const children = node.children.map(serializeNodeHtml).join("");

    switch (node.type) {
      case "placeholder":
        return `<span data-type="placeholder" data-label=${node.label}>${children}</span>`;
      case "paragraph":
        return `<p>${children}</p>`;
      default:
        return children;
    }
  };

  const result = value
    .map(serializeNodeHtml)
    .map((node) => (node === "" ? `<br data-type="linebreak"/>` : node));

  return result.join("");
};

const serializeJson = (value: RichTextValue) => {
  return JSON.stringify(value);
};

const deserializeJson = (json: string) => {
  return JSON.parse(json) as RichTextValue;
};

export const RichText = Object.freeze({
  Empty,
  Placeholders,
  isEmpty,
  length,
  serializeText,
  serializeHtml,
  serializeJson,
  deserializeJson,
});
