import { Tab, TabProps } from "@chakra-ui/react";
import { usePress } from "@react-aria/interactions";
import { PressEvents } from "@react-types/shared";

import { useFilterContext } from "./filter-context";

interface PressableTab extends TabProps, PressEvents {}

const PressableTab = ({
  onPress,
  onPressStart,
  onPressEnd,
  onPressChange,
  onPressUp,
  ...props
}: PressableTab) => {
  const { pressProps } = usePress({
    onPress,
    onPressStart,
    onPressEnd,
    onPressChange,
    onPressUp,
  });

  return <Tab {...props} {...pressProps} />;
};

export interface FilterTabProps extends TabProps {
  index?: number;
}

export const FilterTab = ({ index, ...props }: FilterTabProps) => {
  const { onChange } = useFilterContext();

  return (
    <PressableTab
      justifyContent="flex-start"
      gap={2}
      w={{ base: "fit-content", md: "full" }}
      px={4}
      borderRadius="md"
      fontSize="sm"
      fontWeight="medium"
      onPress={() => onChange(index!)}
      _selected={{
        background: "gray.50",
      }}
      {...props}
    />
  );
};
