import { useMediaQuery as useChakraMediaQuery } from "@chakra-ui/react";
import { isArray } from "lodash";

import { MEDIA_QUERY } from "@/styles/variables";

type Query = keyof typeof MEDIA_QUERY;

export const useMediaQuery = (query: Query | Query[]) => {
  return useChakraMediaQuery(
    isArray(query) ? query.map((x) => MEDIA_QUERY[x]) : MEDIA_QUERY[query],
    {
      ssr: false,
      fallback: false,
    }
  );
};
