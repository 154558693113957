import { Box, Container, Flex, Portal, Spinner } from "@chakra-ui/react";

import { Text } from "@/components/display";

export const HealthOverlay = () => {
  return (
    <Portal>
      <Box
        position="fixed"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="100vw"
        h="100vh"
        top={0}
        left={0}
        zIndex="overlay"
      >
        <Box
          position="absolute"
          w="inherit"
          h="inherit"
          backdropFilter="blur(8px)"
          background="whiteAlpha.800"
          zIndex={1}
        />

        <Container zIndex={2}>
          <Flex flexDirection="column" alignItems="center">
            <Spinner size="sm" mb={2} />

            <Text fontFamily="heading" fontWeight="bold" textAlign="center">
              Things are taking longer than usual. Hang tight...
            </Text>
          </Flex>
        </Container>
      </Box>
    </Portal>
  );
};
