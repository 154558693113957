import { useMemo, useState } from "react";

import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Icon,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { MdCircle, MdOutlineTag } from "react-icons/md";

import { Tag } from "@/components/display";
import { Option, Switch, TagEditable, TagSelect } from "@/components/form";
import { useTags } from "@/services/hooks/crm";

import { useConversationFilterProvider } from "./conversation-filter-context";

export const ContactTabPanel = () => {
  const { value, onChange } = useConversationFilterProvider();

  const [isEditingTags, setIsEditingTags] = useState(false);

  const { data: tags = [], isLoading: isLoadingTags } = useTags(undefined, {
    select: (x) => x.elements,
  });

  const selectedTags = useMemo(() => {
    return value.contactTags
      .map((x) => tags.find((t) => t.id === x))
      .filter((x) => !isNil(x)) as typeof tags;
  }, [tags, value.contactTags]);

  const options = useMemo<Option[]>(
    () =>
      tags
        .filter((x) => !selectedTags.some((y) => y.id === x.id))
        .map((x) => ({
          value: x.id.toString(),
          label: x.label,
          icon: <Icon as={MdCircle} w={2} h={2} color={x.color} />,
        })),
    [selectedTags, tags]
  );

  return (
    <VStack spacing={2} alignItems="flex-start" w="full">
      <Box w="full">
        <FormLabel fontWeight="medium" mr={0}>
          View conversations with contacts that have...
        </FormLabel>

        <Wrap spacing={1} mt={2}>
          <WrapItem>
            <TagEditable
              label="Add tag"
              icon={MdOutlineTag}
              isEditing={isEditingTags}
              isLoading={isLoadingTags}
              onEdit={() => {
                setIsEditingTags(true);
              }}
              onDone={() => {
                setIsEditingTags(false);
              }}
            >
              <TagSelect
                options={options}
                onChange={(option) => {
                  const tag = tags.find((x) => x.id.toString() == option.value);
                  if (!!tag) {
                    onChange({
                      ...value,
                      contactTags: [...value.contactTags, tag.id],
                    });
                  }
                }}
              />
            </TagEditable>
          </WrapItem>

          {selectedTags.map((tag) => (
            <WrapItem key={tag.id}>
              <Tag>
                <TagLeftIcon as={MdCircle} color={tag.color} w={2} h={2} />

                <TagLabel>{tag.label}</TagLabel>

                <TagCloseButton
                  onClick={() => {
                    onChange({
                      ...value,
                      contactTags: value.contactTags.filter(
                        (x) => x !== tag.id
                      ),
                    });
                  }}
                />
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box>

      <Divider />

      <Flex w="full" justifyContent="space-between" gap={2}>
        <FormLabel fontWeight="medium" mr={0} mb={0}>
          View conversations with saved contacts
        </FormLabel>

        <Switch
          value={value.fromContacts}
          defaultValue={value.fromContacts}
          onChange={(x) => onChange({ ...value, fromContacts: x })}
        />
      </Flex>
    </VStack>
  );
};
