import { useState } from "react";

import { Divider, VStack } from "@chakra-ui/react";
import { isNil, pick } from "lodash";
import { FormProvider } from "react-hook-form";

import { Nullish } from "@/common";
import {
  QuerySuspense,
  WizardStep,
  WizardStepProps,
  useWizardContext,
} from "@/components/disclosure";
import { Text } from "@/components/display";
import { Form, FormSection, useForm } from "@/components/form";
import { Link } from "@/components/navigation";
import { useDeepMemo } from "@/hooks";
import {
  InvoicePreview,
  InvoicePreviewBody,
  InvoicePreviewItem,
  InvoicePreviewItemDivider,
  InvoicePreviewItemLeftText,
  InvoicePreviewItemList,
  InvoicePreviewItemRightText,
  PaymentMethodList,
  PromotionButton,
} from "@/partials/billing";
import { calculateReachAmount } from "@/screens/campaigns/utils";
import {
  BillingStepFieldValues,
  CampaignWizardFieldValues,
} from "@/screens/campaigns/wizards";
import { useBillingPortal, useInvoicePreview } from "@/services/hooks/billing";
import { CurrencyUtils } from "@/utils";

import { useHandleSubmitPayment } from "./use-handle-submit-payment";
import { WizardStepActionBar } from "./wizard-step-action-bar";
import { WizardStepAudienceBar } from "./wizard-step-audience-bar";

export interface BillingStepProps extends Pick<WizardStepProps, "name"> {}

export const BillingStep = ({ name }: BillingStepProps) => {
  const { data: wizardData } = useWizardContext<CampaignWizardFieldValues>();

  const reachAmount = calculateReachAmount(wizardData.budget);

  const [promotionCode, setPromotionCode] = useState<Nullish<string>>(null);

  const { data: billingPortalUrl } = useBillingPortal();

  const { data: invoicePreview, isSkeletonData: isSkeletonInvoicePreview } =
    useInvoicePreview(
      { reachAmount, promotionCode },
      { enableSkeletonData: true }
    );

  const methods = useForm({
    defaultValues: pick(
      wizardData,
      Object.keys(billingStepDefaultValues)
    ) as BillingStepFieldValues,
  });

  const { formState, watch } = methods;

  const stepData = watch();
  const stepDataMemo = useDeepMemo(() => stepData, [stepData]);

  const handleSubmit = useHandleSubmitPayment();

  if (!invoicePreview) {
    return null;
  }

  return (
    <WizardStep
      name={name}
      data={stepDataMemo}
      isValid={formState.isValid}
      isSubmitting={formState.isSubmitting}
    >
      <VStack spacing={4} alignItems="normal">
        <QuerySuspense suspended={isSkeletonInvoicePreview}>
          <InvoicePreview>
            <InvoicePreviewBody>
              <InvoicePreviewItemList>
                <InvoicePreviewItem>
                  <InvoicePreviewItemLeftText>
                    Subtotal
                  </InvoicePreviewItemLeftText>

                  <InvoicePreviewItemDivider />

                  <InvoicePreviewItemRightText>
                    {CurrencyUtils.formatCurrency(
                      invoicePreview.subtotal / 100
                    )}
                  </InvoicePreviewItemRightText>
                </InvoicePreviewItem>

                {!!invoicePreview.taxes?.filter((x) => 0 < x.amount).length && (
                  <>
                    {invoicePreview.taxes
                      .filter((x) => 0 < x.amount)
                      .map((tax, index) => (
                        <InvoicePreviewItem key={index} showDivider={false}>
                          <InvoicePreviewItemLeftText>
                            {tax.displayName} ({tax.percentage}%)
                          </InvoicePreviewItemLeftText>

                          <InvoicePreviewItemDivider />

                          <InvoicePreviewItemRightText>
                            {CurrencyUtils.formatCurrency(tax.amount / 100)}
                          </InvoicePreviewItemRightText>
                        </InvoicePreviewItem>
                      ))}

                    <Text mb={2} fontSize="xs">
                      The tax is calculated based on your location and your
                      account type (individual or corporate).
                      <br />
                      You can enter your billing address and tax ID in the{" "}
                      {isNil(billingPortalUrl) ? (
                        "Billing Portal"
                      ) : (
                        <Link to={billingPortalUrl} isExternal>
                          Billing Portal
                        </Link>
                      )}
                      .
                    </Text>

                    <Divider />
                  </>
                )}

                {invoicePreview.discountApplied && (
                  <InvoicePreviewItem color="green.600">
                    <InvoicePreviewItemLeftText>
                      Discount
                    </InvoicePreviewItemLeftText>

                    <InvoicePreviewItemDivider />

                    <InvoicePreviewItemRightText>
                      -
                      {CurrencyUtils.formatCurrency(
                        invoicePreview.discountAmount / 100
                      )}
                    </InvoicePreviewItemRightText>
                  </InvoicePreviewItem>
                )}

                <InvoicePreviewItem showDivider={false} fontWeight="semibold">
                  <InvoicePreviewItemLeftText>
                    Total due today
                  </InvoicePreviewItemLeftText>

                  <InvoicePreviewItemDivider />

                  <InvoicePreviewItemRightText>
                    {CurrencyUtils.formatCurrency(
                      invoicePreview.dueAmount / 100
                    )}
                  </InvoicePreviewItemRightText>
                </InvoicePreviewItem>
              </InvoicePreviewItemList>

              <PromotionButton
                value={invoicePreview.discountApplied ? promotionCode : null}
                mt={4}
                onChange={(value) => {
                  setPromotionCode(value);
                }}
              />
            </InvoicePreviewBody>
          </InvoicePreview>
        </QuerySuspense>

        <FormProvider {...methods}>
          <Form
            id={name}
            onSubmit={methods.handleSubmit(async () => {
              try {
                await handleSubmit({ reachAmount, promotionCode });
              } catch {
                /* empty */
              }
            })}
          >
            <FormSection name="Payment methods">
              <PaymentMethodList />
            </FormSection>
          </Form>
        </FormProvider>
      </VStack>

      <WizardStepActionBar>
        <WizardStepAudienceBar />
      </WizardStepActionBar>
    </WizardStep>
  );
};

export const billingStepDefaultValues: BillingStepFieldValues = {};
