import { useRef } from "react";

import { Box, BoxProps, Icon } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { MdArrowBackIosNew } from "react-icons/md";
import { Outlet } from "react-router-dom";

import logo from "@/assets/logo-plain.svg";
import { Button } from "@/components/form";
import {
  BottomNavigation,
  PAGE_HEADER_ID,
  PAGE_LAYOUT_ID,
  PAGE_NAVIGATION_ID,
  PageContainer,
  PageContent,
} from "@/components/layout";
import { Link } from "@/components/navigation";
import { useElementSize, useReferer } from "@/hooks";
import { usePollConversations } from "@/services/hooks/conversations";
import {
  MotionBox,
  MotionHeader,
  animations,
  transitions,
} from "@/styles/motion";

import { Header, HeaderMenu } from "./header";

export type PageLayoutProps =
  | ({ variant?: "default" } & DefaultPageLayoutProps)
  | ({ variant?: "simple" } & SimplePageLayoutProps);

export const PageLayout = ({
  id = PAGE_LAYOUT_ID,
  ...props
}: PageLayoutProps) => {
  if (props.variant === "simple") {
    return <SimplePageLayout id={id} {...props} />;
  }

  return <DefaultPageLayout id={id} {...props} />;
};

interface DefaultPageLayoutProps extends BoxProps {}

const DefaultPageLayout = ({ ...props }: DefaultPageLayoutProps) => {
  const { height: headerHeight } = useElementSize(`#${PAGE_HEADER_ID}`);
  const { height: navigationHeight } = useElementSize(`#${PAGE_NAVIGATION_ID}`);

  usePollConversations(
    {},
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: false,
    }
  );

  return (
    <>
      <Header id={PAGE_HEADER_ID} />

      <Box
        position="relative"
        w="full"
        minHeight="var(--cosiall-sizes-layout-height)"
        sx={{
          "--cosiall-sizes-layout-height": `calc(100vh - ${headerHeight}px - ${navigationHeight}px)`,
        }}
        {...props}
      >
        <PageContent>
          <Outlet />
        </PageContent>
      </Box>

      <BottomNavigation />
    </>
  );
};

interface SimplePageLayoutProps extends BoxProps {
  defaultRefererUri?: string;
  defaultRefererDisplayName?: string;
  showBackButton?: boolean;
  showHeaderMenu?: boolean;
}

const SimplePageLayout = ({
  defaultRefererUri = "",
  defaultRefererDisplayName = "",
  showBackButton = true,
  showHeaderMenu = false,
  ...props
}: SimplePageLayoutProps) => {
  const { refererUri, refererDisplayName } = useReferer({
    defaultUri: defaultRefererUri,
    defaultDisplayName: defaultRefererDisplayName,
  });

  const headerRef = useRef<HTMLElement>(null);
  const { height: headerHeight } = useElementSize(headerRef);

  return (
    <>
      <PageContainer maxW="container.lg">
        <MotionHeader
          ref={headerRef}
          gridArea="header"
          display={{ base: "flex", md: "grid" }}
          justifyContent={{ base: "space-between", md: "unset" }}
          gridTemplateColumns={{ base: "unset", md: "1fr auto 1fr" }}
          py={4}
          variants={animations.fadeIn}
          initial="initial"
          animate="visible"
          exit="initial"
          // @ts-ignore
          transition={transitions.spring}
        >
          {!showBackButton || isEmpty(refererUri) ? (
            <Box />
          ) : (
            <Button
              as={Link}
              to={refererUri}
              noReferer
              variant="link"
              leftIcon={<Icon as={MdArrowBackIosNew} />}
              w="fit-content"
              colorScheme="blue"
            >
              {refererDisplayName ? `Back to ${refererDisplayName}` : "Back"}
            </Button>
          )}

          <Box
            as="img"
            src={logo}
            alt="logo"
            h={5}
            opacity={{ base: 0, md: 1 }}
          />

          {showHeaderMenu && (
            <Box ml="auto">
              <HeaderMenu />
            </Box>
          )}
        </MotionHeader>
      </PageContainer>

      <MotionBox
        display="grid"
        gridTemplateAreas={`
          "header"
          "content"
        `}
        gridTemplateColumns="minmax(0, 1fr)"
        gridTemplateRows="auto 1fr auto"
        w="full"
        maxWidth="container.lg"
        my={0}
        mx="auto"
        sx={{
          "--cosiall-sizes-layout-height": `calc(100vh - ${headerHeight}px)`,
        }}
        variants={animations.slideLeft}
        initial="initial"
        animate="visible"
        exit="initial"
        // @ts-ignore
        transition={transitions.spring}
        {...props}
      >
        <PageContent gridArea="content">
          <Outlet />
        </PageContent>
      </MotionBox>
    </>
  );
};
