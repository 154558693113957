import { Nullish } from "@/common";

export const MimeType = {
  image: "image/",
  video: "video/",
  audio: "audio/",
  pdf: "application/pdf",
};

export const isOfType = (fileType: Nullish<string>, checkType: string) =>
  fileType?.startsWith(checkType) ?? false;
