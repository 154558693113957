import { useState } from "react";

import { Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import {
  Editable,
  EditableField,
  EditablePreview,
  TextArea,
  useIsDirty,
} from "@/components/form";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const DescriptionEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<string>) => {
  const [nextValue, setNextValue] = useState(defaultValue);

  const isDirty = useIsDirty(defaultValue || "", nextValue || "");

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Description</EditableFieldLabel>

      <Editable
        label="Description"
        isReadOnly={isReadOnly}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>{defaultValue}</EditablePreview>

        <EditableField>
          <TextArea
            w="full"
            value={nextValue || ""}
            onChange={(value) => {
              setNextValue(value);
              onChange?.(value);
            }}
          />
        </EditableField>
      </Editable>
    </Box>
  );
};
