import { isEmpty } from "lodash";

import { Text, TextProps } from "@/components/display";

export interface EditableFieldErrorProps extends TextProps {
  error?: string | null;
}

export const EditableFieldError = ({
  error,
  ...props
}: EditableFieldErrorProps) => {
  if (isEmpty(error)) {
    return null;
  }

  return (
    <Text mt={2} color="red.500" fontSize="sm" {...props}>
      {error}
    </Text>
  );
};
