import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryFnData = AsyncReturnType<typeof CampaignService.getTemplates>;
type Error = unknown;

const queryKey = () => ["campaigns", "templates"];

export const useTemplates = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    () => CampaignService.getTemplates(),
    {
      skeletonData: useConst({
        elements: repeat(gen.predefinedMessageTemplate),
        pagination: {
          skip: 0,
          limit: 10,
          total: 2,
        },
      }),
      ...options,
    }
  );

  return result;
};

useTemplates.queryKey = queryKey;
