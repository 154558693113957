import { noop } from "lodash";

import { createContext } from "@/ui/utils";

export interface FilterContextProps {
  index: number;
  onChange: (index: number) => void;
}

export const [FilterProvider, useFilterContext] =
  createContext<FilterContextProps>({
    name: "FilterContext",
    defaultValue: {
      index: 0,
      onChange: noop,
    },
  });
