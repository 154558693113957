import { AsyncReturnType } from "type-fest";

import { HTTP_STATUS_CODES, isAxiosError } from "@/http";
import { CrmService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryArgs = Parameters<typeof CrmService.getLead>[0];
type QueryFnData = AsyncReturnType<typeof CrmService.getLead> | null;
type Error = unknown;

const queryKey = ({ id, ...restArgs }: QueryArgs) => [
  "crm",
  "leads",
  id,
  restArgs,
];

export const useLead = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    async () => {
      try {
        return await CrmService.getLead(args);
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          if (error?.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
            return null;
          }
        }

        throw error;
      }
    },
    {
      retry: false,
      ...options,
    }
  );

  return response;
};

useLead.queryKey = queryKey;
