import { useMemo, useRef, useState } from "react";

import { useAuth } from "@/contexts";
import { StringUtils } from "@/utils";

export const useLocalStorage = <T = any>(
  key: string,
  initialValue: T
): [T, (value: T | ((val: T) => T)) => void, React.MutableRefObject<T>] => {
  const { user } = useAuth();

  const hashedKey = useMemo(
    () => `csl-${user?.sub}-${StringUtils.hash(key)}`,
    [key, user]
  );

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(hashedKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const storedValueRef = useRef<T>(storedValue);

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore =
      value instanceof Function ? value(storedValueRef.current) : value;

    storedValueRef.current = valueToStore;
    window.localStorage.setItem(hashedKey, JSON.stringify(valueToStore));

    setStoredValue(valueToStore);
  };

  return [storedValue, setValue, storedValueRef];
};
