import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryFnData = AsyncReturnType<typeof CampaignService.getGroups>;
type Error = unknown;

const queryKey = () => ["campaigns", "groups"];

export const useGroups = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    () => CampaignService.getGroups(),
    options
  );

  return result;
};

useGroups.queryKey = queryKey;
