import { useCallback } from "react";

import { isEmpty, isNil } from "lodash";
import { NumberParam, useQueryParam } from "use-query-params";

import { Nullish } from "@/common";
import { useWizardContext } from "@/components/disclosure";
import { ToasterManager } from "@/components/feedback";
import { ts } from "@/locales";
import { calculateReachAmount } from "@/screens/campaigns/utils";
import { CampaignWizardFieldValues } from "@/screens/campaigns/wizards";
import {
  useConfirmCardPayment,
  useCreatePaymentIntent,
  usePaymentMethods,
  useVoidInvoice,
} from "@/services/hooks/billing";
import {
  asSequenceRequest,
  asTypeaheadRequest,
  useCreateCampaign,
  useDeleteCampaign,
  useValidateCampaign,
} from "@/services/hooks/campaigns";
import { CampaignRequest } from "@/services/openapi";

export const useHandleSubmitPayment = () => {
  const [, setCampaignId] = useQueryParam("ref", NumberParam);

  const { data: wizardData, next } =
    useWizardContext<CampaignWizardFieldValues>();

  const { mutateAsync: createCampaign } = useCreateCampaign();
  const { mutateAsync: deleteCampaign } = useDeleteCampaign();
  const { mutateAsync: validateCampaign } = useValidateCampaign();

  const { data: defaultPaymentMethodStripeId } = usePaymentMethods({
    enableSkeletonData: true,
    select: (data) => data.defaultPaymentMethodStripeId,
  });

  const { mutateAsync: createPaymentIntent } = useCreatePaymentIntent();
  const { mutateAsync: confirmCardPayment } = useConfirmCardPayment();
  const { mutateAsync: voidInvoice } = useVoidInvoice();

  const handleSubmit = useCallback(
    async ({
      reachAmount,
      promotionCode,
    }: {
      reachAmount: number;
      promotionCode?: Nullish<string>;
    }) => {
      if (isNil(defaultPaymentMethodStripeId)) {
        ToasterManager.info({
          title: tBilling("ERROR.NO_PAYMENT_METHOD_TITLE"),
          message: tBilling("ERROR.NO_PAYMENT_METHOD_MESSAGE"),
        });

        return;
      }

      const campaignRequest: CampaignRequest = {
        categories: [],
        type: wizardData.type,
        keywords: wizardData.keywords,
        regions: asTypeaheadRequest(wizardData.regions),
        industries: asTypeaheadRequest(wizardData.industries),
        occupations: asTypeaheadRequest(wizardData.occupations),
        companies: asTypeaheadRequest(wizardData.companies),
        experiences: asTypeaheadRequest(wizardData.experiences),
        functions: asTypeaheadRequest(wizardData.functions),
        headcounts: asTypeaheadRequest(wizardData.headcounts),
        languages: asTypeaheadRequest(wizardData.languages),
        seniorities: asTypeaheadRequest(wizardData.seniorities),
        customAudienceList: wizardData.customAudienceList,
        sequence: asSequenceRequest(wizardData.sequence),
        sendFollowUpAfterInitialMessageReply:
          wizardData.sendFollowUpAfterInitialMessageReply,
        reachAmount: calculateReachAmount(wizardData.budget),
        recurring: wizardData.recurring,
        startDate: wizardData.startDate,
      };

      const errors = await validateCampaign({ campaign: campaignRequest });
      if (!isNil(errors) && !isEmpty(errors)) {
        ToasterManager.error({
          title: tCampaign("ERROR.VALIDATION_FAILED_TITLE"),
          message: tCampaign("ERROR.VALIDATION_FAILED_MESSAGE"),
        });

        return;
      }

      let campaignId: number | null = null;
      let invoiceId: string | null = null;

      try {
        const campaign = await createCampaign(campaignRequest);
        campaignId = campaign.id;

        const { clientSecret, invoice } = await createPaymentIntent({
          campaignId,
          reachAmount,
          promotionCode,
        });

        invoiceId = invoice.id;

        if (invoice.status === "paid") {
          setCampaignId(campaign.id);
          next();
          return;
        }

        const { error } = await confirmCardPayment([
          clientSecret!,
          {
            payment_method: defaultPaymentMethodStripeId,
          },
        ]);

        if (error) {
          ToasterManager.error({
            title: tBilling("ERROR.DECLINED_TITLE"),
            message:
              error?.type === "card_error"
                ? error.message
                : tBilling("ERROR.DECLINED_BY_ISSUER_MESSAGE"),
          });

          throw new Error();
        }

        setCampaignId(campaign.id);
        next();
      } catch (error) {
        ToasterManager.error({
          title: tCommon("ERROR.FALLBACK_TITLE"),
          message: tCommon("ERROR.FALLBACK_MESSAGE"),
        });

        if (campaignId) {
          await deleteCampaign({ id: campaignId });
        }

        if (invoiceId) {
          await voidInvoice({ stripeId: invoiceId });
        }

        throw error;
      }
    },
    [
      confirmCardPayment,
      createCampaign,
      createPaymentIntent,
      defaultPaymentMethodStripeId,
      deleteCampaign,
      next,
      setCampaignId,
      validateCampaign,
      voidInvoice,
      wizardData,
    ]
  );

  return handleSubmit;
};

const tCommon = ts("COMMON");
const tCampaign = ts("CAMPAIGN");
const tBilling = ts("BILLING");
