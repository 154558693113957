import { QueryKey, hashQueryKey } from "@tanstack/react-query";
import { atom } from "jotai";

import { MaybePromise } from "@/common";

export interface QueryError {
  queryKey: QueryKey;
  error?: Error;
  refetch: () => MaybePromise<unknown>;
}

export const globalQueryErrorsAtom = atom<QueryError[]>([]);

export const addGlobalQueryErrorAtom = atom(
  null,
  (get, set, queryError: QueryError) => {
    set(globalQueryErrorsAtom, [...get(globalQueryErrorsAtom), queryError]);
  }
);

export const removeGlobalQueryErrorAtom = atom(
  null,
  (get, set, queryKey: QueryKey) => {
    const currentGlobalQueryErrors = get(globalQueryErrorsAtom);
    const nextGlobalQueryErrors = currentGlobalQueryErrors.filter(
      (x) => hashQueryKey(queryKey) !== hashQueryKey(x.queryKey)
    );

    set(globalQueryErrorsAtom, nextGlobalQueryErrors);
  }
);
