import { useCallback } from "react";

import { Box, Stack } from "@chakra-ui/layout";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { ButtonGroup, Icon, StackProps, useClipboard } from "@chakra-ui/react";
import { isFunction } from "lodash";
import { MdContentCopy, MdEdit, MdFileCopy } from "react-icons/md";

import { MaybePromise } from "@/common";
import { Text, TextProps } from "@/components/display";
import { Button, IconButton } from "@/components/form";

export interface EditablePopoverProps extends StackProps {
  isOpen: boolean;
  defaultValue: string;
  emptyLabel?: string;
  hasArrow?: boolean;
  orientation?: "horizontal" | "vertical";
  isReadOnly?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onOpen?: () => MaybePromise<void>;
  onClose?: () => MaybePromise<void>;
  onSave?: () => MaybePromise<void>;
}

export const EditablePopover = ({
  isOpen,
  defaultValue,
  emptyLabel = "Add",
  hasArrow = false,
  orientation = "vertical",
  isReadOnly = false,
  isLoading = false,
  isDisabled = false,
  children,
  onOpen,
  onClose,
  onSave,
  ...props
}: EditablePopoverProps) => {
  const { hasCopied, onCopy } = useClipboard(defaultValue);

  const handleSave = useCallback(async () => {
    await onSave?.();
    await onClose?.();
  }, [onClose, onSave]);

  return (
    <Stack
      spacing={1}
      direction={orientation === "vertical" ? "column" : "row"}
      alignItems={orientation === "vertical" ? "flex-start" : "center"}
      {...props}
    >
      {!!defaultValue && orientation === "vertical" && (
        <ValueTextLabel children={defaultValue} />
      )}

      {!isReadOnly && (
        <Box>
          <Popover
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            placement="bottom-start"
          >
            <ButtonGroup display="flex" spacing={0.5}>
              <PopoverTrigger>
                {!!defaultValue ? (
                  <IconButton
                    icon={<Icon as={MdEdit} w={3} h={3} />}
                    size="xs"
                    variant="outline"
                    aria-label="Edit"
                    onClick={(ev) => ev.stopPropagation()}
                    onKeyDown={(ev) => ev.stopPropagation()}
                  />
                ) : (
                  <Button
                    variant="link"
                    colorScheme="blue"
                    onClick={(ev) => ev.stopPropagation()}
                    onKeyDown={(ev) => ev.stopPropagation()}
                  >
                    {emptyLabel}
                  </Button>
                )}
              </PopoverTrigger>

              {!!defaultValue && (
                <IconButton
                  icon={
                    <Icon
                      as={hasCopied ? MdFileCopy : MdContentCopy}
                      w={3}
                      h={3}
                    />
                  }
                  size="xs"
                  variant="outline"
                  aria-label="Copy"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onCopy();
                  }}
                  onKeyDown={(ev) => ev.stopPropagation()}
                />
              )}
            </ButtonGroup>

            <PopoverContent
              p={4}
              cursor="default"
              onClick={(ev) => ev.stopPropagation()}
              onKeyDown={(ev) => ev.stopPropagation()}
            >
              {hasArrow && <PopoverArrow />}

              <Box>{isFunction(children) ? children() : children}</Box>

              <Button
                loadingText="Saving"
                isDisabled={isDisabled}
                isLoading={isLoading}
                w="fit-content"
                onClick={handleSave}
              >
                Save
              </Button>
            </PopoverContent>
          </Popover>
        </Box>
      )}

      {!!defaultValue && orientation === "horizontal" && (
        <ValueTextLabel children={defaultValue} />
      )}
    </Stack>
  );
};

const ValueTextLabel = (props: TextProps) => (
  <Text
    as="span"
    display="block"
    whiteSpace="pre"
    sx={{
      textWrap: "wrap",
    }}
    {...props}
  />
);
