import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  memo,
  useCallback,
  useMemo,
} from "react";

import { isArray, isFunction, isNil } from "lodash";

import { QuerySuspenseProvider } from "./query-suspense-context";
import { SuspenseStatus } from "./types";

type Children = ReactNode | (() => ReactNode);

export interface QuerySuspenseProps {
  suspended?: boolean;
  status?: SuspenseStatus | SuspenseStatus[];
  fallback?: ReactNode | ((children: Children) => ReactNode);
  children?: Children;
}

const QuerySuspenseRaw = ({
  suspended,
  status = "idle",
  fallback = null,
  children,
}: QuerySuspenseProps) => {
  const combinedStatus = useMemo(() => {
    // Handle for backward compatibility
    if (!isNil(suspended)) {
      return suspended ? "loading" : "success";
    }

    if (!isArray(status)) {
      return status;
    }

    // Idle state defaults to loading for now
    if (status.includes("idle") || status.includes("loading")) {
      return "loading";
    }

    if (status.includes("error")) {
      return "error";
    }

    return "success";
  }, [suspended, status]);

  const isIdle = combinedStatus === "idle";
  const isLoading = combinedStatus === "loading";
  const isSuspended = combinedStatus !== "success";

  const renderChildren = useCallback(() => {
    const childrenNodes = isFunction(children) ? children() : children;

    const childrenNodesWithAttribute = Children.map(childrenNodes, (child) =>
      isNil(child)
        ? child
        : cloneElement(child as ReactElement, {
            ...(isSuspended ? { "data-suspended": isSuspended } : {}),
            ...(isSuspended ? { tabIndex: "-1" } : {}),
          })
    );

    return childrenNodesWithAttribute;
  }, [isSuspended, children]);

  const renderFallback = useCallback(
    () => (isFunction(fallback) ? fallback(children) : fallback),
    [children, fallback]
  );

  return (
    <QuerySuspenseProvider status={combinedStatus}>
      {(isIdle || isLoading) && !isNil(fallback)
        ? renderFallback()
        : renderChildren()}
    </QuerySuspenseProvider>
  );
};

export const QuerySuspense = memo(QuerySuspenseRaw);
