import { get } from "lodash";

import { EmptyObject, Path, PathValue } from "@/common";

import { EN } from "./en";

const tInternal = <TObject extends EmptyObject, TPath extends Path<TObject>>(
  object: TObject,
  path: TPath
) => {
  return get(object, path) as PathValue<TObject, TPath>;
};

export const t = <TPath extends Path<typeof EN>>(path: TPath) => {
  return tInternal(EN, path);
};

export const ts = <TPath extends Path<typeof EN>>(path: TPath) => {
  const object = t(path);

  return <TInnerPath extends Path<typeof object>>(path: TInnerPath) =>
    tInternal(object, path);
};
