import { useState } from "react";

import { Box, Wrap, WrapItem } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { QuerySuspense } from "@/components/disclosure";
import { Tag } from "@/components/display";
import {
  CheckboxBlock,
  CheckboxGroup,
  Editable,
  EditableField,
  EditablePreview,
  Option,
  useIsDirty,
} from "@/components/form";
import { asOptions, useExperiences } from "@/services/hooks/typeahead";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const ExperiencesEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<Option[]>) => {
  const [nextValue, setNextValue] = useState(defaultValue);

  const isDirty = useIsDirty(
    defaultValue?.map((x) => x.value).sort(),
    nextValue?.map((x) => x.value).sort()
  );

  const { data: experiences = [], status: experiencesStatus } = useExperiences({
    select: asOptions,
    enableSkeletonData: true,
  });

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>
        Years of experience
      </EditableFieldLabel>

      <Editable
        label="Years of experience"
        isReadOnly={isReadOnly}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>
          <Wrap spacing={1}>
            {defaultValue?.map((item) => (
              <WrapItem key={item.value}>
                <Tag>{item.label}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </EditablePreview>

        <EditableField>
          <QuerySuspense status={experiencesStatus}>
            <CheckboxGroup
              value={nextValue?.map((x) => x.value)}
              onChange={(value) => {
                const fullValue = experiences!.filter((x) =>
                  value.includes(x.value)
                );

                setNextValue(fullValue);
                onChange?.(fullValue);
              }}
            >
              {experiences?.map((experience) => (
                <CheckboxBlock
                  key={experience.value}
                  htmlValue={experience.value}
                >
                  {experience.label}
                </CheckboxBlock>
              ))}
            </CheckboxGroup>
          </QuerySuspense>
        </EditableField>
      </Editable>
    </Box>
  );
};
