export const shadows = Object.freeze({
  focus: "0 0 0 2px #2F263A",
  outlineSm: "0 0 0 2px rgba(66, 153, 225, 0.6)",
  smGray:
    "0 1px 3px 0 rgba(47, 38, 59, 0.1), 0 1px 2px 0 rgba(47, 38, 59, 0.06)",
  mdGray:
    "0 4px 6px -1px rgba(47, 38, 59, 0.1), 0 2px 4px -1px rgba(47, 38, 59, 0.06)",
  lgGray:
    "0 10px 15px -3px rgba(47, 38, 59, 0.1), 0 4px 6px -2px rgba(47, 38, 59, 0.05)",
  xlGray:
    "0 20px 25px -5px rgba(47, 38, 59, 0.1), 0 10px 10px -5px rgba(47, 38, 59, 0.04)",
  "2xlGray": "0 25px 50px -12px rgba(47, 38, 59, 0.25)",
});
