import { Fragment, useCallback, useState } from "react";

import {
  Box,
  Divider,
  Flex,
  Grid,
  LinkBox,
  LinkOverlay,
  VStack,
} from "@chakra-ui/react";
import { isNil, toNumber } from "lodash";
import qs from "qs";
import { Helmet } from "react-helmet-async";
import { FaLinkedinIn } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { QuerySuspense, useQuerySuspense } from "@/components/disclosure";
import { Heading, SectionHeading, Text } from "@/components/display";
import { Button, IconButton } from "@/components/form";
import { Avatar } from "@/components/media";
import { Link } from "@/components/navigation";
import { useContact, useLeads, useUpdateContact } from "@/services/hooks/crm";
import { ContactRequest } from "@/services/openapi";
import { Contact } from "@/services/types";
import { DateUtils } from "@/utils";

import {
  EmailPopoverField,
  NotePopoverField,
  PhonePopoverField,
} from "./form/fields";

export const ContactDetailScreen = () => {
  const { id } = useParams();

  const {
    data: contact,
    status: contactStatus,
    refetch: refetchContact,
    isSkeletonData: isSkeletonContact,
  } = useContact({ id: toNumber(id!) }, { enableSkeletonData: true });

  return (
    <>
      <Helmet>
        <title>
          {isSkeletonContact ? "Contact" : contact?.fullName} - Cosiall
        </title>
      </Helmet>

      <Box w="full" maxW="container.sm" mx="auto">
        <QuerySuspense status={contactStatus}>
          {contact && (
            <>
              <ProfileSection contact={contact} />
              <CampaignSection contact={contact} />
              <ContactSection contact={contact} onChange={refetchContact} />
              <ConversationSection contact={contact} />
            </>
          )}
        </QuerySuspense>
      </Box>
    </>
  );
};

interface ProfileSectionProps {
  contact: Contact;
}

const ProfileSection = ({ contact }: ProfileSectionProps) => {
  const { isSuspended } = useQuerySuspense();

  const summaryNoOfChars = 10;
  const [summaryNoOfLines, setSummaryNoOfLines] = useState<number | undefined>(
    4
  );

  const { fullName, headline, location } = contact;

  const profilePicture = contact.linkedInProfile.pictures.sort(
    (x, y) => y.width - x.width
  )[0];

  return (
    <>
      <Grid
        templateColumns="auto minmax(0, 1fr) auto"
        templateRows={isSuspended ? "auto" : "repeat(auto-fit, minmax(0, 1fr))"}
        templateAreas={`
        "avatar name meta"
        "avatar headline headline"
        "avatar location location"
      `}
        alignItems="center"
        rowGap={1}
        columnGap={4}
        mb={8}
      >
        <Avatar
          src={profilePicture?.url}
          name={fullName}
          size="xl"
          gridArea="avatar"
        />

        <Heading as="h1" gridArea="name" size="md">
          {fullName}
        </Heading>

        {headline && (
          <Text gridArea="headline" fontSize="lg">
            {headline}
          </Text>
        )}

        {location && (
          <Text gridArea="location" fontSize="md" color="gray.600">
            {location}
          </Text>
        )}

        <IconButton
          as="a"
          gridArea="meta"
          href={contact.linkedInProfile.flagshipProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
          size="xs"
          icon={<FaLinkedinIn />}
          aria-label="LinkedIn profile link"
        />
      </Grid>

      {!!contact?.summary?.trim().length && (
        <Box bg="gray.50" w="full" p={8} mb={8} borderRadius="md">
          <Text mb={4} noOfLines={summaryNoOfLines} whiteSpace="pre-line">
            {contact.summary}
          </Text>

          {summaryNoOfChars < contact.summary?.length && (
            <Button
              variant="link"
              colorScheme="blue"
              onClick={() => setSummaryNoOfLines((x) => (!!x ? undefined : 4))}
            >
              {!!summaryNoOfLines ? "Read more" : "Read less"}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

interface CampaignSectionProps {
  contact: Contact;
}

const CampaignSection = ({ contact }: CampaignSectionProps) => {
  const { data: { elements: leads } = {}, status: leadsStatus } = useLeads(
    { contactId: contact.id },
    { enableSkeletonData: true }
  );

  if (!leads?.length) {
    return null;
  }

  return (
    <QuerySuspense status={leadsStatus}>
      <VStack alignItems="flex-start" spacing={4} mb={8}>
        <Box w="full">
          <SectionHeading>Campaign</SectionHeading>
          <Divider />
        </Box>

        <Text as="div" display="inline-block">
          <Text as="span" hideOnSuspense>
            Belongs to
          </Text>{" "}
          {leads?.map((lead, index, arr) => (
            <Fragment key={index}>
              <Link
                to={{
                  pathname: `/campaigns/${lead.campaign.id}`,
                }}
                variant="link"
              >
                {lead.campaign.title}
              </Link>

              {index < arr.length - 1 && <Text as="span">, </Text>}
            </Fragment>
          ))}
        </Text>
      </VStack>
    </QuerySuspense>
  );
};

interface ContactSectionProps {
  contact: Contact;
  onChange: () => void;
}

const ContactSection = ({ contact, onChange }: ContactSectionProps) => {
  const { mutateAsync: updateContact } = useUpdateContact({
    onSuccess: () => onChange(),
  });

  const createPropertyChangeHandler = useCallback(
    <TKey extends keyof ContactRequest>(propertyName: TKey) =>
      async (value: ContactRequest[TKey]) => {
        await updateContact({
          id: contact.id,
          contact: { ...contact, [propertyName]: value },
        });
      },
    [contact, updateContact]
  );

  return (
    <VStack alignItems="flex-start" spacing={4} mb={8}>
      <Box w="full">
        <SectionHeading>Contact</SectionHeading>
        <Divider />
      </Box>

      <VStack spacing={4} alignItems="flex-start" w="full">
        <EmailPopoverField
          id={`email-form-field-${contact.id}`}
          defaultValue={contact.email}
          onChange={createPropertyChangeHandler("email")}
        />

        <PhonePopoverField
          id={`phone-form-field-${contact.id}`}
          defaultValue={contact.phoneNumber}
          onChange={createPropertyChangeHandler("phoneNumber")}
        />

        <NotePopoverField
          id={`note-form-field-${contact.id}`}
          defaultValue={contact.note}
          onChange={createPropertyChangeHandler("note")}
        />
      </VStack>
    </VStack>
  );
};

interface ConversationSectionProps {
  contact: Contact;
}

const ConversationSection = ({ contact }: ConversationSectionProps) => {
  const { data: { elements: leads } = {}, status: leadsStatus } = useLeads(
    { contactId: contact.id },
    { enableSkeletonData: true }
  );

  if (!leads?.length) {
    return null;
  }

  return (
    <QuerySuspense status={leadsStatus}>
      <VStack alignItems="flex-start" spacing={4} mb={8}>
        <Box w="full">
          <SectionHeading>Conversation</SectionHeading>
          <Divider />
        </Box>

        <VStack w="full" spacing={2}>
          {leads
            ?.filter((lead) => !isNil(lead.conversation))
            .map((lead) => lead.conversation!)
            .map((conversation) => (
              <LinkBox
                key={conversation.id}
                w="full"
                p={2}
                background="gray.50"
                borderRadius="md"
                transition="background var(--transition-very-fast) linear"
                _hover={{
                  background: "gray.100",
                }}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <LinkOverlay
                    as={Link}
                    to={{
                      pathname: "/conversations",
                      search: qs.stringify({
                        conversation: conversation.id,
                      }),
                    }}
                    flex={1}
                    color={conversation.isRead ? "gray.600" : "gray.800"}
                    fontWeight={conversation.isRead ? "normal" : "semibold"}
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <Text noOfLines={1}>
                      {conversation.lastMessageIsOurs && "You: "}{" "}
                      {conversation.lastMessage || "File"}
                    </Text>
                  </LinkOverlay>

                  {conversation.lastMessageDate && (
                    <Text color="gray.600" fontSize="xs">
                      {DateUtils.toRelative(conversation.lastMessageDate)}
                    </Text>
                  )}
                </Flex>
              </LinkBox>
            ))}
        </VStack>
      </VStack>
    </QuerySuspense>
  );
};
