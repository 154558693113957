import { useCallback } from "react";

import { isEmpty, isNil, pick } from "lodash";
import { FieldPath } from "react-hook-form";

import { useWizardContext } from "@/components/disclosure";
import { CampaignWizardFieldValues } from "@/screens/campaigns/wizards";
import {
  asPartialCampaignRequest,
  useValidateCampaign,
} from "@/services/hooks/campaigns";
import { ClientError } from "@/services/types";

export interface UseHandleSubmitProps<
  T extends Partial<CampaignWizardFieldValues>
> {
  onError: (field: FieldPath<T>, error: ClientError) => void;
}

export const useHandleSubmit = <T extends Partial<CampaignWizardFieldValues>>({
  onError,
}: UseHandleSubmitProps<T>) => {
  const { next } = useWizardContext<CampaignWizardFieldValues>();

  const { mutateAsync: validateCampaign } = useValidateCampaign();

  const handleSubmit = useCallback(
    async (data: Partial<T>) => {
      const errors = await validateCampaign({
        campaign: pick(asPartialCampaignRequest(data), Object.keys(data)),
      });

      if (isNil(errors) || isEmpty(errors)) {
        next();
      } else {
        errors.forEach((x) => onError(x.name as FieldPath<T>, x.error));
      }
    },
    [next, validateCampaign, onError]
  );

  return handleSubmit;
};
