import { memo, useCallback, useMemo, useState } from "react";

import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Icon,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  MdArrowForwardIos,
  MdCircle,
  MdDone,
  MdOutlineMailOutline,
  MdOutlineMarkEmailRead,
  MdOutlineTag,
} from "react-icons/md";

import { MaybePromise } from "@/common";
import { QuerySuspense } from "@/components/disclosure";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
} from "@/components/disclosure";
import {
  Badge,
  Heading,
  SectionHeading,
  Tag,
  Text,
} from "@/components/display";
import {
  Button,
  Option,
  TagCreatableSelect,
  TagEditable,
} from "@/components/form";
import { Link } from "@/components/navigation";
import { ContactCard } from "@/partials/contacts";
import { ColorPickerModal } from "@/partials/shared";
import {
  EmailPopoverField,
  NotePopoverField,
  PhonePopoverField,
} from "@/screens/contacts/form/fields";
import { asCampaignViewModel, useCampaign } from "@/services/hooks/campaigns";
import {
  useContact,
  useCreateTag,
  useLinkTag,
  useTags,
  useUnlinkTag,
  useUpdateContact,
} from "@/services/hooks/crm";
import { ContactRequest } from "@/services/openapi";
import { ContactTag, Conversation } from "@/services/types";
import { ArrayUtils, StringUtils, createId } from "@/utils";

export interface ConversationDetailDrawerProps {
  conversation: Conversation;
  isOpen: boolean;
  onClose: () => void;
}

const ConversationDetailDrawerRaw = ({
  conversation,
  isOpen,
  onClose,
}: ConversationDetailDrawerProps) => {
  const { data: campaign, status: campaignStatus } = useCampaign(
    {
      id: conversation.campaignId,
    },
    { select: asCampaignViewModel, enableSkeletonData: true }
  );

  const {
    data: contact,
    status: contactStatus,
    setInterimData: setInterimContact,
  } = useContact(
    {
      id: conversation.recipient.id,
      fromLinkedInId: true,
    },
    { enableSkeletonData: true }
  );

  const { mutateAsync: updateContact } = useUpdateContact({
    onMutate: ({ contact: { email, phoneNumber, note } }) => {
      setInterimContact((draft) => {
        if (!draft) return;

        draft.email = email;
        draft.phoneNumber = phoneNumber;
        draft.note = note;
      });
    },
  });

  const { data: tags, refetch: refetchTags } = useTags(undefined, {
    select: (x) => x.elements,
  });

  const { mutateAsync: linkTag } = useLinkTag({
    onMutate: (data) => {
      return setInterimContact((draft) => {
        const tag = tags?.find((x) => x.id === data.tag.id);
        if (tag) {
          draft?.tags.push(tag);
          draft?.tags.sort((x, y) => x.id - y.id);
        }
      });
    },
  });

  const { mutateAsync: unlinkTag } = useUnlinkTag({
    onMutate: ({ tag }) => {
      return setInterimContact((draft) => {
        ArrayUtils.spliceWhere(draft?.tags ?? [], (x) => x.id === tag.id);
      });
    },
  });

  const createPropertyChangeHandler = useCallback(
    <TKey extends keyof ContactRequest>(propertyName: TKey) =>
      async (value: ContactRequest[TKey]) => {
        await updateContact({
          id: contact!.id,
          contact: { ...contact, [propertyName]: value },
        });
      },
    [contact, updateContact]
  );

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader>
          <Heading size="sm">Conversation info</Heading>
        </DrawerHeader>

        <DrawerBody>
          <VStack alignItems="stretch" spacing={4}>
            <QuerySuspense status={contactStatus}>
              {contact && (
                <>
                  <Box>
                    <SectionHeading>Recipient</SectionHeading>
                    <Divider mb={2} />

                    <ContactCard
                      contact={contact}
                      px={0}
                      py={1}
                      border="none"
                    />

                    <Wrap spacing={1} mt={2}>
                      <WrapItem>
                        <TagEditableButton
                          ignoreTags={contact.tags.map((x) => x.id)}
                          onCreate={async () => {
                            // We refetch here so we can immediately show the added tag after the color picker modal closes
                            await refetchTags();
                          }}
                          onSelect={async (tag) => {
                            await linkTag({
                              contact: { id: contact.id },
                              tag: { id: tag.id },
                            });
                          }}
                        />
                      </WrapItem>

                      {contact.tags.map((tag) => (
                        <WrapItem key={tag.id}>
                          <Tag>
                            <TagLeftIcon
                              as={MdCircle}
                              color={tag.color}
                              w={2}
                              h={2}
                            />

                            <TagLabel>{tag.label}</TagLabel>

                            <TagCloseButton
                              onClick={async () => {
                                await unlinkTag({
                                  contact: { id: contact.id },
                                  tag: { id: tag.id },
                                });
                              }}
                            />
                          </Tag>
                        </WrapItem>
                      ))}
                    </Wrap>

                    <Button
                      as={Link}
                      variant="link"
                      colorScheme="blue"
                      to={`/contacts/${contact?.id}`}
                      rightIcon={<Icon as={MdArrowForwardIos} />}
                      mt={2}
                    >
                      Go to contact
                    </Button>
                  </Box>

                  <Box>
                    <SectionHeading>Contact</SectionHeading>
                    <Divider mb={2} />

                    <Flex
                      w="full"
                      alignItems="normal"
                      flexWrap="wrap"
                      gap={1}
                      py={1}
                      mt={2}
                      overflowX="auto"
                    >
                      <PopoverFieldContainer>
                        <EmailPopoverField
                          defaultValue={contact.email}
                          orientation="horizontal"
                          hidePreviewLabel
                          onChange={createPropertyChangeHandler("email")}
                        />
                      </PopoverFieldContainer>

                      <PopoverFieldContainer>
                        <PhonePopoverField
                          defaultValue={contact.phoneNumber}
                          orientation="horizontal"
                          hidePreviewLabel
                          onChange={createPropertyChangeHandler("phoneNumber")}
                        />
                      </PopoverFieldContainer>

                      <PopoverFieldContainer>
                        <NotePopoverField
                          defaultValue={contact.note}
                          orientation="horizontal"
                          hidePreviewLabel
                          onChange={createPropertyChangeHandler("note")}
                        />
                      </PopoverFieldContainer>
                    </Flex>
                  </Box>
                </>
              )}
            </QuerySuspense>

            <Box>
              <SectionHeading>Campaign</SectionHeading>
              <Divider mb={2} />

              <QuerySuspense status={campaignStatus}>
                <VStack spacing={1} alignItems="flex-start">
                  <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text as="span" fontWeight="semibold">
                      {campaign?.title}
                    </Text>

                    <Flex gap={1} ml={1}>
                      {!!campaign?.group && (
                        <Badge hideOnSuspense colorScheme="purple">
                          {campaign?.group?.name}
                        </Badge>
                      )}

                      <Badge
                        hideOnSuspense
                        colorScheme={
                          campaign?.status === "active" ? "green" : "gray"
                        }
                      >
                        {campaign?.status}
                      </Badge>
                    </Flex>
                  </Flex>

                  {campaign?.description && (
                    <Text noOfLines={3}>{campaign.description}</Text>
                  )}
                </VStack>

                <Button
                  as={Link}
                  variant="link"
                  colorScheme="blue"
                  to={`/campaigns/${campaign?.id}`}
                  rightIcon={<Icon as={MdArrowForwardIos} />}
                  mt={2}
                >
                  Go to campaign
                </Button>
              </QuerySuspense>
            </Box>

            <Box>
              <SectionHeading>Sequence</SectionHeading>
              <Divider mb={2} />

              <VStack alignItems="stretch" gap={2}>
                {campaign?.sequence.map((message, index) => {
                  const isMessageSent =
                    index <= conversation.numberOfSentFollowUps;

                  return (
                    <Flex
                      key={index}
                      alignItems="center"
                      justifyContent="space-between"
                      color={isMessageSent ? "green.600" : "gray.800"}
                    >
                      <Flex gap={2} alignItems="center">
                        <Icon
                          as={
                            isMessageSent
                              ? MdOutlineMarkEmailRead
                              : MdOutlineMailOutline
                          }
                        />

                        <Text
                          as="span"
                          textDecoration={
                            !isMessageSent && !conversation.pendingFollowUp
                              ? "line-through"
                              : "none"
                          }
                        >
                          {index === 0
                            ? "Connection message"
                            : `${message.offset} ${StringUtils.pluralize(
                                message.offset!,
                                "day",
                                "days"
                              )} after previous message`}
                        </Text>
                      </Flex>

                      {isMessageSent && <Icon as={MdDone} />}
                    </Flex>
                  );
                })}
              </VStack>
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const PopoverFieldContainer = (props: FlexProps) => {
  return (
    <Flex
      p={1}
      h={8}
      background="gray.100"
      border="1px solid"
      borderColor="transparent"
      borderRadius="md"
      {...props}
    />
  );
};

interface TagEditableButtonProps {
  ignoreTags?: number[];
  onSelect?: (tag: ContactTag) => MaybePromise<void>;
  onCreate?: (tag: ContactTag) => MaybePromise<void>;
}

// TODO: Extract component?
const TagEditableButton = ({
  ignoreTags = [],
  onSelect,
  onCreate,
}: TagEditableButtonProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tagToCreate, setTagToCreate] = useState<string | null>(null);

  const {
    data: tags = [],
    isLoading: isLoadingTags,
    setInterimData: setInterimTags,
  } = useTags(undefined, {
    select: (x) => x.elements,
  });

  const { mutateAsync: createTag } = useCreateTag({
    onMutate: ({ label, color }) => {
      return setInterimTags((x) => {
        x?.elements.push({ id: createId(), label, color });
      });
    },
  });

  const options = useMemo<Option[]>(
    () =>
      tags
        .filter((x) => !ignoreTags.includes(x.id))
        .map((x) => ({
          value: x.id.toString(),
          label: x.label,
          icon: <Icon as={MdCircle} w={2} h={2} color={x.color} />,
        })),
    [ignoreTags, tags]
  );

  return (
    <Box w="fit-content">
      <ColorPickerModal
        title={
          <>
            Pick a color for <b>#{tagToCreate}</b>
          </>
        }
        isOpen={!!tagToCreate}
        onPick={async (color) => {
          const tag = await createTag({ label: tagToCreate!, color });

          await onCreate?.(tag);
          await onSelect?.(tag);
        }}
        onClose={() => {
          setTagToCreate(null);
        }}
      />

      <TagEditable
        label="Add tag"
        icon={MdOutlineTag}
        isEditing={isEditing}
        isLoading={isLoadingTags}
        onEdit={() => {
          setIsEditing(true);
        }}
        onDone={() => {
          setIsEditing(false);
        }}
      >
        <TagCreatableSelect
          options={options}
          onChange={(option) => {
            const tag = tags.find((x) => x.id.toString() == option.value);
            if (!!tag) {
              onSelect?.(tag);
            }
          }}
          onCreateOption={(value) => {
            setTagToCreate(value);
          }}
        />
      </TagEditable>
    </Box>
  );
};

export const ConversationDetailDrawer = memo(ConversationDetailDrawerRaw);
