import { useState } from "react";

import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { isNil } from "lodash";

import { Modal, ModalProps } from "@/components/disclosure";
import { ResizeTransition } from "@/components/misc";
import { withReset } from "@/hocs";
import { RichTextValue } from "@/types";

import { Action, ChooseStep, CreateStep, PickStep } from "./steps";

export interface AddSequenceStepModalProps
  extends Omit<ModalProps, "children"> {
  onCreate: (value: RichTextValue) => void;
}

const AddSequenceStepModalRaw = ({
  onCreate,
  onClose,
  ...props
}: AddSequenceStepModalProps) => {
  const [action, setAction] = useState<Action>("start");

  const [template, setTemplate] = useState<RichTextValue>();

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size={action === "create" ? "2xl" : "md"}
      onClose={onClose}
      {...props}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Add sequence message</ModalHeader>
        <ModalCloseButton />

        <ResizeTransition animate="none">
          {action === "start" && (
            <ChooseStep onChoose={(action) => setAction(action)} />
          )}

          {action === "create" && (
            <CreateStep
              defaultValue={template}
              onCreate={(value) => {
                onCreate(value);
                onClose();
              }}
              onBack={() => {
                setAction(isNil(template) ? "start" : "pick");
                setTemplate(undefined);
              }}
            />
          )}

          {action === "pick" && (
            <PickStep
              onPick={(value) => {
                setTemplate(value);
                setAction("create");
              }}
              onBack={() => {
                setTemplate(undefined);
                setAction("start");
              }}
            />
          )}
        </ResizeTransition>
      </ModalContent>
    </Modal>
  );
};

export const AddSequenceStepModal = withReset(
  AddSequenceStepModalRaw,
  "isOpen"
);
