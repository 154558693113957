import { useCallback, useState } from "react";

import {
  Box,
  Container,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  VStack,
} from "@chakra-ui/react";
import produce from "immer";
import { isNil, toNumber } from "lodash";
import { Helmet } from "react-helmet-async";
import { MdOutlineArrowOutward, MdSwapHoriz } from "react-icons/md";
import { useParams } from "react-router-dom";

import {
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@/components/disclosure";
import { Badge, Text } from "@/components/display";
import { FullPageSpinner } from "@/components/feedback";
import { Button, Option, Switch } from "@/components/form";
import { useBillingPortal } from "@/services/hooks/billing";
import {
  asCampaignRequest,
  asCampaignViewModel,
  asTypeaheadRequest,
  useCampaign,
  usePotentialReach,
  useUpdateCampaign,
} from "@/services/hooks/campaigns";
import { Campaign, MessageTemplate, Typeahead } from "@/services/types";
import { DateUtils, NumberUtils } from "@/utils";

import {
  CategoriesEditableField,
  CompaniesEditableField,
  CustomAudienceEditableField,
  DescriptionEditableField,
  ExperiencesEditableField,
  FunctionsEditableField,
  GroupEditableField,
  HeadcountsEditableField,
  IndustriesEditableField,
  KeywordsEditableField,
  LanguagesEditableField,
  OccupationsEditableField,
  RegionsEditableField,
  SenioritiesEditableField,
  SequenceEditableField,
  TitleEditableField,
} from "./forms/fields";

export const CampaignDetailScreen = () => {
  const { id } = useParams();

  const {
    data: campaign,
    refetch: refetchCampaign,
    isLoading: isLoadingCampaign,
  } = useCampaign(
    { id: toNumber(id) },
    {
      select: asCampaignViewModel,
    }
  );

  if (isLoadingCampaign) {
    return <FullPageSpinner />;
  }

  if (isNil(campaign)) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{campaign.title} - Cosiall</title>
      </Helmet>

      <Container maxW="container.sm" p={0}>
        <Flex alignItems="center" justifyContent="space-between" mb={8}>
          <Heading as="h1" size="lg">
            {campaign.title}
          </Heading>

          <Flex gap={1} ml={1}>
            {!!campaign?.group && (
              <Badge colorScheme="purple">{campaign?.group?.name}</Badge>
            )}

            <Badge
              colorScheme={campaign?.status === "active" ? "green" : "gray"}
            >
              {campaign?.status}
            </Badge>
          </Flex>
        </Flex>

        <Tabs isFitted>
          <TabList>
            <Tab>General</Tab>
            <Tab>Audience</Tab>
            <Tab>Sequence</Tab>
            <Tab>Schedule</Tab>
            <Tab>Billing</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <GeneralTab campaign={campaign} onChange={refetchCampaign} />
            </TabPanel>

            <TabPanel>
              <AudienceTab campaign={campaign} onChange={refetchCampaign} />
            </TabPanel>

            <TabPanel>
              <SequenceTab campaign={campaign} onChange={refetchCampaign} />
            </TabPanel>

            <TabPanel>
              <ScheduleTab campaign={campaign} onChange={refetchCampaign} />
            </TabPanel>

            <TabPanel>
              <BillingTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
};

interface TabProps {
  campaign: Campaign;
  onChange: () => void;
}

const GeneralTab = ({ campaign, onChange }: TabProps) => {
  const { mutateAsync: updateCampaign } = useUpdateCampaign({
    onSuccess: () => onChange(),
  });

  const createPropertyChangeHandler = useCallback(
    <TProperty extends keyof Campaign>(propertyName: TProperty) =>
      async (value: Campaign[TProperty]) => {
        await updateCampaign({
          id: campaign.id,
          campaign: asCampaignRequest({ ...campaign, [propertyName]: value }),
        });
      },
    [campaign, updateCampaign]
  );

  return (
    <Box>
      <VStack spacing={4} alignItems="flex-start" w="full">
        <Box w="full">
          <TitleEditableField
            defaultValue={campaign.title}
            isReadOnly={isReadOnly(campaign)}
            onSave={(value) => createPropertyChangeHandler("title")(value!)}
          />
        </Box>

        <Box w="full">
          <DescriptionEditableField
            defaultValue={campaign.description}
            onSave={createPropertyChangeHandler("description")}
          />
        </Box>

        <Box w="full">
          <CategoriesEditableField
            defaultValue={campaign.categories?.map((x) => ({
              value: x.id.toString(),
              label: x.label,
            }))}
            onSave={async (value) => {
              await createPropertyChangeHandler("categories")(
                value?.map((x) => ({
                  id: toNumber(x.value),
                  label: x.label,
                }))
              );
            }}
          />
        </Box>

        <Box w="full">
          <GroupEditableField
            defaultValue={
              !!campaign.group
                ? {
                    value: campaign.group.id.toString(),
                    label: campaign.group.name,
                  }
                : null
            }
            onSave={async (value) => {
              await createPropertyChangeHandler("group")(
                !!value
                  ? {
                      id: toNumber(value.value),
                      name: value.label,
                    }
                  : null
              );
            }}
          />
        </Box>
      </VStack>
    </Box>
  );
};

const AudienceTab = ({ campaign, onChange }: TabProps) => {
  const [mode, setMode] = useState<typeof campaign.type>(campaign.type);

  const { mutateAsync: updateCampaign, isLoading: isUpdating } =
    useUpdateCampaign({
      onSuccess: () => onChange(),
    });

  const createPropertyChangeHandler = useCallback(
    <TKey extends keyof Campaign>(propertyName: TKey) =>
      async (value: Campaign[TKey]) => {
        await updateCampaign({
          id: campaign.id,
          campaign: asCampaignRequest({ ...campaign, [propertyName]: value }),
        });
      },
    [campaign, updateCampaign]
  );

  const asOptions = (typeahead?: Typeahead[] | null): Option[] | undefined =>
    typeahead?.map((x) => ({
      value: x.id.toString(),
      label: x.label,
      image: x.image,
    }));

  const asTypeahead = (option?: Option[] | null): Typeahead[] | undefined =>
    option?.map((x) => ({
      id: toNumber(x.value),
      label: x.label,
      image: x.image,
    }));

  if (mode === "custom") {
    return (
      <Box>
        <VStack spacing={4} alignItems="flex-start">
          <CustomAudienceEditableField
            defaultValue={campaign.customAudienceList}
            isReadOnly={isReadOnly(campaign)}
            onSave={async (value) => {
              await createPropertyChangeHandler("customAudienceList")(value);
            }}
          />

          {!isReadOnly(campaign) && (
            <>
              <Divider />

              <Button
                variant="link"
                color="blue.500"
                leftIcon={<Icon as={MdSwapHoriz} />}
                isLoading={isUpdating}
                loadingText="Switch to default audience"
                onClick={async () => {
                  await createPropertyChangeHandler("type")("default");
                  setMode("default");
                }}
              >
                Switch to default audience
              </Button>
            </>
          )}
        </VStack>
      </Box>
    );
  }

  return (
    <Box>
      <VStack spacing={4} alignItems="flex-start">
        <RegionsEditableField
          defaultValue={asOptions(campaign.regions)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("regions")(asTypeahead(value));
          }}
        />

        <IndustriesEditableField
          defaultValue={asOptions(campaign.industries)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("industries")(asTypeahead(value));
          }}
        />

        <LanguagesEditableField
          defaultValue={asOptions(campaign.languages)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("languages")(asTypeahead(value));
          }}
        />

        <KeywordsEditableField
          defaultValue={campaign.keywords}
          isReadOnly={isReadOnly(campaign)}
          onSave={createPropertyChangeHandler("keywords")}
        />

        <OccupationsEditableField
          defaultValue={asOptions(campaign.occupations)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("occupations")(
              asTypeahead(value)
            );
          }}
        />

        <FunctionsEditableField
          defaultValue={asOptions(campaign.functions)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("functions")(asTypeahead(value));
          }}
        />

        <SenioritiesEditableField
          defaultValue={asOptions(campaign.seniorities)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("seniorities")(
              asTypeahead(value)
            );
          }}
        />

        <ExperiencesEditableField
          defaultValue={asOptions(campaign.experiences)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("experiences")(
              asTypeahead(value)
            );
          }}
        />

        <CompaniesEditableField
          defaultValue={asOptions(campaign.companies)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("companies")(asTypeahead(value));
          }}
        />

        <HeadcountsEditableField
          defaultValue={asOptions(campaign.headcounts)}
          isReadOnly={isReadOnly(campaign)}
          onSave={async (value) => {
            await createPropertyChangeHandler("headcounts")(asTypeahead(value));
          }}
        />

        {!isReadOnly(campaign) && (
          <>
            <Divider />

            <Button
              variant="link"
              color="blue.500"
              leftIcon={<Icon as={MdSwapHoriz} />}
              isLoading={isUpdating}
              loadingText="Switch to custom audience"
              onClick={async () => {
                await createPropertyChangeHandler("type")("custom");
                setMode("custom");
              }}
            >
              Switch to custom audience
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};

const SequenceTab = ({ campaign, onChange }: TabProps) => {
  const { mutateAsync: updateCampaign } = useUpdateCampaign({
    onSuccess: () => onChange(),
  });

  const handleSequenceChange = useCallback(
    async (sequence: MessageTemplate[]) => {
      const nextCampaign = produce<Campaign>(campaign, (draft) => {
        draft.sequence = sequence;
      });

      await updateCampaign({
        id: campaign.id,
        campaign: asCampaignRequest(nextCampaign),
      });
    },
    [campaign, updateCampaign]
  );

  const handleFollowUpAfterReplyToggle = useCallback(
    async (checked: boolean) => {
      const nextCampaign = produce<Campaign>(campaign, (draft) => {
        draft.sendFollowUpAfterInitialMessageReply = checked;
      });

      await updateCampaign({
        id: campaign.id,
        campaign: asCampaignRequest(nextCampaign),
      });
    },
    [campaign, updateCampaign]
  );

  // Handles legacy campaigns that don't have a connection message
  if (isNil(campaign.sequence)) {
    return null;
  }

  return (
    <Box>
      <VStack as="form" spacing={4} alignItems="flex-start">
        <FormControl>
          <SequenceEditableField
            defaultValue={campaign.sequence}
            isReadOnly={isReadOnly(campaign)}
            onSave={async (value) => {
              await handleSequenceChange(value!);
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            Continue sending follow-ups after the prospect has replied to your
            initial message?
          </FormLabel>

          <Switch
            isReadOnly={isReadOnly(campaign)}
            value={campaign.sendFollowUpAfterInitialMessageReply}
            onChange={handleFollowUpAfterReplyToggle}
          />

          <FormHelperText>
            If this is enabled, follow-ups will continue sending regardless of
            whether the prospect has replied to your initial message. If
            disabled - follow-ups will not be sent if the prospect has replied
            to your initial message.
          </FormHelperText>
        </FormControl>
      </VStack>
    </Box>
  );
};

const ScheduleTab = ({ campaign, onChange }: TabProps) => {
  const {
    companies,
    experiences,
    functions,
    headcounts,
    industries,
    keywords,
    languages,
    occupations,
    regions,
    seniorities,
    recurring,
  } = campaign;

  const { mutateAsync: updateCampaign } = useUpdateCampaign({
    onSuccess: () => onChange(),
  });

  const { data: potentialReach, isFetching: isFetchingPotentialReach } =
    usePotentialReach({
      key: campaign.id,
      keywords,
      companies: asTypeaheadRequest(companies),
      experiences: asTypeaheadRequest(experiences),
      functions: asTypeaheadRequest(functions),
      headcounts: asTypeaheadRequest(headcounts),
      industries: asTypeaheadRequest(industries),
      languages: asTypeaheadRequest(languages),
      occupations: asTypeaheadRequest(occupations),
      regions: asTypeaheadRequest(regions),
      seniorities: asTypeaheadRequest(seniorities),
    });

  const hasCustomAudience = campaign.type === "custom";

  return (
    <Box>
      <VStack spacing={4} alignItems="flex-start">
        <FormControl>
          <FormLabel>Target reach</FormLabel>

          {NumberUtils.format(campaign.reachAmount)}

          <FormHelperText>
            If you'd like to change your target reach, please get in touch
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Potential reach</FormLabel>
          {isFetchingPotentialReach && !hasCustomAudience ? (
            <SkeletonText noOfLines={1} w="8ch" />
          ) : (
            NumberUtils.format(
              hasCustomAudience
                ? campaign.customAudienceList?.profiles.length ?? 0
                : potentialReach?.pagination.total ?? 0
            )
          )}
        </FormControl>

        <FormControl>
          <FormLabel>Start date</FormLabel>
          <Text as="span">
            {DateUtils.toFull(campaign.startDate || campaign.createdDate)}
            {" · "}
            <Text as="span" color="gray.400">
              {DateUtils.toRelative(campaign.startDate || campaign.createdDate)}
            </Text>
          </Text>
        </FormControl>

        <FormControl>
          <FormLabel>Recurring</FormLabel>

          <Switch
            value={recurring}
            isReadOnly={isReadOnly(campaign)}
            onChange={async (value) => {
              await updateCampaign({
                id: campaign.id,
                campaign: asCampaignRequest({ ...campaign, recurring: value }),
              });
            }}
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

const BillingTab = () => {
  const { data: billingPortalUrl } = useBillingPortal();

  return (
    <FormControl>
      <FormLabel>Manage billing</FormLabel>

      <Button
        as="a"
        variant="link"
        href={billingPortalUrl}
        colorScheme="blue"
        rightIcon={<Icon as={MdOutlineArrowOutward} />}
      >
        Go to Billing Portal
      </Button>
    </FormControl>
  );
};

const isReadOnly = (campaign: Campaign) => ["ended"].includes(campaign.status);
