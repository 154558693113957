import { MultiValue, PropsValue } from "chakra-react-select";
import Fuse from "fuse.js";
import { isArray, isEmpty } from "lodash";

import { Option } from "./types";

export const isMultiValue = <TOption extends Option>(
  value: PropsValue<TOption>
): value is MultiValue<TOption> => isArray(value);

export const fuzzyFilterOption = <TOption extends Option>(
  option: TOption,
  searchQuery: string
) => {
  if (isEmpty(searchQuery)) {
    return true;
  }

  const fuse = new Fuse([option], {
    keys: ["label"],
  });

  return !!fuse.search(searchQuery).map((x) => x.item).length;
};
