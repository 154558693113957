import { isEmpty, isObject, isString } from "lodash";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useReactRouterNavigate,
} from "react-router-dom";

import { useCallbackRef } from "@/hooks";

export const useNavigate = () => {
  const reactRouterNavigate = useReactRouterNavigate();
  const location = useLocation();

  const navigate = useCallbackRef(
    (
      to: To,
      {
        state,
        refererDisplayName,
        noReferer = false,
        ...options
      }: NavigateOptions & {
        refererDisplayName?: string;
        noReferer?: boolean;
      } = {}
    ) => {
      const fallbackRefererDisplayName = document.title.split("-")?.[0].trim();

      const willChangePathname =
        (isString(to) && to !== location.pathname) ||
        (isObject(to) &&
          !isEmpty(to.pathname) &&
          to.pathname !== location.pathname);

      if (!noReferer && willChangePathname && !options.replace) {
        return reactRouterNavigate(to, {
          state: {
            ...location.state,
            refererUri: location.pathname + location.search,
            refererDisplayName:
              refererDisplayName || fallbackRefererDisplayName,
            ...state,
          },
          ...options,
        });
      }

      return reactRouterNavigate(to, {
        state: location.state,
        ...options,
      });
    }
  );

  return navigate;
};
