import { useCallback, useMemo, useRef, useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  BoxProps,
  HStack,
  HeadingProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { Helmet } from "react-helmet-async";

import { Heading, Text } from "@/components/display";
import { FullPageSpinner } from "@/components/feedback";
import { Button } from "@/components/form";
import { Avatar } from "@/components/media";
import { Link } from "@/components/navigation";
import { useAuth } from "@/contexts";
import { useLocalStorage } from "@/hooks";
import { AuthService, NotificationService } from "@/services";
import { useBillingPortal } from "@/services/hooks/billing";

type NotificationPermission =
  | "default"
  | "enabled"
  | "disabled"
  | "unsupported";

export const AccountScreen = () => {
  return (
    <>
      <Helmet>
        <title>My Account - Cosiall</title>
      </Helmet>

      <Box maxW="container.sm" mx="auto">
        <VStack w="full" spacing={4} alignItems="flex-start" mb={8}>
          <BasicInfoSection />
          <ContactInfoSection />
          <BillingSection />
          <DeviceSection />
          <AdvancedSection />
        </VStack>

        <Text color="gray.600" fontSize="sm" textAlign="center">
          If you'd like to update your account details, please contact our
          support team at{" "}
          <Link to="mailto:support@cosiall.com">support@cosiall.com</Link>.
        </Text>
      </Box>
    </>
  );
};

const BasicInfoSection = () => {
  const { user } = useAuth<true>();

  return (
    <SectionCard>
      <SectionCardHeading>Basic info</SectionCardHeading>

      <SectionCardList>
        <SectionCardListItem>
          <Text fontWeight="semibold">Profile picture</Text>

          <Avatar
            src={user.picture ?? undefined}
            name={(user.name || user.email) ?? undefined}
            size="sm"
            background="primary.500"
            color="primary.50"
          />
        </SectionCardListItem>

        <SectionCardListItem>
          <Text fontWeight="semibold">Name</Text>
          <Text>{user.name || "N/A"}</Text>
        </SectionCardListItem>

        <SectionCardListItem>
          <Text fontWeight="semibold">Account ID</Text>
          <Text>{user.sub}</Text>
        </SectionCardListItem>
      </SectionCardList>
    </SectionCard>
  );
};

const ContactInfoSection = () => {
  const { user } = useAuth<true>();

  return (
    <SectionCard>
      <SectionCardHeading>Contact info</SectionCardHeading>

      <SectionCardList>
        <SectionCardListItem>
          <Text fontWeight="semibold">Email address</Text>

          <HStack>
            <Text>{user.email}</Text>

            <Text
              fontSize="sm"
              fontWeight="bold"
              textTransform="uppercase"
              color={user.email_verified ? "green.400" : "red.400"}
            >
              {user.email_verified ? "Verified" : "Not verified"}
            </Text>
          </HStack>
        </SectionCardListItem>

        <SectionCardListItem>
          <Text fontWeight="semibold">Phone number</Text>

          <HStack>
            <Text>
              {isNil(user.phone_number) ? "N/A" : `+${user.phone_number}`}
            </Text>

            <Text
              fontSize="sm"
              fontWeight="bold"
              textTransform="uppercase"
              color={user.phone_number_verified ? "green.400" : "red.400"}
            >
              {!isNil(user.phone_number) && (
                <>{user.phone_number_verified ? "Verified" : "Not verified"}</>
              )}
            </Text>
          </HStack>
        </SectionCardListItem>
      </SectionCardList>
    </SectionCard>
  );
};

const BillingSection = () => {
  const { data: billingPortalUrl, isLoading: isLoadingBillingPortal } =
    useBillingPortal();

  return (
    <SectionCard>
      <SectionCardHeading>Billing</SectionCardHeading>

      <SectionCardList>
        <SectionCardListItem justifyContent="flex-start">
          <Button
            as="a"
            variant="link"
            href={billingPortalUrl}
            colorScheme="blue"
            loadingText="Manage billing"
            spinnerPlacement="end"
            isLoading={isLoadingBillingPortal}
          >
            Manage billing
          </Button>
        </SectionCardListItem>
      </SectionCardList>
    </SectionCard>
  );
};

const DeviceSection = () => {
  const [subscribeToPushNotifications] = useLocalStorage(
    "push-notifications-subscribe",
    null
  );

  const notificationPermission = useMemo<NotificationPermission>(() => {
    if (!("Notification" in window)) {
      return "unsupported";
    }

    if (!subscribeToPushNotifications) {
      return "disabled";
    }

    return Notification?.permission === "granted" ? "enabled" : "disabled";
  }, [subscribeToPushNotifications]);

  return (
    <SectionCard>
      <SectionCardHeading>Device</SectionCardHeading>

      <SectionCardList>
        <SectionCardListItem>
          <Text fontWeight="semibold">Push-notifications</Text>

          <Text
            fontSize="sm"
            fontWeight="bold"
            textTransform="uppercase"
            color={
              notificationPermission === "enabled" ? "green.400" : "red.400"
            }
          >
            {notificationPermission === "enabled" && "Enabled"}
            {notificationPermission === "disabled" && "Disabled"}
            {notificationPermission === "unsupported" && "Not supported"}
          </Text>
        </SectionCardListItem>
      </SectionCardList>
    </SectionCard>
  );
};

const AdvancedSection = () => {
  const {
    isOpen: isAlertDialogOpen,
    onOpen: openAlertDialog,
    onClose: closeAlertDialog,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);

  const [isDeletingCache, setIsDeletingCache] = useState(false);

  const handleDeleteCache = useCallback(async () => {
    try {
      setIsDeletingCache(true);

      try {
        // Need to delete the push token before the cache is cleared
        await NotificationService.deletePushToken();
      } catch {
        /* empty */
      }

      localStorage.clear();
      document.cookie =
        "cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      await AuthService.logout(location.origin);

      closeAlertDialog();
    } finally {
      setIsDeletingCache(false);
    }
  }, [closeAlertDialog]);

  if (isDeletingCache) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <SectionCard>
        <SectionCardHeading>Advanced</SectionCardHeading>

        <SectionCardList>
          <SectionCardListItem justifyContent="flex-start">
            <Box>
              <Button
                variant="outline"
                colorScheme="red"
                onClick={openAlertDialog}
              >
                Delete application cache
              </Button>
            </Box>
          </SectionCardListItem>
        </SectionCardList>
      </SectionCard>

      <AlertDialog
        isOpen={isAlertDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlertDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete application cache
            </AlertDialogHeader>

            <AlertDialogBody>
              Deleting the application cache will clear local cache items,
              including but not limited to unsent messages, draft campaigns,
              device preferences, and more. Additionally, it will cause the page
              to reload and require you to sign-in again.
              <br />
              <br />
              <b>Are you sure you want to proceed?</b>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                disabled={isDeletingCache}
                onClick={closeAlertDialog}
              >
                No, go back
              </Button>

              <Button colorScheme="red" onClick={handleDeleteCache} ml={3}>
                Yes, delete the cache
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const SectionCard = (props: BoxProps) => {
  return (
    <Box
      w="full"
      p={4}
      pb={0}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      {...props}
    />
  );
};

const SectionCardHeading = (props: HeadingProps) => {
  return <Heading as="h3" size="md" mb={2} {...props} />;
};

const SectionCardList = (props: ListProps) => {
  return <List fontSize="sm" {...props} />;
};

const SectionCardListItem = (props: ListItemProps) => {
  return (
    <ListItem
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={4}
      _notLast={{
        borderBottom: "1px solid",
        borderBottomColor: "gray.200",
      }}
      {...props}
    />
  );
};
