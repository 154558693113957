import { FormLabel, FormLabelProps } from "@chakra-ui/react";

import { Text } from "@/components/display";

export interface EditableFieldLabelProps extends FormLabelProps {
  isDirty?: boolean;
}

export const EditableFieldLabel = ({
  isDirty = false,
  children,
  ...props
}: EditableFieldLabelProps) => {
  return (
    <FormLabel {...props}>
      {children}

      <Text
        as="span"
        display="inline"
        color="yellow.500"
        visibility={isDirty ? "visible" : "hidden"}
      >
        {" "}
        ·{" "}
        <Text
          as="span"
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
        >
          You have unsaved changes
        </Text>
      </Text>
    </FormLabel>
  );
};
