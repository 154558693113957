import { createContext, useEffect, useState } from "react";

import { FullPageSpinner } from "@/components/feedback";
import { useLocalStorage } from "@/hooks";
import { useServerStorage } from "@/services/hooks/common";
import { WithChildren } from "@/ui/types";

import { useAuth } from "./auth-context";

export type ServerStateContextProps = Record<string, string>;

export const ServerStateContext = createContext<ServerStateContextProps>({});

export interface ServerStateProviderProps extends WithChildren {}

export const ServerStateProvider = ({ children }: ServerStateProviderProps) => {
  const [
    hasInitializedLocalServerStorage,
    setHasInitializedLocalServerStorage,
  ] = useState(false);

  const { user } = useAuth();

  const [, setLocalServerStorage] = useLocalStorage<Record<string, string>>(
    "server-storage",
    {}
  );

  const { data: serverStorage, status: serverStorageStatus } =
    useServerStorage();

  useEffect(() => {
    if (hasInitializedLocalServerStorage) {
      return;
    }

    if (serverStorageStatus === "loading" && !!user) {
      return;
    }

    if (serverStorageStatus === "error") {
      console.error("Could not fetch server storage.");
      return;
    }

    setHasInitializedLocalServerStorage(true);
    setLocalServerStorage(serverStorage ?? {});
  }, [
    hasInitializedLocalServerStorage,
    serverStorage,
    serverStorageStatus,
    setLocalServerStorage,
    user,
  ]);

  if (!hasInitializedLocalServerStorage) {
    return <FullPageSpinner />;
  }

  return (
    <ServerStateContext.Provider value={serverStorage ?? {}}>
      {children}
    </ServerStateContext.Provider>
  );
};
