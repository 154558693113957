import { isEmpty } from "lodash";

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const spliceWhere = <T>(
  array: T[],
  predicate: (value: T, index: number, obj: T[]) => unknown
) => {
  const index = array.findIndex(predicate);

  if (index >= 0) {
    return array.splice(index, 1);
  }

  return null;
};

const equals = <T>(array1: T[], array2: T[]) => {
  if (isEmpty(array1) || isEmpty(array2)) {
    return false;
  }

  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
};

export const ArrayUtils = Object.freeze({
  range,
  spliceWhere,
  equals,
});
