import { BoxProps, VStack } from "@chakra-ui/react";

export interface InvoicePreviewBodyProps extends BoxProps {}

export const InvoicePreviewBody = (props: InvoicePreviewBodyProps) => {
  return (
    <VStack
      w="full"
      spacing={0}
      alignItems="flex-start"
      p={{ base: 4, md: 6 }}
      pt={{ base: 0, md: 0 }}
      {...props}
    />
  );
};
