import { Select as ChakraSelect, GroupBase, Props } from "chakra-react-select";

import { useQuerySuspense } from "@/components/disclosure";
import { ControlledFieldProps, Option } from "@/components/form";
import { withController } from "@/components/form/hocs";

import { defaultProps } from "./shared";

export interface SelectProps<
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
> extends Omit<Props<TOption, IsMulti, Group>, keyof ControlledFieldProps>,
    ControlledFieldProps<
      Props<TOption>["value"],
      (value: IsMulti extends true ? TOption[] : TOption) => void
    > {
  onSearchQueryChange?: (searchQuery: string) => void;
}

export const Select = <
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  onInputChange,
  onSearchQueryChange,
  ...props
}: SelectProps<TOption, IsMulti, Group>) => {
  const { value, isMulti, onChange, ...restProps } = props;

  const { isLoading } = useQuerySuspense();

  return (
    <ChakraSelect
      {...(defaultProps as Partial<SelectProps<TOption, IsMulti, Group>>)}
      value={value}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      isLoading={isLoading}
      onChange={(option) => {
        onChange?.(option as any);
      }}
      onInputChange={(inputValue, actionMeta) => {
        const { action } = actionMeta;

        onInputChange?.(inputValue, actionMeta);

        if (action === "input-change" || action === "input-blur") {
          onSearchQueryChange?.(inputValue);
        }
      }}
      {...restProps}
    />
  );
};

export const SelectFormField = withController(Select);
