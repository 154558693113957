import fp from "@fingerprintjs/fingerprintjs";

import { RequestData, RequestParams, fetchApi } from "./openapi";

const getAll = (args: RequestParams<"/notifications", "get">) => {
  const result = fetchApi("/notifications", "get", { params: { query: args } });

  return result;
};

const markAsRead = (
  {
    notifications,
    state = "read",
  }: RequestData<"/notifications/mark", "put"> = { state: "read" }
) => {
  const result = fetchApi("/notifications/mark", "put", {
    data: { notifications, state },
  });

  return result;
};

const createPushToken = async ({
  firebaseToken,
}: Omit<
  RequestData<"/notifications/push/token", "post">,
  "deviceFingerprint"
>) => {
  const agent = await fp.load();
  const identifier = await agent.get();
  const deviceFingerprint = identifier.visitorId;

  const result = fetchApi("/notifications/push/token", "post", {
    data: { firebaseToken, deviceFingerprint },
  });

  return result;
};

const deletePushToken = async () => {
  const agent = await fp.load();
  const identifier = await agent.get();
  const deviceFingerprint = identifier.visitorId;

  const result = fetchApi("/notifications/push/token", "delete", {
    params: { query: { deviceFingerprint } },
  });

  return result;
};

export const NotificationService = Object.freeze({
  getAll,
  markAsRead,
  createPushToken,
  deletePushToken,
});
