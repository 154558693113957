import { noop } from "lodash";

import { createContext } from "@/ui/utils";

export interface DrawerContextProps {
  isOpen: boolean;
  onClose: () => void;
}

export const [DrawerProvider, useDrawerContext] =
  createContext<DrawerContextProps>({
    name: "DrawerContext",
    defaultValue: { isOpen: false, onClose: noop },
  });
