import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services/conversation-service";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof ConversationService.markImportant>[0];
type Data = AsyncReturnType<typeof ConversationService.markImportant>;
type Error = unknown;

export const useMarkImportant = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    ConversationService.markImportant,
    options
  );

  return result;
};
