import { forwardRef } from "react";

import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
} from "@chakra-ui/react";

import { ControlledFieldProps } from "@/components/form";
import { withController } from "@/components/form/hocs";

export interface SwitchProps
  extends Omit<
      ChakraSwitchProps,
      keyof ControlledFieldProps | "checked" | "isChecked" | "defaultChecked"
    >,
    ControlledFieldProps<boolean, (checked: boolean, value: string) => void> {
  htmlValue?: string;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      value,
      defaultValue,
      htmlValue,
      isReadOnly,
      onChange,
      ...props
    }: SwitchProps,
    ref
  ) => {
    return (
      <ChakraSwitch
        ref={ref}
        value={htmlValue}
        isChecked={value}
        defaultChecked={defaultValue}
        isReadOnly={isReadOnly}
        pointerEvents={isReadOnly ? "none" : undefined}
        tabIndex={isReadOnly ? -1 : undefined}
        onChange={(ev) => {
          onChange?.(ev.target.checked, ev.target.value);
        }}
        {...props}
      />
    );
  }
);

Switch.displayName = "Switch";

export const SwitchFormField = withController(Switch);
