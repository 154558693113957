import {
  BoxProps,
  ButtonGroup,
  HStack,
  Icon,
  Input,
  InputProps,
  UseNumberInputProps,
  useNumberInput,
} from "@chakra-ui/react";
import { toNumber } from "lodash";
import { MdAdd, MdRemove } from "react-icons/md";

import { IconButton, IconButtonProps } from "@/components/form";
import { withController } from "@/components/form/hocs";
import { useOmitProps, usePickProps } from "@/ui/hooks";
import { createKeys } from "@/utils";

export interface NumberInputProps
  extends UseNumberInputProps,
    TextInputProps,
    SharedProps,
    Omit<BoxProps, keyof UseNumberInputProps> {
  buttonPlacement?: "around" | "end";
}

export const NumberInput = ({
  buttonPlacement = "end",
  ...props
}: NumberInputProps) => {
  const textInputProps = usePickProps(props, textInputPropsKeys);

  const numberInputProps = usePickProps(props, numberInputPropsKeys, {
    min: 0,
    step: 1,
  });

  const sharedProps = usePickProps(props, sharedPropsKeys, { size: "md" });

  const restProps = useOmitProps(props, [
    ...numberInputPropsKeys,
    ...sharedPropsKeys,
  ]);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput(numberInputProps);

  return (
    <HStack spacing={0} {...restProps}>
      {buttonPlacement === "around" && (
        <IconButton
          aria-label="Remove"
          icon={<Icon as={MdRemove} w={4} h={4} />}
          variant="solid"
          borderRightRadius="none"
          {...sharedProps}
          {...getDecrementButtonProps()}
        />
      )}

      <Input
        borderLeftRadius={buttonPlacement === "around" ? "none" : undefined}
        borderRightRadius="none"
        sx={{
          "+ *": {
            height: "var(--input-height)",
          },
        }}
        {...sharedProps}
        {...textInputProps}
        {...getInputProps()}
      />

      <ButtonGroup spacing={0}>
        {buttonPlacement === "end" && (
          <IconButton
            aria-label="Remove"
            icon={<Icon as={MdRemove} />}
            variant="solid"
            borderRadius="none"
            {...sharedProps}
            {...getDecrementButtonProps()}
          />
        )}

        <IconButton
          aria-label="Add"
          icon={<Icon as={MdAdd} />}
          variant="solid"
          borderLeftRadius="none"
          {...sharedProps}
          {...getIncrementButtonProps()}
        />
      </ButtonGroup>
    </HStack>
  );
};
export const NumberInputFormField = withController(NumberInput, {
  baseTransform: {
    in: (x: number) => x.toString(),
    out: (x): number => toNumber(x),
  },
});

type TextInputProps = Pick<InputProps, "variant" | "isInvalid">;

const textInputPropsKeys = createKeys<TextInputProps>({
  variant: "",
  isInvalid: "",
});

type SharedProps = Pick<
  InputProps | IconButtonProps,
  "size" | "textAlign" | "isDisabled"
>;

const sharedPropsKeys = createKeys<SharedProps>({
  size: "",
  textAlign: "",
  isDisabled: "",
});

const numberInputPropsKeys = createKeys<UseNumberInputProps>({
  "aria-describedby": "",
  "aria-label": "",
  "aria-labelledby": "",
  allowMouseWheel: "",
  clampValueOnBlur: "",
  defaultValue: "",
  focusInputOnChange: "",
  format: "",
  getAriaValueText: "",
  id: "",
  inputMode: "",
  isDisabled: "",
  isInvalid: "",
  isReadOnly: "",
  isRequired: "",
  isValidCharacter: "",
  keepWithinRange: "",
  max: "",
  min: "",
  name: "",
  onBlur: "",
  onChange: "",
  onFocus: "",
  onInvalid: "",
  parse: "",
  pattern: "",
  precision: "",
  step: "",
  value: "",
});
