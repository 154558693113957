import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services/conversation-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";

type QueryArgs = Parameters<typeof ConversationService.pollConversations>[0];
type QueryFnData = AsyncReturnType<
  typeof ConversationService.pollConversations
>;
type Error = unknown;

const queryKey = (args?: QueryArgs) => ["conversations", "poll", args];

export const usePollConversations = <TData = QueryFnData>(
  args?: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConversationService.pollConversations(args),
    options
  );

  return response;
};

usePollConversations.queryKey = queryKey;
