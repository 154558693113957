import { HTMLProps, forwardRef } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

export interface ButtonBoxProps
  extends BoxProps,
    Omit<HTMLProps<HTMLButtonElement>, keyof BoxProps> {
  isDisabled?: boolean;
}

export const ButtonBox = forwardRef<HTMLButtonElement, ButtonBoxProps>(
  ({ isDisabled = false, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        as="button"
        type="button"
        disabled={isDisabled}
        borderRadius="md"
        cursor="pointer"
        transitionProperty="common"
        transitionDuration="normal"
        _focusVisible={{
          boxShadow: "outline",
          border: "none",
          outline: "none",
        }}
        _disabled={{
          cursor: "default",
        }}
        {...props}
      />
    );
  }
);

ButtonBox.displayName = "ButtonBox";
