import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services/conversation-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks/use-query";
import { gen } from "@/utils";

type QueryArgs = Parameters<typeof ConversationService.getConversation>[0];
type QueryFnData = AsyncReturnType<typeof ConversationService.getConversation>;
type Error = unknown;

const queryKey = (args: QueryArgs) => ["conversations", args.id];

export const useConversation = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConversationService.getConversation(args),
    {
      skeletonData: useConst(gen.conversation()),
      ...options,
    }
  );

  return response;
};

useConversation.queryKey = queryKey;
