import { ComponentType, FC, useRef } from "react";

import { isEmpty } from "lodash";
import { Controller, ControllerProps, RegisterOptions } from "react-hook-form";
import { Except, PartialDeep } from "type-fest";

import { ControlledFieldProps, useFormFieldControl } from "@/components/form";
import { getDisplayName } from "@/utils";

export type ControllerOptionsResolver<TProps extends ControlledFieldProps> = (
  props: TProps
) => PartialDeep<Except<ControllerProps, "render">>;

export type Transform<TProps extends ControlledFieldProps, TOut = any> = {
  in: (value: TOut, store: Map<string, TProps["value"]>) => TProps["value"];
  out: (value: TProps["value"], store: Map<string, TProps["value"]>) => TOut;
};

export interface WithControllerProps<TProps extends ControlledFieldProps> {
  baseControllerOptionsResolver?: ControllerOptionsResolver<TProps>;
  baseTransform?: Transform<TProps>;
}

export interface ControlledFieldSettings<TProps extends ControlledFieldProps> {
  controllerOptionsResolver?: ControllerOptionsResolver<TProps>;
  fieldTransform?: Transform<TProps>;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

/**
 * @param Component The component that'll be wrapped around a @see Controller
 * @param settings.baseControllerOptionsResolver is a function that gets passed in the component's props
 * and returns @see ControllerProps. This can be set both at a HOC level and/or when rendering the component.
 * If set at both, the component's resolver takes precedence over the HOC's resolver.
 * @param settings.baseTransform is an object of transformer functions. This can be set both at a HOC level
 * and/or when rendering the component. If set at both, the component's transform takes precedence over the HOC's transform.
 * @param settings.baseTransform.out takes as a parameter the value outputted by the wrapped component's
 * @see ControlledFieldProps.onChange function and gives you the opportunity the store the value in your preferred format.
 * @param settings.baseTransform.in takes the stored value as a parameter which you must transform back into the
 * original @see ControlledFieldProps.value type.
 * @returns The component wrapped around a @see Controller
 */
export const withController = <TProps extends ControlledFieldProps>(
  Component: ComponentType<TProps>,
  {
    baseControllerOptionsResolver = () => ({}),
    baseTransform = {
      in: (value) => value,
      out: (value) => value as TProps["value"],
    },
  }: WithControllerProps<TProps> = {}
) => {
  const WithController: FC<
    Except<TProps, "value"> & ControlledFieldSettings<TProps>
  > = ({
    controllerOptionsResolver = () => ({}),
    fieldTransform,
    rules,
    ...restProps
  }) => {
    const { name: formFieldControlName } = useFormFieldControl();

    const transformStore = useRef(new Map());

    const tProps = restProps as TProps;
    const controllerProps = {
      ...baseControllerOptionsResolver(tProps),
      ...controllerOptionsResolver(tProps),
    } as Partial<ControllerProps>;

    if (isEmpty(tProps.name) && isEmpty(formFieldControlName)) {
      throw Error(
        `Form field "name" must be provided either through the "name" prop or by using FormFieldControl.`
      );
    }

    return (
      <Controller
        name={(tProps.name || formFieldControlName) as string}
        defaultValue={tProps.defaultValue}
        {...controllerProps}
        rules={{
          ...(controllerProps.rules || {}),
          ...(rules || {}),
        }}
        render={({ field: { value, onChange } }) => (
          <Component
            {...tProps}
            value={
              typeof fieldTransform?.in === "function"
                ? fieldTransform.in(value, transformStore.current)
                : baseTransform.in(value, transformStore.current)
            }
            onChange={(value) => {
              const transformedValue =
                typeof fieldTransform?.out === "function"
                  ? fieldTransform.out(value, transformStore.current)
                  : baseTransform.out(value, transformStore.current);

              onChange(transformedValue);
              tProps.onChange?.(transformedValue);
            }}
          />
        )}
      />
    );
  };

  WithController.displayName = `WithController(${getDisplayName(Component)})`;

  return WithController;
};
