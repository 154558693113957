import { FormHelperText, FormLabel, VStack } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { pick } from "lodash";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

import {
  WizardStep,
  WizardStepProps,
  useWizardContext,
} from "@/components/disclosure";
import {
  Form,
  FormFieldControl,
  FormSection,
  SwitchFormField,
  useForm,
} from "@/components/form";
import { useDeepMemo } from "@/hooks";
import { SequenceMessageList } from "@/partials/campaigns";
import { SequenceStepFieldValues } from "@/screens/campaigns/wizards";
import { sequenceSchema } from "@/ui/schemas";

import { useHandleSubmit } from "./use-handle-submit";
import { WizardStepActionBar } from "./wizard-step-action-bar";
import { WizardStepAudienceBar } from "./wizard-step-audience-bar";

export interface SequenceStepProps extends Pick<WizardStepProps, "name"> {}

export const SequenceStep = ({ name }: SequenceStepProps) => {
  const { data: wizardData } = useWizardContext();

  const methods = useForm<SequenceStepFieldValues>({
    defaultValues: pick(
      wizardData,
      Object.keys(sequenceStepDefaultValues)
    ) as SequenceStepFieldValues,
    resolver: zodResolver(schema),
  });

  const { formState, watch, setValue, setError } = methods;

  const setSequence = (value: typeof sequence) => {
    setValue("sequence", value, {
      shouldValidate: true,
      shouldDirty: !!value.length,
      shouldTouch: true,
    });

    if (!value.length) {
      // @ts-ignore
      // Forcefully set field as not dirty as it doesn't work otherwise
      formState.dirtyFields.sequence = false;
    }
  };

  const stepData = watch() as SequenceStepFieldValues;
  const stepDataMemo = useDeepMemo(() => stepData, [stepData]);

  const { sequence } = stepDataMemo;

  const handleSubmit = useHandleSubmit<SequenceStepFieldValues>({
    onError: (field, error) => setError(field, error),
  });

  return (
    <WizardStep
      name={name}
      data={stepDataMemo}
      isValid={formState.isValid}
      isSubmitting={formState.isSubmitting}
    >
      <FormProvider {...methods}>
        <Form id={name} onSubmit={methods.handleSubmit(handleSubmit)}>
          <VStack spacing={4} alignItems="normal">
            <FormSection name="General" fields={["sequence"]}>
              <FormFieldControl name="sequence" isRequired>
                <FormLabel>Sequence</FormLabel>

                <SequenceMessageList value={sequence} onChange={setSequence} />
              </FormFieldControl>
            </FormSection>

            <FormSection
              name="Advanced"
              fields={["sendFollowUpAfterInitialMessageReply"]}
              isCollapsible
            >
              <FormFieldControl name="sendFollowUpAfterInitialMessageReply">
                <FormLabel>
                  Continue sending follow-ups after the prospect has replied to
                  your initial message?
                </FormLabel>

                <SwitchFormField />

                <FormHelperText>
                  If this is enabled, follow-ups will continue sending
                  regardless of whether the prospect has replied to your initial
                  message. If disabled - follow-ups will not be sent if the
                  prospect has replied to your initial message.
                </FormHelperText>
              </FormFieldControl>
            </FormSection>
          </VStack>
        </Form>
      </FormProvider>

      <WizardStepActionBar>
        <WizardStepAudienceBar />
      </WizardStepActionBar>
    </WizardStep>
  );
};

export const sequenceStepDefaultValues: SequenceStepFieldValues = {
  sequence: [],
  sendFollowUpAfterInitialMessageReply: false,
};

const schema = z.object({
  sequence: sequenceSchema,
  sendFollowUpAfterInitialMessageReply: z.boolean(),
});
