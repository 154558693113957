import { cloneDeep } from "lodash";

import { AnyObject } from "@/common";
import { useCallbackRef, useDeepEffect } from "@/hooks";
import { WithOptionalChildren } from "@/ui/types";

import { useWizardContext } from "./wizard-context";
import { WizardStepProvider } from "./wizard-step-context";

export interface WizardStepProps<T extends AnyObject = AnyObject>
  extends WithOptionalChildren {
  name: string;
  data: T;
  isValid?: boolean;
  isSubmitting?: boolean;
}

export const WizardStep = ({
  name,
  data,
  isValid = true,
  isSubmitting = false,
  children,
}: WizardStepProps) => {
  const {
    state: { onStepDataChange },
  } = useWizardContext();

  const onStepDataChangeCb = useCallbackRef(onStepDataChange);

  useDeepEffect(() => {
    onStepDataChangeCb(cloneDeep(data));
  }, [data, onStepDataChangeCb]);

  return (
    <WizardStepProvider
      value={{
        name,
        data,
        state: { isValid, isSubmitting },
      }}
    >
      {children}
    </WizardStepProvider>
  );
};
