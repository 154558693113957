export const HTTP_STATUS_CODES = Object.freeze({
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
});

export const API_ERROR_CODES = Object.freeze({
  "campaign-title-empty": "The title cannot be empty",
  "campaign-title-out-of-range":
    "The title exceeds the maximum character limit",
  "campaign-title-not-unique": "Campaign with the given title already exists",
  "campaign-connection-template-required":
    "The campaign must have a connection template",

  "template-content-empty": "The message of the template cannot be empty",
  "template-connection-content-out-of-range":
    "The message of the template exceeds the maximum character limit",

  "profile-skill-out-of-range": "The skill exceeds the maximum character limit",

  "promotion-code-unavailable": "The promotion code is not available",
});
