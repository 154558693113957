import { useEffect } from "react";

import { Box, ModalBody, ModalFooter, VStack } from "@chakra-ui/react";

import { QuerySuspense } from "@/components/disclosure";
import { Button } from "@/components/form";
import { SequenceMessagePreview } from "@/partials/campaigns";
import { selectElements } from "@/services/hooks";
import { useDeleteTemplate, useTemplates } from "@/services/hooks/campaigns";
import { RichTextValue } from "@/types";
import { ArrayUtils, RichText } from "@/utils";

export interface PickStepProps {
  onPick: (value: RichTextValue) => void;
  onBack: () => void;
}

export const PickStep = ({ onPick, onBack }: PickStepProps) => {
  const {
    data: templates = [],
    setInterimData: setInterimTemplates,
    status: templatesStatus,
  } = useTemplates({
    enableSkeletonData: true,
    select: (x) =>
      selectElements(x).map((e) => ({
        ...e,
        contentRichText: RichText.deserializeJson(e.contentJson),
      })),
  });

  const { mutateAsync: deleteTemplate } = useDeleteTemplate({
    onMutate: ({ id }) => {
      return setInterimTemplates((draft) => {
        ArrayUtils.spliceWhere(draft?.elements ?? [], (x) => x.id === id);
      });
    },
  });

  useEffect(() => {
    if (!templates.length && templatesStatus === "success") {
      onBack();
    }
  }, [templates, templatesStatus, onBack]);

  return (
    <>
      <ModalBody>
        <QuerySuspense status={templatesStatus}>
          <VStack spacing={4}>
            {templates.map((template, index) => (
              <SequenceMessagePreview
                key={index}
                value={template.contentRichText}
                onSelect={() => {
                  onPick(template.contentRichText);
                }}
                onRemove={
                  template.isDefault
                    ? undefined
                    : () => {
                        deleteTemplate({ id: template.id });
                      }
                }
              />
            ))}
          </VStack>
        </QuerySuspense>
      </ModalBody>

      <ModalFooter alignItems="center" justifyContent="space-between">
        {onBack ? (
          <Button variant="outline" onClick={onBack}>
            Back
          </Button>
        ) : (
          <Box />
        )}
      </ModalFooter>
    </>
  );
};
