import {
  CreatableSelect as ChakraSelect,
  CreatableProps,
  GroupBase,
  MultiValue,
} from "chakra-react-select";

import { MaybePromise } from "@/common";
import { useQuerySuspense } from "@/components/disclosure";
import { Text } from "@/components/display/text";
import { ControlledFieldProps } from "@/components/form";
import { withController } from "@/components/form/hocs";

import { defaultProps } from "./shared";
import { Option } from "./types";

export interface CreatableSelectProps<
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
> extends Omit<
      CreatableProps<TOption, IsMulti, Group>,
      keyof ControlledFieldProps
    >,
    ControlledFieldProps<
      CreatableProps<TOption, IsMulti, Group>["value"],
      (value: IsMulti extends true ? TOption[] : TOption) => void
    > {
  onSearchQueryChange?: (searchQuery: string) => void;
  onCreate?: (value: string) => MaybePromise<TOption>;
}

// FIX: Menu placement issue - https://github.com/JedWatson/react-select/issues/4108
export const CreatableSelect = <
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  onInputChange,
  onSearchQueryChange,
  onCreate,
  ...props
}: CreatableSelectProps<TOption, IsMulti, Group>) => {
  const { value, isMulti, onChange, ...restProps } = props;

  const { isLoading } = useQuerySuspense();

  return (
    <ChakraSelect
      {...(defaultProps as Partial<
        CreatableSelectProps<TOption, IsMulti, Group>
      >)}
      value={value}
      isMulti={isMulti}
      formatCreateLabel={(label) => label}
      closeMenuOnSelect={!isMulti}
      isLoading={isLoading}
      noOptionsMessage={({ inputValue }) => (
        <Text as="span" display="block" px={1} lineHeight="normal">
          {!!inputValue?.length ? (
            <>
              None of the options match the search term <b>{inputValue}</b>
            </>
          ) : (
            <>Start typing to add a new value</>
          )}
        </Text>
      )}
      onChange={(option) => {
        onChange?.(option as any);
      }}
      onInputChange={(inputValue, actionMeta) => {
        const { action } = actionMeta;

        onInputChange?.(inputValue, actionMeta);

        if (action === "input-change" || action === "input-blur") {
          onSearchQueryChange?.(inputValue);
        }
      }}
      onCreateOption={async (inputValue) => {
        const option = await onCreate?.(inputValue);
        if (!option) return;

        if (isMulti) {
          onChange?.([
            ...((value as MultiValue<TOption>) ?? []),
            option,
          ] as any);
        } else {
          onChange?.(option as any);
        }
      }}
      {...restProps}
    />
  );
};

export const CreatableSelectFormField = withController(CreatableSelect);
