import { GroupBase } from "chakra-react-select";

import {
  CreatableSelect,
  CreatableSelectProps,
  Option,
} from "@/components/form/select";

import { defaultTagProps } from "./shared";
import { useEditableTagContext } from "./tag-editable-context";

export interface TagCreatableSelectProps<
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
> extends CreatableSelectProps<TOption, IsMulti, Group> {}

export const TagCreatableSelect = <
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  onBlur,
  ...props
}: TagCreatableSelectProps<TOption, IsMulti, Group>) => {
  const { label, onDone } = useEditableTagContext();

  return (
    <CreatableSelect
      {...(defaultTagProps as CreatableSelectProps<TOption, IsMulti, Group>)}
      variant="filled"
      placeholder={label}
      onBlur={(ev) => {
        onDone();
        onBlur?.(ev);
      }}
      {...props}
    />
  );
};
