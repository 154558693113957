import { memo } from "react";

import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import EmojiPickerExternal, { EmojiStyle } from "emoji-picker-react";
import { MdEmojiEmotions } from "react-icons/md";

import { IconButton } from "@/components/form";

export interface EmojiPickerProps extends Omit<PopoverProps, "children"> {
  onSelect: (value: string) => void;
}

const EmojiPickerRaw = ({ onSelect, ...props }: EmojiPickerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
      {...props}
    >
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={MdEmojiEmotions} />}
          size="xs"
          variant="ghost"
          aria-label="Select emoji"
        />
      </PopoverTrigger>

      <PopoverContent>
        <Box
          __css={{
            "& > .EmojiPickerReact": {
              "--epr-picker-border-color": "transparent",
            },
          }}
        >
          <EmojiPickerExternal
            width="100%"
            emojiStyle={EmojiStyle.APPLE}
            lazyLoadEmojis={true}
            previewConfig={{
              showPreview: false,
            }}
            onEmojiClick={(value) => onSelect(value.emoji)}
          />
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export const EmojiPicker = memo(EmojiPickerRaw);
