import { useState } from "react";

import { Box, Image, TagLabel, Wrap, WrapItem } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { Tag } from "@/components/display";
import {
  CreatableSelect,
  Editable,
  EditableField,
  EditablePreview,
  Option,
  useIsDirty,
} from "@/components/form";
import { useDebouncedState } from "@/hooks";
import {
  asOptions,
  useCompanies,
  useCreateCompany,
} from "@/services/hooks/typeahead";
import { createId } from "@/utils";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const CompaniesEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<Option[]>) => {
  const [nextValue, setNextValue] = useState(defaultValue);

  const isDirty = useIsDirty(
    defaultValue?.map((x) => x.value).sort(),
    nextValue?.map((x) => x.value).sort()
  );

  const [searchQuery, debouncedSearchQuery, setSearchQuery] =
    useDebouncedState("");

  const {
    data: companies = [],
    setInterimData: setInterimCompanies,
    isLoading: isLoadingCompanies,
  } = useCompanies({ search: debouncedSearchQuery }, { select: asOptions });

  const { mutateAsync: createCompany } = useCreateCompany({
    onMutate: ({ label }) => {
      return setInterimCompanies((draft) => {
        draft?.elements.push({ id: createId(), label });
      });
    },
  });

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Company name</EditableFieldLabel>

      <Editable
        label="Company name"
        isReadOnly={isReadOnly}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>
          <Wrap spacing={1}>
            {defaultValue?.map((item) => (
              <WrapItem key={item.value}>
                <Tag>
                  {item.image && (
                    <Image
                      src={item.image}
                      objectFit="contain"
                      w={4}
                      h={4}
                      mr={2}
                      borderRadius="full"
                    />
                  )}

                  <TagLabel>{item.label}</TagLabel>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </EditablePreview>

        <EditableField>
          <CreatableSelect
            defaultValue={defaultValue}
            value={nextValue}
            options={companies}
            inputValue={searchQuery}
            placeholder="Search for companies..."
            tagVariant="outline"
            isMulti
            isLoading={isLoadingCompanies}
            onSearchQueryChange={(value) => {
              setSearchQuery(value);
            }}
            onChange={(value) => {
              setNextValue(value);
              onChange?.(value);
            }}
            onCreateOption={async (inputValue) => {
              const { id, label, image } = await createCompany({
                label: inputValue,
              });

              setNextValue((prevValue) => [
                ...(prevValue || []),
                { value: id.toString(), label, image },
              ]);

              onChange?.(nextValue);
            }}
          />
        </EditableField>
      </Editable>
    </Box>
  );
};
