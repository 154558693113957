import {
  Alert,
  AlertDescription,
  AlertIcon,
  Collapse,
  FormHelperText,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty, pick, toNumber } from "lodash";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import { FormProvider } from "react-hook-form";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";

import {
  WizardStep,
  WizardStepProps,
  useWizardContext,
} from "@/components/disclosure";
import { Text } from "@/components/display";
import {
  Form,
  FormFieldControl,
  FormSection,
  InputFormField,
  NumberInputFormField,
  SwitchFormField,
  useForm,
} from "@/components/form";
import { useDeepMemo } from "@/hooks";
import { calculateReachAmount } from "@/screens/campaigns/utils";
import {
  CampaignWizardFieldValues,
  ScheduleStepFieldValues,
} from "@/screens/campaigns/wizards";
import {
  asTypeaheadRequest,
  usePotentialReach,
} from "@/services/hooks/campaigns";
import { CurrencyUtils, NumberUtils } from "@/utils";

import { useHandleSubmit } from "./use-handle-submit";
import { WizardStepActionBar } from "./wizard-step-action-bar";
import { WizardStepAudienceBar } from "./wizard-step-audience-bar";

export interface ScheduleStepProps extends Pick<WizardStepProps, "name"> {}

export const ScheduleStep = ({ name }: ScheduleStepProps) => {
  const [campaignClientId] = useQueryParam(
    "cid",
    withDefault(StringParam, nanoid())
  );

  const { data: wizardData } = useWizardContext<CampaignWizardFieldValues>();

  const { data: potentialReach } = usePotentialReach(
    {
      key: campaignClientId,
      keywords: wizardData.keywords,
      regions: asTypeaheadRequest(wizardData.regions),
      industries: asTypeaheadRequest(wizardData.industries),
      occupations: asTypeaheadRequest(wizardData.occupations),
      languages: asTypeaheadRequest(wizardData.languages),
      functions: asTypeaheadRequest(wizardData.functions),
      companies: asTypeaheadRequest(wizardData.companies),
      experiences: asTypeaheadRequest(wizardData.experiences),
      headcounts: asTypeaheadRequest(wizardData.headcounts),
      seniorities: asTypeaheadRequest(wizardData.seniorities),
    },
    {
      enabled: !isEmpty(wizardData.regions),
      staleTime: Infinity,
      retry: false,
    }
  );

  const methods = useForm({
    defaultValues: pick(
      wizardData,
      Object.keys(scheduleStepDefaultValues)
    ) as ScheduleStepFieldValues,
    resolver: zodResolver(schema),
  });

  const { formState, watch, setError } = methods;

  const stepData = watch();
  const stepDataMemo = useDeepMemo(() => stepData, [stepData]);

  const handleSubmit = useHandleSubmit<ScheduleStepFieldValues>({
    onError: (field, error) => setError(field, error),
  });

  const targetReach = calculateReachAmount(stepDataMemo.budget!);

  return (
    <WizardStep
      name={name}
      data={stepDataMemo}
      isValid={formState.isValid}
      isSubmitting={formState.isSubmitting}
    >
      <FormProvider {...methods}>
        <Form id={name} onSubmit={methods.handleSubmit(handleSubmit)}>
          <VStack spacing={4} alignItems="normal">
            <FormSection
              name="General"
              fields={["budget", "startDate", "recurring"]}
            >
              <VStack spacing={4} alignItems="normal">
                <FormFieldControl name="budget">
                  <FormLabel>Budget</FormLabel>

                  <VStack gap={4} alignItems="normal">
                    <NumberInputFormField
                      pattern=".*"
                      allowMouseWheel
                      defaultValue={2500}
                      min={100}
                      max={20000}
                      step={50}
                      format={(value) =>
                        CurrencyUtils.formatCurrency(toNumber(value), {
                          minFractionDigits: 0,
                          maxFractionDigits: 0,
                        })
                      }
                    />
                  </VStack>

                  <FormHelperText>
                    You'll be able to reach up to{" "}
                    <Text as="span" fontWeight="semibold">
                      {NumberUtils.formatSignificantDigits(targetReach)}
                    </Text>{" "}
                    people.
                    <Collapse
                      in={
                        (potentialReach?.pagination.total ?? Infinity) <
                        targetReach
                      }
                      animateOpacity
                    >
                      {(potentialReach?.pagination.total ?? Infinity) <
                        targetReach && (
                        <Alert
                          status="warning"
                          variant="subtle"
                          mt={2}
                          borderRadius="md"
                        >
                          <AlertIcon />

                          <AlertDescription>
                            Your budget allows you to reach up to{" "}
                            <Text as="span" fontWeight="semibold">
                              {NumberUtils.formatSignificantDigits(targetReach)}
                            </Text>{" "}
                            people, however only{" "}
                            <Text as="span" fontWeight="semibold">
                              {NumberUtils.formatSignificantDigits(
                                potentialReach!.pagination.total!
                              )}
                            </Text>{" "}
                            people match your target audience. If you don't plan
                            on changing your target audience in the future, we
                            recommend lowering your budget.
                          </AlertDescription>
                        </Alert>
                      )}
                    </Collapse>
                  </FormHelperText>
                </FormFieldControl>

                <FormFieldControl name="startDate">
                  <FormLabel>Start date</FormLabel>

                  <InputFormField
                    type="date"
                    min={DateTime.now().toISODate()}
                  />

                  <FormHelperText>
                    The start date is not guaranteed as every campaign goes
                    through a manual review that usually takes up to 24 hours.
                  </FormHelperText>
                </FormFieldControl>

                <FormFieldControl name="recurring">
                  <FormLabel>Recurring</FormLabel>

                  <SwitchFormField />

                  <FormHelperText>
                    If this option is enabled, the campaign will run
                    indefinitely, resetting every 30 days. You'll be issued an
                    invoice a few days before the start of each new billing
                    cycle.
                  </FormHelperText>
                </FormFieldControl>
              </VStack>
            </FormSection>
          </VStack>
        </Form>
      </FormProvider>

      <WizardStepActionBar>
        <WizardStepAudienceBar />
      </WizardStepActionBar>
    </WizardStep>
  );
};

export const scheduleStepDefaultValues: ScheduleStepFieldValues = {
  budget: 2000,
  startDate: DateTime.now().toISODate(),
  recurring: false,
};

const schema = z.object({
  budget: z
    .number()
    .min(100, "The budget cannot be less than $100")
    .max(20000, "The budget cannot be more than $20,000"),
  startDate: z.string(),
  recurring: z.boolean(),
});
