const pricePerUnit = 0.2;

export const calculateReachAmount = (budget: number) => {
  const reachAmount = Math.ceil(budget / pricePerUnit);

  return reachAmount;
};

export const calculateBudget = (reachAmount: number) => {
  const budget = reachAmount * pricePerUnit;

  return budget;
};
