import { createContext } from "@/ui/utils";

export interface FormFieldControlContextProps {
  name?: string;
}

export const [FormFieldControlProvider, useFormFieldControl] =
  createContext<FormFieldControlContextProps>({
    name: "FormFieldControlContext",
    strict: false,
  });
