import { useState } from "react";

import { Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import {
  CreatableSelect,
  Editable,
  EditableField,
  EditablePreview,
  Option,
  useIsDirty,
} from "@/components/form";
import { useCreateGroup, useGroups } from "@/services/hooks/campaigns";
import { createId } from "@/utils";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const GroupEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<Option>) => {
  const [nextValue, setNextValue] = useState(defaultValue);

  const isDirty = useIsDirty(defaultValue?.value || "", nextValue?.value || "");

  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: groups = [],
    setInterimData: setInterimGroups,
    isLoading: isLoadingGroups,
  } = useGroups({
    select: (x) =>
      x.elements.map((y) => ({ value: y.id.toString(), label: y.name })),
  });

  const { mutateAsync: createGroup } = useCreateGroup({
    onMutate: ({ name }) => {
      return setInterimGroups((draft) => {
        draft?.elements.push({ id: createId(), name });
      });
    },
  });

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Group</EditableFieldLabel>

      <Editable
        label="Group"
        isReadOnly={isReadOnly}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>{defaultValue?.label}</EditablePreview>

        <EditableField>
          <CreatableSelect
            defaultValue={defaultValue}
            value={nextValue}
            options={groups}
            inputValue={searchQuery}
            placeholder="Search for groups..."
            tagVariant="outline"
            isClearable
            isLoading={isLoadingGroups}
            onSearchQueryChange={(value) => {
              setSearchQuery(value);
            }}
            onChange={(value) => {
              setNextValue(value);
              onChange?.(value);
            }}
            onCreateOption={async (inputValue) => {
              const { id, name } = await createGroup({
                name: inputValue,
              });

              setNextValue({ value: id.toString(), label: name });

              setTimeout(() => {
                onChange?.(nextValue);
              });
            }}
          />
        </EditableField>
      </Editable>
    </Box>
  );
};
