import { BillingService } from "@/services/billing-service";
import { useQuery } from "@/services/hooks/use-query";

const queryKey = () => ["billing", "portal"];

export const useBillingPortal = (options = {}) => {
  const result = useQuery(
    queryKey(),
    () =>
      BillingService.createBillingPortal({
        returnUrl: window.location.href,
      }),
    {
      // 5 minutes
      refetchInterval: 300000,
      promptRetryOnError: false,
      ...options,
    }
  );

  return result;
};

useBillingPortal.queryKey = queryKey;
