import {
  Textarea as ChakraTextArea,
  TextareaProps as ChakraTextAreaProps,
} from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/react";

import { ControlledFieldProps } from "@/components/form";
import { withController } from "@/components/form/hocs";

export interface TextAreaProps
  extends Omit<ChakraTextAreaProps, keyof ControlledFieldProps>,
    ControlledFieldProps<
      ChakraTextAreaProps["value"],
      (value: string) => void
    > {}

export const TextArea = forwardRef<TextAreaProps, "textarea">(
  ({ onChange, ...props }: TextAreaProps, ref) => {
    return (
      <ChakraTextArea
        ref={ref}
        resize="none"
        borderRadius="md"
        onChange={(ev) => {
          onChange?.(ev.target.value);
        }}
        {...props}
      />
    );
  }
);

TextArea.displayName = "TextArea";

export const TextAreaFormField = withController(TextArea);
