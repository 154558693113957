import { toInteger } from "lodash";

import { CampaignFilterPopoverBody } from "@/partials/shared";

import { useConversationFilterProvider } from "./conversation-filter-context";

export const CampaignTabPanel = () => {
  const { value, onChange } = useConversationFilterProvider();

  return (
    <CampaignFilterPopoverBody
      value={value.campaigns.map((x) => x.toString())}
      onChange={(x) =>
        onChange({
          ...value,
          campaigns: x.map(toInteger),
        })
      }
    />
  );
};
