import {
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps,
} from "@chakra-ui/react";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";

export const Badge = withSkeleton<ChakraBadgeProps, "span">(
  ChakraBadge,
  ({ pulse, ...props }) => (
    <Skeleton pulse={pulse} w="fit-content" h="fit-content" borderRadius="sm">
      <ChakraBadge {...props} />
    </Skeleton>
  )
);
