import { forwardRef } from "react";

import { Circle, Grid, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { Text } from "@/components/display";
import { DateUtils } from "@/utils";

export const Notification = forwardRef<any, any>(
  ({ link, message, date, state, ...props }, ref) => {
    return (
      <LinkBox
        ref={ref}
        borderRadius="md"
        transitionProperty="background-color"
        transitionDuration="ultra-fast"
        transitionTimingFunction="linear"
        _hover={{
          bgColor: "gray.50",
        }}
        {...props}
      >
        <LinkOverlay as={Link} to={link}>
          <Grid
            alignItems="baseline"
            templateAreas={`
            "text stat"
            "date date"
            `}
            gridTemplateColumns="1fr auto"
            py={3}
            px={3}
            rowGap={2}
            fontSize="sm"
          >
            <Text
              as="span"
              gridArea="text"
              w="full"
              noOfLines={3}
              color="gray.800"
            >
              {message}
            </Text>

            {state !== "read" && (
              <Circle size={2} bgColor="blue.500" gridArea="stat" ml="auto" />
            )}

            <Text as="span" gridArea="date" color="blue.600">
              {DateUtils.toRelative(date)}
            </Text>
          </Grid>
        </LinkOverlay>
      </LinkBox>
    );
  }
);

Notification.displayName = "Notification";
