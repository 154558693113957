import { useMemo } from "react";

import {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery as useBaseInfiniteQuery,
} from "@tanstack/react-query";
import produce, { Draft } from "immer";
import { useAtom } from "jotai";
import { isNil, isUndefined } from "lodash";

import { useQuerySuspense } from "@/components/disclosure";
import { useCallbackRef, useUnmount } from "@/hooks";
import { isInternalServerError, isNetworkError } from "@/http";
import {
  addGlobalQueryErrorAtom,
  removeGlobalQueryErrorAtom,
} from "@/ui/state";

import { useAuthStatus } from "./account";
import { useQueryClient } from "./use-query-client";

export type UseInfiniteQuerySimpleOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TFlatData = TData,
  TQueryData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>,
  "queryKey" | "queryFn"
> & {
  authenticated?: boolean;
  enableSkeletonData?: boolean;
  skeletonData?: InfiniteData<TQueryFnData>;
  flatSelect?: (data?: InfiniteData<TData>) => TFlatData;
  promptRetryOnError?: boolean;
  onBeforeQuery?: () => void;
};

export const useInfiniteQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TFlatData = TData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  {
    enabled = true,
    authenticated = true,
    select,
    flatSelect,
    enableSkeletonData = false,
    skeletonData,
    promptRetryOnError = true,
    onBeforeQuery,
    ...options
  }: UseInfiniteQuerySimpleOptions<
    TQueryFnData,
    TError,
    TData,
    TFlatData,
    TQueryFnData,
    TQueryKey
  > = {}
) => {
  const [, addGlobalQueryError] = useAtom(addGlobalQueryErrorAtom);
  const [, removeGlobalQueryError] = useAtom(removeGlobalQueryErrorAtom);

  const { data: isAuthenticated, isFetched: hasFetchedAuthStatus } =
    useAuthStatus({
      enabled: authenticated,
    });

  const flatSelectCb = useCallbackRef(flatSelect);

  const queryClient = useQueryClient();

  const { isSuspended = false } = useQuerySuspense();

  const userCanRunQuery = authenticated
    ? hasFetchedAuthStatus && isAuthenticated
    : true;

  const result = useBaseInfiniteQuery(
    queryKey,
    (...args) => {
      onBeforeQuery?.();
      return queryFn(...args);
    },
    {
      ...options,
      enabled:
        enabled &&
        !isSuspended &&
        !queryClient.isQueryPaused(queryKey) &&
        userCanRunQuery,
      onError: (error) => {
        if (!promptRetryOnError) {
          return;
        }

        if (isNetworkError(error) || isInternalServerError(error)) {
          addGlobalQueryError({
            queryKey,
            refetch: () => {
              removeGlobalQueryError(queryKey);
              return result.refetch();
            },
          });
        }
      },
    }
  );

  useUnmount(() => {
    removeGlobalQueryError(queryKey);
  });

  const { data, isFetched, isSuccess } = result;

  const infiniteSkeletonData = useMemo(
    () =>
      isNil(select) || isNil(skeletonData)
        ? (skeletonData as InfiniteData<TData> | undefined)
        : select(skeletonData),
    [skeletonData, select]
  );

  const flatSkeletonData = useMemo(
    () =>
      isNil(flatSelectCb) || isNil(skeletonData)
        ? (skeletonData as TFlatData | undefined)
        : flatSelectCb(infiniteSkeletonData),
    [skeletonData, infiniteSkeletonData, flatSelectCb]
  );

  const flatData = useMemo(
    () => (isNil(flatSelectCb) ? (data as TFlatData) : flatSelectCb(data)),
    [data, flatSelectCb]
  );

  const isSkeletonData = enableSkeletonData && (!isFetched || !isSuccess);

  const setInterimData = useCallbackRef(
    async (
      setter: (
        draft?: Draft<InfiniteData<TQueryFnData> | undefined>
      ) => InfiniteData<TQueryFnData> | void,
      {
        shouldInvalidate = true,
        shouldCancel = true,
        shouldPause = false,
        shouldRollback = true,
        settle: settleExternal,
        rollback: rollbackExternal,
      }: {
        shouldInvalidate?: boolean;
        shouldCancel?: boolean;
        shouldPause?: boolean;
        shouldRollback?: boolean;
        settle?: () => void;
        rollback?: (
          draft?: Draft<InfiniteData<TQueryFnData> | undefined>
        ) => InfiniteData<TQueryFnData> | void;
      } = {}
    ) => {
      if (shouldPause) {
        queryClient.pauseQuery(queryKey);
      }

      if (shouldCancel) {
        await queryClient.cancelQueries(queryKey, {
          exact: true,
        });
      }

      const snapshot = queryClient.getQueryData<InfiniteData<TData>>(queryKey);

      queryClient.setQueryData<InfiniteData<TQueryFnData>>(queryKey, (data) => {
        let setterResult: InfiniteData<TQueryFnData> | undefined;

        const draftResult = produce(data, (draft) => {
          setterResult = setter(draft) as
            | InfiniteData<TQueryFnData>
            | undefined;
        });

        // Setter's return value takes precedence over the draft value
        return isUndefined(setterResult) ? draftResult : setterResult;
      });

      const settle = () => {
        if (shouldPause) {
          queryClient.resumeQuery(queryKey);
        }

        if (shouldInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }

        if (settleExternal) {
          settleExternal();
        }
      };

      const rollback = () => {
        if (shouldRollback && !rollbackExternal) {
          queryClient.setQueryData(queryKey, snapshot);
        }

        if (rollbackExternal) {
          queryClient.setQueryData<InfiniteData<TQueryFnData>>(
            queryKey,
            (data) => {
              let rollbackResult: InfiniteData<TQueryFnData> | undefined;

              const draftResult = produce(data, (draft) => {
                rollbackResult = rollbackExternal(draft) as
                  | InfiniteData<TQueryFnData>
                  | undefined;
              });

              // Rollback's return value takes precedence over the draft value
              return isUndefined(rollbackResult) ? draftResult : rollbackResult;
            }
          );
        }
      };

      return { settle, rollback };
    }
  );

  const memoizedResult = useMemo(
    () => ({
      queryKey,
      ...result,
      isSkeletonData,
      setInterimData,
      data: isSkeletonData ? infiniteSkeletonData : data,
      flatData: isSkeletonData ? flatSkeletonData : flatData,
      // Override isLoading due to breaking change in React Query 4,
      // that causes isLoading to be true when query is in idle state
      isLoading: result.isInitialLoading,
    }),
    [
      data,
      flatData,
      flatSkeletonData,
      infiniteSkeletonData,
      isSkeletonData,
      queryKey,
      result,
      setInterimData,
    ]
  );

  return memoizedResult;
};
