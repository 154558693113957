import { Dispatch, SetStateAction, useEffect, useState } from "react";

const DEFAULT_DELAY = 500;

export const useDebouncedState = <T = any>(
  initialValue: T,
  delay = DEFAULT_DELAY
): [T, T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return [value, debouncedValue, setValue];
};
