import { memo } from "react";

import { Grid, GridProps } from "@chakra-ui/react";

export interface FilePreviewGridProps extends GridProps {}

const FilePreviewGridRaw = (props: FilePreviewGridProps) => {
  return (
    <Grid
      w="full"
      gridTemplateColumns="repeat(auto-fill, minmax(128px, 1fr))"
      gap={1}
      {...props}
    />
  );
};

export const FilePreviewGrid = memo(FilePreviewGridRaw);
