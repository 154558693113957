import { useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { isEmpty, isNil, toInteger, toString } from "lodash";
import { MdExpandMore, MdOutlineCampaign } from "react-icons/md";

import { QuerySuspense } from "@/components/disclosure";
import { ButtonProps, SearchInput } from "@/components/form";
import { useDebouncedState } from "@/hooks";
import { asCampaignViewModel, useCampaigns } from "@/services/hooks/campaigns";
import { useMediaQuery } from "@/ui/hooks";

export interface CampaignFilterPopoverProps<T extends string[]> {
  size?: ButtonProps["size"];
  value: T;
  onChange: (value: T) => void;
}

export const CampaignFilterPopover = <T extends string[]>({
  size,
  value,
  onChange,
}: CampaignFilterPopoverProps<T>) => {
  const [isMdScreen] = useMediaQuery("md");

  const { data: { elements: campaigns = [] } = {} } = useCampaigns(
    {
      search: "",
      skip: 0,
      limit: 1000,
    },
    {
      keepPreviousData: true,
      select: (x) => ({
        elements: x.elements.map(asCampaignViewModel),
        pagination: x.pagination,
      }),
    }
  );

  const triggerLabel = useMemo(() => {
    if (value.length === 0) {
      return "All Campaigns";
    }

    if (value.length === 1) {
      const selectedCampaign = campaigns.find(
        (x) => x.id === toInteger(value[0])
      );

      return selectedCampaign?.title ?? <Spinner size="sm" />;
    }

    if (value.length > 1) {
      return "Multiple Campaigns";
    }
  }, [campaigns, value]);

  return (
    <Box>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          {isMdScreen ? (
            <Button
              size={size}
              leftIcon={<Icon as={MdOutlineCampaign} />}
              rightIcon={<Icon as={MdExpandMore} />}
            >
              {isMdScreen ? triggerLabel : value.length || ""}
            </Button>
          ) : (
            <IconButton
              size={size}
              icon={<Icon as={MdOutlineCampaign} />}
              aria-label="Select campaigns"
            />
          )}
        </PopoverTrigger>

        <PopoverContent p={2} maxH={64} overflowY="auto">
          <PopoverBody p={0}>
            <CampaignFilterPopoverBody value={value} onChange={onChange} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export interface CampaignFilterPopoverBodyProps<T extends string[]> {
  value: T;
  onChange: (value: T) => void;
}

export const CampaignFilterPopoverBody = <T extends string[]>({
  value,
  onChange,
}: CampaignFilterPopoverBodyProps<T>) => {
  const searchInputRef = useRef(null);

  const [searchQuery, searchQueryDebounced, setSearchQuery] =
    useDebouncedState("");

  const [limit, setLimit] = useState(isNil(value) || isEmpty(value) ? 5 : 1000);

  const {
    data: { elements: campaigns = [], pagination: campaignPagination } = {},
    status: campaignsStatus,
    isFetching: isFetchingCampaigns,
  } = useCampaigns(
    {
      search: searchQueryDebounced,
      skip: 0,
      limit,
    },
    {
      keepPreviousData: true,
      select: (x) => ({
        elements: x.elements.map(asCampaignViewModel),
        pagination: x.pagination,
      }),
    }
  );

  return (
    <>
      <SearchInput
        ref={searchInputRef}
        size="sm"
        value={searchQuery}
        placeholder="Search..."
        mb={4}
        onChange={(value) => setSearchQuery(value)}
      />

      <QuerySuspense
        status={campaignsStatus}
        fallback={<Spinner display="block" size="sm" mx="auto" mt={1} />}
      >
        {campaignsStatus === "success" && !!campaigns.length ? (
          <Box>
            <Box px={1}>
              <CheckboxGroup value={value} onChange={onChange}>
                <VStack
                  spacing={2}
                  alignItems="flex-start"
                  // offset CheckboxGroup horizontal padding
                  ml={-1}
                >
                  {campaigns.map((campaign) => (
                    <Checkbox key={campaign.id} value={toString(campaign.id)}>
                      {campaign.title}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            </Box>

            {limit > 0 && campaigns.length < campaignPagination!.total! && (
              <Button
                variant="link"
                colorScheme="blue"
                mt={4}
                loadingText="View all"
                isLoading={isFetchingCampaigns}
                spinnerPlacement="end"
                onClick={() => setLimit(campaignPagination!.total!)}
              >
                View all
              </Button>
            )}
          </Box>
        ) : (
          <Text fontSize="sm">No campaigns match the current search term.</Text>
        )}
      </QuerySuspense>
    </>
  );
};
