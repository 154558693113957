import { z } from "zod";

import { RichTextValue } from "@/types";
import { RichText } from "@/utils";

import { idSchema } from "./common";

export const typeaheadSchema = z.object({
  id: idSchema,
  label: z.string(),
  image: z.string().optional().nullable(),
});

export const profileLinkSchema = z.string().refine(
  (value) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/([A-Za-z0-9._~\-!$&'()*+,;=:@%]|%[A-Fa-f0-9]{2})+\/?/;

    return urlRegex.test(value);
  },
  { message: "The value must be a valid profile URL" }
);

export const categorySchema = z.object({
  id: idSchema,
  label: z.string(),
});

export const groupSchema = z
  .object({
    id: idSchema,
    name: z.string(),
  })
  .optional()
  .nullable();

export const sequenceSchema = z
  .array(
    z.object({
      id: idSchema,
      content: z.array(z.unknown()),
      offset: z.number(),
    })
  )
  .min(1, "You must define at least one sequence message")
  .max(6, "You cannot define more than 6 sequence messages")
  .refine(
    (value) => {
      const initialMessage = value[0];

      if (!initialMessage) {
        return true;
      }

      const serializedValue = RichText.serializeText(
        initialMessage.content as RichTextValue
      );

      return serializedValue.length >= 1 && serializedValue.length <= 190;
    },
    {
      message: "The initial message must be between 1 and 190 characters",
    }
  )
  .refine(
    (value) => {
      const subsequentMessages = value.slice(1);

      if (!subsequentMessages.length) {
        return true;
      }

      return subsequentMessages.some((message) => {
        const serializedValue = RichText.serializeText(
          message.content as RichTextValue
        );

        return serializedValue.length >= 1 && serializedValue.length <= 2000;
      });
    },
    {
      message: "The follow-up messages must be between 1 and 2000 characters",
    }
  );
