import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, BoxProps } from "@chakra-ui/react";
import { isEmpty, isNil, isNumber } from "lodash";

import { AnyObject, MaybePromise } from "@/common";
import { useHashState } from "@/hooks";

import { Step } from "./types";
import { WizardProvider } from "./wizard-context";

export interface WizardProps<T extends AnyObject = AnyObject>
  extends Omit<BoxProps, "onChange"> {
  data: T;
  onNext?: () => MaybePromise<void>;
  onPrevious?: () => MaybePromise<void>;
  onChange?: (data: T) => MaybePromise<void>;
  onComplete?: (data: T) => MaybePromise<void>;
}

export const Wizard = <T extends AnyObject>({
  data,
  children,
  onNext,
  onPrevious,
  onComplete,
  onChange,
  ...props
}: WizardProps<T>) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [currentHash, setCurrentHash] = useHashState();

  const currentStep = useMemo(
    () =>
      steps?.find(
        (x) => x.name.toLowerCase() === decodeURIComponent(currentHash)
      ),
    [steps, currentHash]
  );

  const setCurrentStep = useCallback(
    (step: string | number) => {
      const innerStep = isNumber(step)
        ? steps?.[step]
        : steps?.find((x) => x.name.toLowerCase() === step.toLowerCase());

      if (!isNil(innerStep)) {
        const nextHash = encodeURIComponent(innerStep.name.toLowerCase());
        setCurrentHash(nextHash, { replace: isEmpty(currentHash) });
      }
    },
    [steps, currentHash, setCurrentHash]
  );

  const currentStepIndex = useMemo(
    () => steps.findIndex((x) => x.name === currentStep?.name),
    [steps, currentStep]
  );

  const isFirstStep = currentStepIndex === 0;
  const isFinalStep = currentStepIndex === steps.length - 1;

  useEffect(() => {
    const initialStep = steps?.[0];

    if (isEmpty(currentStep) && !isNil(initialStep)) {
      setCurrentStep(initialStep.name);
    }
  }, [steps, currentStep, setCurrentStep]);

  const next = useCallback(async () => {
    if (isFinalStep) {
      await onComplete?.({ ...data });
    } else {
      await onNext?.();

      const nextIndex = currentStepIndex + 1;
      setCurrentStep(nextIndex);
    }
  }, [data, isFinalStep, currentStepIndex, setCurrentStep, onComplete, onNext]);

  const previous = useCallback(async () => {
    await onPrevious?.();

    const previousIndex = Math.max(0, currentStepIndex - 1);
    setCurrentStep(previousIndex);
  }, [currentStepIndex, setCurrentStep, onPrevious]);

  const handleStepDataChange = useCallback(
    (stepData: Partial<T>) => {
      onChange?.({ ...data, ...stepData });
    },
    [data, onChange]
  );

  const state = useMemo(
    () => ({
      steps,
      setSteps,
      currentStep,
      setCurrentStep,
      isFirstStep,
      isFinalStep,
      onStepDataChange: handleStepDataChange,
    }),
    [
      steps,
      currentStep,
      setCurrentStep,
      isFirstStep,
      isFinalStep,
      handleStepDataChange,
    ]
  );

  return (
    <WizardProvider<T>
      value={{
        data,
        state,
        next,
        previous,
      }}
    >
      <Box {...props}>{children}</Box>
    </WizardProvider>
  );
};
