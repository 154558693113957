import { memo, useCallback, useRef } from "react";

import { BoxProps, VStack } from "@chakra-ui/react";

import { MaybePromise } from "@/common";
import { PickedFile } from "@/components/form";
import { useBoolean } from "@/hooks";
import { ScheduledMessagesModal } from "@/screens/conversations/conversation-schedule";
import { Conversation, Message } from "@/services/types";

import {
  ConversationChat,
  ConversationChatBox,
  ConversationChatBoxElement,
  ConversationChatHeader,
  ConversationChatInput,
  ConversationChatInputProps,
} from "./conversation-chat";
import { ConversationDetailDrawer } from "./conversation-detail-drawer";

// TODO: add these props inside the component's state
export interface ConversationDetailProps extends BoxProps {
  conversation: Conversation;
  messages: Message[];
  isImportant: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  onSend: (message: {
    text: string;
    attachments: PickedFile[];
  }) => MaybePromise<void>;
  onSchedule: (message: { text: string; date: Date }) => MaybePromise<void>;
  onResend: (message: { id: Message["id"] }) => MaybePromise<void>;
  onDiscard: (message: { id: Message["id"] }) => MaybePromise<void>;
  onMarkAsRead: (conversation: Conversation) => MaybePromise<void>;
  onMarkAsUnread: (conversation: Conversation) => MaybePromise<void>;
  onMarkAsImportant: (conversation: Conversation) => MaybePromise<void>;
  onMarkAsNotImportant: (conversation: Conversation) => MaybePromise<void>;
}

const ConversationDetailRaw = ({
  conversation,
  messages = [],
  isImportant,
  isLoadingMore,
  hasMore,
  onLoadMore,
  onSend,
  onSchedule,
  onResend,
  onDiscard,
  onMarkAsRead,
  onMarkAsUnread,
  onMarkAsImportant,
  onMarkAsNotImportant,
  ...props
}: ConversationDetailProps) => {
  const chatBoxRef = useRef<ConversationChatBoxElement>(null);

  const [
    isConversationDrawerOpen,
    openConversationDrawer,
    closeConversationDrawer,
  ] = useBoolean(false);

  const scrollToBottom = useCallback(() => {
    chatBoxRef.current?.scrollToBottom();
  }, []);

  const onSendHandler: ConversationChatInputProps["onSend"] = useCallback(
    ({ text, attachments }) => {
      scrollToBottom();
      onSend({ text, attachments });
    },
    [onSend, scrollToBottom]
  );

  const [
    isScheduledMessagesModalOpen,
    openScheduledMessages,
    closeScheduledMessages,
  ] = useBoolean();

  return (
    <VStack
      position="relative"
      alignItems="flex-start"
      w="full"
      h="full"
      overflow="hidden"
      {...props}
    >
      <ConversationDetailDrawer
        conversation={conversation}
        isOpen={isConversationDrawerOpen}
        onClose={closeConversationDrawer}
      />

      <ConversationChat>
        <ConversationChatHeader
          conversation={conversation}
          isImportant={isImportant}
          onMarkAsRead={onMarkAsRead}
          onMarkAsUnread={onMarkAsUnread}
          onMarkAsImportant={onMarkAsImportant}
          onMarkAsNotImportant={onMarkAsNotImportant}
          onViewScheduledMessages={openScheduledMessages}
          onViewInfo={openConversationDrawer}
        />

        <ConversationChatBox
          ref={chatBoxRef}
          conversation={conversation}
          messages={messages}
          hasMore={hasMore}
          isLoadingMore={isLoadingMore}
          onLoadMore={onLoadMore}
          onResend={onResend}
          onSchedule={async ({ message, date }) => {
            await onSchedule({ text: message.text!, date });
            openScheduledMessages();
          }}
          onDiscard={onDiscard}
        />

        <ConversationChatInput
          conversation={conversation}
          onSend={onSendHandler}
          onSchedule={async (message) => {
            await onSchedule(message);
            openScheduledMessages();
          }}
        />
      </ConversationChat>

      <ScheduledMessagesModal
        isOpen={isScheduledMessagesModalOpen}
        onClose={closeScheduledMessages}
        conversation={conversation}
      />
    </VStack>
  );
};

export const ConversationDetail = memo(ConversationDetailRaw);
