import { useMemo } from "react";

import { range } from "lodash";

export interface UsePaginationProps {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  siblingCount?: number;
  separator?: string;
}

export const usePagination = ({
  totalCount,
  currentPage,
  pageSize,
  siblingCount = 0,
  separator = "|",
}: UsePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2 * SEPARATOR
    const totalPageNumbers = siblingCount + 5;

    // Case 1:
    // If the number of pages is less than the page numbers we want to show in our
    // paginationComponent, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return inclusiveRange(1, totalPageCount);
    }

    // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    // We do not show separators just when there is just one page number to be
    // inserted between the extremes of sibling and the page limits i.e 1
    // and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    const shouldShowLeftSeparators = leftSiblingIndex > 2;
    const shouldShowRightSeparators = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // Case 2: No left separators to show, but rights separators to be shown
    if (!shouldShowLeftSeparators && shouldShowRightSeparators) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = inclusiveRange(1, leftItemCount);

      return [...leftRange, separator, totalPageCount];
    }

    // Case 3: No right separators to show, but left separators to be shown
    if (shouldShowLeftSeparators && !shouldShowRightSeparators) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = inclusiveRange(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );

      return [firstPageIndex, separator, ...rightRange];
    }

    // Case 4: Both left and right separators to be shown
    if (shouldShowLeftSeparators && shouldShowRightSeparators) {
      const middleRange = inclusiveRange(leftSiblingIndex, rightSiblingIndex);

      return [
        firstPageIndex,
        separator,
        ...middleRange,
        separator,
        lastPageIndex,
      ];
    }
  }, [totalCount, pageSize, siblingCount, currentPage, separator]);

  return paginationRange ?? [];
};

const inclusiveRange = (start: number, stop: number) => range(start, stop + 1);
