import { alertAnatomy } from "@chakra-ui/anatomy";
import {
  ComponentStyleConfig,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

export const Alert: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    icon: {
      w: 4,
      h: 4,
    },
  }),
});
