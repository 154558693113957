import { StyleConfig } from "@chakra-ui/react";

export const Button: StyleConfig = {
  baseStyle: {
    fontWeight: "medium",
    _hover: {
      textDecoration: "none",
    },
  },
  sizes: {
    xs: {
      fontSize: "sm",
    },
  },
  defaultProps: {
    size: "sm",
  },
};
