import { forwardRef } from "react";

import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from "@chakra-ui/react";
import { useLongPress } from "@react-aria/interactions";
import { LongPressEvent } from "@react-types/shared";

import { Skeleton } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { useStyleProps } from "@/ui/hooks";

type LongPressEventHandler = (ev: LongPressEvent) => void;

export type IconButtonProps = ChakraIconButtonProps & {
  onLongPress?: LongPressEventHandler;
  onLongPressStart?: LongPressEventHandler;
  onLongPressEnd?: LongPressEventHandler;
};

const IconButtonRaw = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ onLongPress, onLongPressStart, onLongPressEnd, ...props }, ref) => {
    const { longPressProps } = useLongPress({
      onLongPress,
      onLongPressStart,
      onLongPressEnd,
      threshold: 300,
      isDisabled: props.isDisabled,
    });

    return <ChakraIconButton ref={ref} {...longPressProps} {...props} />;
  }
);

IconButtonRaw.displayName = "IconButtonRaw";

export const IconButton = withSkeleton<IconButtonProps, "button">(
  IconButtonRaw,
  ({ pulse, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return (
      <Skeleton
        pulse={pulse}
        w="fit-content"
        h="fit-content"
        borderRadius="md"
        {...styleProps}
      >
        <IconButtonRaw {...restProps} />
      </Skeleton>
    );
  }
);
