import {
  MutationFunction,
  UseMutationOptions,
  useMutation as useBaseMutation,
} from "@tanstack/react-query";

export type UseMutationSimpleOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  "mutationFn"
> & { shouldSettleContext?: boolean; shouldRollbackContext?: boolean };

export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  {
    shouldSettleContext = true,
    shouldRollbackContext = true,
    ...options
  }: UseMutationSimpleOptions<TData, TError, TVariables, TContext> = {}
) => {
  return useBaseMutation<TData, TError, TVariables, TContext>(mutationFn, {
    ...options,
    onSettled: (...args) => {
      const [, , , context] = args;

      if (shouldSettleContext) {
        // @ts-ignore
        context?.settle?.();
      }

      options?.onSettled?.(...args);
    },
    onError: (...args) => {
      const [, , context] = args;

      if (shouldRollbackContext) {
        // @ts-ignore
        context?.rollback?.();
      }

      options?.onError?.(...args);
    },
  });
};
