import { forwardRef } from "react";

import {
  Textarea as ChakraTextArea,
  TextareaProps as ChakraTextAreaProps,
} from "@chakra-ui/react";
import ResizeTextarea, { TextareaAutosizeProps } from "react-textarea-autosize";

export interface TextAreaResizableProps
  extends Omit<ChakraTextAreaProps, keyof TextareaAutosizeProps>,
    TextareaAutosizeProps {}

export const TextAreaResizable = forwardRef<
  HTMLTextAreaElement,
  TextAreaResizableProps
>((props, ref) => (
  <ChakraTextArea
    as={ResizeTextarea}
    ref={ref}
    minH="unset"
    w="100%"
    resize="none"
    borderRadius="md"
    minRows={1}
    {...props}
  />
));

TextAreaResizable.displayName = "TextAreaResizable";
