import { ButtonGroup, VStack } from "@chakra-ui/react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { isNil } from "lodash";

import { Text } from "@/components/display";
import { ToasterManager } from "@/components/feedback";
import { Button } from "@/components/form";
import { FIREBASE } from "@/config";
import { useAuth } from "@/contexts";
import { useAsyncEffect, useLocalStorage } from "@/hooks";
import { NotificationService } from "@/services";
import { useMutation, useQuery } from "@/services/hooks";
import { executeAfterStateUpdate } from "@/ui/utils";
import { convertMToMs } from "@/utils";
import { registerWorker } from "@/worker-registration";

const firebaseApp = initializeApp({
  apiKey: FIREBASE.API_KEY,
  authDomain: FIREBASE.AUTH_DOMAIN,
  projectId: FIREBASE.PROJECT_ID,
  storageBucket: FIREBASE.STORAGE_BUCKET,
  messagingSenderId: FIREBASE.MESSAGING_SENDER_ID,
  appId: FIREBASE.APP_ID,
});

export const usePushNotifications = () => {
  const { isAuthenticated } = useAuth();

  const [subscribeToPushNotifications, setSubscribeToPushNotifications] =
    useLocalStorage<boolean | null>("push-notifications-subscribe", null);

  const { mutateAsync: createPushToken } = useMutation(
    NotificationService.createPushToken
  );

  const { refetch: handleNotificationPermissionChange } = useQuery(
    ["app", "push-notifications"],
    async () => {
      if (!isAuthenticated) {
        return { token: null, permission: null };
      }

      const isBrowserSupported = await isSupported();
      if (!isBrowserSupported || !subscribeToPushNotifications) {
        return { token: null, permission: null };
      }

      const permission = Notification?.permission ?? "default";
      if (permission === "default") {
        return { token: null, permission };
      }

      if (permission === "granted") {
        const firebaseMessaging = getMessaging(firebaseApp);

        const token = await getToken(firebaseMessaging, {
          vapidKey: FIREBASE.VAPID_KEY,
          serviceWorkerRegistration: await registerWorker(),
        });

        await createPushToken({ firebaseToken: token });

        return { token, permission };
      }

      if (permission === "denied") {
        await createPushToken({ firebaseToken: null });

        return { token: null, permission };
      }
    },
    { refetchInterval: convertMToMs(5) }
  );

  useAsyncEffect(
    async (isMounted) => {
      const toastId = "push-notifications-prompt";

      if (!isAuthenticated) return;

      const isBrowserSupported = await isSupported();
      if (!isBrowserSupported || !isMounted()) return;

      // Preference has already been set
      if (!isNil(subscribeToPushNotifications)) {
        ToasterManager.dismiss(toastId);
        return;
      }

      const permission = Notification?.permission;
      if (permission === "denied") return;

      ToasterManager.info({
        title: "Enable push notifications?",
        message: (
          <VStack spacing={4} w="full" alignItems="flex-start">
            <Text>
              Always stay up to date and never miss a message from a potential
              prospect. No marketing, we promise 🤞.
            </Text>

            <ButtonGroup>
              <Button
                colorScheme="blue"
                onClick={async () => {
                  const permission = await Notification?.requestPermission();

                  if (permission !== "default") {
                    setSubscribeToPushNotifications(permission === "granted");
                  }

                  executeAfterStateUpdate(async () => {
                    await handleNotificationPermissionChange();
                  });
                }}
              >
                Allow push-notifications
              </Button>

              <Button
                variant="link"
                colorScheme="blue"
                onClick={() => setSubscribeToPushNotifications(false)}
              >
                Not now
              </Button>
            </ButtonGroup>
          </VStack>
        ),
        options: {
          id: toastId,
          duration: Infinity,
        },
        closeable: false,
      });
    },
    [isAuthenticated, subscribeToPushNotifications]
  );
};
