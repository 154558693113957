import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services/campaign-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof CampaignService.getAll>[0];
type QueryFnData = AsyncReturnType<typeof CampaignService.getAll>;
type Error = unknown;

const queryKey = (args?: QueryArgs) => ["campaigns", args];

export const useCampaigns = <TData = QueryFnData>(
  args?: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CampaignService.getAll(args) as any,
    {
      skeletonData: useConst({
        elements: repeat(gen.campaign),
        pagination: {
          skip: 0,
          limit: 10,
          total: 2,
        },
      }),
      ...options,
    }
  );

  return response;
};

useCampaigns.queryKey = queryKey;
