import { isArray, isNil } from "lodash";

import { Transform } from "./hocs";
import { Option, SelectProps, isMultiValue } from "./select";

export const createSelectFieldTransform = <
  TValue,
  TOption extends Option = Option
>(
  parseIn: (value: TValue) => TOption,
  parseOut: (option: TOption) => TValue
): Transform<SelectProps<TOption, boolean>, TValue | TValue[] | null> => {
  return {
    in: (value) => {
      if (isNil(value)) {
        return null;
      }

      if (isArray(value)) {
        return value.map(parseIn);
      } else {
        return parseIn(value);
      }
    },
    out: (value) => {
      if (isNil(value)) {
        return null;
      }

      if (isMultiValue(value)) {
        return value.map(parseOut);
      } else {
        return parseOut(value);
      }
    },
  };
};
