import { createContext } from "@/ui/utils";
import { noop } from "@/utils";

import { ConversationFilterValue } from "./types";

export interface ConversationFilterContextProps {
  value: ConversationFilterValue;
  defaultValue?: ConversationFilterValue;
  onChange: (value: ConversationFilterValue) => void;
}

export const [ConversationFilterProvider, useConversationFilterProvider] =
  createContext<ConversationFilterContextProps>({
    name: "ConversationFilterContext",
    defaultValue: {
      value: {
        campaigns: [],
        contactTags: [],
        fromContacts: false,
        recent: false,
        important: false,
        interest: 30,
      },
      onChange: noop,
    },
  });
