import { useState } from "react";

import { Outlet } from "react-router-dom";

import { FullPageSpinner } from "@/components/feedback";
import { useAuth } from "@/contexts";
import { useAsyncEffect } from "@/hooks";
import { AuthService } from "@/services";
import { WithOptionalChildren } from "@/ui/types";

export interface ProtectedRouteProps extends WithOptionalChildren {}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth();

  const [hasMounted, setHasMounted] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useAsyncEffect(async () => {
    if (!isAuthenticated) {
      try {
        setIsRedirecting(true);
        await AuthService.login();
      } catch {
        setIsRedirecting(false);
      }
    }

    setHasMounted(true);
  }, [isAuthenticated]);

  if (!hasMounted || isRedirecting) {
    return <FullPageSpinner />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return children ? <>children</> : <Outlet />;
};
