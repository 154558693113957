import { GroupBase } from "chakra-react-select";

import { Option, Select, SelectProps } from "@/components/form/select";

import { defaultTagProps } from "./shared";
import { useEditableTagContext } from "./tag-editable-context";

export interface TagSelectProps<
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
> extends SelectProps<TOption, IsMulti, Group> {}

export const TagSelect = <
  TOption extends Option = Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>
>({
  onBlur,
  ...props
}: TagSelectProps<TOption, IsMulti, Group>) => {
  const { label, onDone } = useEditableTagContext();

  return (
    <Select
      {...(defaultTagProps as SelectProps<TOption, IsMulti, Group>)}
      variant="filled"
      placeholder={label}
      onBlur={(ev) => {
        onDone();
        onBlur?.(ev);
      }}
      {...props}
    />
  );
};
