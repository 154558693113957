import { Box, BoxProps } from "@chakra-ui/react";

export interface ContainerProps extends BoxProps {}

export const PageContainer = (props: ContainerProps) => (
  <Box
    position="relative"
    w="full"
    maxW={{ base: "full", md: "container.lg", lg: "container-2xl" }}
    minH="full"
    my={0}
    mx="auto"
    py={0}
    px={4}
    {...props}
  />
);
