import { AsyncReturnType } from "type-fest";

import { AccountService } from "@/services/account-service";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryFnData = AsyncReturnType<typeof AccountService.getOnboarding>;
type Error = unknown;

const queryKey = () => ["account", "onboarding"];

export const useOnboarding = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    AccountService.getOnboarding,
    options
  );

  return result;
};

useOnboarding.queryKey = queryKey;
