import { useState } from "react";

import { Box, Wrap, WrapItem } from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { Tag } from "@/components/display";
import {
  Editable,
  EditableField,
  EditablePreview,
  Option,
  Select,
  useIsDirty,
} from "@/components/form";
import { asOptions, useLanguages } from "@/services/hooks/typeahead";

import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const LanguagesEditableField = ({
  defaultValue,
  isReadOnly = false,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<Option[]>) => {
  const [nextValue, setNextValue] = useState(defaultValue);

  const isDirty = useIsDirty(
    defaultValue?.map((x) => x.value).sort(),
    nextValue?.map((x) => x.value).sort()
  );

  const [searchQuery, setSearchQuery] = useState("");

  const { data: languages = [], isLoading: isLoadingLanguages } = useLanguages({
    select: asOptions,
  });
  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Language</EditableFieldLabel>

      <Editable
        label="Language"
        isReadOnly={isReadOnly}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>
          <Wrap spacing={1}>
            {defaultValue?.map((item) => (
              <WrapItem key={item.value}>
                <Tag>{item.label}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </EditablePreview>

        <EditableField>
          <Select
            defaultValue={defaultValue}
            value={nextValue}
            options={languages}
            inputValue={searchQuery}
            placeholder="Search for languages..."
            tagVariant="outline"
            isMulti
            isLoading={isLoadingLanguages}
            onSearchQueryChange={(value) => {
              setSearchQuery(value);
            }}
            onChange={(value) => {
              setNextValue(value);
              onChange?.(value);
            }}
          />
        </EditableField>
      </Editable>
    </Box>
  );
};
