import { useRef } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { KEY_CODE } from "@/dom";
import { useStyleProps } from "@/ui/hooks";

export interface ButtonOverlayProps extends BoxProps {
  isDisabled?: boolean;
}

export const ButtonOverlay = ({
  isDisabled = false,
  children,
  ...props
}: ButtonOverlayProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  const [styleProps, restProps] = useStyleProps(props);

  return (
    <Box
      position="relative"
      borderRadius="md"
      transitionProperty="common"
      transitionDuration="normal"
      sx={{
        "&:has(.button-overlay__overlay:focus-visible)": {
          boxShadow: "outline",
        },
        "& :is(a, button, input)": {
          position: "relative",
          zIndex: 1,
        },
      }}
      {...styleProps}
    >
      <Box
        ref={buttonRef}
        className="button-overlay__overlay"
        tabIndex={0}
        zIndex={1}
        position="absolute"
        inset={0}
        w="full"
        h="full"
        opacity={0}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        onKeyDown={(ev) => {
          if ([KEY_CODE.ENTER, KEY_CODE.SPACE].includes(ev.key)) {
            buttonRef.current?.click();
          }
        }}
        {...restProps}
      />

      {children}
    </Box>
  );
};
