import { BoxProps, Icon, VStack, useDisclosure } from "@chakra-ui/react";
import { Reorder } from "framer-motion";
import { MdAdd } from "react-icons/md";

import { Button } from "@/components/form";
import { AddSequenceStepModal } from "@/partials/campaigns";
import { createId } from "@/utils";

import { SequenceMessageListItem } from "./sequence-message-list-item";
import { SequenceMessage } from "./types";

export interface SequenceMessageListProps extends Omit<BoxProps, "onChange"> {
  value: SequenceMessage[];
  isReadOnly?: boolean;
  onChange?: (value: SequenceMessage[]) => void;
}

export const SequenceMessageList = ({
  value: sequence,
  isReadOnly = false,
  children,
  onChange,
  ...props
}: SequenceMessageListProps) => {
  const {
    isOpen: isSequenceModalOpen,
    onOpen: openSequenceModal,
    onClose: closeSequenceModal,
  } = useDisclosure();

  return (
    <VStack spacing={4} alignItems="normal" w="full" {...props}>
      <VStack
        as={Reorder.Group}
        values={sequence}
        axis="y"
        display={!!sequence.length ? "flex" : "none"}
        spacing={4}
        alignItems="normal"
        w="full"
        onReorder={(nextSequence: any) => {
          onChange?.(nextSequence as typeof sequence);
        }}
      >
        {sequence.map((message, index) => (
          <SequenceMessageListItem
            key={message.id}
            value={message}
            hasOffset={1 <= index}
            isDraggable={1 < sequence.length && !isReadOnly}
            isReadOnly={isReadOnly}
            onChange={(value) => {
              const nextSequence = sequence.map((x, i) =>
                i === index ? value : x
              );

              onChange?.(nextSequence);
            }}
            onRemove={() => {
              const nextSequence = sequence.filter((_, i) => i !== index);
              onChange?.(nextSequence);
            }}
          />
        ))}
      </VStack>

      {!isReadOnly && (
        <>
          <Button
            w="fit-content"
            rightIcon={<Icon as={MdAdd} />}
            onClick={openSequenceModal}
          >
            Add sequence message
          </Button>

          <AddSequenceStepModal
            isOpen={isSequenceModalOpen}
            onCreate={(value) =>
              onChange?.([
                ...sequence,
                { id: createId(), content: value, offset: 1 },
              ])
            }
            onClose={closeSequenceModal}
          />
        </>
      )}
    </VStack>
  );
};
