import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { isEmpty, isNumber } from "lodash";
import { nanoid } from "nanoid";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { Modal, useWizardContext } from "@/components/disclosure";
import { useBoolean, useDebounce } from "@/hooks";
import { ContactCard } from "@/partials/contacts";
import { CampaignWizardFieldValues } from "@/screens/campaigns/wizards";
import {
  asTypeaheadRequest,
  usePotentialReach,
} from "@/services/hooks/campaigns";
import { NumberUtils } from "@/utils";

export const WizardStepAudienceBar = () => {
  const [campaignClientId] = useQueryParam(
    "cid",
    withDefault(StringParam, nanoid())
  );

  const { data } = useWizardContext<CampaignWizardFieldValues>();
  const wizardDataDebounced = useDebounce(data);

  const isEnabled = !isEmpty(wizardDataDebounced.regions) && !!campaignClientId;

  const [isSampleReachModalOpen, openSampleReachModal, closeSampleReachModal] =
    useBoolean();

  const {
    data: potentialReach,
    status: potentialReachStatus,
    isFetching: isFetchingPotentialReach,
  } = usePotentialReach(
    {
      key: campaignClientId,
      keywords: wizardDataDebounced.keywords,
      regions: asTypeaheadRequest(wizardDataDebounced.regions),
      industries: asTypeaheadRequest(wizardDataDebounced.industries),
      occupations: asTypeaheadRequest(wizardDataDebounced.occupations),
      languages: asTypeaheadRequest(wizardDataDebounced.languages),
      functions: asTypeaheadRequest(wizardDataDebounced.functions),
      companies: asTypeaheadRequest(wizardDataDebounced.companies),
      experiences: asTypeaheadRequest(wizardDataDebounced.experiences),
      headcounts: asTypeaheadRequest(wizardDataDebounced.headcounts),
      seniorities: asTypeaheadRequest(wizardDataDebounced.seniorities),
    },
    {
      enabled: isEnabled,
      keepPreviousData: isEnabled,
      staleTime: Infinity,
      retry: false,
    }
  );

  const hasCustomAudience = wizardDataDebounced.type === "custom";
  const hasPotentialReach = isNumber(potentialReach?.pagination.total);
  const hasPositivePotentialReach =
    hasPotentialReach && (potentialReach?.pagination.total ?? 0) > 0;

  return (
    <Alert
      variant="solid"
      borderRadius="md"
      fontWeight="semibold"
      status={
        hasPotentialReach
          ? "success"
          : potentialReachStatus === "error"
          ? "error"
          : "info"
      }
    >
      <Flex alignItems="center" justifyContent="space-between" gap={3} w="full">
        <Flex alignItems="center">
          <AlertIcon />

          <AlertTitle m={0}>
            {hasCustomAudience || hasPotentialReach ? (
              <>
                Your campaign can reach up to{" "}
                {hasCustomAudience
                  ? NumberUtils.format(
                      wizardDataDebounced.customAudienceList?.profiles.length ??
                        0
                    )
                  : NumberUtils.format(potentialReach!.pagination.total!)}{" "}
                people{" "}
                {!hasCustomAudience && hasPositivePotentialReach && (
                  <Button
                    variant="link"
                    color="inherit"
                    fontWeight="inherit"
                    fontSize="inherit"
                    textDecoration="underline"
                    _hover={{
                      color: "inherit",
                    }}
                    _active={{
                      color: "inherit",
                    }}
                    onClick={openSampleReachModal}
                  >
                    Preview target audience
                  </Button>
                )}
              </>
            ) : potentialReachStatus === "error" ? (
              <>
                We couldn't estimate your campaign's potential reach at this
                time
              </>
            ) : (
              <>
                Define your target audience to get an estimated potential reach
              </>
            )}
          </AlertTitle>
        </Flex>

        <Flex
          display={
            isFetchingPotentialReach && !hasCustomAudience ? "flex" : "none"
          }
          alignItems="center"
        >
          <Spinner color="white" size="sm" />
        </Flex>
      </Flex>

      <Modal
        isOpen={isSampleReachModalOpen && hasPositivePotentialReach}
        onClose={closeSampleReachModal}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Target audience</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <VStack spacing={1}>
              {potentialReach?.elements?.map((profile, index) => (
                <ContactCard
                  key={index}
                  contact={{
                    ...profile,
                    id: index,
                    createdDate: "",
                    linkedInProfile: { id: index, createdDate: "", ...profile },
                  }}
                  w="full"
                  px={0}
                  border="none"
                  borderBottom="1px solid"
                  borderBottomColor="inherit"
                  borderRadius="none"
                  transition="background var(--transition-very-fast) linear"
                  _last={{
                    borderBottomColor: "transparent",
                  }}
                />
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Alert>
  );
};
