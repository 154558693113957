import {
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from "@chakra-ui/react";

import { SkeletonText } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";

export type TextProps = ChakraTextProps;

export const Text = withSkeleton<TextProps, "p">(ChakraText, (props) => (
  <SkeletonText w="fit-content" noOfLines={1} {...props} />
));
