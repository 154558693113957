import { createContext, useContext } from "react";

import { WithChildren } from "@/ui/types";

import { StepState } from "./types";

interface WizardStepContextProps<T = unknown> {
  name: string;
  state: StepState;
  data: T;
}

const WizardStepContext = createContext<WizardStepContextProps>({
  name: "",
  state: {
    isValid: true,
    isSubmitting: false,
  },
  data: null,
});

export interface WizardStepProviderProps<T = unknown> extends WithChildren {
  value: WizardStepContextProps<T>;
}

export const WizardStepProvider = <TData,>(
  props: WizardStepProviderProps<TData>
) => {
  return <WizardStepContext.Provider {...props} />;
};

export const useWizardStepContext = () => {
  const context = useContext<WizardStepContextProps>(WizardStepContext);

  if (context === undefined) {
    throw Error(
      `"useWizardStep" must used within "${WizardStepProvider.name}".`
    );
  }

  return context;
};
