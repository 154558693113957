import { useState } from "react";

import {
  Box,
  BoxProps,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Icon,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
} from "@chakra-ui/react";
import { MdLocalOffer } from "react-icons/md";

import { Nullish } from "@/common";
import { useQuerySuspense } from "@/components/disclosure";

export interface PromotionButtonProps
  extends Omit<BoxProps, "value" | "onChange"> {
  value?: Nullish<string>;
  onChange: (value?: Nullish<string>) => void;
}

// TODO: Use TagEditable and export as TagInput
export const PromotionButton = ({
  value: promotionCode,
  onChange,
  ...props
}: PromotionButtonProps) => {
  const { isSuspended } = useQuerySuspense();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box {...props}>
      {!promotionCode ? (
        <>
          {isEditing ? (
            <Tag size="md" colorScheme="green">
              <TagLeftIcon as={MdLocalOffer} />

              <Editable
                startWithEditView
                submitOnBlur
                selectAllOnFocus
                defaultValue={promotionCode ?? undefined}
                placeholder="WELCOME"
                width="15ch"
                textTransform="uppercase"
                onSubmit={(value) => {
                  setIsEditing(false);

                  if (value !== promotionCode) {
                    onChange(value || null);
                  }
                }}
              >
                <EditablePreview w="inherit" />

                <EditableInput
                  autoFocus
                  w="inherit"
                  fontWeight="semibold"
                  textTransform="inherit"
                  color="green.700"
                  _focus={{
                    boxShadow: "none",
                  }}
                />
              </Editable>
            </Tag>
          ) : (
            <Button
              size="xs"
              fontSize="sm"
              variant="solid"
              leftIcon={<Icon as={MdLocalOffer} />}
              isDisabled={isSuspended}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Apply promotion code
            </Button>
          )}
        </>
      ) : (
        <Tag size="md" colorScheme="green">
          <TagLeftIcon as={MdLocalOffer} />

          <TagLabel textTransform="uppercase" fontWeight="semibold">
            {promotionCode}
          </TagLabel>

          {!isSuspended && (
            <TagCloseButton
              onClick={() => {
                onChange(null);
              }}
            />
          )}
        </Tag>
      )}
    </Box>
  );
};
