import { ComponentProps, useState } from "react";

import { Box, BoxProps, Popover, useBreakpointValue } from "@chakra-ui/react";

import { FilterProvider } from "./filter-context";

export interface FilterProps extends BoxProps {}

export const Filter = ({ children, ...props }: FilterProps) => {
  const [index, setIndex] = useState(0);

  const placement = useBreakpointValue<
    ComponentProps<typeof Popover>["placement"]
  >({
    base: "bottom-end",
    md: "bottom",
  });

  return (
    <FilterProvider value={{ index, onChange: setIndex }}>
      <Box {...props}>
        <Popover placement={placement} closeOnBlur={false}>
          {children}
        </Popover>
      </Box>
    </FilterProvider>
  );
};
