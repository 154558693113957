import { Box } from "@chakra-ui/react";
import { RenderElementProps, useFocused, useSelected } from "slate-react";

export const PlaceholderElement = ({
  element,
  attributes,
  children,
}: RenderElementProps) => {
  const isFocused = useFocused();
  const isSelected = useSelected();

  if (element.type !== "placeholder") {
    return null;
  }

  const { label } = element;

  return (
    <Box
      as="span"
      display="inline-block"
      w="fit-content"
      borderRadius="md"
      boxShadow={isFocused && isSelected ? "outline" : undefined}
      color="blue.600"
      fontWeight="semibold"
      contentEditable={false}
      {...attributes}
    >
      {label}

      {children}
    </Box>
  );
};
