import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery as useBaseQuery,
} from "@tanstack/react-query";
import produce, { Draft } from "immer";
import { useAtom } from "jotai";
import { isNil, isUndefined } from "lodash";

import { useQuerySuspense } from "@/components/disclosure";
import { useCallbackRef, useUnmount } from "@/hooks";
import { isInternalServerError, isNetworkError } from "@/http";
import {
  addGlobalQueryErrorAtom,
  removeGlobalQueryErrorAtom,
} from "@/ui/state";

import { useAuthStatus } from "./account";
import { useQueryClient } from "./use-query-client";

export type UseQuerySimpleOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "queryKey" | "queryFn"
> & {
  authenticated?: boolean;
  enableSkeletonData?: boolean;
  skeletonData?: TQueryFnData;
  promptRetryOnError?: boolean;
  onBeforeQuery?: () => void;
};

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  {
    enabled = true,
    authenticated = true,
    select,
    enableSkeletonData = false,
    skeletonData,
    promptRetryOnError = true,
    onBeforeQuery,
    ...options
  }: UseQuerySimpleOptions<TQueryFnData, TError, TData, TQueryKey> = {}
) => {
  const [, addGlobalQueryError] = useAtom(addGlobalQueryErrorAtom);
  const [, removeGlobalQueryError] = useAtom(removeGlobalQueryErrorAtom);

  const { data: isAuthenticated, isFetched: hasFetchedAuthStatus } =
    useAuthStatus({
      enabled: authenticated,
    });

  const queryClient = useQueryClient();

  const { isSuspended = false } = useQuerySuspense();

  const userCanRunQuery = authenticated
    ? hasFetchedAuthStatus && isAuthenticated
    : true;

  const result = useBaseQuery(
    queryKey,
    (...args) => {
      onBeforeQuery?.();
      return queryFn(...args);
    },
    {
      ...options,
      enabled:
        enabled &&
        !isSuspended &&
        !queryClient.isQueryPaused(queryKey) &&
        userCanRunQuery,
      select,
      onError: (error) => {
        if (!promptRetryOnError) {
          return;
        }

        if (isNetworkError(error) || isInternalServerError(error)) {
          addGlobalQueryError({
            queryKey,
            refetch: () => {
              removeGlobalQueryError(queryKey);
              return result.refetch();
            },
          });
        }
      },
    }
  );

  useUnmount(() => {
    removeGlobalQueryError(queryKey);
  });

  const { data, isFetched, isSuccess } = result;

  const transformedSkeletonData =
    isNil(select) || isNil(skeletonData)
      ? (skeletonData as TData | undefined)
      : select(skeletonData);

  const isSkeletonData = enableSkeletonData && (!isFetched || !isSuccess);

  const setInterimData = useCallbackRef(
    async (
      setter: (draft?: Draft<TQueryFnData | undefined>) => TQueryFnData | void,
      {
        shouldInvalidate = true,
        shouldCancel = true,
        shouldPause = false,
        shouldRollback = true,
        settle: settleExternal,
        rollback: rollbackExternal,
      }: {
        shouldInvalidate?: boolean;
        shouldCancel?: boolean;
        shouldPause?: boolean;
        shouldRollback?: boolean;
        settle?: () => void;
        rollback?: (
          draft?: Draft<TQueryFnData | undefined>
        ) => TQueryFnData | void;
      } = {}
    ) => {
      if (shouldPause) {
        queryClient.pauseQuery(queryKey);
      }

      if (shouldCancel) {
        await queryClient.cancelQueries(queryKey, {
          exact: true,
        });
      }

      const snapshot = queryClient.getQueryData<TData>(queryKey);

      queryClient.setQueryData<TQueryFnData>(queryKey, (data) => {
        let setterResult: TQueryFnData | undefined;

        const draftResult = produce(data, (draft) => {
          setterResult = setter(draft) as TQueryFnData | undefined;
        });

        // Setter's return value takes precedence over the draft value
        return isUndefined(setterResult) ? draftResult : setterResult;
      });

      const settle = () => {
        if (shouldPause) {
          queryClient.resumeQuery(queryKey);
        }

        if (shouldInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }

        if (settleExternal) {
          settleExternal();
        }
      };

      const rollback = () => {
        if (shouldRollback) {
          queryClient.setQueryData(queryKey, snapshot);
        }

        if (rollbackExternal) {
          queryClient.setQueryData<TQueryFnData>(queryKey, (data) => {
            let rollbackResult: TQueryFnData | undefined;

            const draftResult = produce(data, (draft) => {
              rollbackResult = rollbackExternal(draft) as
                | TQueryFnData
                | undefined;
            });

            // Rollback's return value takes precedence over the draft value
            return isUndefined(rollbackResult) ? draftResult : rollbackResult;
          });
        }
      };

      return { settle, rollback };
    }
  );

  return {
    queryKey,
    ...result,
    isSkeletonData,
    data: isSkeletonData ? transformedSkeletonData : data,
    setInterimData,
    // Override isLoading due to breaking change in React Query 4,
    // that causes isLoading to be true when query is in idle state
    isLoading: result.isInitialLoading,
  };
};
