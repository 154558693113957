import { FormControl, FormControlProps } from "@chakra-ui/react";
import { isArray, isEmpty, uniqBy } from "lodash";
import { useFormContext } from "react-hook-form";

import { QuerySuspense } from "@/components/disclosure";

import { FormError } from "./form-error";
import { FormFieldControlProvider } from "./form-field-control-context";

export interface FormFieldControlProps extends FormControlProps {
  name: string;
  isLoading?: boolean;
}

export const FormFieldControl = ({
  name,
  isLoading = false,
  isDisabled,
  children,
  ...props
}: FormFieldControlProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessages = isEmpty(errors[name])
    ? []
    : isArray(errors[name])
    ? uniqBy(errors[name] as unknown as [], "message")
    : [errors[name]];

  const isInvalid = !isEmpty(errorMessages);

  return (
    <FormFieldControlProvider value={{ name }}>
      <QuerySuspense suspended={isLoading}>
        <FormControl isInvalid={isInvalid} isDisabled={isDisabled} {...props}>
          {children}

          <FormError
            errors={errorMessages.map((x) => x?.message?.toString())}
          />
        </FormControl>
      </QuerySuspense>
    </FormFieldControlProvider>
  );
};
