import { FormEvent, HTMLProps } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

export interface FormProps
  extends Omit<BoxProps, keyof HTMLProps<HTMLFormElement>>,
    HTMLProps<HTMLFormElement> {}

export const Form = (props: FormProps) => {
  return (
    <Box
      {...props}
      as="form"
      onSubmit={(ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        ev.stopPropagation();

        props?.onSubmit?.(ev);
      }}
    />
  );
};
