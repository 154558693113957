import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import { Application } from "./application";
import "./styles/global.scss";
import "./styles/normalize.scss";
import "./styles/typography.scss";
import { reportWebVitals } from "./utils";
import "./wdyr";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Application />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
