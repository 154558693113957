import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof CampaignService.create>[0];
type Data = AsyncReturnType<typeof CampaignService.create>;
type Error = unknown;

export const useCreateCampaign = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    CampaignService.create,
    options
  );

  return result;
};
