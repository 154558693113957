import { Fragment, useCallback } from "react";

import {
  Box,
  BoxProps,
  Divider,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { PaymentMethod, StripeError, Token } from "@stripe/stripe-js";

import { QuerySuspense } from "@/components/disclosure";
import { Tag, Text } from "@/components/display";
import { ToasterManager } from "@/components/feedback";
import { Button, IconButton } from "@/components/form";
import { MoreHorizontalIcon } from "@/components/media/icons/material";
import { ts } from "@/locales";
import { PaymentTypeIcon } from "@/partials/billing/payment-type-icon";
import {
  useCreatePaymentMethod,
  useDeletePaymentMethod,
  usePaymentMethods,
  useUpdatePaymentMethod,
} from "@/services/hooks/billing";

import { PaymentMethodModal } from "./payment-method-modal";

export interface PaymentMethodListProps extends BoxProps {}

export const PaymentMethodList = (props: PaymentMethodListProps) => {
  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: openPaymentMethodModal,
    onClose: closePaymentMethodModal,
  } = useDisclosure();

  const {
    data: { defaultPaymentMethodStripeId, paymentMethods } = {},
    status: paymentMethodsStatus,
    refetch: refetchPaymentMethods,
  } = usePaymentMethods({
    enableSkeletonData: true,
  });

  const {
    mutateAsync: createPaymentMethod,
    isLoading: isCreatingPaymentMethod,
  } = useCreatePaymentMethod({
    onSuccess: () => refetchPaymentMethods(),
  });

  const {
    mutateAsync: updatePaymentMethod,
    isLoading: isUpdatingPaymentMethod,
  } = useUpdatePaymentMethod({
    onSuccess: () => refetchPaymentMethods(),
  });

  const {
    mutateAsync: deletePaymentMethod,
    isLoading: isDeletingPaymentMethod,
  } = useDeletePaymentMethod({
    onSuccess: () => refetchPaymentMethods(),
  });

  const isChangingPaymentMethods =
    isCreatingPaymentMethod ||
    isUpdatingPaymentMethod ||
    isDeletingPaymentMethod;

  const handlePaymentMethodCreate = useCallback(
    async ({
      paymentMethod,
      cardToken,
      setAsDefault,
    }: {
      paymentMethod: PaymentMethod;
      cardToken: Token;
      setAsDefault: boolean;
    }) => {
      await createPaymentMethod({
        stripePaymentMethodId: paymentMethod.id,
        stripeCardTokenId: cardToken.id,
        setAsDefault,
      });

      closePaymentMethodModal();
    },
    [createPaymentMethod, closePaymentMethodModal]
  );

  const handlePaymentMethodError = useCallback((error?: StripeError) => {
    ToasterManager.error({
      title: tBilling("ERROR.DECLINED_TITLE"),
      message:
        error?.type === "card_error"
          ? error.message
          : tBilling("ERROR.DECLINED_BY_ISSUER_MESSAGE"),
    });
  }, []);

  return (
    <QuerySuspense status={paymentMethodsStatus}>
      <Box {...props}>
        <VStack
          alignItems="flex-start"
          spacing={0}
          opacity={isChangingPaymentMethods ? 0.5 : 1}
          pointerEvents={isChangingPaymentMethods ? "none" : "unset"}
        >
          {paymentMethods?.map((paymentMethod, index, array) => (
            <Fragment key={paymentMethod.stripeId}>
              <Flex justifyContent="space-between" w="full" py={2}>
                <HStack>
                  <Box as="i" borderRadius="sm" overflow="hidden">
                    <Icon
                      as={PaymentTypeIcon}
                      type={paymentMethod.card.brand}
                    />
                  </Box>

                  <Text>•••• {paymentMethod.card.lastFourDigits}</Text>

                  {defaultPaymentMethodStripeId === paymentMethod.stripeId && (
                    <Tag variant="outline" size="sm" colorScheme="blue">
                      Default
                    </Tag>
                  )}
                </HStack>

                <Flex alignItems="center">
                  <Menu placement="bottom-end">
                    <MenuButton
                      as={IconButton}
                      size="xs"
                      icon={<MoreHorizontalIcon />}
                      variant="ghost"
                    />

                    <MenuList minW={32} py={1} fontSize="sm">
                      {defaultPaymentMethodStripeId !==
                        paymentMethod.stripeId && (
                        <MenuItem
                          onClick={() =>
                            updatePaymentMethod({
                              stripeId: paymentMethod.stripeId!,
                            })
                          }
                        >
                          Make default
                        </MenuItem>
                      )}

                      <MenuItem
                        _hover={{
                          color: "red.600",
                          background: "red.50",
                        }}
                        _focus={{
                          color: "red.600",
                          background: "red.50",
                        }}
                        onClick={() =>
                          deletePaymentMethod({
                            stripeId: paymentMethod.stripeId,
                          })
                        }
                      >
                        Remove
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>

              {index !== array.length - 1 && <Divider />}
            </Fragment>
          ))}
        </VStack>

        <Button
          mt={!!paymentMethods?.length ? 4 : 0}
          variant="link"
          colorScheme="blue"
          onClick={openPaymentMethodModal}
        >
          Add payment method
        </Button>

        <PaymentMethodModal
          isOpen={isPaymentMethodModalOpen}
          onClose={closePaymentMethodModal}
          onAddPaymentMethod={handlePaymentMethodCreate}
          onAddPaymentMethodError={handlePaymentMethodError}
        />
      </Box>
    </QuerySuspense>
  );
};

const tBilling = ts("BILLING");
