import { forwardRef } from "react";

import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { useLocation } from "react-router";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { SkeletonText } from "@/components/disclosure";
import { withSkeleton } from "@/components/disclosure/hocs";
import { useStyleProps } from "@/ui/hooks";

export type AppLinksProps = RouterLinkProps &
  ChakraLinkProps & { refererDisplayName?: string; noReferer?: boolean };

const AppLink = forwardRef<HTMLAnchorElement, AppLinksProps>(
  ({ state, refererDisplayName, noReferer = false, ...props }, ref) => {
    const location = useLocation();

    const fallbackRefererDisplayName = document.title.split("-")?.[0].trim();

    const stateWithReferer = noReferer
      ? state
      : {
          refererUri: location.pathname + location.search,
          refererDisplayName: refererDisplayName || fallbackRefererDisplayName,
          ...state,
        };

    return (
      <ChakraLink
        ref={ref}
        as={RouterLink}
        state={stateWithReferer}
        {...props}
      />
    );
  }
);

AppLink.displayName = "AppLink";

export const Link = withSkeleton<AppLinksProps, "a">(
  AppLink,
  ({ to, pulse, ...props }) => {
    const [styleProps, restProps] = useStyleProps(props);

    return (
      <SkeletonText pulse={pulse} w="fit-content" noOfLines={1} {...styleProps}>
        <AppLink to={to} {...restProps} />
      </SkeletonText>
    );
  }
);
