import { isNil, isString } from "lodash";
import { DateTime } from "luxon";

import { ArrayUtils } from "./array-utils";

export const MONTHS = Object.freeze([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

const getYears = (endYear: number, length: number) => {
  const startYear = endYear - length;

  return ArrayUtils.range(endYear, startYear, -1);
};

const getMonths = () => MONTHS;

const getShortMonthName = (month = "") => month.substr(0, 3);

const getMonthFromString = (month: number | string) =>
  new Date(`${month}-1-01`).getMonth();

const createUtcDate = (year: number, month = 0, day = 1) => {
  if (!year) {
    return null;
  }

  const dateUTC = Date.UTC(year, month, day);
  return new Date(dateUTC);
};

const toRelative = (date: Date | string, dateTimeOptions = { zone: "utc" }) => {
  if (isNil(date)) {
    return null;
  }

  const dateTime = parse(date, dateTimeOptions);

  const isLessThan60Seconds = isNil(dateTime)
    ? false
    : dateTime.diffNow().as("seconds") >= -60;

  if (isLessThan60Seconds) {
    return "Just now";
  }

  return dateTime?.toRelative();
};

const toRelativeCalendar = (
  date: Date | string,
  dateTimeOptions = { zone: "utc" }
) => {
  if (isNil(date)) {
    return null;
  }

  const dateTime = parse(date, dateTimeOptions);

  return dateTime?.toRelativeCalendar();
};

const toShort = (date: Date | string, dateTimeOptions = { zone: "utc" }) => {
  if (isNil(date)) {
    return null;
  }

  const dateTime = parse(date, dateTimeOptions);
  return dateTime?.toLocal().toLocaleString(DateTime.DATETIME_SHORT);
};

const toSimple = (date: Date | string, dateTimeOptions = { zone: "utc" }) => {
  if (isNil(date)) {
    return null;
  }

  const dateTime = parse(date, dateTimeOptions);
  return dateTime?.toLocal().toLocaleString(DateTime.TIME_SIMPLE);
};

const toFull = (date: Date | string, dateTimeOptions = { zone: "utc" }) => {
  if (isNil(date)) {
    return null;
  }

  const dateTime = parse(date, dateTimeOptions);
  return dateTime?.toLocal().toLocaleString(DateTime.DATE_FULL);
};

const parse = (date: Date | string, dateTimeOptions = { zone: "utc" }) => {
  if (isNil(date)) {
    return null;
  }

  if (isString(date)) {
    return DateTime.fromISO(date, dateTimeOptions).setLocale("en");
  } else {
    return DateTime.fromJSDate(date, dateTimeOptions).setLocale("en");
  }
};

const areSameDay = (firstDate: Date | string, secondDate: Date | string) => {
  const firstDateTime = parse(firstDate);
  const secondDateTime = parse(secondDate);

  if (isNil(firstDateTime) || isNil(secondDateTime)) {
    return false;
  }

  return firstDateTime.startOf("day") <= secondDateTime.startOf("day");
};

const areSameHour = (firstDate: Date | string, secondDate: Date | string) => {
  const firstDateTime = parse(firstDate);
  const secondDateTime = parse(secondDate);

  if (isNil(firstDateTime) || isNil(secondDateTime)) {
    return false;
  }

  return firstDateTime.startOf("hour") <= secondDateTime.startOf("hour");
};

const areSameMinute = (firstDate: Date | string, secondDate: Date | string) => {
  const firstDateTime = parse(firstDate);
  const secondDateTime = parse(secondDate);

  if (isNil(firstDateTime) || isNil(secondDateTime)) {
    return false;
  }

  return firstDateTime.startOf("minute") <= secondDateTime.startOf("minute");
};

const max = (firstDate: Date | string, secondDate: Date | string) => {
  const firstDateTime = parse(firstDate);
  const secondDateTime = parse(secondDate);

  if (isNil(firstDateTime)) {
    return secondDateTime;
  }

  if (isNil(secondDateTime)) {
    return firstDateTime;
  }

  const maxDateTime = DateTime.max(firstDateTime, secondDateTime);
  return maxDateTime;
};

export const convertMToMs = (minutes: number) => {
  const milliseconds = minutes * 60000;
  return milliseconds;
};

export const DateUtils = Object.freeze({
  getYears,
  getMonths,
  getShortMonthName,
  getMonthFromString,
  createUtcDate,
  toRelative,
  toRelativeCalendar,
  toShort,
  toSimple,
  toFull,
  parse,
  areSameDay,
  areSameHour,
  areSameMinute,
  max,
  convertMToMs,
});
