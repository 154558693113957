import { Box, BoxProps } from "@chakra-ui/react";

import { useEditableContext } from "./editable-context";

export interface EditableFieldProps extends BoxProps {}

export const EditableField = (props: EditableFieldProps) => {
  const { isEditing } = useEditableContext();

  if (!isEditing) {
    return null;
  }

  return <Box {...props} />;
};
