import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Circle,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  ScaleFade,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { QuerySuspense } from "@/components/disclosure";
import { useElementSize, useWindowSize } from "@/hooks";
import { NotificationService } from "@/services";
import { useNotifications } from "@/services/hooks/notifications";
import { toPx } from "@/styles";
import { createEmptyPromise, gen, repeat } from "@/utils";

import { Notification } from "./notification";
import { parseNotification } from "./utils";

export const NotificationListPopover = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: { elements: notifications = [] } = {},
    status: notificationsStatus,
    fetchNextPage: fetchMoreNotifications,
    refetch: refetchNotifications,
    hasNextPage: hasMoreNotifications,
    isFetchingNextPage: isLoadingMoreNotifications,
    isLoading: isLoadingNotifications,
  } = useNotifications(undefined, {
    enableSkeletonData: true,
  });

  const hasUnreadNotifications = useMemo(() => {
    return notifications.some((x) => x.state === "unread");
  }, [notifications]);

  const handleClose = useCallback(async () => {
    onClose();

    if (hasUnreadNotifications) {
      await NotificationService.markAsRead();
      await refetchNotifications();
    }
  }, [hasUnreadNotifications, refetchNotifications, onClose]);

  return (
    <Box>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={handleClose}>
        <PopoverTrigger>
          <Box position="relative">
            <IconButton
              isRound
              icon={<Icon as={MdOutlineNotificationsNone} />}
              width={8}
              height={8}
              aria-label="Notifications"
            />

            <Box w={2} h={2} position="absolute" top="1px" right="1px">
              <ScaleFade initialScale={0.8} in={hasUnreadNotifications}>
                <Circle size={2} bgColor="blue.500" />
              </ScaleFade>
            </Box>
          </Box>
        </PopoverTrigger>

        <PopoverContent p={2}>
          <PopoverHeader fontWeight="bold" border={0}>
            Notifications
          </PopoverHeader>

          <PopoverBody p={0}>
            <QuerySuspense status={notificationsStatus}>
              <Box>
                <NotificationList
                  data={notifications}
                  hasMore={hasMoreNotifications}
                  isLoading={isLoadingNotifications}
                  isLoadingMore={isLoadingMoreNotifications}
                  onLoadMore={() =>
                    isLoadingNotifications ||
                    isLoadingMoreNotifications ||
                    !hasMoreNotifications
                      ? createEmptyPromise()
                      : fetchMoreNotifications()
                  }
                  onRowClick={handleClose}
                />
              </Box>
            </QuerySuspense>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

const NotificationList = ({
  data,
  hasMore,
  isLoading,
  isLoadingMore,
  onLoadMore,
  onRowClick,
}: any) => {
  const parentData = data;

  const listRef = useRef<VariableSizeList | null>(null);
  const sizeMap = useRef<Record<number, number>>({});

  const getSize = (index: number) => sizeMap.current[index] || 100;

  const setSize = useCallback((index: any, size: any) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef?.current?.resetAfterIndex(index);
  }, []);

  return (
    <InfiniteLoader
      itemCount={Number.POSITIVE_INFINITY}
      isItemLoaded={(index) => index < data.length}
      loadMoreItems={() => onLoadMore()}
    >
      {({ ref, onItemsRendered }: any) => (
        <VariableSizeList
          ref={(list) => {
            ref(list);
            listRef.current = list;
          }}
          width="100%"
          // ChakraUI 96size is 24rem; VariableSizeList works only with pixels
          height={toPx(24)}
          // When we have more items, we render 3 additional rows
          // which we use as skeleton placeholders
          itemCount={hasMore ? data.length + 3 : data.length}
          itemSize={getSize}
          itemData={
            isLoadingMore ? [...data, ...repeat(gen.notification, 3)] : data
          }
          onItemsRendered={onItemsRendered}
        >
          {({ data, index, style }: any) => (
            <QuerySuspense
              suspended={
                isLoading || (isLoadingMore && parentData.length - 1 < index)
              }
            >
              <Box style={style}>
                <NotificationRow
                  index={index}
                  data={data[index]}
                  onClick={onRowClick}
                  onResize={setSize}
                />
              </Box>
            </QuerySuspense>
          )}
        </VariableSizeList>
      )}
    </InfiniteLoader>
  );
};

const NotificationRow = ({ index, data, onClick, onResize }: any) => {
  const { link, message, date, state, persistent }: any = data
    ? parseNotification(data)
    : gen.notification();

  const [rowElement, setRowElement] = useState();

  const { width: windowWidth } = useWindowSize();
  const { height: rowHeight } = useElementSize(rowElement);

  useEffect(() => {
    if (persistent) {
      onResize(index, rowHeight);
    } else {
      // Using 1 instead of 0 because 0 is not taken into account.
      onResize(index, 1);
    }
  }, [index, persistent, windowWidth, rowHeight, onResize, rowElement]);

  if (!persistent) {
    return null;
  }

  return (
    <Notification
      ref={setRowElement}
      link={link}
      message={message}
      date={date}
      state={state}
      onClick={() => onClick()}
    />
  );
};
