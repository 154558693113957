import { useState } from "react";

import { Box } from "@chakra-ui/react";
import { isEmpty, isNil } from "lodash";

import {
  Editable,
  EditableField,
  EditablePreview,
  useIsDirty,
} from "@/components/form";
import { SequenceMessage, SequenceMessageList } from "@/partials/campaigns";
import { sequenceSchema } from "@/ui/schemas";

import { EditableFieldError } from "./editable-field-error";
import { EditableFieldLabel } from "./editable-field-label";
import { EditableFieldProps } from "./types";

export const SequenceEditableField = ({
  defaultValue,
  isReadOnly,
  onChange,
  onSave,
  onCancel,
}: EditableFieldProps<SequenceMessage[]>) => {
  const [nextValue, setNextValue] = useState(defaultValue);
  const [error, setError] = useState<string | null>(null);

  const isDirty = useIsDirty(
    defaultValue?.map((x) => ({ content: x.content, offset: x.offset })),
    nextValue?.map((x) => ({ content: x.content, offset: x.offset }))
  );

  return (
    <Box w="full">
      <EditableFieldLabel isDirty={isDirty}>Sequence</EditableFieldLabel>

      <Editable
        label="Sequence"
        isReadOnly={isReadOnly}
        isDisabled={!isNil(error)}
        hasValue={!isEmpty(defaultValue)}
        onSave={async () => {
          if (!isNil(error)) return;
          await onSave?.(nextValue);
        }}
        onCancel={() => {
          setError(null);
          setNextValue(defaultValue);
          onCancel?.();
        }}
      >
        <EditablePreview>
          <SequenceMessageList value={nextValue!} isReadOnly />
        </EditablePreview>

        <EditableField>
          <SequenceMessageList
            value={nextValue!}
            onChange={(value) => {
              sequenceSchema.safeParseAsync(value).then((result) => {
                if (result.success) {
                  setError(null);
                } else {
                  setError(result.error.issues[0].message);
                }
              });

              setNextValue(value);
              onChange?.(value);
            }}
          />

          <EditableFieldError error={error} />
        </EditableField>
      </Editable>
    </Box>
  );
};
