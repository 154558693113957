import { isUndefined } from "lodash";

import { AnyObject } from "@/common";

const pickDefined = (object: AnyObject) =>
  Object.keys(object).reduce((acc: AnyObject, key) => {
    if (!isUndefined(object[key])) {
      acc[key] = object[key];
    }

    return acc;
  }, {});

const deepFreeze = (object: AnyObject) => {
  Object.freeze(object);

  Object.getOwnPropertyNames(object).forEach((prop) => {
    if (
      // eslint-disable-next-line no-prototype-builtins
      object.hasOwnProperty(prop) &&
      object[prop] !== null &&
      (typeof object[prop] === "object" ||
        typeof object[prop] === "function") &&
      !Object.isFrozen(object[prop])
    ) {
      ObjectUtils.deepFreeze(object[prop]);
    }
  });

  return object;
};

export const ObjectUtils = Object.freeze({
  pickDefined,
  deepFreeze,
});
