import { useMemo, useState } from "react";

import { Box, ButtonGroup, Divider, HStack, Icon } from "@chakra-ui/react";
import { isEqual } from "lodash";
import {
  MdClose,
  MdDone,
  MdEdit,
  MdLabelOutline,
  MdOutlineCampaign,
  MdOutlinePeople,
} from "react-icons/md";

import {
  Filter,
  FilterBody,
  FilterButton,
  FilterContent,
  FilterFooter,
  FilterHeader,
  FilterTab,
  FilterTabList,
  FilterTabPanel,
  FilterTabPanels,
  FilterTabs,
} from "@/components/disclosure";
import { Button, IconButton } from "@/components/form";
import { useBoolean, useServerState } from "@/hooks";
import { MotionBox, animations } from "@/styles/motion";

import { CampaignTabPanel } from "./campaign-tab-panel";
import { ContactTabPanel } from "./contact-tab-panel";
import { ConversationFilterProvider } from "./conversation-filter-context";
import { CreateViewModal } from "./create-view-modal";
import { StatusTabPanel } from "./status-tab-panel";
import { ConversationFilterValue, ConversationFilterView } from "./types";

export interface ConversationFilterProps {
  value: ConversationFilterValue;
  defaultValue: ConversationFilterValue;
  onChange: (value: ConversationFilterValue) => void;
}

export const ConversationFilter = ({
  value,
  defaultValue,
  onChange,
}: ConversationFilterProps) => {
  const [views = [], setViews] =
    useServerState<ConversationFilterView[]>("conversation-views");

  const [isEditingViews, setIsEditingViews] = useState(false);

  const isDirty = useMemo(
    () => !isEqual(value, defaultValue),
    [defaultValue, value]
  );

  const [isSaveViewModalOpen, openSaveViewModal, closeSaveViewModal] =
    useBoolean(false);

  return (
    <ConversationFilterProvider value={{ value, defaultValue, onChange }}>
      <Filter>
        <CreateViewModal
          isOpen={isSaveViewModalOpen}
          filter={value}
          onClose={closeSaveViewModal}
        />

        <FilterButton isApplied={isDirty} />

        <FilterContent>
          {!!views.length && (
            <FilterHeader overflowX="auto">
              <HStack position="relative" gap={2}>
                {views.map((view) => (
                  <MotionBox
                    position="relative"
                    key={view.name}
                    flexShrink={0}
                    variants={animations.wobble}
                    initial="initial"
                    animate={isEditingViews ? "visible" : "initial"}
                    exit="initial"
                  >
                    <Button
                      variant={isEditingViews ? "solid" : "ghost"}
                      size="xs"
                      isDisabled={isEditingViews}
                      onClick={() => {
                        onChange(view.filter);
                      }}
                    >
                      {view.name}
                    </Button>

                    {isEditingViews && (
                      <IconButton
                        aria-label="Remove"
                        icon={<Icon as={MdClose} w={3} h={3} />}
                        variant="outline"
                        size="xs"
                        position="absolute"
                        top={0}
                        right={0}
                        transform="translate(25%, -25%)"
                        minWidth={0}
                        width={4}
                        height={4}
                        background="white"
                        borderRadius="full"
                        onClick={() => {
                          setViews((prev) =>
                            prev.filter((x) => x.name !== view.name)
                          );
                        }}
                      />
                    )}
                  </MotionBox>
                ))}

                <Box
                  position="sticky"
                  right={0}
                  ml="auto"
                  background="white"
                  borderRadius="md"
                >
                  {isEditingViews ? (
                    <IconButton
                      aria-label="Done"
                      icon={<Icon as={MdDone} />}
                      variant="ghost"
                      size="xs"
                      onClick={() => {
                        setIsEditingViews(false);
                      }}
                    />
                  ) : (
                    <IconButton
                      aria-label="Edit"
                      icon={<Icon as={MdEdit} />}
                      variant="ghost"
                      size="xs"
                      onClick={() => {
                        setIsEditingViews(true);
                      }}
                    />
                  )}
                </Box>
              </HStack>
            </FilterHeader>
          )}

          <FilterBody>
            <FilterTabs>
              <FilterTabList>
                <FilterTab>
                  <Icon as={MdLabelOutline} /> Status
                </FilterTab>

                <FilterTab>
                  <Icon as={MdOutlineCampaign} /> Campaign
                </FilterTab>

                <FilterTab>
                  <Icon as={MdOutlinePeople} /> Contact
                </FilterTab>
              </FilterTabList>

              <Divider
                display={{ base: "none", md: "block" }}
                orientation="vertical"
                h="inherit"
                mx={4}
              />

              <FilterTabPanels>
                <FilterTabPanel>
                  <StatusTabPanel />
                </FilterTabPanel>

                <FilterTabPanel>
                  <CampaignTabPanel />
                </FilterTabPanel>

                <FilterTabPanel>
                  <ContactTabPanel />
                </FilterTabPanel>
              </FilterTabPanels>
            </FilterTabs>
          </FilterBody>

          {isDirty && (
            <FilterFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="xs">
                <Button onClick={() => onChange(defaultValue)}>
                  Clear filter
                </Button>

                <Button onClick={() => openSaveViewModal()}>Save view</Button>
              </ButtonGroup>
            </FilterFooter>
          )}
        </FilterContent>
      </Filter>
    </ConversationFilterProvider>
  );
};
