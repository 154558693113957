import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { CampaignService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryArgs = Parameters<typeof CampaignService.analytics>[0];
type QueryFnData = AsyncReturnType<typeof CampaignService.analytics>;
type Error = unknown;

const queryKey = (args: QueryArgs) => ["campaigns", args.id, "stats"];

export const useStats = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => CampaignService.analytics(args),
    {
      skeletonData: useConst({
        stats: repeat(
          () => ({
            type: gen.pickone(["reached", "connected", "responded", "saved"]),
            name: gen.word(),
            currentAmount: gen.integer({ min: 0, max: 1000 }),
            totalAmount: gen.integer({ min: 0, max: 1000 }),
            trendAmount: gen.integer({ min: 0, max: 10 }),
            trendType: gen.pickone(["neutral", "positive"]),
          }),
          4
        ),
      }),
      ...options,
    }
  );

  return result;
};

useStats.queryKey = queryKey;
