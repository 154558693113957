import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { useInView } from "framer-motion";
import produce from "immer";
import { isNil } from "lodash";
import { Helmet } from "react-helmet-async";
import {
  MdArrowBackIosNew,
  MdOutlineArrowDownward,
  MdOutlineArrowUpward,
} from "react-icons/md";
import { Merge } from "type-fest";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import { ArrayType } from "@/common";
import {
  ActionTabItem,
  ActionTabs,
  QuerySuspense,
} from "@/components/disclosure";
import { Heading, Text } from "@/components/display";
import { IconButton, PickedFile, SearchInput } from "@/components/form";
import {
  useDebounce,
  useDebouncedQueryParams,
  useEventListener,
  useLocalForage,
  useServerState,
} from "@/hooks";
import { HTTP_STATUS_CODES, isAxiosError } from "@/http";
import { RequiredNumericArrayParam } from "@/router/params";
import { flattenPages, useQueryClient } from "@/services/hooks";
import {
  useConversation,
  useConversations,
  useMarkImportant,
  useMarkRead,
  useMessages,
  usePollMessages,
  useScheduleMessage,
  useScheduledMessages,
  useSendMessage,
} from "@/services/hooks/conversations";
import { Message } from "@/services/types";
import { useMediaQuery } from "@/ui/hooks";
import { ComponentProp } from "@/ui/types";
import {
  ArrayUtils,
  NumberUtils,
  createId,
  mergeFunctions,
  noop,
} from "@/utils";

import {
  ConversationDetail,
  ConversationDetailProps,
} from "./conversation-detail";
import {
  ConversationFilter,
  ConversationFilterValue,
} from "./conversation-filter";
import { ConversationList } from "./conversation-list";
import { ConversationListItem } from "./conversation-list-item";
import { RecentConversation } from "./types";

const CONVERSATION_LIMIT = 20;
const MESSAGE_LIMIT = 25;

export const ConversationListScreen = () => {
  const queryClient = useQueryClient();

  const [isLgScreen] = useMediaQuery("lg");

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery);

  const [tab = "inbox", setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, "inbox")
  );

  const [activeConversationId, setActiveConversationId] = useQueryParam(
    "conversation",
    NumberParam
  );

  const defaultFilter: ConversationFilterValue = {
    campaigns: [],
    contactTags: [],
    fromContacts: false,
    recent: true,
    important: false,
    interest: 30,
  };

  const [filter, setFilter] = useDebouncedQueryParams({
    campaigns: withDefault(RequiredNumericArrayParam, defaultFilter.campaigns),
    contactTags: withDefault(
      RequiredNumericArrayParam,
      defaultFilter.contactTags
    ),
    fromContacts: withDefault(BooleanParam, defaultFilter.fromContacts),
    recent: withDefault(BooleanParam, defaultFilter.recent),
    important: withDefault(BooleanParam, defaultFilter.important),
    interest: withDefault(NumberParam, defaultFilter.interest),
  });

  const [recentConversations = [], setRecentConversations] = useServerState<
    RecentConversation[]
  >("recent-conversations");

  const conversationListRef = useRef<HTMLDivElement>(null);
  const conversationListItemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [failedMessagesByConversation, setFailedMessagesByConversation] =
    useLocalForage<Record<number, MessageWithPickedFiles[]>>(
      "failed-messages",
      {}
    );

  const failedMessages = useMemo(
    () =>
      failedMessagesByConversation[activeConversationId!]?.map((message) => ({
        ...message,
        attachments: message.attachments?.map((attachment) => ({
          ...attachment,
          url: URL.createObjectURL(attachment.data),
        })),
      })) || [],
    [activeConversationId, failedMessagesByConversation]
  );

  const {
    flatData: unreadConversationsCount,
    refetch: refetchUnreadConversationsCount,
    isLoading: isLoadingUnreadConversationsCount,
  } = useConversations(
    {
      campaignIds: filter.campaigns,
      contactTags: filter.contactTags,
      fromContacts: filter.fromContacts,
      sortByRecent: filter.recent,
      unread: true,
      important: filter.important ? filter.interest : undefined,
      search: debouncedSearchQuery,
      limit: 0,
    },
    {
      flatSelect: (data) => data?.pages?.[0]?.pagination.total,
    }
  );

  const {
    flatData: conversations,
    setInterimData: setInterimConversations,
    refetch: refetchConversations,
    fetchPreviousPage: fetchPreviousConversations,
    fetchNextPage: fetchMoreConversations,
    hasPreviousPage: hasPreviousConversations = false,
    hasNextPage: hasMoreConversations = false,
    isFetchingPreviousPage: isFetchingPreviousConversations,
    isFetchingNextPage: isFetchingMoreConversations,
    isSkeletonData: isSkeletonConversations,
  } = useConversations(
    {
      campaignIds: filter.campaigns,
      contactTags: filter.contactTags,
      fromContacts: filter.fromContacts,
      sortByRecent: filter.recent,
      unread: tab === "unread",
      pending: tab === "pending",
      important:
        tab !== "pending" && filter.important ? filter.interest : undefined,
      search: debouncedSearchQuery,
      limit: CONVERSATION_LIMIT,
    },
    {
      refetchInterval: 10000,
      enableSkeletonData: true,
      flatSelect: (data) => data?.pages.flatMap((x) => x.elements),
      onBeforeQuery: () => {
        refetchUnreadConversationsCount();
      },
    }
  );

  const {
    data: activeConversation,
    setInterimData: setInterimActiveConversation,
    isSkeletonData: isSkeletonActiveConversation,
  } = useConversation(
    { id: activeConversationId! },
    {
      enabled: !isNil(activeConversationId),
      initialData: conversations?.find((x) => x!.id === activeConversationId),
      enableSkeletonData: true,
      onError: (error: unknown) => {
        if (isAxiosError(error)) {
          const statusCode = error?.response?.status;
          if (statusCode === HTTP_STATUS_CODES.NOT_FOUND) {
            setActiveConversationId(conversations?.[0]?.id);
          }
        }
      },
    }
  );

  const {
    flatData: messages = [],
    setInterimData: setInterimMessages,
    refetch: refetchMessages,
    fetchPreviousPage: fetchMoreMessages,
    hasPreviousPage: hasMoreMessages = false,
    isFetchingPreviousPage: isFetchingMoreMessages,
    isFetched: hasFetchedMessages,
  } = useMessages(
    {
      id: activeConversationId!,
      limit: MESSAGE_LIMIT,
    },
    {
      enabled: !isNil(activeConversationId),
      refetchInterval: 10000,
      flatSelect: (data) => data?.pages.flatMap((x) => x.elements),
    }
  );

  const allMessages = useMemo(
    () => [...messages, ...failedMessages],
    [messages, failedMessages]
  );

  const { mutateAsync: markRead } = useMarkRead({
    onMutate: async ({ id, isRead = true }) => {
      const result = await Promise.all([
        setInterimActiveConversation((draft) => {
          if (!draft) return;
          draft.isRead = isRead;
        }),

        setInterimConversations((draft) => {
          if (!draft) return;

          const flatDraft = flattenPages(draft);
          const element = flatDraft.find((x) => x.id === id);

          if (element) {
            element.isRead = isRead;
          }
        }),
      ]);

      const settle = mergeFunctions(...result.map((x) => x.settle));
      const rollback = mergeFunctions(...result.map((x) => x.rollback));

      return { settle, rollback };
    },
  });

  const { mutateAsync: markImportant } = useMarkImportant({
    onMutate: async ({ id, isImportant = true }) => {
      const result = await Promise.all([
        setInterimActiveConversation((draft) => {
          if (!draft) return;
          draft.interestScore = isImportant ? 100 : 0;
        }),
        setInterimConversations((draft) => {
          if (!draft) return;

          const flatDraft = flattenPages(draft);
          const element = flatDraft.find((x) => x.id === id);

          if (element) {
            element.interestScore = isImportant ? 100 : 0;
          }
        }),
      ]);

      const settle = mergeFunctions(...result.map((x) => x.settle));
      const rollback = mergeFunctions(...result.map((x) => x.rollback));

      return { settle, rollback };
    },
  });

  const { mutateAsync: sendMessage } = useSendMessage({
    retry: false,
    shouldSettleContext: false,
    onMutate: async ({ id, data }) => {
      const intermediateMessage = {
        id: createId(),
        text: data.message,
        isOurs: true,
        sentDate: new Date().toISOString().split("Z")[0],
        readDate: null,
        status: "sending",
        attachments: data.attachments?.map(
          // @ts-ignore
          // FIXME: type is wrong; id is provided by the FilePicker
          ({ id, name, size, type, data }) => ({
            id,
            name,
            size,
            type,
            data,
            url: URL.createObjectURL(data),
          })
        ),
      };

      const result = await Promise.all([
        setInterimMessages(
          (draft) => {
            draft?.pages?.at(-1)?.elements.push(intermediateMessage);
          },
          {
            shouldPause: true,
            shouldInvalidate: false,
            rollback: (draft) => {
              ArrayUtils.spliceWhere(
                draft?.pages?.at(-1)?.elements ?? [],
                (x) => x.id === intermediateMessage.id
              );
            },
          }
        ),

        setInterimConversations(
          (draft) => {
            if (!draft) return;

            let elementToMove:
              | (typeof draft)["pages"][0]["elements"][0]
              | null = null;

            draft.pages.forEach((page) => {
              const elementIndex = page.elements.findIndex((e) => e.id === id);

              if (elementIndex === -1) return;

              const element = page.elements[elementIndex];
              element.isRead = true;
              element.lastMessage = intermediateMessage;

              elementToMove = page.elements.splice(elementIndex, 1)[0];

              return;
            });

            if (!elementToMove) return;

            draft.pages[0].elements.unshift(elementToMove);
          },
          {
            shouldPause: true,
            shouldInvalidate: false,
          }
        ),
      ]);

      const settle = mergeFunctions(...result.map((x) => x.settle));
      const rollback = mergeFunctions(...result.map((x) => x.rollback));

      await markRead({ id });

      return { settle, rollback, message: intermediateMessage };
    },
    onSettled: (_, __, ___, context) => {
      const runningSendMessageMutations = queryClient.isMutating({
        mutationKey: useSendMessage.mutationKey(),
      });

      // Settle on last mutation
      if (runningSendMessageMutations <= 1) {
        context?.settle?.();
      }
    },
    onSuccess: () => {
      const runningSendMessageMutations = queryClient.isMutating({
        mutationKey: useSendMessage.mutationKey(),
      });

      // Refetch on last mutation
      if (runningSendMessageMutations <= 1) {
        refetchConversations();
        refetchMessages();
      }
    },
    onError: async (_, { id }, context) => {
      await setFailedMessagesByConversation((prev) =>
        produce(prev, (draftState) => {
          draftState[id] = [
            ...(draftState[id] || []),
            { ...context!.message, status: "failed" },
          ];
        })
      );
    },
  });

  const { mutateAsync: scheduleMessage } = useScheduleMessage({
    onSuccess: (_, { id }) => {
      const queryKey = useScheduledMessages.queryKey({ id });
      queryClient.invalidateQueries(queryKey);
    },
  });

  usePollMessages(
    {
      id: activeConversationId!,
    },
    {
      enabled: !isNil(activeConversationId),
      refetchInterval: 1000,
      refetchIntervalInBackground: false,
    }
  );

  useEventListener(
    "onNotificationReceived",
    (ev: any) => {
      const { notification } = ev.detail;

      refetchConversations();

      if (notification.payload.conversationId === activeConversationId) {
        refetchMessages();
      }
    },
    document
  );

  useEffect(() => {
    if (isLgScreen && !isSkeletonConversations) {
      setActiveConversationId(
        (conversationId) => conversationId || conversations?.[0]?.id
      );
    }
  }, [
    conversations,
    isSkeletonConversations,
    setActiveConversationId,
    isLgScreen,
  ]);

  const activeConversationElement = useMemo<HTMLDivElement | null>(() => {
    const activeConversationIndex = conversations?.findIndex(
      (x) => x.id === activeConversationId
    );

    if (isNil(activeConversationIndex) || activeConversationIndex === -1) {
      return null;
    }

    const activeConversationElement =
      conversationListItemRefs.current[activeConversationIndex];

    return activeConversationElement;
  }, [activeConversationId, conversations]);

  const isActiveConversationInView = useInView(
    {
      current: activeConversationElement,
    },
    { root: conversationListRef }
  );

  const showReturnToActiveConversation =
    activeConversationElement && !isActiveConversationInView;

  const isActiveConversationAbove =
    (activeConversationElement?.offsetTop ?? 0) <
    (conversationListRef.current?.scrollTop ?? 0);

  const addRecentConversation = useCallback(
    (conversation: RecentConversation) => {
      const existingIndex = recentConversations.findIndex(
        (x) => x.id === conversation.id
      );

      if (existingIndex !== -1) {
        recentConversations.splice(existingIndex, 1);
      }

      recentConversations.unshift(conversation);

      if (recentConversations.length > 10) {
        recentConversations.pop();
      }

      setRecentConversations([...recentConversations]);
    },
    [recentConversations, setRecentConversations]
  );

  const handleConversationSelect: ComponentProp<
    typeof ConversationListItem,
    "onSelect"
  > = useCallback(
    (conversation) => {
      setActiveConversationId(conversation.id);
      if (!conversation.isRead) {
        markRead({ id: conversation.id });
      }

      addRecentConversation({
        id: conversation.id,
        recipient: {
          name: conversation.recipient.fullName,
          image: conversation.recipient.image,
        },
      });
    },
    [addRecentConversation, markRead, setActiveConversationId]
  );

  const handleConversationSelectRecent: ComponentProp<
    typeof ConversationList,
    "onSelectRecent"
  > = useCallback(
    (conversation) => {
      setActiveConversationId(conversation.id);
      markRead({ id: conversation.id });

      addRecentConversation(conversation);
    },
    [addRecentConversation, markRead, setActiveConversationId]
  );

  const handleConversationMarkAsRead: ComponentProp<
    typeof ConversationListItem,
    "onMarkAsRead"
  > = useCallback(
    (conversation) => {
      markRead({ id: conversation.id, isRead: true });
    },
    [markRead]
  );

  const handleConversationMarkAsUnread: ComponentProp<
    typeof ConversationListItem,
    "onMarkAsUnread"
  > = useCallback(
    (conversation) => {
      markRead({ id: conversation.id, isRead: false });
    },
    [markRead]
  );

  const handleConversationMarkAsImportant: ComponentProp<
    typeof ConversationListItem,
    "onMarkAsImportant"
  > = useCallback(
    (conversation) => {
      markImportant({
        id: conversation.id,
        isImportant: true,
      });
    },
    [markImportant]
  );

  const handleConversationMarkAsNotImportant: ComponentProp<
    typeof ConversationListItem,
    "onMarkAsNotImportant"
  > = useCallback(
    (conversation) => {
      markImportant({
        id: conversation.id,
        isImportant: false,
      });
    },
    [markImportant]
  );

  const handleMessageSend: ComponentProp<typeof ConversationDetail, "onSend"> =
    useCallback(
      ({ text, attachments }) => {
        sendMessage({
          id: activeConversationId!,
          data: {
            message: text,
            attachments,
          },
        });
      },
      [activeConversationId, sendMessage]
    );

  const handleMessageSchedule: ComponentProp<
    typeof ConversationDetail,
    "onSchedule"
  > = useCallback(
    async ({ text, date }) => {
      await scheduleMessage({
        id: activeConversationId!,
        data: {
          text,
          scheduledDate: date.toISOString(),
        },
      });
    },
    [activeConversationId, scheduleMessage]
  );

  const handleMessageResend: ComponentProp<
    typeof ConversationDetail,
    "onResend"
  > = useCallback(
    async ({ id }) => {
      const failedMessage = failedMessages.find((x) => x.id === id);
      if (!failedMessage) return;

      const { text: persistedMessage, attachments: persistedAttachments } =
        failedMessage;

      await setFailedMessagesByConversation((prev) =>
        produce(prev, (draftState) => {
          ArrayUtils.spliceWhere(
            draftState[activeConversationId!] || [],
            (x) => x.id === id
          );
        })
      );

      await sendMessage({
        id: activeConversationId!,
        data: {
          message: persistedMessage!,
          attachments: persistedAttachments!,
        },
      });
    },
    [
      activeConversationId,
      failedMessages,
      sendMessage,
      setFailedMessagesByConversation,
    ]
  );

  const handleMessageDiscard: ConversationDetailProps["onDiscard"] =
    useCallback(
      async ({ id }) => {
        await setFailedMessagesByConversation((prev) =>
          produce(prev, (draftState) => {
            ArrayUtils.spliceWhere(
              draftState[activeConversationId!] || [],
              (x) => x.id === id
            );
          })
        );
      },
      [activeConversationId, setFailedMessagesByConversation]
    );

  const handleReturnToActiveConversation = useCallback(() => {
    if (!activeConversationElement) {
      return;
    }

    const offsetTop = activeConversationElement.offsetTop;
    const stickyHeight = 88;

    conversationListRef.current?.scrollTo({
      top: offsetTop - stickyHeight,
      behavior: "smooth",
    });
  }, [activeConversationElement]);

  return (
    <>
      <Helmet>
        <title>Conversations - Cosiall</title>
      </Helmet>

      <Flex
        flexDir="column"
        alignItems="flex-start"
        height="var(--cosiall-sizes-content-height)"
        overflowY="hidden"
      >
        {(isLgScreen || isNil(activeConversationId)) && (
          <>
            <Stack
              w="full"
              direction={{ base: "column", md: "row" }}
              alignItems="flex-start"
              spacing={{ base: 2, lg: 4 }}
              mb={{ base: 2, lg: 4 }}
              p="2px"
            >
              <Heading
                as="h1"
                size="lg"
                display={{ base: "none", md: "unset" }}
                suspendable={false}
              >
                Conversations
              </Heading>

              <Flex flex="1" gap={1} w={{ base: "full", lg: "unset" }}>
                <SearchInput
                  value={searchQuery}
                  size="sm"
                  placeholder="Search conversations"
                  flex="2 1 auto"
                  maxW={{ base: "unset", md: "40ch" }}
                  onChange={(value) => setSearchQuery(value)}
                />

                <ConversationFilter
                  value={filter}
                  defaultValue={defaultFilter}
                  onChange={setFilter}
                />
              </Flex>
            </Stack>

            <ActionTabs w="full">
              <ActionTabItem
                tabKey="inbox"
                active={tab === "inbox"}
                onSelect={setTab}
              >
                Inbox
              </ActionTabItem>

              <ActionTabItem
                tabKey="unread"
                active={tab === "unread"}
                onSelect={setTab}
              >
                Unread
                {(!!unreadConversationsCount ||
                  isLoadingUnreadConversationsCount) && (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    ml={2}
                    px={1}
                    h={4}
                    background="red.400"
                    borderRadius="full"
                    fontSize="2xs"
                    color="white"
                  >
                    <Text whiteSpace="nowrap">
                      {!!unreadConversationsCount && (
                        <>
                          {NumberUtils.formatSignificantDigits(
                            unreadConversationsCount ?? 0
                          )}
                        </>
                      )}
                    </Text>

                    {isLoadingUnreadConversationsCount && (
                      <Spinner w={2} h={2} color="white" />
                    )}
                  </Flex>
                )}
              </ActionTabItem>

              <ActionTabItem
                tabKey="pending"
                active={tab === "pending"}
                onSelect={setTab}
              >
                Pending
              </ActionTabItem>
            </ActionTabs>
          </>
        )}

        {!isLgScreen && !isNil(activeConversationId) && (
          <Button
            variant="link"
            colorScheme="blue"
            leftIcon={<Icon as={MdArrowBackIosNew} />}
            onClick={() => {
              setActiveConversationId(undefined);
            }}
          >
            Back to Conversations
          </Button>
        )}

        <Grid
          position="relative"
          gridTemplateColumns={isLgScreen ? "45ch 1fr" : "1fr"}
          gap={6}
          alignItems="start"
          w="full"
          minH={0}
          flex="1"
          mt={{ base: 2, lg: 6 }}
        >
          <QuerySuspense suspended={isSkeletonConversations}>
            {(isLgScreen || isNil(activeConversationId)) && (
              <ConversationList
                ref={conversationListRef}
                hasPrevious={hasPreviousConversations}
                hasMore={hasMoreConversations}
                isLoadingPrevious={isFetchingPreviousConversations}
                isLoadingMore={isFetchingMoreConversations}
                onSelectRecent={handleConversationSelectRecent}
                onLoadPrevious={async () => {
                  const firstElement = conversationListItemRefs.current[0];

                  await fetchPreviousConversations();

                  firstElement?.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                    inline: "nearest",
                  });
                }}
                onLoadMore={() => fetchMoreConversations()}
              >
                <IconButton
                  aria-label="Return to active conversation"
                  icon={<Icon as={MdOutlineArrowUpward} />}
                  size="xs"
                  suspendable={false}
                  opacity={
                    showReturnToActiveConversation && isActiveConversationAbove
                      ? 1
                      : 0
                  }
                  position="sticky"
                  zIndex="sticky"
                  top={100}
                  mt={-34}
                  rounded="full"
                  onClick={handleReturnToActiveConversation}
                />

                <Box position="relative" w="full">
                  {isFetchingPreviousConversations && (
                    <QuerySuspense suspended={isFetchingPreviousConversations}>
                      {conversations?.slice(0, 3).map((conversation, index) => (
                        <ConversationListItem
                          key={index}
                          conversation={{
                            ...conversation,
                            interestScore: 0,
                            isRead: true,
                          }}
                          onSelect={noop}
                        />
                      ))}
                    </QuerySuspense>
                  )}
                </Box>

                {conversations
                  ?.filter((conversation) => !isNil(conversation?.lastMessage))
                  .map((conversation, index) => (
                    <ConversationListItem
                      key={conversation!.id}
                      ref={(ref) =>
                        (conversationListItemRefs.current[index] = ref)
                      }
                      conversation={conversation}
                      isActive={activeConversationId === conversation!.id}
                      isImportant={
                        filter.interest <= (conversation!.interestScore ?? 0)
                      }
                      onSelect={handleConversationSelect}
                      onMarkAsRead={handleConversationMarkAsRead}
                      onMarkAsUnread={handleConversationMarkAsUnread}
                      onMarkAsImportant={handleConversationMarkAsImportant}
                      onMarkAsNotImportant={
                        handleConversationMarkAsNotImportant
                      }
                    />
                  ))}

                {isFetchingMoreConversations && (
                  <QuerySuspense suspended={isFetchingMoreConversations}>
                    {conversations?.slice(0, 3).map((conversation, index) => (
                      <ConversationListItem
                        key={index}
                        conversation={{
                          ...conversation,
                          interestScore: 0,
                          isRead: true,
                        }}
                        onSelect={noop}
                      />
                    ))}
                  </QuerySuspense>
                )}

                <IconButton
                  aria-label="Return to active conversation"
                  icon={<Icon as={MdOutlineArrowDownward} />}
                  size="xs"
                  suspendable={false}
                  opacity={
                    showReturnToActiveConversation && !isActiveConversationAbove
                      ? 1
                      : 0
                  }
                  position="sticky"
                  zIndex="sticky"
                  bottom={2}
                  mt={-34}
                  rounded="full"
                  onClick={handleReturnToActiveConversation}
                />
              </ConversationList>
            )}
          </QuerySuspense>

          {!isNil(activeConversationId) && (
            <QuerySuspense
              suspended={isSkeletonActiveConversation || !hasFetchedMessages}
            >
              {!isNil(activeConversation) && (
                <ConversationDetail
                  key={activeConversation.id}
                  conversation={activeConversation}
                  messages={allMessages}
                  isImportant={
                    filter.interest <= (activeConversation?.interestScore ?? 0)
                  }
                  hasMore={hasMoreMessages}
                  isLoadingMore={isFetchingMoreMessages}
                  onLoadMore={fetchMoreMessages}
                  onSend={handleMessageSend}
                  onSchedule={handleMessageSchedule}
                  onResend={handleMessageResend}
                  onDiscard={handleMessageDiscard}
                  onMarkAsRead={handleConversationMarkAsRead}
                  onMarkAsUnread={handleConversationMarkAsUnread}
                  onMarkAsImportant={handleConversationMarkAsImportant}
                  onMarkAsNotImportant={handleConversationMarkAsNotImportant}
                />
              )}
            </QuerySuspense>
          )}
        </Grid>
      </Flex>
    </>
  );
};

type MessageWithPickedFiles = Merge<
  Message,
  {
    attachments?: (ArrayType<NonNullable<Message["attachments"]>> &
      PickedFile)[];
  }
>;
