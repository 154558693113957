import { RequestParams, fetchApi } from "./openapi";

const getOnboarding = () => {
  const result = fetchApi("/account/onboarding", "get");

  return result;
};

const completeTour = ({
  name,
}: RequestParams<"/account/onboarding/{name}", "put">) => {
  const result = fetchApi("/account/onboarding/{name}", "put", {
    params: { path: { name } },
    data: { name, status: "completed" },
  });

  return result;
};

export const AccountService = Object.freeze({
  getOnboarding,
  completeTour,
});
