import { AsyncReturnType } from "type-fest";

import { CrmService } from "@/services/crm-service";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<typeof CrmService.removeLead>[0];
type Data = AsyncReturnType<typeof CrmService.removeLead>;
type Error = unknown;

export const useRemoveLead = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    CrmService.removeLead,
    options
  );

  return result;
};
