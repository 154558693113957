import { useCallback, useState } from "react";

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod, StripeError, Token } from "@stripe/stripe-js";

import { MaybePromise } from "@/common";
import { Modal, ModalProps } from "@/components/disclosure";
import { Button, CardInput, Switch } from "@/components/form";
import { useIsMounted } from "@/hooks";

export interface PaymentMethodModalProps extends Omit<ModalProps, "children"> {
  onAddPaymentMethod: (value: {
    paymentMethod: PaymentMethod;
    cardToken: Token;
    setAsDefault: boolean;
  }) => MaybePromise<void>;
  onAddPaymentMethodError: (error?: StripeError) => void;
}

export const PaymentMethodModal = ({
  onAddPaymentMethod,
  onAddPaymentMethodError,
  ...props
}: PaymentMethodModalProps) => {
  const isMounted = useIsMounted();

  const stripe = useStripe();
  const stripeElements = useElements();

  const [cardFieldCompleted, setCardFieldCompleted] = useState(false);
  const [setAsDefaultPaymentMethod, setSetAsDefaultPaymentMethod] =
    useState(true);
  const [isAddingPaymentMethodLoading, setIsAddingPaymentMethodLoading] =
    useState(false);

  const createPaymentMethod = useCallback(async () => {
    const cardElement = stripeElements!.getElement(CardElement)!;

    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      onAddPaymentMethodError(error);
    }

    return paymentMethod;
  }, [stripe, stripeElements, onAddPaymentMethodError]);

  const createCardToken = useCallback(async () => {
    const cardElement = stripeElements!.getElement(CardElement);
    const { token, error } = await stripe!.createToken(cardElement!);

    if (error) {
      onAddPaymentMethodError(error);
    }

    return token;
  }, [stripe, stripeElements, onAddPaymentMethodError]);

  const handleAddPaymentMethodClick = useCallback(async () => {
    setIsAddingPaymentMethodLoading(true);

    try {
      const paymentMethod = await createPaymentMethod();
      const cardToken = await createCardToken();

      if (!paymentMethod || !cardToken) return;

      await onAddPaymentMethod({
        paymentMethod,
        cardToken,
        setAsDefault: setAsDefaultPaymentMethod,
      });
    } catch {
      onAddPaymentMethodError();
    } finally {
      if (isMounted()) {
        setIsAddingPaymentMethodLoading(false);
      }
    }
  }, [
    isMounted,
    setAsDefaultPaymentMethod,
    createPaymentMethod,
    createCardToken,
    onAddPaymentMethod,
    onAddPaymentMethodError,
  ]);

  return (
    <Modal closeOnOverlayClick={false} closeOnEsc={false} {...props}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Add payment method</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Card information</FormLabel>

              <CardInput
                onChange={(state) => setCardFieldCompleted(state.complete)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Use as default payment method?</FormLabel>

              <Switch
                value={setAsDefaultPaymentMethod}
                onChange={(value) => setSetAsDefaultPaymentMethod(value)}
              />

              <FormHelperText>
                The default payment method will be used for all charges.
              </FormHelperText>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Box>
            <Button
              colorScheme="primary"
              isLoading={isAddingPaymentMethodLoading}
              loadingText="Adding payment method"
              isDisabled={!cardFieldCompleted || isAddingPaymentMethodLoading}
              onClick={handleAddPaymentMethodClick}
            >
              Add payment method
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
