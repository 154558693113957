import { ReactNode, useState } from "react";

import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
  useToken,
} from "@chakra-ui/react";

import { MaybePromise } from "@/common";
import { Modal } from "@/components/disclosure";
import { Button } from "@/components/form";

export interface ColorPickerModalProps extends Omit<ModalProps, "children"> {
  title?: ReactNode;
  onPick?: (color: string) => MaybePromise<void>;
}

export const ColorPickerModal = ({
  title,
  isOpen,
  onPick,
  onClose,
}: ColorPickerModalProps) => {
  const colors = useToken("colors", [
    "green.500",
    "yellow.500",
    "orange.500",
    "red.500",
    "pink.500",
    "purple.500",
    "blue.500",
    "blue.800",
    "gray.500",
    "gray.700",
  ]);

  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody pt={2} pb={4}>
          <SimpleGrid columns={5} spacing={2}>
            {colors.map((color) => (
              <Button
                key={color}
                variant="unstyled"
                isDisabled={isProcessing}
                pointerEvents={isProcessing ? "none" : undefined}
                w="full"
                h="auto"
                aspectRatio={1}
                background={color}
                borderRadius="md"
                onClick={async () => {
                  try {
                    setIsProcessing(true);
                    await onPick?.(color);
                    onClose();
                  } finally {
                    setIsProcessing(false);
                  }
                }}
              />
            ))}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
