import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { isEqual } from "lodash";
import { ZodSchema, typeToFlattenedError } from "zod";

import { AnyObject } from "@/common";

export interface UseFormStateOptions<T extends AnyObject> {
  defaultValue?: T;
  schema?: ZodSchema<T>;
}

export interface UseFormStateResult<T extends AnyObject> {
  state: T;
  isDirty: boolean;
  isValid?: boolean;
  errors?: typeToFlattenedError<T>["fieldErrors"];
  setState: Dispatch<SetStateAction<T>>;
  updateState: Dispatch<SetStateAction<Partial<T>>>;
  reset: () => void;
}

export const useFormState = <T extends AnyObject>({
  defaultValue,
  schema,
}: UseFormStateOptions<T>): UseFormStateResult<T> => {
  const [state, setState] = useState(defaultValue!);

  const [isValid, setIsValid] = useState(
    () => schema?.safeParse(state).success ?? true
  );

  const [errors, setErrors] = useState(() => {
    const validation = schema?.safeParse(state);

    if (!validation?.success) {
      return validation?.error.flatten().fieldErrors;
    }

    return undefined;
  });

  const isDirty = useMemo(
    () => !isEqual(state, defaultValue),
    [defaultValue, state]
  );

  const setStateWithValidation: Dispatch<SetStateAction<T>> = useCallback(
    (nextStateAction) => {
      const nextState =
        typeof nextStateAction === "function"
          ? nextStateAction(state!)
          : nextStateAction;

      const validation = schema?.safeParse(nextState);

      setState(nextState);
      setIsValid(validation?.success ?? true);
      setErrors(
        validation?.success
          ? undefined
          : validation?.error.flatten().fieldErrors
      );
    },
    [schema, state]
  );

  const updateStateWithValidation: Dispatch<SetStateAction<Partial<T>>> =
    useCallback(
      (nextPartialStateAction) => {
        const nextPartialState =
          typeof nextPartialStateAction === "function"
            ? nextPartialStateAction(state!)
            : nextPartialStateAction;

        setStateWithValidation((prevState) => ({
          ...prevState,
          ...nextPartialState,
        }));
      },
      [setStateWithValidation, state]
    );

  const reset = useCallback(() => {
    setStateWithValidation(defaultValue!);
  }, [defaultValue, setStateWithValidation]);

  return {
    state,
    isValid,
    isDirty,
    errors: isDirty ? errors : undefined,
    setState: setStateWithValidation,
    updateState: updateStateWithValidation,
    reset,
  };
};
