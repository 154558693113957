import { useStripe } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { AsyncReturnType } from "type-fest";

import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<Stripe["confirmCardPayment"]>;
type Data = AsyncReturnType<Stripe["confirmCardPayment"]>;
type Error = unknown;

export const useConfirmCardPayment = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const stripe = useStripe();

  const result = useMutation<Data, Error, Variables, TContext>(
    (args) => stripe!.confirmCardPayment(...args),
    options
  );

  return result;
};
