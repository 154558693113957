import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
} from "@chakra-ui/react";

export type ModalProps = ChakraModalProps;

export const Modal = (props: ModalProps) => {
  return (
    <ChakraModal
      isCentered
      autoFocus={false}
      scrollBehavior="inside"
      {...props}
    />
  );
};
