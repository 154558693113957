import { Replay } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { pdfjs } from "react-pdf";

import { tryParseFloat } from "./utils";

export const ENV = Object.freeze({
  IS_DEVELOPMENT: import.meta.env.MODE === "development",
  IS_PRODUCTION: import.meta.env.MODE === "production",
});

export const API = Object.freeze({
  ENDPOINT: import.meta.env.VITE_COSIALL_API as string,
});

export const API_HUB = Object.freeze({
  ENDPOINT: import.meta.env.VITE_COSIALL_API_HUB as string,
});

export const OIDC = Object.freeze({
  AUTHORITY: import.meta.env.VITE_STS_AUTHORITY as string,
  CLIENT_ID: import.meta.env.VITE_STS_CLIENT_ID as string,
  LOGIN_REDIRECT_URI: import.meta.env.VITE_STS_LOGIN_REDIRECT as string,
  LOGOUT_REDIRECT_URI: import.meta.env.VITE_STS_LOGOUT_REDIRECT as string,
  SCOPES: [
    "openid",
    "profile",
    "email",
    "phone",
    "custom_data",
    "offline_access",
  ],
  RESPONSE_TYPE: "code",
});

// prettier-ignore
export const FIREBASE = Object.freeze({
  API_KEY: import.meta.env.VITE_FIREBASE_API_KEY as string,
  AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN as string,
  PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID as string,
  STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string,
  MESSAGING_SENDER_ID: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID as string,
  APP_ID: import.meta.env.VITE_FIREBASE_APP_ID as string,
  VAPID_KEY: import.meta.env.VITE_FIREBASE_VAPID_KEY as string,
});

export const STRIPE = Object.freeze({
  PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
});

export const ZAPIER = Object.freeze({
  CLIENT_ID: import.meta.env.VITE_ZAPIER_CLIENT_ID as string,
});

export const SENTRY = Object.freeze({
  ENV: import.meta.env.VITE_SENTRY_ENV as string,
  DSN: import.meta.env.VITE_SENTRY_DSN as string,
  INTEGRATIONS: [
    new BrowserTracing(),
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  TRACE_SAMPLE_RATE: tryParseFloat(
    import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE
  ),
  REPLAY_SESSION_SAMPLE_RATE: tryParseFloat(
    import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE
  ),
  REPLAY_SESSION_ERROR_SAMPLE_RATE: tryParseFloat(
    import.meta.env.VITE_SENTRY_REPLAY_SESSION_ERROR_SAMPLE_RATE
  ),
});

export const MIXPANEL = Object.freeze({
  TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN as string,
  IGNORE_DNT: true,
  DEBUG: ENV.IS_DEVELOPMENT,
});

export const FULLSTORY = Object.freeze({
  ORG_ID: import.meta.env.VITE_FULLSTORY_ORGID as string,
  DEV_MODE: ENV.IS_DEVELOPMENT,
});

export const FLAGSMITH = Object.freeze({
  KEY: import.meta.env.VITE_FLAGSMITH_KEY as string,
});

export const SMARTLOOK = Object.freeze({
  KEY: import.meta.env.VITE_SMARTLOOK_KEY as string,
});

export const ASSET = Object.freeze({
  PDF_WORKER: `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`,
  AUDIO_ALERT:
    "https://res.cloudinary.com/cosiall/video/upload/v1662980429/message-alert_dyh67b.mp3",
});
