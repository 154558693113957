import { LOCALES } from "@/locales";

const PHONE_NUMBER_REGEX = new RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);

const toTitleCase = (value: string) =>
  value.replace(
    /\w\S*/g,
    (x) => x.charAt(0).toUpperCase() + x.substr(1).toLowerCase()
  );

const pluralize = (
  length: number | undefined | null,
  singular: string,
  plural: string
) => {
  return length === 1 ? singular : plural;
};

export const hash = (value: string) => {
  let hash = 0;

  if (value.length === 0) return hash;

  for (let i = 0; i < value.length; i++) {
    const chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash);
};

const formatCurrency = (
  value: number | bigint,
  { locales = LOCALES.EN_US, currency = "USD", maximumFractionDigits = 2 } = {}
) => {
  const formatter = new Intl.NumberFormat(locales, {
    style: "currency",
    currency,
    maximumFractionDigits,
  });

  return formatter.format(value);
};

const toBoolean = (str: string) => {
  if (str?.toLowerCase?.() === "true") {
    return true;
  }

  if (str?.toLowerCase?.() === "false") {
    return false;
  }

  return undefined;
};

export const isPhoneNumber = (value: string) => {
  return PHONE_NUMBER_REGEX.test(value);
};

export const StringUtils = Object.freeze({
  toTitleCase,
  pluralize,
  hash,
  formatCurrency,
  toBoolean,
  isPhoneNumber,
});
