import { AsyncReturnType } from "type-fest";

import { HealthCheckService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryFnData = AsyncReturnType<typeof HealthCheckService.check>;
type Error = unknown;

const queryKey = () => ["app", "health"];

export const useHealth = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    async () => {
      const isHealthy = await HealthCheckService.check();

      if (!isHealthy) {
        throw new Error("Server unhealthy.");
      }

      return isHealthy;
    },
    {
      authenticated: false,
      promptRetryOnError: false,
      ...options,
    }
  );

  return result;
};

useHealth.queryKey = queryKey;
