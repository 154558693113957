import { useCallback, useMemo, useState } from "react";

import {
  ButtonGroup,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { DateTime } from "luxon";

import { MaybePromise } from "@/common";
import { Modal } from "@/components/disclosure";
import { Text } from "@/components/display";
import {
  Button,
  DatePicker,
  TimePicker,
  TimePickerValue,
} from "@/components/form";
import { DateUtils } from "@/utils";

export interface ScheduleMessageModalProps
  extends Omit<ModalProps, "children"> {
  onSchedule: (date: Date) => MaybePromise<void>;
}

export const ScheduleMessageModal = ({
  onSchedule,
  onClose,
  ...props
}: ScheduleMessageModalProps) => {
  const now = DateTime.local();

  const [date, setDate] = useState<Date | undefined>(new Date());
  const [time, setTime] = useState<TimePickerValue>({
    hours: now.plus({ hour: 1 }).hour,
    minutes: now.minute,
  });

  const dateTime = useMemo(() => {
    return !!date
      ? DateTime.fromJSDate(date)
          .set({
            hour: time.hours,
            minute: time.minutes,
            second: 0,
          })
          .toJSDate()
      : null;
  }, [date, time]);

  const [isScheduling, setIsScheduling] = useState(false);

  const handleScheduleClick = useCallback(
    async (dateTime: Date) => {
      try {
        setIsScheduling(true);
        await onSchedule(dateTime!);
        onClose();
      } finally {
        setIsScheduling(false);
      }
    },
    [onClose, onSchedule]
  );

  return (
    <Modal onClose={onClose} {...props}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Schedule message</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex alignItems="center" flexWrap="wrap" gap={2}>
            <DatePicker
              value={date}
              suspendable={false}
              variant="outline"
              mode="single"
              icon="none"
              from={now.startOf("day").toJSDate()}
              isClearable={false}
              onChange={setDate}
            />

            <Text suspendable={false}>at</Text>

            <TimePicker
              variant="outline"
              format="hh:mm"
              value={time}
              onChange={setTime}
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              variant="link"
              isDisabled={isScheduling}
              onClick={() => handleScheduleClick(new Date())}
            >
              Send immediately
            </Button>

            <Button
              isDisabled={!date || isScheduling}
              onClick={() => handleScheduleClick(dateTime!)}
            >
              {!!dateTime
                ? `Send ${DateUtils.toRelativeCalendar(
                    dateTime
                  )} at ${dateTime.getHours()}:${dateTime.getMinutes()}`
                : "Schedule"}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
