import { Box, PopoverContent, PopoverContentProps } from "@chakra-ui/react";

import { WithChildren } from "@/ui/types";

export interface FilterContentProps extends PopoverContentProps, WithChildren {}

export const FilterContent = ({ children, ...props }: FilterContentProps) => {
  return (
    <PopoverContent
      w={{ base: "md", md: "lg" }}
      maxH={72}
      overflowY="scroll"
      {...props}
    >
      <Box>
        <Box px={2}>{children}</Box>
      </Box>
    </PopoverContent>
  );
};
