import { KeyboardEvent, useCallback, useMemo, useState } from "react";

import { Box, Flex } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import produce from "immer";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import qs from "qs";
import { Helmet } from "react-helmet-async";
import { MdAdd } from "react-icons/md";
import { Step } from "react-joyride";
import { useLocation } from "react-router-dom";
import {
  NumberParam,
  createEnumParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import { Paginator, Tour } from "@/components/disclosure";
import {
  ActionTabItem,
  ActionTabs,
  QuerySuspense,
} from "@/components/disclosure";
import { Banner, Heading, Text } from "@/components/display";
import { ToasterManager } from "@/components/feedback";
import { Button, IconButton, SearchInput } from "@/components/form";
import { Link } from "@/components/navigation";
import { KEY_CODE } from "@/dom";
import { useDebouncedState, useServerState } from "@/hooks";
import { useFeature } from "@/modules/flags";
import { useNavigate } from "@/router";
import {
  asCampaignViewModel,
  useCampaigns,
  useDeleteCampaign,
} from "@/services/hooks/campaigns";
import { Campaign, CampaignStatus } from "@/services/types";
import { useMediaQuery } from "@/ui/hooks";
import { executeAfterStateUpdate } from "@/ui/utils";
import { ArrayUtils, StringUtils } from "@/utils";

import { CampaignDrawer } from "./campaign-drawer";
import { CampaignGrid, CampaignGridProps } from "./campaign-grid";
import { DraftCampaign } from "./types";
import { calculateBudget } from "./utils";

const CAMPAIGN_STATUSES = [
  "active",
  "pending",
  "draft",
  "paused",
  "ended",
] as CampaignStatus[];

// TODO: Extract?
const CampaignStatusEnumParam =
  createEnumParam<CampaignStatus>(CAMPAIGN_STATUSES);

export const CampaignScreen = () => {
  const [isMdScreen] = useMediaQuery("md");

  const isCampaignFeatureEnabled = useFeature("campaigns");

  const navigate = useNavigate();
  const location = useLocation();

  const [searchQuery, debouncedSearchQuery, setSearchQuery] =
    useDebouncedState("");

  const [status = "active", setStatus] = useQueryParam(
    "status",
    withDefault(CampaignStatusEnumParam, "active")
  );

  const [selectedCampaignId, setSelectedCampaignId] = useQueryParam(
    "campaign",
    NumberParam
  );

  const [currentPage, setCurrentPage] = useQueryParam(
    "page",
    withDefault(NumberParam, 1)
  );

  const [pageSize] = useState(12);

  const {
    data: { elements: campaigns, pagination: campaignPagination } = {},
    status: campaignsStatus,
    refetch: refetchCampaigns,
    isSkeletonData: isSkeletonCampaigns,
  } = useCampaigns(
    {
      status,
      search: debouncedSearchQuery,
      skip: (currentPage - 1) * pageSize,
      limit: pageSize,
    },
    {
      enabled: status !== "draft",
      enableSkeletonData: true,
      select: (x) => ({
        elements: x.elements.map(asCampaignViewModel),
        pagination: x.pagination,
      }),
    }
  );

  const [draftCampaigns = [], setDraftCampaigns] =
    useServerState<DraftCampaign[]>("draft-campaigns");

  const draftPagination = {
    skip: (currentPage - 1) * pageSize,
    total: draftCampaigns.length,
    limit: 10,
  };

  const preCreationTourSteps = useMemo<Step[]>(
    () => [
      {
        target: `[data-tour-step="campaign-screen__create"]`,
        title: "Hey there 👋",
        content: (
          <Text>
            We're glad to have you on board! <br />
            <br />
            In order to get started you'll first need to create a campaign.{" "}
            <Text as="span" fontWeight="bold">
              Click the highlighted button
            </Text>{" "}
            to continue.
          </Text>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        hideCloseButton: true,
        hideBackButton: true,
      },
    ],
    []
  );

  const preCreationDraftTourSteps = useMemo<Step[]>(
    () => [
      {
        target: `[data-tour-step="campaign-screen__action-tab-draft"]`,
        title: " Let's continue where you left off",
        content: (
          <Text>
            It seems you've started setting up a campaign but haven't published
            it yet.{" "}
            <Text as="span" fontWeight="bold">
              Press the Draft tab
            </Text>{" "}
            to continue.
          </Text>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        hideCloseButton: true,
        hideBackButton: true,
        proceedOnTargetClick: true,
      },
      {
        target: `[data-tour-step="campaign-grid__card"]:nth-child(1)`,
        title: "Here's your draft campaign ⬆️",
        content: (
          <Text>
            <Text as="span" fontWeight="bold">
              Select your campaign
            </Text>{" "}
            to continue your setup.
          </Text>
        ),
        disableBeacon: true,
        spotlightClicks: true,
        hideCloseButton: true,
        hideBackButton: true,
      },
    ],
    []
  );

  const { mutateAsync: deleteCampaign } = useDeleteCampaign({
    onSuccess: () => refetchCampaigns(),
  });

  const totalCampaigns =
    status === "draft" ? draftPagination.total : campaignPagination?.total ?? 0;

  const selectedCampaign = useMemo(
    () => campaigns?.find((x) => x.id === selectedCampaignId),
    [campaigns, selectedCampaignId]
  );

  const handleSearchChange = useCallback(
    (value: string) => setSearchQuery(value),
    [setSearchQuery]
  );

  const handleSidebarClose = useCallback(() => {
    setSelectedCampaignId(undefined);
  }, [setSelectedCampaignId]);

  const handleRootKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === KEY_CODE.ESCAPE) {
        handleSidebarClose();
      }
    },
    [handleSidebarClose]
  );

  const handleCampaignSelect = useCallback(
    (campaign: Campaign) => {
      if (status === "draft") {
        navigate({
          pathname: "/campaigns/create",
          search: qs.stringify({ cid: campaign.id }),
        });
      } else {
        setSelectedCampaignId(campaign.id);
      }
    },
    [status, navigate, setSelectedCampaignId]
  );

  const handleCampaignClone: NonNullable<CampaignGridProps["onClone"]> =
    useCallback(
      async (campaign) => {
        const draftCampaignId = nanoid();

        setDraftCampaigns((prev) => [
          {
            id: draftCampaignId,
            type: campaign.type,
            status: "draft",
            title: `Clone of "${
              campaign.title
            }" (${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)})`,
            regions: campaign.regions ?? [],
            industries: campaign.industries ?? [],
            languages: campaign.languages ?? [],
            keywords: campaign.keywords ?? "",
            occupations: campaign.occupations ?? [],
            functions: campaign.functions ?? [],
            seniorities: campaign.seniorities ?? [],
            experiences: campaign.experiences ?? [],
            companies: campaign.companies ?? [],
            headcounts: campaign.headcounts ?? [],
            customAudienceList: campaign.customAudienceList ?? null,
            sequence: campaign.sequence,
            budget: calculateBudget(campaign.reachAmount),
            recurring: campaign.recurring,
            sendFollowUpAfterInitialMessageReply:
              campaign.sendFollowUpAfterInitialMessageReply,
            startDate: campaign.startDate ?? DateTime.now().toISODate(),
          },
          ...prev,
        ]);

        executeAfterStateUpdate(() => {
          navigate({
            pathname: "/campaigns/create",
            search: qs.stringify({ cid: draftCampaignId.toString() }),
          });
        });
      },
      [navigate, setDraftCampaigns]
    );

  const handleCampaignDelete = useCallback(
    async (campaign: Campaign) => {
      if (status === "draft") {
        setDraftCampaigns((prev) =>
          produce(prev, (draftState) => {
            ArrayUtils.spliceWhere(
              draftState,
              (x) => x.id === campaign.id.toString()
            );
          })
        );
      } else {
        await deleteCampaign({ id: campaign.id });

        ToasterManager.success({
          title: (
            <span>
              The campaign <strong>{campaign.title}</strong> was deleted
              successfully.
            </span>
          ),
          options: { id: `campaign-${campaign.title}-delete-success-id` },
        });
      }
    },
    [deleteCampaign, status, setDraftCampaigns]
  );

  return (
    <>
      <Helmet>
        <title>Campaigns - Cosiall</title>
      </Helmet>

      <Tour
        name="campaignWizard"
        steps={
          !!draftCampaigns?.length
            ? preCreationDraftTourSteps
            : preCreationTourSteps
        }
        preliminary={true}
        continuous={false}
        restartOnReload
      />

      <Box onKeyDown={handleRootKeyDown}>
        {!isCampaignFeatureEnabled && (
          <Banner id="campaigns-disabled">
            New campaigns are temporarily disabled. You can contact us at{" "}
            <b>support@cosiall.com</b> for additional information.
          </Banner>
        )}

        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: 2, lg: 4 }}
          mb={{ base: 2, lg: 4 }}
          p="2px"
        >
          <Heading
            as="h1"
            size="lg"
            display={{ base: "none", md: "unset" }}
            suspendable={false}
          >
            Campaigns
          </Heading>

          <Flex flex="1" gap={1} w={{ base: "full", lg: "unset" }}>
            <SearchInput
              value={searchQuery}
              size="sm"
              placeholder={`Search ${status} campaigns`}
              flex="2 1 auto"
              maxW={{ base: "unset", md: "40ch" }}
              onChange={handleSearchChange}
            />

            {isCampaignFeatureEnabled && (
              <>
                {isMdScreen ? (
                  <Button
                    as={Link}
                    to={{
                      pathname: `${location.pathname}/create`,
                      search: qs.stringify({ cid: nanoid() }),
                    }}
                    colorScheme="primary"
                    data-tour-step="campaign-screen__create"
                  >
                    Create Campaign
                  </Button>
                ) : (
                  <IconButton
                    as={Link}
                    icon={<Icon as={MdAdd} />}
                    to={{
                      pathname: `${location.pathname}/create`,
                      search: qs.stringify({ cid: nanoid() }),
                    }}
                    colorScheme="primary"
                    data-tour-step="campaign-screen__create"
                    aria-label="Create campaign"
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>

        <ActionTabs>
          {CAMPAIGN_STATUSES.map((tab) => (
            <ActionTabItem
              key={tab}
              tabKey={tab}
              active={tab === status}
              data-tour-step={`campaign-screen__action-tab-${tab}`}
              onSelect={(x) => {
                setStatus(x as CampaignStatus);
                setTimeout(() => {
                  setCurrentPage(1);
                });
              }}
            >
              {tab}
            </ActionTabItem>
          ))}
        </ActionTabs>

        <Box as="section" position="relative" my={6}>
          <QuerySuspense
            status={status === "draft" ? "success" : campaignsStatus}
          >
            <Box
              as="span"
              display="inline-block"
              ml={1}
              mb={2}
              fontSize="sm"
              color="gray.500"
            >
              <Text
                as="span"
                display="inline-block"
                mr="0.5ch"
                fontWeight="bold"
              >
                {totalCampaigns ?? 0}
              </Text>

              <Text as="span" display="inline-block">
                {StringUtils.pluralize(
                  campaigns?.length ?? 0,
                  "campaign",
                  "campaigns"
                )}
              </Text>
            </Box>

            <CampaignGrid
              campaigns={
                status === "draft"
                  ? (draftCampaigns as unknown as Campaign[])
                  : campaigns
              }
              onSelect={handleCampaignSelect}
              onClone={handleCampaignClone}
              onRemove={handleCampaignDelete}
            />
          </QuerySuspense>

          {!isSkeletonCampaigns && !!totalCampaigns && status !== "draft" && (
            <Paginator
              key={status}
              totalCount={totalCampaigns}
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={setCurrentPage}
              mt={8}
              mx="auto"
            />
          )}
        </Box>

        {selectedCampaign && (
          <CampaignDrawer
            campaign={selectedCampaign}
            isOpen={!isNil(selectedCampaign)}
            onClose={handleSidebarClose}
          />
        )}
      </Box>
    </>
  );
};
