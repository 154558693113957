import { ReactElement } from "react";

import {
  Box,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import { DateRange } from "react-day-picker";
import { MdOutlineCalendarMonth } from "react-icons/md";

import { Calendar } from "@/components/display";
import { Button, ButtonProps } from "@/components/form";
import { DateUtils } from "@/utils";

export type DatePickerProps = {
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  label?: "full" | "relative";
  from?: Date;
  to?: Date;
  icon?: ReactElement | "none";
  suspendable?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  withPresets?: boolean;
} & (SingleDatePickerProps | RangeDatePickerProps);

export const DatePicker = (props: DatePickerProps) => {
  const {
    size,
    variant,
    label = "full",
    from,
    to,
    icon = <Icon as={MdOutlineCalendarMonth} />,
    suspendable,
    isRequired = false,
    isClearable = true,
    withPresets = false,
  } = props;

  const hasSelection = isSingleDatePicker(props)
    ? !!props.value
    : !!props.value?.from || !!props.value?.to;

  const today = DateTime.local();

  return (
    <Popover placement="bottom-start" closeOnBlur>
      <PopoverTrigger>
        <Button
          suspendable={suspendable}
          size={size}
          variant={variant}
          rightIcon={icon === "none" ? undefined : icon}
        >
          {!hasSelection || isNil(props.value) ? (
            "Select a date"
          ) : (
            <>
              {isSingleDatePicker(props) && (
                <>
                  {label === "full"
                    ? DateUtils.toFull(props.value)
                    : DateUtils.toRelative(props.value)}
                </>
              )}

              {isRangeDatePicker(props) &&
                [props.value?.from, props.value?.to]
                  .filter((x) => !isNil(x))
                  .map((x) =>
                    label === "full"
                      ? DateUtils.toFull(x!)
                      : DateUtils.toRelative(x!)
                  )
                  .join(" - ")}
            </>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent w="fit-content" p={2}>
        <PopoverBody p={0}>
          {withPresets && (
            <Wrap spacing={1} mb={2}>
              <WrapItem>
                <Button
                  size="xs"
                  onClick={() => {
                    if (isSingleDatePicker(props)) {
                      props.onChange(today.startOf("day").toJSDate());
                    }

                    if (isRangeDatePicker(props)) {
                      props.onChange({
                        from: today.startOf("day").toJSDate(),
                        to: today.endOf("day").toJSDate(),
                      });
                    }
                  }}
                >
                  Today
                </Button>
              </WrapItem>

              <WrapItem>
                <Button
                  size="xs"
                  onClick={() => {
                    if (isSingleDatePicker(props)) {
                      props.onChange(today.startOf("month").toJSDate());
                    }

                    if (isRangeDatePicker(props)) {
                      props.onChange({
                        from: today.startOf("month").toJSDate(),
                        to: today.endOf("month").toJSDate(),
                      });
                    }
                  }}
                >
                  This month
                </Button>
              </WrapItem>

              <WrapItem>
                <Button
                  size="xs"
                  onClick={() => {
                    if (isSingleDatePicker(props)) {
                      props.onChange(
                        today.minus({ months: 1 }).startOf("month").toJSDate()
                      );
                    }

                    if (isRangeDatePicker(props)) {
                      props.onChange({
                        from: today
                          .minus({ months: 1 })
                          .startOf("month")
                          .toJSDate(),
                        to: today
                          .minus({ months: 1 })
                          .endOf("month")
                          .toJSDate(),
                      });
                    }
                  }}
                >
                  Last month
                </Button>
              </WrapItem>
            </Wrap>
          )}

          {isSingleDatePicker(props) && (
            <Calendar
              mode={props.mode}
              required={isRequired}
              disabled={{ before: from!, after: to! }}
              selected={props.value}
              onSelect={(value: unknown) => {
                props.onChange(value as Date);
              }}
            />
          )}

          {isRangeDatePicker(props) && (
            <Calendar
              mode={props.mode}
              required={isRequired}
              disabled={{ before: from!, after: to! }}
              min={props.min}
              max={props.max}
              selected={props.value}
              onSelect={(value: unknown) => {
                props.onChange(value as DateRange);
              }}
            />
          )}

          {isClearable && (
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                variant="link"
                size="xs"
                isDisabled={!hasSelection}
                onClick={() => {
                  if (isSingleDatePicker(props)) {
                    props.onChange(undefined);
                  }

                  if (isRangeDatePicker(props)) {
                    props.onChange({
                      from: undefined,
                      to: undefined,
                    });
                  }
                }}
              >
                Clear selection
              </Button>
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

type SingleDatePickerProps = {
  mode?: "single";
  value?: Date;
  onChange: (value?: Date) => void;
};

type RangeDatePickerProps = {
  mode?: "range";
  value?: DateRange;
  min?: number;
  max?: number;
  onChange: (value?: DateRange) => void;
};

const isSingleDatePicker = (
  props: DatePickerProps
): props is SingleDatePickerProps => props.mode === "single";

const isRangeDatePicker = (
  props: DatePickerProps
): props is RangeDatePickerProps => props.mode === "range";
