import { Box } from "@chakra-ui/react";

export interface FormSectionIndicatorProps {
  status: "incomplete" | "active";
}

export const FormSectionIndicator = ({ status }: FormSectionIndicatorProps) => {
  const currentColor = status === "active" ? "blue.400" : "gray.200";

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      w={5}
      h={5}
      color={currentColor}
      border="4px solid"
      borderColor="currentColor"
      borderRadius="50%"
      transitionProperty="common"
      transitionDuration="normal"
    >
      <Box w="50%" h="50%" background="currentColor" borderRadius="inherit" />
    </Box>
  );
};
