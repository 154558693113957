import { createContext, useCallback, useContext } from "react";

import { isNil } from "lodash";

import { ToasterManager } from "@/components/feedback";
import { ASSET } from "@/config";
import { useNotifications } from "@/services/hooks/notifications";
import { Notification } from "@/services/types";
import { useMediaQuery } from "@/ui/hooks";
import { WithOptionalChildren } from "@/ui/types";
import { DateUtils } from "@/utils";

import { usePushNotifications } from "./use-push-notifications";
import { parseNotification } from "./utils";

const NotificationContext = createContext({});

const audioAlert = new Audio(ASSET.AUDIO_ALERT);

export interface NotificationProvidersProps extends WithOptionalChildren {}

export const NotificationProvider = ({
  children,
}: NotificationProvidersProps) => {
  const [isMdScreen] = useMediaQuery("md");

  usePushNotifications();

  const handleReceiveNotification = useCallback(
    async (notification: Notification) => {
      const parsedNotification = parseNotification(notification);

      const notificationReceivedEvent = new CustomEvent(
        "onNotificationReceived",
        {
          detail: { notification: parsedNotification },
        }
      );

      document.dispatchEvent(notificationReceivedEvent);

      const {
        id,
        title,
        link,
        date,
        message,
        audible = false,
      } = parsedNotification as any;

      const isRecent = isNil(date)
        ? false
        : DateUtils.parse(date)!.diffNow().as("seconds") >= -120;

      if (isRecent) {
        if (audible) {
          try {
            await audioAlert.play();
          } catch {
            // Do nothing on "Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first."
          }
        }

        if (isMdScreen) {
          ToasterManager.notification({ id, title, message, link, date });
        }
      }
    },
    [isMdScreen]
  );

  const notificationsResult = useNotifications({
    onReceiveNotification: handleReceiveNotification,
  });

  return (
    <NotificationContext.Provider value={notificationsResult}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw Error(
      `"useNotificationContext" must used within "${NotificationProvider.name}".`
    );
  }

  return context;
};
