import { AsyncReturnType } from "type-fest";

import { ConversationService } from "@/services";
import { UseMutationSimpleOptions, useMutation } from "@/services/hooks";

type Variables = Parameters<
  typeof ConversationService.updateScheduledMessage
>[0];
type Data = AsyncReturnType<typeof ConversationService.updateScheduledMessage>;
type Error = unknown;

export const useUpdateScheduledMessage = <TContext = unknown>(
  options?: UseMutationSimpleOptions<Data, Error, Variables, TContext>
) => {
  const result = useMutation<Data, Error, Variables, TContext>(
    ConversationService.updateScheduledMessage,
    options
  );

  return result;
};
