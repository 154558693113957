import { useCallback, useState } from "react";

import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { MdMoreVert, MdOutlineTableView } from "react-icons/md";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";

import { Paginator } from "@/components/disclosure";
import {
  ActionTabItem,
  ActionTabs,
  QuerySuspense,
} from "@/components/disclosure";
import { Heading, Text } from "@/components/display";
import { IconButton, SearchInput } from "@/components/form";
import { useDebouncedState } from "@/hooks";
import { CampaignFilterPopover } from "@/partials/shared";
import { RequiredNumericArrayParam } from "@/router/params";
import { useContacts, useUpdateContact } from "@/services/hooks/crm";
import { Contact } from "@/services/types";
import { StringUtils } from "@/utils";

import { ContactExportModal } from "./contact-export-modal";
import { ContactRow, ContactRowProps, ContactTable } from "./contact-table";

export const ContactListScreen = () => {
  const [campaignIds = [], setCampaignIds] = useQueryParam(
    "campaign",
    withDefault(RequiredNumericArrayParam, [])
  );

  const [currentPage = 1, setCurrentPage] = useQueryParam(
    "page",
    withDefault(NumberParam, 1)
  );

  const [pageSize] = useState(10);

  const [searchQuery, debouncedSearchQuery, setSearchQuery] =
    useDebouncedState("");

  const {
    isOpen: isContactExportModalOpen,
    onOpen: openContactExportModal,
    onClose: closeContactExportModal,
  } = useDisclosure();

  const { refetch: fetchContactsToExport } = useContacts(
    {
      campaignIds,
      search: debouncedSearchQuery,
      skip: 0,
      limit: 10 ** 6,
    },
    {
      enabled: false,
    }
  );

  const {
    data: { elements: contacts, pagination: contactsPagination } = {},
    status: contactsStatus,
    refetch: refetchContacts,
    isSkeletonData: isSkeletonContacts,
  } = useContacts(
    {
      campaignIds,
      search: debouncedSearchQuery,
      skip: (currentPage - 1) * pageSize,
      limit: pageSize,
    },
    {
      enableSkeletonData: true,
    }
  );

  const { mutateAsync: updateContact } = useUpdateContact({
    onSuccess: () => refetchContacts(),
  });

  const handlePropertyChange = useCallback(
    async (
      contact: Contact,
      propertyName: Parameters<ContactRowProps["onPropertyChange"]>[0],
      value: Parameters<ContactRowProps["onPropertyChange"]>[1]
    ) => {
      await updateContact({
        id: contact.id,
        contact: { ...contact, [propertyName]: value },
      });
    },
    [updateContact]
  );

  return (
    <>
      <Helmet>
        <title>Contacts - Cosiall</title>
      </Helmet>

      <ContactExportModal
        isOpen={isContactExportModalOpen}
        onPrepare={async () => {
          const { data: { elements: contactsToExport = [] } = {} } =
            await fetchContactsToExport();

          return contactsToExport;
        }}
        onClose={closeContactExportModal}
      />

      <Box>
        <Stack
          w="full"
          direction={{ base: "column", md: "row" }}
          alignItems="flex-start"
          spacing={{ base: 2, lg: 4 }}
          mb={{ base: 2, lg: 4 }}
          p="2px"
        >
          <Heading
            as="h1"
            size="lg"
            display={{ base: "none", md: "unset" }}
            suspendable={false}
          >
            Contacts
          </Heading>

          <Flex flex="1" gap={1} w={{ base: "full", lg: "unset" }}>
            <SearchInput
              value={searchQuery}
              size="sm"
              placeholder="Search contacts"
              flex="2 1 auto"
              maxW={{ base: "unset", md: "40ch" }}
              onChange={(value) => setSearchQuery(value)}
            />

            <CampaignFilterPopover
              value={campaignIds.map((x) => x.toString())}
              onChange={(values) =>
                setCampaignIds(values.map((x) => parseInt(x)))
              }
            />
          </Flex>
        </Stack>

        <ActionTabs>
          <ActionTabItem active tabKey="all" onSelect={() => {}}>
            All
          </ActionTabItem>
        </ActionTabs>

        <Box as="section" my={6}>
          <QuerySuspense status={contactsStatus}>
            <Flex justifyContent="space-between" mb={2} pl={1}>
              <Box
                as="span"
                display="inline-block"
                fontSize="sm"
                color="gray.500"
              >
                <Text
                  as="span"
                  display="inline-block"
                  mr="0.5ch"
                  fontWeight="bold"
                >
                  {contactsPagination?.total ?? 0}
                </Text>

                <Text as="span" display="inline-block">
                  {StringUtils.pluralize(
                    contactsPagination?.total,
                    "contact",
                    "contacts"
                  )}
                </Text>
              </Box>

              {0 < (contactsPagination?.total ?? 0) && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    size="xs"
                    icon={<Icon as={MdMoreVert} />}
                    ml="auto"
                  />

                  <MenuList fontSize="sm">
                    <MenuItem
                      icon={<Icon as={MdOutlineTableView} display="flex" />}
                      onClick={openContactExportModal}
                    >
                      Export
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>

            {!!contacts?.length && (
              <ContactTable>
                {contacts.map((contact) => (
                  <ContactRow
                    key={contact.id}
                    contact={contact}
                    onPropertyChange={(propertyName, value) =>
                      handlePropertyChange(contact, propertyName, value)
                    }
                  />
                ))}
              </ContactTable>
            )}
          </QuerySuspense>

          {!isSkeletonContacts && !!contactsPagination?.total && (
            <Paginator
              totalCount={contactsPagination.total}
              currentPage={currentPage}
              pageSize={pageSize}
              mt={8}
              mx="auto"
              onPageChange={(page) => {
                setCurrentPage(page);
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
