import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
} from "@chakra-ui/react";

export interface FormControlProps extends ChakraFormControlProps {
  errors?: string[];
}

export const FormControl = ({
  errors,
  isInvalid,
  children,
  ...props
}: FormControlProps) => {
  return (
    <ChakraFormControl isInvalid={isInvalid || !!errors?.length} {...props}>
      {children}

      {errors?.map((error, index) =>
        !!error ? (
          <FormErrorMessage key={index}>{error}</FormErrorMessage>
        ) : (
          <></>
        )
      )}
    </ChakraFormControl>
  );
};
