import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { TypeaheadService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryFnData = AsyncReturnType<typeof TypeaheadService.getExperiences>;
type Error = unknown;

const queryKey = () => ["typeahead", "experiences"];

export const useExperiences = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const result = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    () => TypeaheadService.getExperiences(),
    {
      skeletonData: useConst({
        elements: repeat(gen.typeahead),
        pagination: {
          skip: 0,
          limit: 10,
          total: 2,
        },
      }),
      ...options,
    }
  );

  return result;
};

useExperiences.queryKey = queryKey;
