import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";

import { FullPageSpinner } from "@/components/feedback";
import { AuthService } from "@/services";
import { useQuery, useQueryClient } from "@/services/hooks";
import { useAccount } from "@/services/hooks/account";

export const CallbackScreen = () => {
  const queryClient = useQueryClient();

  const { isFetched } = useQuery(
    ["app", "login-cb"],
    async () => {
      await AuthService.loginCallback(window.location.href);
      await queryClient.invalidateQueries(useAccount.queryKey());

      return true;
    },
    {
      authenticated: false,
    }
  );

  return (
    <>
      <Helmet>
        <title>Cosiall</title>
      </Helmet>

      {isFetched ? <Navigate to="/" replace /> : <FullPageSpinner />}
    </>
  );
};
