import { forwardRef } from "react";

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { MdClear, MdSearch } from "react-icons/md";

import { ControlledFieldProps, IconButton } from "@/components/form";

export interface SearchInputProps
  extends Omit<ChakraInputProps, keyof ControlledFieldProps>,
    ControlledFieldProps<ChakraInputProps["value"], (value: string) => void> {}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, placeholder, onChange, ...props }: SearchInputProps, ref) => {
    return (
      <InputGroup {...props}>
        <InputLeftElement pointerEvents="none">
          <Icon as={MdSearch} />
        </InputLeftElement>

        <ChakraInput
          ref={ref}
          value={value}
          placeholder={placeholder}
          borderRadius="md"
          onChange={(ev) => {
            onChange?.(ev.target.value);
          }}
        />

        {!isEmpty(value) && (
          <InputRightElement>
            <IconButton
              type="button"
              variant="ghost"
              size="xs"
              icon={<Icon as={MdClear} />}
              aria-label="Clear"
              onClick={(ev) => {
                ev.preventDefault();
                onChange?.("");
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);

SearchInput.displayName = "SearchInput";
